

// v9.4 on click open full vid and stop auto play on hover in the thumbnail, add category base on folder on minio, fix the backend too, thumbnail does not work on phone



// ok v9.3 fix padding and auto submit navigation
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import S3VideoPlayer from './S3VideoPlayer';
import config from '../config';

const MinioFileList = () => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
    const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails
    const [pageInput, setPageInput] = useState(page); // State for the page input field

    const baseUrl = `${config.getAllURL}media/files?page=${page}`;
    const objURL = 'https://demo.comtruaduytan.com/all-media/';

    useEffect(() => {
        const fetchFiles = async () => {
            setLoading(true);
            try {
                const response = await fetch(baseUrl);
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                const data = await response.json();
                // console.log('API Response:', data);

                if (Array.isArray(data.files)) {
                    setFiles(data.files);
                    setTotalPages(data.totalPages || 1);

                    // Generate thumbnails for all video files
                    const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
                    videoFiles.forEach(file => {
                        generateThumbnail(`${objURL}${file}`).then(thumbnail => {
                            setThumbnails(prevThumbnails => ({
                                ...prevThumbnails,
                                [file]: thumbnail
                            }));
                        }).catch(err => {
                            console.error(`Error generating thumbnail for ${file}:`, err);
                        });
                    });
                } else {
                    throw new Error('Unexpected response format');
                }
                setLoading(false);
            } catch (err) {
                console.error('Fetch error:', err);
                setError(err.message || 'Unknown error');
                setLoading(false);
            }
        };

        fetchFiles();
    }, [page]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleVideoClick = (file) => {
        setSelectedVideo(file); // Open the video popup
    };

    const handleClosePopup = () => {
        setSelectedVideo(null); // Close the video popup
    };

    const handleMouseEnter = (event) => {
        try {
            const video = event.currentTarget.querySelector('video');
            if (video) {
                // Prevent video autoplay; only trigger play after user interaction
                // video.play(); // Ensure the video plays after user interaction (click or hover)
            }
        } catch (error) {
            console.error('Error in mouse enter:', error);
        }
    };

    const handleMouseLeave = (event) => {
        try {
            const video = event.currentTarget.querySelector('video');
            if (video) {
                video.pause();
            }
        } catch (error) {
            console.error('Error in mouse leave:', error);
        }
    };

    // Generate thumbnail with fallback for CORS issue
    const generateThumbnail = (videoUrl) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            video.src = videoUrl;
            video.currentTime = 5; // Seek to 5 seconds

            video.addEventListener('seeked', () => {
                // Ensure video is from the same origin or fallback
                try {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    resolve(canvas.toDataURL()); // Return the image as a data URL
                } catch (e) {
                    reject('Error generating thumbnail: CORS issue');
                }
            });

            video.addEventListener('error', reject);
        });
    };

    const handlePageInputChange = (e) => {
        // Set the value of the input field
        setPageInput(Number(e.target.value));
    };

    const handleGoButtonClick = () => {
        // Ensure the input is a valid number and within range
        if (pageInput >= 1 && pageInput <= totalPages) {
            setPage(pageInput);
        } else {
            alert(`Please enter a valid page number between 1 and ${totalPages}`);
        }
    };

    if (loading) return <p>Loading...</p>;

    // Render the error properly as a string or JSX element
    if (error) return <p>Error: {String(error)}</p>;

    return (
        <div>
            <h1>Files</h1>

            {/* Display files in a grid layout */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)', // 3 items per row
                    gap: '0', // No space between items
                    margin: '0', // Remove margin around the grid
                    padding: '0', // Remove padding from the container
                }}
            >
                {files.length > 0 ? (
                    files.map((file, index) => {
                        const fileUrl = `${objURL}${file}`;
                        const fileExtension = file.split('.').pop().toLowerCase();
                        const isVideo = ['mp4', 'webm'].includes(fileExtension);
                        const thumbnail = thumbnails[file];

                        return (
                            <div
                                key={index}
                                style={{
                                    background: '#fff',
                                    border: '1px solid #ddd',
                                    borderRadius: '0', // Removed border-radius to make it sharp
                                    boxShadow: 'none', // Removed shadow to make it flat
                                    position: 'relative',
                                    cursor: 'pointer',
                                    overflow: 'hidden', // Hide overflow
                                }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {isVideo ? (
                                    <div>
                                        {/* Show thumbnail or video player */}
                                        {thumbnail ? (
                                            <img
                                                src={thumbnail}
                                                alt={`Thumbnail ${index}`}
                                                style={{
                                                    width: '100%', // Make the image take up the full width of the container
                                                    height: 'auto', // Maintain the aspect ratio
                                                    objectFit: 'contain', // Prevent the image from being stretched
                                                }}
                                                onClick={() => handleVideoClick(file)}
                                            />
                                        ) : (
                                            <S3VideoPlayer
                                                style={{ width: '100%' }}
                                                controls
                                                videoUrl={fileUrl}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <p>Unsupported file type: {file}</p>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <p>No files found.</p>
                )}
            </div>

            {/* Pagination controls */}
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                    style={{ padding: '10px 20px', marginRight: '10px' }}
                >
                    Previous
                </button>
                <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
                <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                    style={{ padding: '10px 20px', marginLeft: '10px' }}
                >
                    Next
                </button>
                {/* Input for specific page */}
                <div style={{ marginTop: '10px' }}>
                    <label htmlFor="page-input">Go to page: </label>
                    <input
                        id="page-input"
                        type="number"
                        min="1"
                        max={totalPages}
                        value={pageInput}
                        onChange={handlePageInputChange}
                        style={{
                            marginLeft: '10px',
                            padding: '5px',
                            width: '60px',
                            borderRadius: '5px',
                            border: '1px solid #ddd',
                        }}
                    />
                    <button
                        onClick={handleGoButtonClick}
                        style={{
                            marginLeft: '10px',
                            padding: '5px 10px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                        }}
                    >
                        Go
                    </button>
                </div>
            </div>

            {/* Video Popup */}
            {selectedVideo && (
                ReactDOM.createPortal(
                    <div
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            padding: '20px',
                            zIndex: 1000,
                            width: '80%',
                            maxWidth: '800px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <button
                            onClick={handleClosePopup}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                background: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                padding: '10px',
                                zIndex: 1001
                            }}
                        >
                            Close
                        </button>
                        <video
                            controls
                            style={{ width: '100%' }}
                            src={`${objURL}${selectedVideo}`}
                        >
                            Your browser does not support the video tag.
                        </video>
                    </div>,
                    document.body
                )
            )}
        </div>
    );
};

export default MinioFileList;


// ok v9.2 make 3 items per row
// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
// import S3VideoPlayer from './S3VideoPlayer';
// import config from '../config';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = `${config.getAllURL}media/files?page=${page}`;
//     const objURL = 'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(baseUrl);
//                 if (!response.ok) {
//                     throw new Error(`Network response was not ok: ${response.statusText}`);
//                 }
//                 const data = await response.json();
//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${objURL}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         }).catch(err => {
//                             console.error(`Error generating thumbnail for ${file}:`, err);
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 console.error('Fetch error:', err);
//                 setError(err.message || 'Unknown error');
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         try {
//             const video = event.currentTarget.querySelector('video');
//             if (video) {
//                 // Prevent video autoplay; only trigger play after user interaction
//                 // video.play(); // Ensure the video plays after user interaction (click or hover)
//             }
//         } catch (error) {
//             console.error('Error in mouse enter:', error);
//         }
//     };

//     const handleMouseLeave = (event) => {
//         try {
//             const video = event.currentTarget.querySelector('video');
//             if (video) {
//                 video.pause();
//             }
//         } catch (error) {
//             console.error('Error in mouse leave:', error);
//         }
//     };

//     // Generate thumbnail with fallback for CORS issue
//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 // Ensure video is from the same origin or fallback
//                 try {
//                     canvas.width = video.videoWidth;
//                     canvas.height = video.videoHeight;
//                     context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                     resolve(canvas.toDataURL()); // Return the image as a data URL
//                 } catch (e) {
//                     reject('Error generating thumbnail: CORS issue');
//                 }
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;

//     // Render the error properly as a string or JSX element
//     if (error) return <p>Error: {String(error)}</p>;

//     return (
//         <div>
//             <h1>Files</h1>

//             {/* Display files in a grid layout */}
//             <div
//                 style={{
//                     display: 'grid',
//                     gridTemplateColumns: 'repeat(3, 1fr)', // 3 items per row
//                     gap: '20px', // Space between items
//                     padding: '20px', // Padding for the container
//                 }}
//             >
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${objURL}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{
//                                     background: '#fff',
//                                     border: '1px solid #ddd',
//                                     borderRadius: '8px',
//                                     boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
//                                     padding: '10px',
//                                     position: 'relative',
//                                     cursor: 'pointer',
//                                     overflow: 'hidden', // Hide overflow
//                                 }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         {/* Show thumbnail or video player */}
//                                         {thumbnail ? (
//                                             <img
//                                                 src={thumbnail}
//                                                 alt={`Thumbnail ${index}`}
//                                                 style={{
//                                                     width: '100%', // Make the image take up the full width of the container
//                                                     height: 'auto', // Maintain the aspect ratio
//                                                     borderRadius: '8px', // Round the corners for a nice look
//                                                     objectFit: 'contain', // Prevent the image from being stretched and maintain the original aspect ratio
//                                                 }}
//                                                 onClick={() => handleVideoClick(file)}
//                                             />
//                                         ) : (
//                                             <S3VideoPlayer
//                                                 style={{ width: '100%', borderRadius: '8px' }}
//                                                 controls
//                                                 videoUrl={fileUrl}
//                                             />
//                                         )}
//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>

//             {/* Pagination controls */}
//             <div style={{ marginTop: '20px', textAlign: 'center' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                     style={{ padding: '10px 20px', marginRight: '10px' }}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                     style={{ padding: '10px 20px', marginLeft: '10px' }}
//                 >
//                     Next
//                 </button>
//                 {/* Input for specific page */}
//                 <div style={{ marginTop: '10px' }}>
//                     <label htmlFor="page-input">Go to page: </label>
//                     <input
//                         id="page-input"
//                         type="number"
//                         min="1"
//                         max={totalPages}
//                         value={page}
//                         onChange={(e) => handlePageChange(Number(e.target.value))}
//                         style={{
//                             marginLeft: '10px',
//                             padding: '5px',
//                             width: '60px',
//                             borderRadius: '5px',
//                             border: '1px solid #ddd',
//                         }}
//                     />
//                 </div>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && (
//                 ReactDOM.createPortal(
//                     <div
//                         style={{
//                             position: 'fixed',
//                             top: '50%',
//                             left: '50%',
//                             transform: 'translate(-50%, -50%)',
//                             backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                             padding: '20px',
//                             zIndex: 1000,
//                             width: '80%',
//                             maxWidth: '800px',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             alignItems: 'center',
//                             justifyContent: 'center'
//                         }}
//                     >
//                         <button
//                             onClick={handleClosePopup}
//                             style={{
//                                 position: 'absolute',
//                                 top: '10px',
//                                 right: '10px',
//                                 background: 'white',
//                                 border: 'none',
//                                 borderRadius: '5px',
//                                 padding: '10px',
//                                 zIndex: 1001
//                             }}
//                         >
//                             Close
//                         </button>
//                         <video
//                             controls
//                             style={{ width: '100%' }}
//                             src={`${objURL}${selectedVideo}`}
//                         >
//                             Your browser does not support the video tag.
//                         </video>
//                     </div>,
//                     document.body
//                 )
//             )}
//         </div>
//     );
// };

// export default MinioFileList;



// ok v9.1 add pagenation
// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
// import S3VideoPlayer from './S3VideoPlayer';
// import config from '../config';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = `${config.getAllURL}media/files?page=${page}`;
//     const objURL =  'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(baseUrl);
//                 if (!response.ok) {
//                     throw new Error(`Network response was not ok: ${response.statusText}`);
//                 }
//                 const data = await response.json();
//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${objURL}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         }).catch(err => {
//                             console.error(`Error generating thumbnail for ${file}:`, err);
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 console.error('Fetch error:', err);
//                 setError(err.message || 'Unknown error');
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         try {
//             const video = event.currentTarget.querySelector('video');
//             if (video) {
//                 // Prevent video autoplay; only trigger play after user interaction
//                 // video.play(); // Ensure the video plays after user interaction (click or hover)
//             }
//         } catch (error) {
//             console.error('Error in mouse enter:', error);
//         }
//     };

//     const handleMouseLeave = (event) => {
//         try {
//             const video = event.currentTarget.querySelector('video');
//             if (video) {
//                 video.pause();
//             }
//         } catch (error) {
//             console.error('Error in mouse leave:', error);
//         }
//     };

//     // Generate thumbnail with fallback for CORS issue
//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 // Ensure video is from the same origin or fallback
//                 try {
//                     canvas.width = video.videoWidth;
//                     canvas.height = video.videoHeight;
//                     context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                     resolve(canvas.toDataURL()); // Return the image as a data URL
//                 } catch (e) {
//                     reject('Error generating thumbnail: CORS issue');
//                 }
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;

//     // Render the error properly as a string or JSX element
//     if (error) return <p>Error: {String(error)}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${objURL}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', position: 'relative' }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         {/* Show thumbnail or video player */}
//                                         {thumbnail ? (
//                                             <img
//                                                 src={thumbnail}
//                                                 alt={`Thumbnail ${index}`}
//                                                 style={{ width: '100%', cursor: 'pointer' }}
//                                                 onClick={() => handleVideoClick(file)}
//                                             />
//                                         ) : (
//                                             <S3VideoPlayer
//                                                 style={{ width: '100%' }}
//                                                 controls
//                                                 videoUrl={fileUrl}
//                                             />
//                                         )}
//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && (
//                 ReactDOM.createPortal(
//                     <div
//                         style={{
//                             position: 'fixed',
//                             top: '50%',
//                             left: '50%',
//                             transform: 'translate(-50%, -50%)',
//                             backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                             padding: '20px',
//                             zIndex: 1000,
//                             width: '80%',
//                             maxWidth: '800px',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             alignItems: 'center',
//                             justifyContent: 'center'
//                         }}
//                     >
//                         <button
//                             onClick={handleClosePopup}
//                             style={{
//                                 position: 'absolute',
//                                 top: '10px',
//                                 right: '10px',
//                                 background: 'white',
//                                 border: 'none',
//                                 borderRadius: '5px',
//                                 padding: '10px',
//                                 zIndex: 1001
//                             }}
//                         >
//                             Close
//                         </button>
//                         <video
//                             controls
//                             style={{ width: '100%' }}
//                             src={`${objURL}${selectedVideo}`}
//                         >
//                             Your browser does not support the video tag.
//                         </video>
//                     </div>,
//                     document.body
//                 )
//             )}
//         </div>
//     );
// };

// export default MinioFileList;


// ok v9 make call to express backend
// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
// import S3VideoPlayer from './S3VideoPlayer';
// import config from '../config';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = config.getAllURL + 'media/files';
//     const objURL =  'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(baseUrl);
//                 if (!response.ok) {
//                     throw new Error(`Network response was not ok: ${response.statusText}`);
//                 }
//                 const data = await response.json();
//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${objURL}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         }).catch(err => {
//                             console.error(`Error generating thumbnail for ${file}:`, err);
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 console.error('Fetch error:', err);
//                 setError(err.message || 'Unknown error');
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         try {
//             // Prevent video autoplay; only trigger play after user interaction
//             const video = event.currentTarget.querySelector('video');
//             if (video) {
//                 // video.play(); // Ensure the video plays after user interaction (click or hover)
//             }
//         } catch (error) {
//             console.error('Error in mouse enter:', error);
//         }
//     };

//     const handleMouseLeave = (event) => {
//         try {
//             const video = event.currentTarget.querySelector('video');
//             if (video) {
//                 video.pause();
//             }
//         } catch (error) {
//             console.error('Error in mouse leave:', error);
//         }
//     };

//     // Generate thumbnail with fallback for CORS issue
//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 // Ensure video is from the same origin or fallback
//                 try {
//                     canvas.width = video.videoWidth;
//                     canvas.height = video.videoHeight;
//                     context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                     resolve(canvas.toDataURL()); // Return the image as a data URL
//                 } catch (e) {
//                     reject('Error generating thumbnail: CORS issue');
//                 }
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;

//     // Render the error properly as a string or JSX element
//     if (error) return <p>Error: {String(error)}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${objURL}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', position: 'relative' }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         {/* Show thumbnail or video player */}
//                                         {thumbnail ? (
//                                             <img
//                                                 src={thumbnail}
//                                                 alt={`Thumbnail ${index}`}
//                                                 style={{ width: '100%', cursor: 'pointer' }}
//                                                 onClick={() => handleVideoClick(file)}
//                                             />
//                                         ) : (
//                                             <S3VideoPlayer
//                                                 style={{ width: '100%' }}
//                                                 controls
//                                                 videoUrl={fileUrl}
//                                             />
//                                         )}
//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && (
//                 ReactDOM.createPortal(
//                     <div
//                         style={{
//                             position: 'fixed',
//                             top: '50%',
//                             left: '50%',
//                             transform: 'translate(-50%, -50%)',
//                             backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                             padding: '20px',
//                             zIndex: 1000,
//                             width: '80%',
//                             maxWidth: '800px',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             alignItems: 'center',
//                             justifyContent: 'center'
//                         }}
//                     >
//                         <button
//                             onClick={handleClosePopup}
//                             style={{
//                                 position: 'absolute',
//                                 top: '10px',
//                                 right: '10px',
//                                 background: 'white',
//                                 border: 'none',
//                                 borderRadius: '5px',
//                                 padding: '10px',
//                                 zIndex: 1001
//                             }}
//                         >
//                             Close
//                         </button>
//                         <video
//                             controls
//                             style={{ width: '100%' }}
//                             src={`${objURL}${selectedVideo}`}
//                         >
//                             Your browser does not support the video tag.
//                         </video>
//                     </div>,
//                     document.body
//                 )
//             )}
//         </div>
//     );
// };

// export default MinioFileList;



// faile ~ v8 (backend v4 still cannot check this, so improve map first and auth later) try to add auth to request to back end, clone from v6
// import React, { useEffect, useState, useRef } from 'react';
// import ReactDOM from 'react-dom';
// import S3VideoPlayer from './S3VideoPlayer';
// import './MinioFileList.css'; // Import the CSS file

// import config from '../config';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = config.getAllURL +'media/files';
//     // const baseUrl = 'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 // Retrieve the token from localStorage (or other source)
//                 const token = localStorage.getItem('authToken');

//                 const response = await fetch(baseUrl+`?page=${page}&per_page=20`, {
//                     method: 'GET',
//                     headers: {
//                         'Authorization': `Bearer ${token}` // Include the token in the Authorization header
//                     }
//                 });

//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();

//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${baseUrl}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 setError(err);
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.play();
//         }
//     };

//     const handleMouseLeave = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.pause();
//         }
//     };

//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 canvas.width = video.videoWidth;
//                 canvas.height = video.videoHeight;
//                 context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                 resolve(canvas.toDataURL()); // Return the image as a data URL
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;


//     return (
//         <div className="cards container">
//             {/* <h1>Files</h1> */}
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${baseUrl}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 // className={`file-item card col-sm-12 col-md-6 col-lg-4`}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <S3VideoPlayer

//                                         style={{ display: 'none', width: '100%' }}
//                                         controls
//                                         videoUrl={fileUrl}
//                                     />
//                                 ) : (
//                                     <p className="unsupported-file">Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p className="no-files">No files found.</p>
//                 )}

//             <div className="pagination">
//                 <button
//                     className="pagination-button"
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span className="pagination-info">Page {page} of {totalPages}</span>
//                 <button
//                     className="pagination-button"
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && ReactDOM.createPortal(
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                         padding: '20px',
//                         zIndex: 1000,
//                         width: '80%',
//                         maxWidth: '800px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         justifyContent: 'center'
//                     }}
//                 >
//                     <button
//                         onClick={handleClosePopup}
//                         style={{
//                             position: 'absolute',
//                             top: '10px',
//                             right: '10px',
//                             background: 'white',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding: '10px',
//                             zIndex: 1001 // Ensure button is on top
//                         }}
//                     >
//                         Close
//                     </button>
//                     <video
//                         controls
//                         style={{ width: '100%' }}
//                         src={`${baseUrl}${selectedVideo}`}
//                     >
//                         Your browser does not support the video tag.
//                     </video>
//                 </div>,
//                 document.body
//             )}
//         </div>
//     );
// };

// export default MinioFileList;



// failed (has some console log cookie token ) v7 add pagenation, console log user info
// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
// import S3VideoPlayer from './S3VideoPlayer';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails
//     const [userInfo, setUserInfo] = useState(null); // State to hold user info

//     const baseUrl = 'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=${page}&per_page=20`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();

//                 console.log('API Response:', data);

//                 // Log user info
//                 if (data.user_info) {
//                     console.log('User Infoo:', data.user_info);
//                     setUserInfo(data.user_info);
//                 }


//                 if (data.auth_cookie) {
//                     console.log('User auth_cookie:', data.auth_cookie);
//                     setUserInfo(data.auth_cookie);
//                 }

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     // Calculate total pages based on the total number of files
//                     setTotalPages(Math.ceil(data.total / 20)); // Adjust 20 to match per_page if needed

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => typeof file === 'string' && (file.endsWith('.mp4') || file.endsWith('.webm')));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${baseUrl}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 setError(err);
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.play();
//         }
//     };

//     const handleMouseLeave = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.pause();
//         }
//     };

//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 canvas.width = video.videoWidth;
//                 canvas.height = video.videoHeight;
//                 context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                 resolve(canvas.toDataURL()); // Return the image as a data URL
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             {/* Display user information */}
//             {userInfo && (
//                 <div>
//                     <h2>User Information</h2>
//                     <p>ID: {userInfo.ID}</p>
//                     <p>Login: {userInfo.user_login}</p>
//                     <p>Email: {userInfo.user_email}</p>
//                     <p>Display Name: {userInfo.display_name}</p>
//                     <p>Roles: {userInfo.roles.join(', ')}</p>
//                 </div>
//             )}
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${baseUrl}${file}`;
//                         const fileExtension = typeof file === 'string' ? file.split('.').pop().toLowerCase() : '';
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', position: 'relative' }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         <img
//                                             src={thumbnail || 'https://via.placeholder.com/150'} // Fallback image
//                                             alt={`Thumbnail ${index}`}
//                                             style={{ width: '100%', cursor: 'pointer' }}
//                                             onClick={() => handleVideoClick(file)}
//                                         />
//                                         <S3VideoPlayer
//                                             style={{ display: 'none', width: '100%' }}
//                                             controls
//                                             videoUrl={fileUrl}
//                                         />
//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 {Array.from({ length: totalPages }, (_, i) => (
//                     <button
//                         key={i + 1}
//                         onClick={() => handlePageChange(i + 1)}
//                         style={{ margin: '0 5px', backgroundColor: page === (i + 1) ? '#ddd' : '#fff' }}
//                     >
//                         {i + 1}
//                     </button>
//                 ))}
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && ReactDOM.createPortal(
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                         padding: '20px',
//                         zIndex: 1000,
//                         width: '80%',
//                         maxWidth: '800px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         justifyContent: 'center'
//                     }}
//                 >
//                     <button
//                         onClick={handleClosePopup}
//                         style={{
//                             position: 'absolute',
//                             top: '10px',
//                             right: '10px',
//                             background: 'white',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding: '10px',
//                             zIndex: 1001 // Ensure button is on top
//                         }}
//                     >
//                         Close
//                     </button>
//                     <video
//                         controls
//                         style={{ width: '100%' }}
//                         src={`${baseUrl}${selectedVideo}`}
//                     >
//                         Your browser does not support the video tag.
//                     </video>
//                 </div>,
//                 document.body
//             )}
//         </div>
//     );
// };

// export default MinioFileList;



// (no scroll, next page does not work) v6 scroll in popup mode, thumb nail, nextpage button
// import React, { useEffect, useState, useRef } from 'react';
// import ReactDOM from 'react-dom';
// import S3VideoPlayer from './S3VideoPlayer';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = 'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=${page}&per_page=20`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();

//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${baseUrl}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 setError(err);
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.play();
//         }
//     };

//     const handleMouseLeave = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.pause();
//         }
//     };

//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 canvas.width = video.videoWidth;
//                 canvas.height = video.videoHeight;
//                 context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                 resolve(canvas.toDataURL()); // Return the image as a data URL
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;


//     const videoUrl = 'https://demo.comtruaduytan.com/all-media/defloration%2Fanna-sanglante-vid_720p.mp4';

//     return (
//         <div>
//             <h1>Filess</h1>
//             {/* <S3VideoPlayer videoUrl={videoUrl} /> */}
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${baseUrl}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', position: 'relative' }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         {/* <img
//                                             src={thumbnail || 'https://via.placeholder.com/150'} // Fallback image
//                                             alt={`Thumbnail ${index}`}
//                                             style={{ width: '100%', cursor: 'pointer' }}
//                                             onClick={() => handleVideoClick(file)}
//                                         /> */}
//                                         {/* <video
//                                             style={{ display: 'none', width: '100%' }}
//                                             controls
//                                             src={fileUrl}
//                                         /> */}
//                                         <S3VideoPlayer
//                                         style={{ display: 'none', width: '100%' }}
//                                         controls
//                                         videoUrl={fileUrl}
//                                         />

//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && ReactDOM.createPortal(
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                         padding: '20px',
//                         zIndex: 1000,
//                         width: '80%',
//                         maxWidth: '800px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         justifyContent: 'center'
//                     }}
//                 >
//                     <button
//                         onClick={handleClosePopup}
//                         style={{
//                             position: 'absolute',
//                             top: '10px',
//                             right: '10px',
//                             background: 'white',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding: '10px',
//                             zIndex: 1001 // Ensure button is on top
//                         }}
//                     >
//                         Close
//                     </button>
//                     <video
//                         controls
//                         style={{ width: '100%' }}
//                         src={`${baseUrl}${selectedVideo}`}
//                     >
//                         Your browser does not support the video tag.
//                     </video>
//                 </div>,
//                 document.body
//             )}
//         </div>
//     );
// };

// export default MinioFileList;



// v5 , close button, ,
// import React, { useEffect, useState, useRef } from 'react';
// import ReactDOM from 'react-dom';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = 'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=${page}&per_page=10`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();

//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${baseUrl}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 setError(err);
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.play();
//         }
//     };

//     const handleMouseLeave = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.pause();
//         }
//     };

//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 canvas.width = video.videoWidth;
//                 canvas.height = video.videoHeight;
//                 context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                 resolve(canvas.toDataURL()); // Return the image as a data URL
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${baseUrl}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', position: 'relative' }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         <img
//                                             src={thumbnail || 'https://via.placeholder.com/150'} // Fallback image
//                                             alt={`Thumbnail ${index}`}
//                                             style={{ width: '100%', cursor: 'pointer' }}
//                                             onClick={() => handleVideoClick(file)}
//                                         />
//                                         <video
//                                             style={{ display: 'none', width: '100%' }}
//                                             controls
//                                             src={fileUrl}
//                                         />
//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && ReactDOM.createPortal(
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                         padding: '20px',
//                         zIndex: 1000,
//                         width: '80%',
//                         maxWidth: '800px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         justifyContent: 'center'
//                     }}
//                 >
//                     <button
//                         onClick={handleClosePopup}
//                         style={{
//                             position: 'absolute',
//                             top: '10px',
//                             right: '10px',
//                             background: 'white',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding: '10px',
//                             zIndex: 1001 // Ensure button is on top
//                         }}
//                     >
//                         Close
//                     </button>
//                     <video
//                         controls
//                         style={{ width: '100%' }}
//                         src={`${baseUrl}${selectedVideo}`}
//                     >
//                         Your browser does not support the video tag.
//                     </video>
//                 </div>,
//                 document.body
//             )}
//         </div>
//     );
// };

// export default MinioFileList;


// OK v4 display the s3 obj -> use vid as thumbnail does not work, yet popup vid does work, close button failed
// import React, { useEffect, useState, useRef } from 'react';
// import ReactDOM from 'react-dom';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = 'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=${page}&per_page=10`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();

//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${baseUrl}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 setError(err);
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.play();
//         }
//     };

//     const handleMouseLeave = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.pause();
//         }
//     };

//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 canvas.width = video.videoWidth;
//                 canvas.height = video.videoHeight;
//                 context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                 resolve(canvas.toDataURL()); // Return the image as a data URL
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${baseUrl}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', position: 'relative' }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         <img
//                                             src={thumbnail || 'https://via.placeholder.com/150'} // Fallback image
//                                             alt={`Thumbnail ${index}`}
//                                             style={{ width: '100%', cursor: 'pointer' }}
//                                             onClick={() => handleVideoClick(file)}
//                                         />
//                                         <video
//                                             style={{ display: 'none', width: '100%' }}
//                                             controls
//                                             src={fileUrl}
//                                         />
//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && ReactDOM.createPortal(
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                         padding: '20px',
//                         zIndex: 1000,
//                         width: '80%',
//                         maxWidth: '800px'
//                     }}
//                 >
//                     <button
//                         onClick={handleClosePopup}
//                         style={{
//                             position: 'absolute',
//                             top: '10px',
//                             right: '10px',
//                             background: 'white',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding: '10px'
//                         }}
//                     >
//                         Close
//                     </button>
//                     <video
//                         controls
//                         style={{ width: '100%' }}
//                         src={`${baseUrl}${selectedVideo}`}
//                     >
//                         Your browser does not support the video tag.
//                     </video>
//                 </div>,
//                 document.body
//             )}
//         </div>
//     );
// };

// export default MinioFileList;


// OK v3 add container and button for pages -> display the link inside containers
// import React, { useEffect, useState } from 'react';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=${page}&per_page=10`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();

//                 // Log data to verify response structure
//                 console.log('API Response:', data);

//                 // Ensure data.files is an array of strings
//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1); // Adjust this if your API provides total pages
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 setError(err);
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => (
//                         <div key={index} style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd' }}>
//                             {/* Display the file link */}
//                             <a href={`https://www.demo.comtruaduytan.com/path/to/files/${file}`} target="_blank" rel="noopener noreferrer">
//                                 {file}
//                             </a>
//                         </div>
//                     ))
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default MinioFileList;




//v2 ok
// import React, { useEffect, useState } from 'react';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         fetch('https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=1&per_page=10')
//             .then(response => {
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 return response.json();
//             })
//             .then(data => {
//                 // Ensure data.files is an array
//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             })
//             .catch(err => {
//                 setError(err);
//                 setLoading(false);
//             });
//     }, []);

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <ul>
//                 {files.length > 0 ? (
//                     files.map((file, index) => (
//                         <li key={index}>{file}</li>
//                     ))
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </ul>
//         </div>
//     );
// };

// export default MinioFileList;



// v1 failed
// import React, { useEffect, useState } from 'react';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         fetch('https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=1&per_page=10')
//             .then(response => response.json())
//             .then(data => {
//                 setFiles(data.files);
//                 setLoading(false);
//             })
//             .catch(err => {
//                 setError(err);
//                 setLoading(false);
//             });
//     }, []);

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <ul>
//                 {files.map((file, index) => (
//                     <li key={index}>{file}</li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default MinioFileList;


