


// v4.4


// ok (still virtical) v4.3 rearrange the icons - > the css in App.styles2.js can fix that but it will affect the img in container, fix this
import React, { useState } from 'react';
import image from '../../images/house-location-pin.svg';
import { Filter } from '../';
import { Link } from 'react-router-dom';
import Login from '../LoginClass';  // Import Login Modal
import { useNavigate } from 'react-router-dom';

export const Header = ({ filterIsVisible, toggleFilter, handleFilterChange, clearFilter }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);  // Manage modal state locally
  const navigate = useNavigate();  // Hook for redirecting to other pages


  // Open and close modal functions
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCrosshairClick = () => {
    navigate('/');
  };

  const handleCompassClick = () => {
    navigate('/explore');  // Redirect to /
  };

  const handleHomeClick = () => {
    // navigate('/');
    navigate('/homepage');
  };
  return (
<header className={`${filterIsVisible ? 'filter-is-visible' : ''}`}>
  <Filter
    toggleFilter={toggleFilter}
    handleFilterChange={handleFilterChange}
    clearFilter={clearFilter}
  />

  {/* Custom SVG Button for Home */}
  <div className="x9f619" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
    <div
      className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p"
      // onClick={(e) => toggleFilter(e)}
      onClick={() => handleHomeClick()}
    >
      <svg
        aria-label="Home"
        className="x1lliihq x1n2onr6 x5n08af"
        fill="currentColor"
        height="24"
        role="img"
        viewBox="0 0 24 24"
        width="24"
      >
        <title>Home</title>
        <path d="M22 23h-6.001a1 1 0 0 1-1-1v-5.455a2.997 2.997 0 1 0-5.993 0V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V11.543a1.002 1.002 0 0 1 .31-.724l10-9.543a1.001 1.001 0 0 1 1.38 0l10 9.543a1.002 1.002 0 0 1 .31.724V22a1 1 0 0 1-1 1Z"></path>
      </svg>
    </div>
  </div>

  {/* Custom SVG Button for Explore */}
  <div className="x9f619" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
    <div
      className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p"
      onClick={() => handleCompassClick()}
    >
      <svg
        aria-label="Explore"
        className="x1lliihq x1n2onr6 x5n08af"
        fill="currentColor"
        height="24"
        role="img"
        viewBox="0 0 24 24"
        width="24"
      >
        <title>Explore</title>
        <polygon
          fill="none"
          points="13.941 13.953 7.581 16.424 10.06 10.056 16.42 7.585 13.941 13.953"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></polygon>
        <polygon
          fillRule="evenodd"
          points="10.06 10.056 13.949 13.945 7.581 16.424 10.06 10.056"
        ></polygon>
        <circle
          cx="12.001"
          cy="12.005"
          fill="none"
          r="10.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></circle>
      </svg>
    </div>
  </div>

  {/* Custom SVG Button for Search */}
  {/* <div className="x9f619" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
    <div
      className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p"
      // onClick={(e) => toggleFilter(e)}
    >
      <svg
        aria-label="Search"
        className="x1lliihq x1n2onr6 x5n08af"
        fill="currentColor"
        height="24"
        role="img"
        viewBox="0 0 24 24"
        width="24"
      >
        <title>Search</title>
        <path
          d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></path>
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          x1="16.511"
          x2="22"
          y1="16.511"
          y2="22"
        ></line>
      </svg>
    </div>
  </div> */}

  {/* Custom Human Icon Button */}
  <div className="x9f619" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
    <div
      className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p"
      onClick={(e) => openModal()}
    >
      <svg
        aria-label="User"
        className="x1lliihq x1n2onr6 x5n08af"
        fill="currentColor"
        height="24"
        role="img"
        viewBox="0 0 24 24"
        width="24"
      >
        <title>User</title>
        <path
          d="M12 2a6 6 0 1 1-6 6 6 6 0 0 1 6-6Zm0 12c-4.29 0-8 2.24-8 5v2h16v-2c0-2.76-3.71-5-8-5Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></path>
      </svg>
    </div>
  </div>

{/* Crosshair Icon with an empty center (more minimal) */}
<div className="x9f619" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
    <div
      className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p"
      onClick={handleCrosshairClick}  // Handle the click event, e.g., redirect to '/current'
    >
      <svg
        aria-label="Target Crosshair"
        className="x1lliihq x1n2onr6 x5n08af"
        fill="none"
        height="24"
        role="img"
        viewBox="0 0 24 24"
        width="24"
      >
        <title>Target Crosshair</title>
        <circle cx="12" cy="12" r="3" fill="black" />
        <circle cx="12" cy="12" r="8" stroke="black" strokeWidth="2" fill="none" />
        <rect x="11" y="1" width="2" height="3" fill="black" />
        <rect x="11" y="20" width="2" height="3" fill="black" />
        <rect x="1" y="11" width="3" height="2" fill="black" />
        <rect x="20" y="11" width="3" height="2" fill="black" />
      </svg>
    </div>
</div>


  {/* Render Login Modal */}
  <Login isModalOpen={isModalOpen} closeModal={closeModal} />
</header>

  );
};

export default Header;







// ok v4.2 fix 4.1
// import React, { useState } from 'react';
// import image from '../../images/house-location-pin.svg';
// import { Filter } from '../';
// import { Link } from 'react-router-dom';
// import Login from '../LoginClass';  // Import Login Modal
// import { useNavigate } from 'react-router-dom';

// export const Header = ({ filterIsVisible, toggleFilter, handleFilterChange, clearFilter }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);  // Manage modal state locally
//   const navigate = useNavigate();  // Hook for redirecting to other pages


//   // Open and close modal functions
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   const handleCrosshairClick = () => {
//     navigate('/current');  // Redirect to /current
//   };


//   return (
//     <header className={`${filterIsVisible ? 'filter-is-visible' : ''}`}>
//       <Filter
//         toggleFilter={toggleFilter}
//         handleFilterChange={handleFilterChange}
//         clearFilter={clearFilter}
//       />

//       {/* <img src={image} alt="Property Listings" /> */}
//       <h1> </h1>

//       {/* Custom SVG Button for Home */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Home"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Home</title>
//             <path d="M22 23h-6.001a1 1 0 0 1-1-1v-5.455a2.997 2.997 0 1 0-5.993 0V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V11.543a1.002 1.002 0 0 1 .31-.724l10-9.543a1.001 1.001 0 0 1 1.38 0l10 9.543a1.002 1.002 0 0 1 .31.724V22a1 1 0 0 1-1 1Z"></path>
//           </svg>
//         </div>
//       </div>

//       {/* Custom SVG Button for Explore */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Explore"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Explore</title>
//             <polygon
//               fill="none"
//               points="13.941 13.953 7.581 16.424 10.06 10.056 16.42 7.585 13.941 13.953"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></polygon>
//             <polygon
//               fill-rule="evenodd"
//               points="10.06 10.056 13.949 13.945 7.581 16.424 10.06 10.056"
//             ></polygon>
//             <circle
//               cx="12.001"
//               cy="12.005"
//               fill="none"
//               r="10.5"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></circle>
//           </svg>
//         </div>
//       </div>

//       {/* Custom SVG Button for Search */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Search"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Search</title>
//             <path
//               d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z"
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></path>
//             <line
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//               x1="16.511"
//               x2="22"
//               y1="16.511"
//               y2="22"
//             ></line>
//           </svg>
//         </div>
//       </div>

//       {/* Custom Human Icon Button */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => openModal()}>
//           <svg
//             aria-label="User"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>User</title>
//             <path
//               d="M12 2a6 6 0 1 1-6 6 6 6 0 0 1 6-6Zm0 12c-4.29 0-8 2.24-8 5v2h16v-2c0-2.76-3.71-5-8-5Z"
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></path>
//           </svg>
//         </div>
//       </div>

//       {/* Crosshair Icon with an empty center (more minimal) */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div
//           className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p"
//           onClick={handleCrosshairClick}  // Handle the click event, e.g., redirect to '/current'
//         >
//           <svg
//             aria-label="Target Crosshair"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="none"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Target Crosshair</title>
//             {/* Solid Circle at the center */}
//             <circle cx="12" cy="12" r="3" fill="black" />
//             {/* Larger Outer Circle */}
//             <circle cx="12" cy="12" r="8" stroke="black" strokeWidth="2" fill="none" />
//             {/* Rectangular Protrusions (Top, Right, Bottom, Left) */}
//             <rect x="11" y="1" width="2" height="3" fill="black" />
//             <rect x="11" y="20" width="2" height="3" fill="black" />
//             <rect x="1" y="11" width="3" height="2" fill="black" />
//             <rect x="20" y="11" width="3" height="2" fill="black" />
//           </svg>
//         </div>
//       </div>


//       {/* Render Login Modal */}
//       <Login isModalOpen={isModalOpen} closeModal={closeModal} />
//     </header>
//   );
// };

// export default Header;






// failed  v4.1 click on icon to navigate
// import React, { useState } from 'react';
// import image from '../../images/house-location-pin.svg';
// import { Filter } from '../';
// import { useNavigate } from 'react-router-dom';  // useNavigate for redirection
// import Login from '../LoginClass';  // Import Login Modal

// export const Header = ({ filterIsVisible, toggleFilter, handleFilterChange, clearFilter }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);  // Manage modal state locally
//   const navigate = useNavigate();  // Hook for redirecting to other pages

//   // Open and close modal functions
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   // Function to handle crosshair redirection
//   const handleCrosshairClick = () => {
//     navigate('/current');  // Redirect to /current
//   };

//   return (
//     <header className={`${filterIsVisible ? 'filter-is-visible' : ''}`}>
//       <Filter
//         toggleFilter={toggleFilter}
//         handleFilterChange={handleFilterChange}
//         clearFilter={clearFilter}
//       />

//       {/* <img src={image} alt="Property Listings" /> */}
//       <h1> </h1>

//       {/* Custom SVG Button for Home */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Home"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Home</title>
//             <path d="M22 23h-6.001a1 1 0 0 1-1-1v-5.455a2.997 2.997 0 1 0-5.993 0V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V11.543a1.002 1.002 0 0 1 .31-.724l10-9.543a1.001 1.001 0 0 1 1.38 0l10 9.543a1.002 1.002 0 0 1 .31.724V22a1 1 0 0 1-1 1Z"></path>
//           </svg>
//         </div>
//       </div>

//       {/* Custom SVG Button for Explore */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Explore"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Explore</title>
//             <polygon
//               fill="none"
//               points="13.941 13.953 7.581 16.424 10.06 10.056 16.42 7.585 13.941 13.953"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></polygon>
//             <polygon
//               fill-rule="evenodd"
//               points="10.06 10.056 13.949 13.945 7.581 16.424 10.06 10.056"
//             ></polygon>
//             <circle
//               cx="12.001"
//               cy="12.005"
//               fill="none"
//               r="10.5"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></circle>
//           </svg>
//         </div>
//       </div>

//       {/* Custom SVG Button for Search */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Search"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Search</title>
//             <path
//               d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z"
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></path>
//             <line
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//               x1="16.511"
//               x2="22"
//               y1="16.511"
//               y2="22"
//             ></line>
//           </svg>
//         </div>
//       </div>

//       {/* Crosshair Icon with an empty center (more minimal) */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div
//           className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p"
//           onClick={handleCrosshairClick}  // Handle the click event, e.g., redirect to '/current'
//         >
//           <svg
//             aria-label="Target Crosshair"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="none"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Target Crosshair</title>
//             {/* Solid Circle at the center */}
//             <circle cx="12" cy="12" r="3" fill="black" />
//             {/* Larger Outer Circle */}
//             <circle cx="12" cy="12" r="8" stroke="black" strokeWidth="2" fill="none" />
//             {/* Rectangular Protrusions (Top, Right, Bottom, Left) */}
//             <rect x="11" y="1" width="2" height="3" fill="black" />
//             <rect x="11" y="20" width="2" height="3" fill="black" />
//             <rect x="1" y="11" width="3" height="2" fill="black" />
//             <rect x="20" y="11" width="3" height="2" fill="black" />
//           </svg>
//         </div>
//       </div>

//       {/* Custom Human Icon Button */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={openModal}>
//           <svg
//             aria-label="User"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>User</title>
//             <path
//               d="M12 2a6 6 0 1 1-6 6 6 6 0 0 1 6-6Zm0 12c-4.29 0-8 2.24-8 5v2h16v-2c0-2.76-3.71-5-8-5Z"
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></path>
//           </svg>
//         </div>
//       </div>

//       {/* Modal for Login */}
//       {isModalOpen && <Login closeModal={closeModal} />}
//     </header>
//   );
// };

// export default Header;




// ok v4 add the icon from insta
// import React, { useState } from 'react';
// import image from '../../images/house-location-pin.svg';
// import { Filter } from '../';
// import { Link } from 'react-router-dom';
// import Login from '../LoginClass';  // Import Login Modal

// export const Header = ({ filterIsVisible, toggleFilter, handleFilterChange, clearFilter }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);  // Manage modal state locally

//   // Open and close modal functions
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   return (
//     <header className={`${filterIsVisible ? 'filter-is-visible' : ''}`}>
//       <Filter
//         toggleFilter={toggleFilter}
//         handleFilterChange={handleFilterChange}
//         clearFilter={clearFilter}
//       />

//       {/* <img src={image} alt="Property Listings" /> */}
//       <h1> </h1>

//       {/* Custom SVG Button for Home */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Home"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Home</title>
//             <path d="M22 23h-6.001a1 1 0 0 1-1-1v-5.455a2.997 2.997 0 1 0-5.993 0V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V11.543a1.002 1.002 0 0 1 .31-.724l10-9.543a1.001 1.001 0 0 1 1.38 0l10 9.543a1.002 1.002 0 0 1 .31.724V22a1 1 0 0 1-1 1Z"></path>
//           </svg>
//         </div>
//       </div>

//       {/* Custom SVG Button for Explore */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Explore"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Explore</title>
//             <polygon
//               fill="none"
//               points="13.941 13.953 7.581 16.424 10.06 10.056 16.42 7.585 13.941 13.953"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></polygon>
//             <polygon
//               fill-rule="evenodd"
//               points="10.06 10.056 13.949 13.945 7.581 16.424 10.06 10.056"
//             ></polygon>
//             <circle
//               cx="12.001"
//               cy="12.005"
//               fill="none"
//               r="10.5"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></circle>
//           </svg>
//         </div>
//       </div>

//       {/* Custom SVG Button for Search */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => toggleFilter(e)}>
//           <svg
//             aria-label="Search"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>Search</title>
//             <path
//               d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z"
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></path>
//             <line
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//               x1="16.511"
//               x2="22"
//               y1="16.511"
//               y2="22"
//             ></line>
//           </svg>
//         </div>
//       </div>

//       {/* Custom Human Icon Button */}
//       <div className="x9f619 xjbqb8w x78zum5 x168nmei x13lgxp2 x5pf9jr xo71vjh x1n2onr6 x1plvlek xryxfnj x1c4vz4f x2lah0s xdt5ytf xqjyukv x1qjc9v5 x1oa3qoh x1nhvcw1">
//         <div className="x9f619 xxk0z11 xii2z7h x11xpdln x19c4wfv xvy4d1p" onClick={(e) => openModal()}>
//           <svg
//             aria-label="User"
//             className="x1lliihq x1n2onr6 x5n08af"
//             fill="currentColor"
//             height="24"
//             role="img"
//             viewBox="0 0 24 24"
//             width="24"
//           >
//             <title>User</title>
//             <path
//               d="M12 2a6 6 0 1 1-6 6 6 6 0 0 1 6-6Zm0 12c-4.29 0-8 2.24-8 5v2h16v-2c0-2.76-3.71-5-8-5Z"
//               fill="none"
//               stroke="currentColor"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//               stroke-width="2"
//             ></path>
//           </svg>
//         </div>
//       </div>

//       <Link to="/current">
//         <button className="btn-current">Current</button>
//       </Link>

//       {/* Trigger the modal */}
//       <button className="btn-login" onClick={openModal}>
//         Login
//       </button>

//       {/* Render Login Modal */}
//       <Login isModalOpen={isModalOpen} closeModal={closeModal} />
//     </header>
//   );
// };

// export default Header;








// ok v3.1 revert back to non bootstrap
// import React, { useState } from 'react';
// import image from '../../images/house-location-pin.svg';
// import { Filter } from '../';
// import { Link } from 'react-router-dom';
// import Login from '../LoginClass';  // Import Login Modal

// export const Header = ({ filterIsVisible, toggleFilter, handleFilterChange, clearFilter }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);  // Manage modal state locally

//   // Open and close modal functions
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   return (
//     <header className={`${filterIsVisible ? 'filter-is-visible' : ''}`}>
//       <Filter
//         toggleFilter={toggleFilter}
//         handleFilterChange={handleFilterChange}
//         clearFilter={clearFilter}
//       />

//       <img src={image} alt="Property Listings" />
//       <h1> </h1>

//       <button className="btn-filter" onClick={(e) => toggleFilter(e)}>
//         Filter
//       </button>

//       <Link to="/current">
//         <button className="btn-current">Current</button>
//       </Link>

//       {/* Trigger the modal */}
//       <button className="btn-login" onClick={openModal}>
//         Login
//       </button>

//       {/* Render Login Modal */}
//       <Login isModalOpen={isModalOpen} closeModal={closeModal} />
//     </header>
//   );
// };

// export default Header;


// failed ?? v3 make the header nicer, ref airbnb
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import Login from '../LoginClass';  // Import Login Modal
// import './Header.css';  // Keep this import path
// import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap styles

// export const Header = ({ filterIsVisible, toggleFilter, handleFilterChange, clearFilter }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);  // Manage modal state locally

//   // Open and close modal functions
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   return (
//     <header className="header container-fluid p-0">
//       <div className="row w-100 m-0 align-items-center">
//         <div className="col-6 col-md-4 p-0">
//           {/* Logo container */}
//           <div className="logo-container">
//             {/* Optional: Add logo image here */}
//             {/* <h1>Airbnb</h1> */}
//           </div>
//         </div>

//         <div className="col-6 col-md-8 d-flex justify-content-end align-items-center p-0">
//           {/* Filter Button */}
//           <button className="btn btn-outline-secondary d-flex align-items-center ms-1" onClick={(e) => toggleFilter(e)}>
//             <div className="icon filter"></div>
//             <span className="btn-text ms-2">Filter</span>
//           </button>

//           {/* Current Button with Crosshair Icon */}
//           <Link to="/current" className="btn btn-outline-secondary d-flex align-items-center ms-1">
//             <div className="icon crosshair"></div>
//             <span className="btn-text ms-2">Current</span>
//           </Link>

//           {/* Login Button with User Icon */}
//           <button className="btn btn-outline-secondary d-flex align-items-center ms-1" onClick={openModal}>
//             <div className="icon user"></div>
//             <span className="btn-text ms-2">Login</span>
//           </button>
//         </div>
//       </div>

//       {/* Render Login Modal */}
//       <Login isModalOpen={isModalOpen} closeModal={closeModal} />
//     </header>
//   );
// };

// export default Header;





// ok v2 add modal
// import React, { useState } from 'react';
// import image from '../../images/house-location-pin.svg';
// import { Filter } from '../';
// import { Link } from 'react-router-dom';
// import Login from '../LoginClass';  // Import Login Modal

// export const Header = ({ filterIsVisible, toggleFilter, handleFilterChange, clearFilter }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);  // Manage modal state locally

//   // Open and close modal functions
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);

//   return (
//     <header className={`${filterIsVisible ? 'filter-is-visible' : ''}`}>
//       <Filter
//         toggleFilter={toggleFilter}
//         handleFilterChange={handleFilterChange}
//         clearFilter={clearFilter}
//       />

//       <img src={image} alt="Property Listings" />
//       <h1> </h1>

//       <button className="btn-filter" onClick={(e) => toggleFilter(e)}>
//         Filter
//       </button>

//       <Link to="/current">
//         <button className="btn-current">Current</button>
//       </Link>

//       {/* Trigger the modal */}
//       <button className="btn-login" onClick={openModal}>
//         Login
//       </button>

//       {/* Render Login Modal */}
//       <Login isModalOpen={isModalOpen} closeModal={closeModal} />
//     </header>
//   );
// };

// export default Header;





// ok v1
// import React from 'react';
// import image from '../../images/house-location-pin.svg';
// import { Filter } from '../';
// import { Link } from 'react-router-dom';
// import Login from '../LoginClass';

// export const Header = ({filterIsVisible, toggleFilter, handleFilterChange, clearFilter}) => {
//     return (
//         <header className={`${filterIsVisible ? 'filter-is-visible': ''}`}>
//           <Filter
//             toggleFilter={toggleFilter}
//             handleFilterChange={handleFilterChange}
//             clearFilter={clearFilter}
//           />

//           <img src={image} alt="Property Listings" />
//           <h1> </h1>
//           <button className="btn-filter" onClick={(e) => toggleFilter(e)}>Filter</button>

//           <Link to="/current">
//             <button className="btn-current">Current</button>
//           </Link>
//           {/* <Link to="/login">
//             <button className="btn-current">Login</button>
//           </Link> */}
//           {/* <button type="submit">Login</button> */}
//           <Login/>


//         </header>
//     )
// }

// export default Header;