
// ok v2.2 public view of user marker, only map for whole user page layout, ref the prev versions, fix one hand move map
import React, { useState, useEffect } from "react";
import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";
import Header from "./Header";
import { AppContainer } from "../App.styles2";
import axios from "axios";
import { useParams } from 'react-router-dom';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';


const UserPage = () => {
    const defaultProps = {
        center: {
            lat:  21.044175, // Center to San Francisco
            lng: 105.846411,
        },
        zoom: 10,
    };
    const { id } = useParams();  // Use `id` from the URL parameters
    const [markers, setMarkers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);



    // console.log("######## In UserPage.js #####");
    // console.log(`Fetching data for user with ID: ${id}`);  // Log the ID being fetched

    const fetchMarkers = async () => {
        setLoading(true);
        setError(null);

        // Retrieve token from localStorage
        const token = localStorage.getItem('token');

        try {
          // Include the token in the Authorization header for the API request
          // const response = await axios.get(`${config.getAllURL}users/${id}`);
          const response = await axios.get(`${apiBaseUrl}publicview/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add token to the Authorization header
            },
          });

          // setPlaces(response.data)
          console.log("########### fetch response App.js ######### ");
          console.log(response.data);
          setMarkers(response.data);  // Update the state with fetched markers
        } catch (error) {
          setError('Error fetching markers');
          console.error('Error fetching markers:', error);
        } finally {
          setLoading(false);
        }
    };



    const [hoveredMarker, setHoveredMarker] = useState(null);


    useEffect(() => {
        fetchMarkers();
    }, []);

    return (
        <AppContainer>
            <Header/>
            <div className="app" style={{ height: "100vh", width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "" }} // Replace with your Google Maps API key
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  gestureHandling: 'greedy', // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
                  // scrollwheel: false,       // Disable scroll zoom
                  // draggable: false,         // Disable dragging
                  // disableDoubleClickZoom: true, // Disable double-click zoom
                }}
            >
                {/* <Marker
                    lat={21.044175} // Latitude for the marker
                    lng={105.846411} // Longitude for the marker
                    text="San Francisco"
                    isHovered={hoveredMarker === 1}
                    onMouseEnter={() => setHoveredMarker(1)}
                    onMouseLeave={() => setHoveredMarker(null)}
                /> */}

                {loading ? (
                    <p>Loading markers...</p>
                  ) : (
                    markers.map((marker) => (
                      <Marker
                        key={marker._id} // Ensure each marker has a unique key
                        lat={marker.location.coordinates[0]} // Latitude at index 1
                        lng={marker.location.coordinates[1]} // Longitude at index 0
                        text={marker.title} // Title of the marker
                        // property={marker}
                        // mapRef={mapRef}
                        // onClick={handleMarkerClick} // Trigger the handleMarkerClick function
                        // selectedPlace={selectedPlace}
                        // setGestureHandling={setGestureHandling}
                      />
                    ))
                  )}
            </GoogleMapReact>
        </div>
        </AppContainer>

    );
};

export default UserPage;


// ~ (working but nothing added, might use this 2.1 or 2.0) v2.1 display those fetched data, and add user name to jwt or somewhere
// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import L from 'leaflet';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import './UserPage.css';  // Assume you have custom styles for this
// import config from '../config';
// import { useParams } from 'react-router-dom';
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";


// // Default image to use as a fallback
// const defaultImage = "https://via.placeholder.com/150";

// const UserPage = () => {
//   const { id } = useParams();  // Use `id` from the URL parameters
//   const [user, setUser] = useState(null); //
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [position, setPosition] = useState([51.505, -0.09]); // Default location (can be user's location)
//   const [currentPage, setCurrentPage] = useState(1);
//   const [images, setImages] = useState([]);
//   const [items, setItems] = useState([]);
//   const [page, setPage] = useState(1);

//   const mapRef = useRef(null);



//   // Fetch user data based on dynamic id
//   useEffect(() => {
//     console.log("######## In UserPage.js #####");
//     console.log(`Fetching data for user with ID: ${id}`);  // Log the ID being fetched

//     const fetchUserData = async () => {
//       try {
//         const response = await axios.get(`${config.getAllURL}users/${id}`); // Use dynamic ID in the URL
//         setUser(response.data);  // Set user data from the response
//         console.log("######## user data UserPage.js #####");
//         console.log(response.data);
//       } catch (err) {
//         setError("Error fetching user data");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserData();
// }, []);  // Re-fetch when `id` changes

// //   }, [id]);  // Re-fetch when `id` changes

//   // Fetch images for List Page (replace with real API call)
//   useEffect(() => {
//     axios.get(`${config.getAllURL}/images?page=${currentPage}`)
//       .then(response => {
//         setImages(prevImages => [...prevImages, ...response.data.images]);
//       })
//       .catch(err => {
//         console.log("Error fetching images:", err);
//         // Show a fallback image list if fetching fails
//         setImages([defaultImage, defaultImage, defaultImage]); // Example: Set default images
//       });
//   }, [currentPage]);

//   // Fetch reel content (replace with real API)
//   useEffect(() => {
//     axios.get(`${config.getAllURL}/reel?page=${page}`)
//       .then(response => {
//         setItems(prevItems => [...prevItems, ...response.data.items]);
//       })
//       .catch(err => {
//         console.log("Error fetching reel:", err);
//         // Fallback to default images
//         setItems([{ imageUrl: defaultImage }]); // Example: Placeholder
//       });
//   }, [page]);



// //   // Initialize the map only after the DOM is available
// //   useEffect(() => {
// //     if (mapRef.current) {
// //       const map = L.map(mapRef.current).setView(position, 13);

// //       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(map);

// //       const marker = L.marker(position).addTo(map);
// //       marker.bindPopup("You are here").openPopup();

// //       map.on('click', (e) => {
// //         const { lat, lng } = e.latlng;
// //         setPosition([lat, lng]);
// //         marker.setLatLng([lat, lng]);
// //       });

// //       return () => {
// //         map.remove();
// //       };
// //     }
// //   }, [position]);
//   // trrouble shoot map
//   useEffect(() => {
//     console.log(" ******* Inside UserPage.js *****"); // Log map ref

//     if (mapRef.current) {
//       console.log("Initializing map with ref:", mapRef.current); // Log map ref
//       const map = L.map(mapRef.current).setView(position, 13);
//       console.log("Map initialized:", map); // Log map initialization

//       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(map);

//       const marker = L.marker(position).addTo(map);
//       marker.bindPopup("You are here").openPopup();

//       map.on('click', (e) => {
//         const { lat, lng } = e.latlng;
//         console.log("Map clicked at lat:", lat, "lng:", lng); // Log map click event
//         setPosition([lat, lng]);
//         marker.setLatLng([lat, lng]);
//       });

//       return () => {
//         console.log("Cleaning up map...");
//         map.remove();
//       };
//     } else {
//       console.log("##### mapRef is not available yet. ###");
//     }

//     console.log("Done UserPage.js"); // Log map ref

//   }, [position]);

//   useEffect(()=> {

//     console.log("########### UserPage useEffect test ##############")
//   }, [position])



//   const loadMoreImages = () => {
//     setCurrentPage(currentPage + 1);
//   };

//   const loadMoreReels = () => {
//     setPage(page + 1);
//   };

//   // If loading data, show a placeholder message or component
//   if (loading) {
//     return (
//       <div className="loading-placeholder">
//         <h2>Loading your profile...</h2>
//         <div className="loading-spinner">Loading...</div> {/* You can use a spinner here */}
//       </div>
//     );
//   }

//   // If user data not found, show a placeholder
//   if (!user) {
//     return (
//       <div className="user-not-found">
//         <h2>User not found</h2>
//         <div className="loading-spinner">Loading...</div>
//       </div>
//     );
//   }


// //   console.log("######## username UserPage.js #####");
// //   console.log(user);

//   return (
//     <div className="user-page">
//       <h1>{user.user.username}'s Profile</h1>
//       <div className="user-info">
//         <img
//           src={user.profilePicture || defaultImage}
//           alt={user.user.username}
//           className="user-profile-pic"
//         />
//         <p>{user.email}</p>
//         <p>{user.bio}</p>
//       </div>

//       {/* Map Page */}
//       <div className="map-section">
//         <h2>My Markers</h2>
//         <MapContainer
//         center={[51.505, -0.09]}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}

//         >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         </MapContainer>
//         {position ? (
//           <div id="map" ref={mapRef} style={{ height: '400px' }} />
//         ) : (
//           <div className="map-placeholder">Loading map...</div>
//         )}
//       </div>

//       {/* List Page with Infinite Scroll */}
//       <div className="list-section">
//         <h2>Images</h2>
//         <InfiniteScroll
//           dataLength={images.length}
//           next={loadMoreImages}
//           hasMore={true}
//           loader={<div>Loading more images...</div>}
//           endMessage={<div>No more images</div>}
//         >
//           <div className="image-grid">
//             {images.length === 0 ? (
//               <div className="loading-placeholder">No images available</div>
//             ) : (
//               images.map((img, index) => (
//                 <div key={index} className="image-card">
//                   <img src={img || defaultImage} alt={`Image ${index}`} />
//                 </div>
//               ))
//             )}
//           </div>
//         </InfiniteScroll>
//       </div>

//       {/* Reel Page with Infinite Scroll */}
//       <div className="reel-section">
//         <h2>Reels</h2>
//         <InfiniteScroll
//           dataLength={items.length}
//           next={loadMoreReels}
//           hasMore={true}
//           loader={<div>Loading more reels...</div>}
//           endMessage={<div>No more reels</div>}
//         >
//           <div className="reel-items">
//             {items.length === 0 ? (
//               <div className="loading-placeholder">No reels available</div>
//             ) : (
//               items.map((item, index) => (
//                 <div key={index} className="reel-item">
//                   <img src={item.imageUrl || defaultImage} alt={`Reel ${index}`} />
//                 </div>
//               ))
//             )}
//           </div>
//         </InfiniteScroll>
//       </div>
//     </div>
//   );
// };

// export default UserPage;



// ok v2 fetch each user
// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import L from 'leaflet';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import './UserPage.css';  // Assume you have custom styles for this
// import config from '../config';
// import { useParams } from 'react-router-dom';

// // Default image to use as a fallback
// const defaultImage = "https://via.placeholder.com/150";

// const UserPage = () => {
//   const { id } = useParams();  // Use `id` from the URL parameters
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [position, setPosition] = useState([51.505, -0.09]); // Default location (can be user's location)
//   const [currentPage, setCurrentPage] = useState(1);
//   const [images, setImages] = useState([]);
//   const [items, setItems] = useState([]);
//   const [page, setPage] = useState(1);

//   const mapRef = useRef(null);

//   // Fetch user data based on dynamic id
//   useEffect(() => {
//     console.log("######## In UserPage.js #####");
//     console.log(`Fetching data for user with ID: ${id}`);  // Log the ID being fetched

//     const fetchUserData = async () => {
//       try {
//         const response = await axios.get(`${config.getAllURL}users/${id}`); // Use dynamic ID in the URL
//         setUser(response.data);  // Set user data from the response
//         console.log("######## user data UserPage.js #####");
//         console.log(response.data);
//       } catch (err) {
//         setError("Error fetching user data");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserData();
// }, []);  // Re-fetch when `id` changes

// //   }, [id]);  // Re-fetch when `id` changes

//   // Fetch images for List Page (replace with real API call)
//   useEffect(() => {
//     axios.get(`${config.getAllURL}/images?page=${currentPage}`)
//       .then(response => {
//         setImages(prevImages => [...prevImages, ...response.data.images]);
//       })
//       .catch(err => {
//         console.log("Error fetching images:", err);
//         // Show a fallback image list if fetching fails
//         setImages([defaultImage, defaultImage, defaultImage]); // Example: Set default images
//       });
//   }, [currentPage]);

//   // Fetch reel content (replace with real API)
//   useEffect(() => {
//     axios.get(`${config.getAllURL}/reel?page=${page}`)
//       .then(response => {
//         setItems(prevItems => [...prevItems, ...response.data.items]);
//       })
//       .catch(err => {
//         console.log("Error fetching reel:", err);
//         // Fallback to default images
//         setItems([{ imageUrl: defaultImage }]); // Example: Placeholder
//       });
//   }, [page]);

//   // Initialize the map only after the DOM is available
//   useEffect(() => {
//     if (mapRef.current) {
//       const map = L.map(mapRef.current).setView(position, 13);

//       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(map);

//       const marker = L.marker(position).addTo(map);
//       marker.bindPopup("You are here").openPopup();

//       map.on('click', (e) => {
//         const { lat, lng } = e.latlng;
//         setPosition([lat, lng]);
//         marker.setLatLng([lat, lng]);
//       });

//       return () => {
//         map.remove();
//       };
//     }
//   }, [position]);

//   const loadMoreImages = () => {
//     setCurrentPage(currentPage + 1);
//   };

//   const loadMoreReels = () => {
//     setPage(page + 1);
//   };

//   // If loading data, show a placeholder message or component
//   if (loading) {
//     return (
//       <div className="loading-placeholder">
//         <h2>Loading your profile...</h2>
//         <div className="loading-spinner">Loading...</div> {/* You can use a spinner here */}
//       </div>
//     );
//   }

//   // If user data not found, show a placeholder
//   if (!user) {
//     return (
//       <div className="user-not-found">
//         <h2>User not found</h2>
//         <div className="loading-spinner">Loading...</div>
//       </div>
//     );
//   }


//   console.log("######## username UserPage.js #####");
//   console.log(user);

//   return (
//     <div className="user-page">
//       <h1>{user.user.username}'s Profile</h1>
//       <div className="user-info">
//         <img
//           src={user.profilePicture || defaultImage}
//           alt={user.user.username}
//           className="user-profile-pic"
//         />
//         <p>{user.email}</p>
//         <p>{user.bio}</p>
//       </div>

//       {/* Map Page */}
//       <div className="map-section">
//         <h2>Your Location</h2>
//         {position ? (
//           <div id="map" ref={mapRef} style={{ height: '400px' }} />
//         ) : (
//           <div className="map-placeholder">Loading map...</div>
//         )}
//       </div>

//       {/* List Page with Infinite Scroll */}
//       <div className="list-section">
//         <h2>Images</h2>
//         <InfiniteScroll
//           dataLength={images.length}
//           next={loadMoreImages}
//           hasMore={true}
//           loader={<div>Loading more images...</div>}
//           endMessage={<div>No more images</div>}
//         >
//           <div className="image-grid">
//             {images.length === 0 ? (
//               <div className="loading-placeholder">No images available</div>
//             ) : (
//               images.map((img, index) => (
//                 <div key={index} className="image-card">
//                   <img src={img || defaultImage} alt={`Image ${index}`} />
//                 </div>
//               ))
//             )}
//           </div>
//         </InfiniteScroll>
//       </div>

//       {/* Reel Page with Infinite Scroll */}
//       <div className="reel-section">
//         <h2>Reels</h2>
//         <InfiniteScroll
//           dataLength={items.length}
//           next={loadMoreReels}
//           hasMore={true}
//           loader={<div>Loading more reels...</div>}
//           endMessage={<div>No more reels</div>}
//         >
//           <div className="reel-items">
//             {items.length === 0 ? (
//               <div className="loading-placeholder">No reels available</div>
//             ) : (
//               items.map((item, index) => (
//                 <div key={index} className="reel-item">
//                   <img src={item.imageUrl || defaultImage} alt={`Reel ${index}`} />
//                 </div>
//               ))
//             )}
//           </div>
//         </InfiniteScroll>
//       </div>
//     </div>
//   );
// };

// export default UserPage;



// // ok v1 init
// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import L from 'leaflet';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import './UserPage.css';  // Assume you have custom styles for this
// import config from '../config';

// // Default image to use as a fallback
// const defaultImage = "https://via.placeholder.com/150";

// const UserPage = () => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [position, setPosition] = useState([51.505, -0.09]); // Default location (can be user's location)
//   const [currentPage, setCurrentPage] = useState(1);
//   const [images, setImages] = useState([]);
//   const [items, setItems] = useState([]);
//   const [page, setPage] = useState(1);

//   const mapRef = useRef(null);

//   // Fetch user data (replace URL with your API)
//   useEffect(() => {

//     console.log("######## In UserPage.js #####");
//     // console.log(items); // Log the items once at the start

//     const fetchUserData = async () => {
//       try {
//         const response = await axios.get(`https://api.example.com/users/1`); // Replace with user-specific ID
//         setUser(response.data);
//       } catch (err) {
//         setError("Error fetching user data");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserData();
//   }, []);

//   // Fetch images for List Page (replace with real API call)
//   useEffect(() => {
//     axios.get(`https://api.example.com/images?page=${currentPage}`)
//       .then(response => {
//         setImages(prevImages => [...prevImages, ...response.data.images]);
//       })
//       .catch(err => {
//         console.log("Error fetching images:", err);
//         // Show a fallback image list if fetching fails
//         setImages([defaultImage, defaultImage, defaultImage]); // Example: Set default images
//       });
//   }, [currentPage]);

//   // Fetch reel content (replace with real API)
//   useEffect(() => {
//     axios.get(`https://api.example.com/reel?page=${page}`)
//       .then(response => {
//         setItems(prevItems => [...prevItems, ...response.data.items]);
//       })
//       .catch(err => {
//         console.log("Error fetching reel:", err);
//         // Fallback to default images
//         setItems([{ imageUrl: defaultImage }]); // Example: Placeholder
//       });
//   }, [page]);

//   // Initialize the map only after the DOM is available
//   useEffect(() => {
//     if (mapRef.current) {
//       const map = L.map(mapRef.current).setView(position, 13);

//       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(map);

//       const marker = L.marker(position).addTo(map);
//       marker.bindPopup("You are here").openPopup();

//       map.on('click', (e) => {
//         const { lat, lng } = e.latlng;
//         setPosition([lat, lng]);
//         marker.setLatLng([lat, lng]);
//       });

//       return () => {
//         map.remove();
//       };
//     }
//   }, [position]);

//   const loadMoreImages = () => {
//     setCurrentPage(currentPage + 1);
//   };

//   const loadMoreReels = () => {
//     setPage(page + 1);
//   };

//   // If loading data, show a placeholder message or component
//   if (loading) {
//     return (
//       <div className="loading-placeholder">
//         <h2>Loading your profile...</h2>
//         <div className="loading-spinner">Loading...</div> {/* You can use a spinner here */}
//       </div>
//     );
//   }

//   // If user data not found, show a placeholder
//   if (!user) {
//     return (
//       <div className="user-not-found">
//         <h2>User not found</h2>
//         <div className="loading-spinner">Loading...</div>
//       </div>
//     );
//   }

//   return (
//     <div className="user-page">
//       <h1>{user.username}'s Profile</h1>
//       <div className="user-info">
//         <img
//           src={user.profilePicture || defaultImage}
//           alt={user.username}
//           className="user-profile-pic"
//         />
//         <p>{user.email}</p>
//         <p>{user.bio}</p>
//       </div>

//       {/* Map Page */}
//       <div className="map-section">
//         <h2>Your Location</h2>
//         {position ? (
//           <div id="map" ref={mapRef} style={{ height: '400px' }} />
//         ) : (
//           <div className="map-placeholder">Loading map...</div>
//         )}
//       </div>

//       {/* List Page with Infinite Scroll */}
//       <div className="list-section">
//         <h2>Images</h2>
//         <InfiniteScroll
//           dataLength={images.length}
//           next={loadMoreImages}
//           hasMore={true}
//           loader={<div>Loading more images...</div>}
//           endMessage={<div>No more images</div>}
//         >
//           <div className="image-grid">
//             {images.length === 0 ? (
//               <div className="loading-placeholder">No images available</div>
//             ) : (
//               images.map((img, index) => (
//                 <div key={index} className="image-card">
//                   <img src={img || defaultImage} alt={`Image ${index}`} />
//                 </div>
//               ))
//             )}
//           </div>
//         </InfiniteScroll>
//       </div>

//       {/* Reel Page with Infinite Scroll */}
//       <div className="reel-section">
//         <h2>Reels</h2>
//         <InfiniteScroll
//           dataLength={items.length}
//           next={loadMoreReels}
//           hasMore={true}
//           loader={<div>Loading more reels...</div>}
//           endMessage={<div>No more reels</div>}
//         >
//           <div className="reel-items">
//             {items.length === 0 ? (
//               <div className="loading-placeholder">No reels available</div>
//             ) : (
//               items.map((item, index) => (
//                 <div key={index} className="reel-item">
//                   <img src={item.imageUrl || defaultImage} alt={`Reel ${index}`} />
//                 </div>
//               ))
//             )}
//           </div>
//         </InfiniteScroll>
//       </div>
//     </div>
//   );
// };

// export default UserPage;
