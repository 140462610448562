


// ok v1
// src/components/TripFetcher.js
import React, { useState, useEffect } from 'react';
import config from '../config';
import axios from 'axios';


const TripFetcher = () => {
  const [tripData, setTripData] = useState([]);  // State to hold the fetched trip data
  const [error, setError] = useState(null);  // State to hold any error messages
  const [loading, setLoading] = useState(true);  // State for loading status
  const [newTrip, setNewTrip] = useState({
    name: '',
    description: '',
    price: '',
  });  // State for new trip form

  // Fetch trips from the API
  useEffect(() => {
    const fetchTripData = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await fetch(config.getAllURL + 'trips/trip', {
            headers: {
                Authorization: `Bearer ${token}`  // Include the token in the request header
            }
        }); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setTripData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTripData();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrip((prevTrip) => ({
      ...prevTrip,
      [name]: value,
    }));
  };

  // Handle form submission for adding a new trip
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!newTrip.name || !newTrip.description || !newTrip.price) {
      alert("Please fill in all fields.");
      return;
    }

    try {


        const token = localStorage.getItem('token');


        const payload = {
            name: newTrip.name,
            description: newTrip.description,
            price: newTrip.price,
        };

        const response = await axios.post(config.getAllURL + 'trips/trip', payload, {
            headers: {
              Authorization: `Bearer ${token}`,  // Include the token in the request header
            },
          });

        // const response = await fetch(config.getAllURL + 'trips/trip', payload, {
        //     headers: {
        //         Authorization: `Bearer ${token}`  // Include the token in the request header
        //     }
        // });

    //   const response = await fetch('http://localhost:5000/api/trips', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(newTrip),
    //   });

      if (!response.ok) {
        throw new Error('Failed to post new trip');
      }

      const addedTrip = await response.json();
      setTripData((prevTrips) => [...prevTrips, addedTrip]); // Add the new trip to the existing list
      setNewTrip({ name: '', description: '', price: '' }); // Reset the form
      alert("Trip created successfully!"); // Optionally, show a success message
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Trips</h1>

      {/* Add New Trip Form */}
      <h2>Add a New Trip</h2>
      <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          name="name"
          value={newTrip.name}
          onChange={handleInputChange}
          placeholder="Trip Name"
          required
        />
        <input
          type="text"
          name="description"
          value={newTrip.description}
          onChange={handleInputChange}
          placeholder="Trip Description"
          required
        />
        <input
          type="number"
          name="price"
          value={newTrip.price}
          onChange={handleInputChange}
          placeholder="Trip Price"
          required
        />
        <button type="submit">Add Trip</button>
      </form>

      {/* Existing Trips List */}
      <h2>Existing Trips</h2>
      {tripData.length > 0 ? (
        <ul>
          {tripData.map((trip) => (
            <li key={trip._id}>
              <h3>{trip.name}</h3>
              <p>{trip.description}</p>
              <p>Price: ${trip.price}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No trips found.</p>
      )}
    </div>
  );
};

export default TripFetcher;
