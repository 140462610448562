


// v5.5
// should keep rotation, so user can move around the building
// fix disable pull down reload, now this will affect card click
// fix the offset recenter map
// the marker jiggle on mapbox, fix this, temp by trying this const MemoizedMarker2 = React.memo(Marker2);
// fix the marker and popup css
// -> offset does not work well, sometimes it gets rezoom, others dont (Im setting it inside Card.js handleClick)
// use shared MapInstance

// ok v5.5 make the map nicer: tilted, rotated, etc
import GoogleMapReact from "google-map-react";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import logo from './logo.svg';
import './App.css';
import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect, useRef } from 'react';
import { AppContainer } from './App.styles2';
import image from './images/location-map.svg';
import { Header, Card, GoogleMap, MinioFileList } from './components';
import data from './data';
import Cookies from 'js-cookie'; // For easier cookie handling
import axios from "axios";
import { TileLayer, useMapEvents } from "react-leaflet";
import MarkerForm from "./components/MarkerForm";
import Upload from "./components/Upload";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MapComponent from "./components/MapComponent";
import Marker2 from "./components/Marker2";
import Popup from "./components/Card/Popup";
import FullScreenSidebar from "./components/FullScreenSidebar";
import { store} from './redux/MyRedux'
// import GridLayout from "./components/UserList"; // UserList -> in ExplorePage.js and imported as Gridlayout
import UserPage from "./components/UserPage";
// import MinioFileList from "./components";
import HomePage from "./components/HomePage";
import UserList from "./components/UserList";

import { GridAsymmetrical } from "./components/MapComponent Mantine";
import HomePageV2 from "./components/HomePageV2";

// // map box
// import ReactMapGL from 'react-map-gl'; // Import react-map-gl
// import mapboxgl from 'mapbox-gl'; // Import Mapbox GL
// import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox CSS

// https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
// import mapboxgl from 'mapbox-gl'
// import 'mapbox-gl/dist/mapbox-gl.css';

//https://codesandbox.io/p/sandbox/react-mapbox-draw-circle-forked-ji8zgq?file=%2Fsrc%2Findex.js%3A3%2C1-4%2C1
//https://stackoverflow.com/questions/75210457/mapbox-pins-dont-render
import ReactMapboxGl, { Marker }  from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapInstance from "./components/MapBoxMap/MapInstance";
import { useMap } from "./components/MapBoxMap/MapInstance";
import { MapProvider } from "./components/MapBoxMap/MapInstance";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA"
});

// https://docs.goong.io/goong-map-react/docs/get-started/get-started -> look ugly and slow
// import ReactMapGL from '@goongmaps/goong-map-react';
// import '@goongmaps/goong-js/dist/goong-js.css';


// MinIO Configuration
const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
const BUCKET_NAME = "demo-map";
const FILE_NAME = "location.json";

const center = {
  lat: 21.044175,
  lng: 105.846411,
};

export default function MapContainer() {
  const mapRef = useRef();
  let markersArray = [];
  const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const [properties, setProperties] = useState(data.properties);
  const [DefaultCenter] = useState(data.properties[0]);
  const [activeProperty, setActiveProperty] = useState(data.properties[0]);
  const [filterIsVisible, setFilterIsVisible] = useState(false);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

  const [isClicked, setIsClicked] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  // const handleCartItemClick = (placeId) => {
  //   const place = markers.find((p) => p._id === placeId);
  //   setSelectedPlace(place);
  //   console.log("##### App.js _id #######")
  //   console.log(placeId)
  //   console.log(place._id)
  //   console.log(place)
  //   console.log(selectedPlace)
  //   console.log(selectedPlace._id)
  //   console.log("### done ###")

  // };

  const [mapCenter, setMapCenter] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(14);



  useEffect(() => {
    if (showPopup) {
      // Dispatch the "expand" action when showPopup is true
      store.dispatch({ type: "EXTEND" });
      // console.log("####### redux app.js extend #########")
      // console.log(store.getState().isExtented)
    } else {
      // Dispatch the "shrink" action when showPopup is false
      store.dispatch({ type: "SHRINK" });
      // console.log("####### redux app.js shrink #########")
      // console.log(store.getState().isExtented)
    }
  }, [showPopup]);

  const handleCartItemClick = (placeId) => {
    const place = markers.find((p) => p._id === placeId);
    setSelectedPlace(place);
    // console.log("##### App.js _id #######");
    // console.log(placeId);
    // console.log(place._id);
    // console.log(place);
  };

  // Use `useEffect` to track changes to `selectedPlace`
  useEffect(() => {
    if (selectedPlace) {
      // console.log("##### selectedPlace has changed #####");
      // console.log(selectedPlace._id);
      // console.log(selectedPlace);


      const lat = selectedPlace.location.coordinates[0];
      const lng = selectedPlace.location.coordinates[1];

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Check if the screen is mobile or PC
      const isMobile = screenWidth <= 768;

      let newCenter = {};

      // temp disable offset
      if (false) {
        // For mobile, offset vertically (1/3 of the screen height)
        const offsetY = screenHeight / 3;  // 1/3 of the screen height
        const latitudeOffset = offsetY / 110540;  // Approximate conversion factor (meters per degree of latitude)

        newCenter = {
          lat: lat - latitudeOffset*17,  // Adjust the latitude based on the offset
          // lat: lat - 0.0185,  // Adjust the latitude based on the offset
          lng: lng,  // Keep longitude the same
        };
      } else {
        // For PC, offset horizontally (1/3 of the screen width)
        const offsetX = screenWidth / 3;  // 1/3 of the screen width

        // Approximate 1 degree of longitude change is about 111320 meters at the equator
        const longitudeOffset = offsetX / 111320;  // Convert to degrees longitude

        console.log("############## App.js longitudeOffset ###########")
        console.log(longitudeOffset)

        newCenter = {
          lat: lat,  // Keep latitude the same
          lng: lng + longitudeOffset*17,  // Adjust the longitude based on the offset
          // lng: lng +0.06,  // Adjust the longitude based on the offset

        };
      }

      // setZoomLevel(14)
      // console.log("############## App.js zoomLevel ###########")
      // console.log(zoomLevel)
      setMapCenter(newCenter);


    }
  }, [selectedPlace]); // This effect will run every time `selectedPlace` changes



  // disable pull down to reload -> fix disable pull down reload, now this will affect card click
  // useEffect(() => {
  //   // Function to disable pull-to-refresh on mobile devices, without affecting other events like clicks
  //   const preventPullToRefresh = (event) => {
  //     // Prevent pull-to-refresh by checking if the user is at the top and pulling down
  //     if (window.scrollY <= 0 && event.touches.length === 1) {
  //       const touch = event.touches[0];

  //       // Prevent default only if the user is pulling down at the top of the page
  //       if (touch.clientY > 0) {
  //         event.preventDefault(); // Prevent pull-to-refresh
  //       }
  //     }
  //   };

  //   // Add event listeners to capture and prevent pull-to-refresh actions
  //   document.addEventListener('touchstart', preventPullToRefresh, { passive: false });
  //   document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

  //   return () => {
  //     // Cleanup event listeners when the component is unmounted
  //     document.removeEventListener('touchstart', preventPullToRefresh);
  //     document.removeEventListener('touchmove', preventPullToRefresh);
  //   };
  // }, []);



  // disable zoom
  useEffect(() => {
    const preventZoom = (event) => {
      // Prevent zoom with mouse wheel (Ctrl or Meta key)
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };

    const preventZoomKeyboard = (event) => {
      // Prevent zoom using keyboard shortcuts (Ctrl + '+' / '-' or Cmd + '+' / '-')
      if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
        event.preventDefault();
      }
    };

    const preventPinchZoom = (event) => {
      // Prevent pinch-to-zoom on touch devices
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    // Add event listeners for wheel (mouse zoom), keydown (keyboard zoom), and touch events (pinch zoom)
    document.addEventListener('wheel', preventZoom, { passive: false });
    document.addEventListener('keydown', preventZoomKeyboard, { passive: false });
    document.addEventListener('touchstart', preventPinchZoom, { passive: false });
    document.addEventListener('touchmove', preventPinchZoom, { passive: false });

    return () => {
      // Cleanup event listeners when the component is unmounted
      document.removeEventListener('wheel', preventZoom);
      document.removeEventListener('keydown', preventZoomKeyboard);
      document.removeEventListener('touchstart', preventPinchZoom);
      document.removeEventListener('touchmove', preventPinchZoom);
    };
  }, []);


  const handleClosePopup = (e) => {
    e.stopPropagation(); // Prevent click from propagating to the overlay
    setIsClicked(false); // Close the popup
    setIsSidebarOpen(false); // Close the sidebar
  };

  const [handleFilter, setHandleFilter] = useState({
    filterBedrooms: 'any',
    filterBathrooms: 'any',
    filterCars: 'any',
    filterSort: 'any',
    priceFrom: '0',
    priceTo: '1000001',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'

  const [markers, setMarkers] = useState([]);

  const handleCardClick = (lat, lng) => {
    // Update the highlighted marker coordinates based on the card clicked
    setHighlightedMarker({ lat, lng });
  };

  const fetchMarkers = async () => {
    setLoading(true);
    setError(null);

    // Retrieve token from localStorage
    const token = localStorage.getItem('token');

    try {
      // Include the token in the Authorization header for the API request
      const response = await axios.get(apiBaseUrl, {
        headers: {
          Authorization: `Bearer ${token}`, // Add token to the Authorization header
        },
      });

      // setPlaces(response.data)
      // console.log("########### fetch response App.js ######### ");
      // console.log(response.data);
      setMarkers(response.data);  // Update the state with fetched markers
    } catch (error) {
      setError('Error fetching markers');
      console.error('Error fetching markers:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMarkers();
  }, []);

  const propertiesList = isFiltering ? filteredProperties : properties;

  // Function to toggle filter visibility
  function toggleFilter(e) {
    e.preventDefault();
    setFilterIsVisible(!filterIsVisible);
  }

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
  }

  function clearFilter(e, form) {
    e.preventDefault();
    setProperties(properties.sort((a, b) => a.index - b.index));
    setFilteredProperties([]);
    setActiveProperty(properties[0]);
    setIsFiltering(false);
    setHandleFilter((prevState) => ({
      ...prevState,
      filterBedrooms: 'any',
      filterBathrooms: 'any',
      filterCars: 'any',
      filterSort: 'any',
      priceFrom: '0',
      priceTo: '1000001',
    }));

    form.current.reset();
  }

  const handleMarkerClick = () => {
    // Disable pointer events on the map when a marker is clicked
    if (mapRef?.current && mapRef.current.googleMap) {
      const mapContainer = mapRef.current.googleMap.getDiv();
      mapContainer.style.pointerEvents = 'none'; // Disable pointer events
      setIsClicked(true);

      // Optionally, reset pointer events after a set time or an action
      setTimeout(() => {
        mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
        setIsClicked(false); // Reset the state
      }, 3000); // Adjust timeout duration as needed
    }
  };

  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const handleMapChange = ({ center, zoom, bounds }) => {
    setMapBounds(bounds);
    // Filter places that are within the bounds of the map
    const visible = markers.filter((place) =>
      bounds.contains({ lat: place.lat, lng: place.lng })
    );
    setVisibleMarkers(visible);
  };


  // const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state

  const [gestureHandling, setGestureHandling] = useState('greedy'); // Gesture handling state


  const mapStyle2 = [
    {
      "featureType": "landscape.natural",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#e3f1cc"  // Set the tree/forest area to the green color
        }
      ]
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#a0d08b"  // Darker green for higher tree/land areas
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8c8b7e"  // Set text color to #8c8b7e
        }
      ]
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fafcfb"  // Snow-capped mountains with white color
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#FFFFFF"  // All roads color (white)
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#FFFFFF"  // Arterial roads color (white)
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#FFFFFF"  // Highways color (white)
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#FFFFFF"  // Highway stroke color (white)
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#FFFFFF"  // Controlled-access highways color (white)
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#FFFFFF"  // Text color for local roads (optional, white)
        }
      ]
    },
  ];

  const mapStylev1 = [
    {
        // Styling for country boundaries (e.g., nations)
        "featureType": "administrative.country",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ff0000"  // Red color for country areas
            }
        ]
    },
    {
        // Styling for provinces or states within countries
        "featureType": "administrative.province",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#7b3535"  // Dark red-brown color for provinces
            }
        ]
    },
    {
        // Styling for cities (localities)
        "featureType": "administrative.locality",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#794c4c"  // Medium red-brown for localities
            }
        ]
    },
    {
        // Styling for neighborhoods within cities
        "featureType": "administrative.neighborhood",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#531a1a"  // Dark red color for neighborhood areas
            }
        ]
    },
    {
        // Display labels for neighborhoods
        "featureType": "administrative.neighborhood",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"  // Make neighborhood labels visible
            }
        ]
    },
    {
        // Display text labels for neighborhoods
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"  // Ensure text labels for neighborhoods are visible
            }
        ]
    },
    {
        // Styling for land parcels (plots of land or property boundaries)
        "featureType": "administrative.land_parcel",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#381313"  // Dark reddish-brown color for land parcels
            }
        ]
    },
    {
        // Styling for man-made landscapes (buildings, streets, etc.)
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#e1e1e1"  // Light gray color for man-made features
            }
        ]
    },
    {
        // Styling for arterial roads (main roads that connect major areas)
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"  // Ensure arterial roads are visible
            }
        ]
    },
    {
        // Styling for arterial roads' borders (road strokes)
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"  // Make the road borders (strokes) visible
            }
        ]
    },
    {
        // Styling for local roads (smaller roads for neighborhoods)
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"  // Ensure local roads are visible
            },
            {
                "weight": "0.5"  // Thinner line weight for local roads
            },
            {
                "gamma": "1.65"  // Adjust brightness for local roads
            }
        ]
    },
    {
        // Hide labels for local roads (e.g., road names or street signs)
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"  // Hide text labels for local roads
            }
        ]
    },
    {
        // Styling for water features (lakes, oceans, rivers)
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#cbe4ed"  // Light blue color for water areas
            },
            {
                "visibility": "simplified"  // Simplified representation for water
            },
            {
                "weight": "1.00"  // Set a specific weight for the water geometry
            }
        ]
    },
    {
        // Fill styling for water areas (more detailed color adjustment)
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "48"  // Adjust saturation for water color
            },
            {
                "visibility": "on"  // Ensure water areas are visible
            }
        ]
    },
    {
        // Hide labels for water features (e.g., names of lakes/rivers)
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"  // Hide labels for water areas
            }
        ]
    },
    {
        // Hide text labels for water (names or descriptions)
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"  // Hide text labels for water
            }
        ]
    }
];

  const mapStyle3 =
[

//     {
//       "featureType": "landscape.natural",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#e3f1cc"  // Set the tree/forest area to the green color
//         }
//       ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     // {
//     //     "featureType": "landscape",
//     //     "elementType": "all",
//     //     "stylers": [
//     //         {
//     //             "visibility": "on"
//     //         }
//     //     ]
//     // },
//     {
//         "featureType": "poi",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "lightness": "0"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.medical",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "lightness": "-5"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.park",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "color": "#a7ce95"
//             },
//             {
//                 "lightness": "45"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.school",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#be9b7b"
//             },
//             {
//                 "lightness": "70"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.sports_complex",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#5d4b46"
//             },
//             {
//                 "lightness": "60"
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.station",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "saturation": "23"
//             },
//             {
//                 "lightness": "10"
//             },
//             {
//                 "gamma": "0.8"
//             },
//             {
//                 "hue": "#b000ff"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#cbe4ed"
//             }
//         ]
//     },


//     // country and state border
//     {
//       // Styling for country boundaries (e.g., nations) with a white border
//       "featureType": "administrative.country",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "color": "#ff0000"  // Red color for country areas
//           }
//       ]
//   },
//   {
//       // Styling for the border of country boundaries (white border)
//       "featureType": "administrative.country",
//       "elementType": "geometry.stroke",
//       "stylers": [
//           {
//               "color": "#8c857a"  // White color for the country boundary border
//           }
//       ]
//   },
//   {
//       // Styling for provinces (states) within countries (add dashed lines)
//       "featureType": "administrative.province",
//       "elementType": "geometry.stroke",
//       "stylers": [
//           {
//               "color": "#8c857a",  // White color for the state border
//               "weight": 2,  // Border width
//               "visibility": "on",
//               "strokeOpacity": 0.7,  // Slightly transparent to create a softer effect
//               "strokeDasharray": "4, 2"  // Dashed effect (4px dashes with 2px gaps)
//           }
//       ]
//   },
//   {
//       // Styling for localities (cities)
//       "featureType": "administrative.locality",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "color": "#794c4c"  // Medium red-brown for localities
//           }
//       ]
//   },
//   {
//       // Styling for neighborhoods within cities
//       "featureType": "administrative.neighborhood",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "color": "#531a1a"  // Dark red color for neighborhood areas
//           }
//       ]
//   },
//   {
//       // Display labels for neighborhoods
//       "featureType": "administrative.neighborhood",
//       "elementType": "labels",
//       "stylers": [
//           {
//               "visibility": "on"  // Make neighborhood labels visible
//           }
//       ]
//   },
//   {
//       // Display text labels for neighborhoods
//       "featureType": "administrative.neighborhood",
//       "elementType": "labels.text",
//       "stylers": [
//           {
//               "visibility": "on"  // Ensure text labels for neighborhoods are visible
//           }
//       ]
//   },
//   {
//       // Styling for land parcels (plots of land or property boundaries)
//       "featureType": "administrative.land_parcel",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "color": "#381313"  // Dark reddish-brown color for land parcels
//           }
//       ]
//   },
//   {
//       // Styling for man-made landscapes (buildings, streets, etc.)
//       "featureType": "landscape.man_made",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "color": "#e1e1e1"  // Light gray color for man-made features
//           }
//       ]
//   },
//   {
//       // Styling for arterial roads (main roads that connect major areas)
//       "featureType": "road.arterial",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "visibility": "on"  // Ensure arterial roads are visible
//           }
//       ]
//   },
//   {
//       // Styling for arterial roads' borders (road strokes)
//       "featureType": "road.arterial",
//       "elementType": "geometry.stroke",
//       "stylers": [
//           {
//               "visibility": "on"  // Make the road borders (strokes) visible
//           }
//       ]
//   },
//   {
//       // Styling for local roads (smaller roads for neighborhoods)
//       "featureType": "road.local",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "visibility": "on"  // Ensure local roads are visible
//           },
//           {
//               "weight": "0.5"  // Thinner line weight for local roads
//           },
//           {
//               "gamma": "1.65"  // Adjust brightness for local roads
//           }
//       ]
//   },
//   {
//       // Hide labels for local roads (e.g., road names or street signs)
//       "featureType": "road.local",
//       "elementType": "labels",
//       "stylers": [
//           {
//               "visibility": "off"  // Hide text labels for local roads
//           }
//       ]
//   },
//   {
//       // Styling for water features (lakes, oceans, rivers)
//       "featureType": "water",
//       "elementType": "geometry",
//       "stylers": [
//           {
//               "color": "#add4dd"  // Light blue color for water areas
//           },
//           {
//               "visibility": "simplified"  // Simplified representation for water
//           },
//           {
//               "weight": "1.00"  // Set a specific weight for the water geometry
//           }
//       ]
//   },
//   {
//       // Fill styling for water areas (more detailed color adjustment)
//       "featureType": "water",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "saturation": "48"  // Adjust saturation for water color
//           },
//           {
//               "visibility": "on"  // Ensure water areas are visible
//           }
//       ]
//   },
//   {
//       // Hide labels for water features (e.g., names of lakes/rivers)
//       "featureType": "water",
//       "elementType": "labels",
//       "stylers": [
//           {
//               "visibility": "off"  // Hide labels for water areas
//           }
//       ]
//   },
//   {
//       // Hide text labels for water (names or descriptions)
//       "featureType": "water",
//       "elementType": "labels.text.fill",
//       "stylers": [
//           {
//               "visibility": "off"  // Hide text labels for water
//           }
//       ]
//   },


// // the road
// {
//   "featureType": "road",
//   "elementType": "geometry",
//   "stylers": [
//     {
//       "color": "#FFFFFF"  // All roads color (white)
//     }
//   ]
// },
// {
//   "featureType": "road.arterial",
//   "elementType": "geometry",
//   "stylers": [
//     {
//       "color": "#FFFFFF"  // Arterial roads color (white)
//     }
//   ]
// },
// {
//   "featureType": "road.highway",
//   "elementType": "geometry",
//   "stylers": [
//     {
//       "color": "#FFFFFF"  // Highways color (white)
//     }
//   ]
// },
// {
//   "featureType": "road.highway",
//   "elementType": "geometry.stroke",
//   "stylers": [
//     {
//       "color": "#FFFFFF"  // Highway stroke color (white)
//     }
//   ]
// },
// {
//   "featureType": "road.highway.controlled_access",
//   "elementType": "geometry",
//   "stylers": [
//     {
//       "color": "#FFFFFF"  // Controlled-access highways color (white)
//     }
//   ]
// },
// {
//   "featureType": "road.highway.controlled_access",
//   "elementType": "geometry.stroke",
//   "stylers": [
//     {
//       "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
//     }
//   ]
// },
// {
//   "featureType": "road.local",
//   "elementType": "labels.text.fill",
//   "stylers": [
//     {
//       "color": "#FFFFFF"  // Text color for local roads (optional, white)
//     }
//   ]
// },


// land with diff height
// {
//   "featureType": "landscape.natural",
//   "elementType": "geometry.fill",
//   "stylers": [
//     {
//       "color": "#e3f1cc"  // Light green for parks/green spaces
//     }
//   ]
// },
// {
//   "featureType": "landscape.natural.wood",
//   "elementType": "geometry.fill",
//   "stylers": [
//     {
//       "color": "#6b8e23"  // Darker green for woodlands (forest green)
//     }
//   ]
// },
// {
//   "featureType": "landscape.natural.earth",
//   "elementType": "geometry.fill",
//   "stylers": [
//     {
//       "color": "#a8d08d"  // Medium green for grasslands or open areas
//     }
//   ]
// },
// {
//   "featureType": "landscape.natural",
//   "elementType": "geometry.fill",
//   "stylers": [
//     {
//       "color": "#e3f1cc"  // Set the tree/forest area to the green color
//     }
//   ]
// },

];

const mapStyle=[
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#121212"
      },
      {
        "lightness": 60
      },
      {
        "weight": 0.5
      }
    ]
  },
  {
    "featureType": "landscape.natural.landcover",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "weight": 3.5
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#e3f1cc"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];
const mapStyle5= [
  {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "hue": null,
              "lightness": null,
              "saturation": null,
              "gamma": null,
              "invertLightness": null,
              "visibility": null,
              "color": "#71757a",
              "weight": null,
              "textScale": null,
              "zoom": null
          }
      ]
  },
  {
      "featureType": "land.land_cover.landsat_vegetation.dry_crops",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "hue": null,
              "lightness": null,
              "saturation": null,
              "gamma": null,
              "invertLightness": null,
              "visibility": null,
              "color": "#e7f2ca",
              "weight": null,
              "textScale": null,
              "zoom": null
          }
      ]
  },
  {
      "featureType": "land.land_cover.landsat_vegetation.mixed_crops",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "hue": null,
              "lightness": null,
              "saturation": null,
              "gamma": null,
              "invertLightness": null,
              "visibility": null,
              "color": "#dbf2c4",
              "weight": null,
              "textScale": null,
              "zoom": null
          }
      ]
  },
  {
      "featureType": "land.land_cover.landsat_vegetation.mixed_forest",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "hue": null,
              "lightness": null,
              "saturation": null,
              "gamma": null,
              "invertLightness": null,
              "visibility": null,
              "color": "#dbf2c4",
              "weight": null,
              "textScale": null,
              "zoom": null
          }
      ]
  },
  {
      "featureType": "land.water",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "hue": null,
              "lightness": null,
              "saturation": null,
              "gamma": null,
              "invertLightness": null,
              "visibility": null,
              "color": "#cae8f2",
              "weight": null,
              "textScale": null,
              "zoom": null
          }
      ]
  },
  {
      "featureType": "transportation.road",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "hue": null,
              "lightness": null,
              "saturation": null,
              "gamma": null,
              "invertLightness": null,
              "visibility": null,
              "color": "#ffffff",
              "weight": null,
              "textScale": null,
              "zoom": null
          }
      ]
  },
  {
      "featureType": "transportation.road",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "hue": null,
              "lightness": null,
              "saturation": null,
              "gamma": null,
              "invertLightness": null,
              "visibility": null,
              "color": null,
              "weight": 0,
              "textScale": null,
              "zoom": null
          }
      ]
  }
];

const mapStyle6 =[
  {
    "featureType": "land.land_cover.landsat_vegetation.dry_crops",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "hue": null,
            "lightness": null,
            "saturation": null,
            "gamma": null,
            "invertLightness": null,
            "visibility": null,
            "color": "#e7f2ca",
            "weight": null,
            "textScale": null,
            "zoom": null
        }
    ]
},

];


// mapbox
// const [viewport, setViewport] = useState({
//   latitude: 21.032275891056212,
//   longitude: 105.81722252298444,
//   zoom: 12,
// });
//   // Replace this with your Mapbox Access Token
//   const mapboxAccessToken = 'pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA';

//   // Customize your Mapbox style if needed
//   const mapBoxStyle = 'mapbox://styles/mapbox/streets-v11';

const [viewport, setViewport] = React.useState({
  latitude: 21.03072,
  longitude: 105.85239,
  zoom: 12
});




// fix jiggling


// use shared map instance
  const map = useMap(); // Access the shared map instance
  useEffect(() => {
    if (map) {
      // You can perform additional map manipulation if needed, like adding markers or setting the center/zoom
      console.log('Shared map instance:', map);
      // Example: map.setCenter([105.8000, 21.0381]); // You can adjust the map center programmatically
    }

    console.log('App.js done useEffect');

  }, [map]);

  return (
    // <MapProvider>
    <Router>
      <Routes>
        <Route
          path="/explore"
          element={
            <AppContainer>
              {/* listings - Start */}
              <div className="mapContainer">

                <h1 className="userList" style={{zIndex: '1 !important'}}>
                  <UserList className="userList" />
                </h1>


                {/* <ReactMapGL
                  {...viewport}
                  width="100%"
                  height="100%"
                  onViewportChange={(viewport) => setViewport(viewport)}
                /> */}


                {/* use share map instance, retry later, map does not work prop, cannot drag, only load 1/2 */}
                {/* <div style={{ height: '100%', width: '100%' }}>
                      {map && (
                        <>
                                  {loading ? (
                                    <p>Loading markers...</p>
                                  ) : (
                                    markers.map((marker) => {
                                      const { coordinates } = marker.location; // Destructure coordinates
                                      const lat = coordinates[1]; // Latitude
                                      const lng = coordinates[0]; // Longitude

                                      // Log lat and lon for each marker
                                      // console.log(`Marker ID: ${marker._id}, Latitude: ${lat}, Longitude: ${lng}`);

                                      return(
                                      <Marker coordinates={[lat, lng]}>
                                        <Marker2
                                        key={marker._id} // Ensure each marker has a unique key
                                        lat={marker.location.coordinates[0]} // Latitude at index 1
                                        lng={marker.location.coordinates[1]} // Longitude at index 0
                                        text={marker.title} // Title of the marker
                                        property={marker}
                                        mapRef={map}
                                        onClick={handleMarkerClick} // Trigger the handleMarkerClick function
                                        selectedPlace={selectedPlace}
                                        setGestureHandling={setGestureHandling}
                                      />
                                    </Marker>
                                      )
                                    })
                                  )}
                        </>
                      )}
                </div> */}



                <Map
                  // style="mapbox://styles/mapbox/standard"
                  style="mapbox://styles/nhan131998/cm5e0e8o100fp01rg8n9a0ws6"
                  containerStyle={{
                    height: "100vh",
                    width: "100vw"
                  }}
                  center={[ 105.8000, 21.0381 ]} // Set the default center of the map to Hanoi, Vietnam (Longitude, Latitude)
                  //     105.8542, 21.0285
                  zoom={[12]} // Set the default zoom level
                  pitch={[50]} // Set the default tilt (pitch) angle (45 degrees)
                  // dragRotate={false} // Disable rotation (dragRotate set to false)
                  // touchZoomRotate={false} // Disable zoom/rotate with touch gestures
                  scrollZoom={true} // Enable zoom with scroll
                  // doubleClickZoom={false} // Disable double click to zoom
                  bearing={[60]}
                >


                  <Marker coordinates={[ -73.9857, 40.7484 ]}>
                    <div style={{ backgroundColor: 'red', padding: '5px', borderRadius: '50%' }}>
                      <span style={{ color: 'white', fontSize: '16px' }}>📍</span>
                    </div>
                  </Marker>

                  {loading ? (
                    <p>Loading markers...</p>
                  ) : (
                    markers.map((marker) => {
                      const { coordinates } = marker.location; // Destructure coordinates
                      const lat = coordinates[1]; // Latitude
                      const lng = coordinates[0]; // Longitude

                      // Log lat and lon for each marker
                      console.log(`Marker ID: ${marker._id}, Latitude: ${lat}, Longitude: ${lng}`);

                      return(
                      <Marker coordinates={[lat, lng]}>
                        <Marker2
                        key={marker._id} // Ensure each marker has a unique key
                        lat={marker.location.coordinates[0]} // Latitude at index 1
                        lng={marker.location.coordinates[1]} // Longitude at index 0
                        text={marker.title} // Title of the marker
                        property={marker}
                        mapRef={mapRef}
                        onClick={handleMarkerClick} // Trigger the handleMarkerClick function
                        selectedPlace={selectedPlace}
                        setGestureHandling={setGestureHandling}
                      />
                    </Marker>
                      )
                    })
                  )}
                </Map>




                 {/* <GoogleMapReact

                  bootstrapURLKeys={{ key:
                    //  "AIzaSyAvjN_L592ZgMDP6u_P39hhtqZ8wtNYE6s"
                  "AIzaSyDfnY7GcBdHHFQTxRCSJGR-AGUEUnMBfqo"
                  // ""
                   }}
                  // bootstrapURLKeys={{ key: "" }}
                  defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
                  // defaultZoom={12} // 20 fix zoom
                  yesIWantToUseGoogleMapApiInternals
                  ref={mapRef}
                  options={{
                    gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
                    // scrollwheel: false,       // Disable scroll zoom
                    // draggable: false,         // Disable dragging
                    // disableDoubleClickZoom: true, // Disable double-click zoom
                    styles: mapStyle,
                    // mapId: 'd9fa7933e82bb5fd'
                  }}
                  // onChange={handleMapChange}
                  // center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1]+0.35 } : undefined}

                  center={mapCenter}


                  // zoom={selectedPlace ? 14 : 12}
                  zoom={zoomLevel}
                  onChange={({ center, zoom }) => {
                    // Optional: You can log or do something when the map center or zoom changes
                    console.log('Zoom changed to: ', zoom);
                    console.log("############## App.js zoomLevel insdie onChange ###########")
                    console.log(zoomLevel)
                  }}
                  onZoomAnimationEnd={() => {
                    // Optional: You can handle the zoom completion if needed
                    console.log('Zoom Animation Ended');
                  }}
                >


                  {loading ? (
                    <p>Loading markers...</p>
                  ) : (
                    markers.map((marker) => (
                      <Marker2
                        key={marker._id} // Ensure each marker has a unique key
                        lat={marker.location.coordinates[0]} // Latitude at index 1
                        lng={marker.location.coordinates[1]} // Longitude at index 0
                        text={marker.title} // Title of the marker
                        property={marker}
                        mapRef={mapRef}
                        onClick={handleMarkerClick} // Trigger the handleMarkerClick function
                        selectedPlace={selectedPlace}
                        setGestureHandling={setGestureHandling}
                      />
                    ))
                  )}
                </GoogleMapReact> */}
                {/* </ReactMapGL> */}

              </div>

              <div className="listings">
                <Header
                  filterIsVisible={filterIsVisible}
                  toggleFilter={toggleFilter}
                  clearFilter={clearFilter}
                  handleFilterChange={handleFilterChange}
                />
                {/* <div
                    style={{
                        position: "absolute",
                        top: "0%", // Always at the bottom of the expanded area
                        left: "0",
                        width: "100%",
                        height: "25px", // Fixed height for the swipeable bar
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        zIndex: 10,
                    }}
                    // onTouchStart={handleTouchStart}
                    // onTouchMove={handleTouchMove}
                    // onTouchEnd={handleTouchEnd}
                >

                    <div
                        style={{
                            position: "absolute",
                            width: "40px",
                            height: "5px",
                            backgroundColor: "#D3D3D3",
                            borderRadius: "3px",
                            top: "50%",
                            left: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                        }}
                    ></div>

                </div> */}
                <div className="cards container">
                  <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
                    {markers.map((marker, index) => {
                      const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
                      const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

                      return (
                        <Card
                          key={marker._id}
                          property={marker}
                          activeProperty={activeProperty}
                          setActiveProperty={setActiveProperty}
                          setShowPopup={setShowPopup}
                          isDoubleHeight={isDoubleHeight}  // Pass the calculated value
                          onCartItemClick={handleCartItemClick}
                          setZoomLevel={setZoomLevel}
                        />
                      );
                    })}
                  </div>

                  {isFiltering && propertiesList.length === 0 && (
                    <p className="warning">
                      <img src={image} alt="No properties were found." />
                      <br />
                      No properties were found.
                    </p>
                  )}

                  {showPopup && activeProperty && (
                    <Popup property={activeProperty} setShowPopup={setShowPopup} />
                  )}
                </div>
              </div>
              {/* listings - End */}
            </AppContainer>
          }
        />
        <Route path="/" element={<MapComponent />} />
        {/* <Route path="/" element={<GridLayout items={markers} />} /> */}
        <Route path="/user/:id" element={<UserPage />} />
        <Route path="/media/files" element={<MinioFileList />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/man" element={<GridAsymmetrical />} />



      </Routes>
    </Router>
    // </MapProvider>
  );
}

// Raindrop Component
const RainDrop = ({ initialCoordinates, speed = 0.0001, size = 5 }) => {
  const [dropPosition, setDropPosition] = useState(initialCoordinates);
  const [falling, setFalling] = useState(true);

  useEffect(() => {
    const animateRainDrop = () => {
      let dropLongitude = dropPosition[0];
      let dropLatitude = dropPosition[1];

      // Move the raindrop downwards (latitude decreases)
      if (falling) {
        dropLatitude -= speed; // Move raindrop down
        if (dropLatitude < 20.0) { // If the raindrop reaches the bottom, reset position
          dropLatitude = 21.5; // Reset to top
          dropLongitude = 105.85 + Math.random() * 0.2; // Randomize longitude
        }
      }

      // Update raindrop position
      setDropPosition([dropLongitude, dropLatitude]);
    };

    const interval = setInterval(animateRainDrop, 50);

    return () => clearInterval(interval);
  }, [dropPosition, falling, speed]);

  return (
    <Marker coordinates={dropPosition}>
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: 'rgba(0, 0, 255, 0.6)',  // Blue color for the raindrop
          borderRadius: '50%', // Circle shape
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Optional: Shadow for floating effect
        }}
      />
    </Marker>
  );
};

// RainAnimation Component
const RainAnimation = ({ numDrops = 50, speed = 0.0001 }) => {
  const drops = Array.from({ length: numDrops }, () => ({
    coordinates: [105.85 + Math.random() * 0.2, 21.5], // Randomize longitude positions
  }));

  return (
    <div>
      {drops.map((drop, index) => (
        <RainDrop
          key={index}
          initialCoordinates={drop.coordinates}
          speed={speed}
        />
      ))}
    </div>
  );
};


const FlyingCloud = ({ initialCoordinates, numberOfRaindrops = 100, rainOffsetX = 10 }) => {
  const [raindrops, setRaindrops] = useState([]);

  // Number of lumps the cloud has
  const cloudLumps = [
    { xOffset: 5, yOffset: 5 },
    { xOffset: 25, yOffset: 15 },
    { xOffset: 60, yOffset: 5 },
    { xOffset: 75, yOffset: 20 },
    { xOffset: 105, yOffset: 35 },
  ];

  // Generate initial raindrop positions and speeds
  useEffect(() => {
    const generateRaindrops = () => {
      const newRaindrops = [];

      for (let i = 0; i < numberOfRaindrops; i++) {
        const randomLump = cloudLumps[Math.floor(Math.random() * cloudLumps.length)];

        newRaindrops.push({
          id: i,
          startX: randomLump.xOffset + Math.random() * 20 - 10, // Random X offset around the lump
          startY: randomLump.yOffset + 30, // Start slightly below the lump
          endY: randomLump.yOffset + 80 + Math.random() * 50, // Random fall distance
          positionX: randomLump.xOffset + Math.random() * 20 - 10 + rainOffsetX, // Apply the horizontal offset
          positionY: randomLump.yOffset + 30, // Start slightly below the lump
          speed: Math.random() * 2 + 2, // Random speed for each raindrop
          isFalling: true,
        });
      }
      setRaindrops(newRaindrops);
    };

    generateRaindrops();
  }, [numberOfRaindrops, rainOffsetX]); // Re-run the generation if offset or number changes

  // Move raindrops at a fixed interval
  useEffect(() => {
    const interval = setInterval(() => {
      setRaindrops((prevRaindrops) =>
        prevRaindrops.map((drop) => {
          if (drop.isFalling) {
            if (drop.positionY < drop.endY) {
              return {
                ...drop,
                positionY: drop.positionY + drop.speed, // Move raindrop down with its own speed
              };
            } else {
              // Reset raindrop to the starting position after falling for 2 seconds
              return {
                ...drop,
                positionY: drop.startY,
                isFalling: true, // Set to falling state again
              };
            }
          }
          return drop;
        })
      );
    }, 50); // 50ms interval for smoother animation

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <Marker coordinates={initialCoordinates}>
      <div style={{
        position: 'relative',
        width: '120px',
        height: '60px',
        zIndex: 10,
      }}>
        {/* Cloud Lumps */}
        <div style={{
          position: 'absolute',
          width: '40px',
          height: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '50px',
          top: '5px',
          left: '5px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }}></div>

        <div style={{
          position: 'absolute',
          width: '50px',
          height: '25px',
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
          borderRadius: '50px',
          top: '15px',
          left: '25px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        }}></div>

        <div style={{
          position: 'absolute',
          width: '45px',
          height: '25px',
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          borderRadius: '50px',
          top: '5px',
          left: '60px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        }}></div>

        <div style={{
          position: 'absolute',
          width: '60px',
          height: '30px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '50px',
          top: '20px',
          left: '75px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        }}></div>

        <div style={{
          position: 'absolute',
          width: '50px',
          height: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          borderRadius: '50px',
          top: '35px',
          left: '105px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        }}></div>

        {/* Raindrops */}
        {raindrops.map((drop) => (
          <div
            key={drop.id}
            style={{
              position: 'absolute',
              top: `${drop.positionY}px`,
              left: `${drop.positionX}px`,
              width: '2px',
              height: '12px', // Thinner and longer raindrop
              backgroundColor: 'rgb(196,230,248)', // Raindrop color
              borderRadius: '2px', // Round edges for a smooth look
              animation: 'fall 2s linear infinite', // Smooth falling effect
              opacity: Math.random() * 0.6 + 0.4, // Random opacity for realism
            }}
          />
        ))}
      </div>
    </Marker>
  );
};










// v5.4 make the recenter offset more dynamic
// fix zoom level, it does not return back to 14 on click on a card -> the zoomLevel is updated but the map recenter still has prob when i custom zoom in
// ok lock zoomin

// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";
// import { store} from './redux/MyRedux'
// // import GridLayout from "./components/UserList"; // UserList -> in ExplorePage.js and imported as Gridlayout
// import UserPage from "./components/UserPage";
// // import MinioFileList from "./components";
// import HomePage from "./components/HomePage";
// import UserList from "./components/UserList";

// import { GridAsymmetrical } from "./components/MapComponent Mantine";


// // // map box
// // import ReactMapGL from 'react-map-gl'; // Import react-map-gl
// // import mapboxgl from 'mapbox-gl'; // Import Mapbox GL
// // import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox CSS

// // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
// // import mapboxgl from 'mapbox-gl'
// // import 'mapbox-gl/dist/mapbox-gl.css';

// //https://codesandbox.io/p/sandbox/react-mapbox-draw-circle-forked-ji8zgq?file=%2Fsrc%2Findex.js%3A3%2C1-4%2C1
// //https://stackoverflow.com/questions/75210457/mapbox-pins-dont-render
// import ReactMapboxGl, { Marker }  from "react-mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";


// const Map = ReactMapboxGl({
//   accessToken:
//     "pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA"
// });

// // https://docs.goong.io/goong-map-react/docs/get-started/get-started -> look ugly and slow
// // import ReactMapGL from '@goongmaps/goong-map-react';
// // import '@goongmaps/goong-js/dist/goong-js.css';


// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   const [mapCenter, setMapCenter] = useState(null);
//   const [zoomLevel, setZoomLevel] = useState(14);



//   useEffect(() => {
//     if (showPopup) {
//       // Dispatch the "expand" action when showPopup is true
//       store.dispatch({ type: "EXTEND" });
//       // console.log("####### redux app.js extend #########")
//       // console.log(store.getState().isExtented)
//     } else {
//       // Dispatch the "shrink" action when showPopup is false
//       store.dispatch({ type: "SHRINK" });
//       // console.log("####### redux app.js shrink #########")
//       // console.log(store.getState().isExtented)
//     }
//   }, [showPopup]);

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     // console.log("##### App.js _id #######");
//     // console.log(placeId);
//     // console.log(place._id);
//     // console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       // console.log("##### selectedPlace has changed #####");
//       // console.log(selectedPlace._id);
//       // console.log(selectedPlace);


//       const lat = selectedPlace.location.coordinates[0];
//       const lng = selectedPlace.location.coordinates[1];

//       const screenWidth = window.innerWidth;
//       const screenHeight = window.innerHeight;

//       // Check if the screen is mobile or PC
//       const isMobile = screenWidth <= 768;

//       let newCenter = {};

//       // temp disable offset
//       if (false) {
//         // For mobile, offset vertically (1/3 of the screen height)
//         const offsetY = screenHeight / 3;  // 1/3 of the screen height
//         const latitudeOffset = offsetY / 110540;  // Approximate conversion factor (meters per degree of latitude)

//         newCenter = {
//           lat: lat - latitudeOffset*17,  // Adjust the latitude based on the offset
//           // lat: lat - 0.0185,  // Adjust the latitude based on the offset
//           lng: lng,  // Keep longitude the same
//         };
//       } else {
//         // For PC, offset horizontally (1/3 of the screen width)
//         const offsetX = screenWidth / 3;  // 1/3 of the screen width

//         // Approximate 1 degree of longitude change is about 111320 meters at the equator
//         const longitudeOffset = offsetX / 111320;  // Convert to degrees longitude

//         console.log("############## App.js longitudeOffset ###########")
//         console.log(longitudeOffset)

//         newCenter = {
//           lat: lat,  // Keep latitude the same
//           lng: lng + longitudeOffset*17,  // Adjust the longitude based on the offset
//           // lng: lng +0.06,  // Adjust the longitude based on the offset

//         };
//       }

//       // setZoomLevel(14)
//       // console.log("############## App.js zoomLevel ###########")
//       // console.log(zoomLevel)
//       setMapCenter(newCenter);


//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes



//   // disable pull down to reload -> fix disable pull down reload, now this will affect card click
//   // useEffect(() => {
//   //   // Function to disable pull-to-refresh on mobile devices, without affecting other events like clicks
//   //   const preventPullToRefresh = (event) => {
//   //     // Prevent pull-to-refresh by checking if the user is at the top and pulling down
//   //     if (window.scrollY <= 0 && event.touches.length === 1) {
//   //       const touch = event.touches[0];

//   //       // Prevent default only if the user is pulling down at the top of the page
//   //       if (touch.clientY > 0) {
//   //         event.preventDefault(); // Prevent pull-to-refresh
//   //       }
//   //     }
//   //   };

//   //   // Add event listeners to capture and prevent pull-to-refresh actions
//   //   document.addEventListener('touchstart', preventPullToRefresh, { passive: false });
//   //   document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

//   //   return () => {
//   //     // Cleanup event listeners when the component is unmounted
//   //     document.removeEventListener('touchstart', preventPullToRefresh);
//   //     document.removeEventListener('touchmove', preventPullToRefresh);
//   //   };
//   // }, []);



//   // disable zoom
//   useEffect(() => {
//     const preventZoom = (event) => {
//       // Prevent zoom with mouse wheel (Ctrl or Meta key)
//       if (event.ctrlKey || event.metaKey) {
//         event.preventDefault();
//       }
//     };

//     const preventZoomKeyboard = (event) => {
//       // Prevent zoom using keyboard shortcuts (Ctrl + '+' / '-' or Cmd + '+' / '-')
//       if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
//         event.preventDefault();
//       }
//     };

//     const preventPinchZoom = (event) => {
//       // Prevent pinch-to-zoom on touch devices
//       if (event.touches.length > 1) {
//         event.preventDefault();
//       }
//     };

//     // Add event listeners for wheel (mouse zoom), keydown (keyboard zoom), and touch events (pinch zoom)
//     document.addEventListener('wheel', preventZoom, { passive: false });
//     document.addEventListener('keydown', preventZoomKeyboard, { passive: false });
//     document.addEventListener('touchstart', preventPinchZoom, { passive: false });
//     document.addEventListener('touchmove', preventPinchZoom, { passive: false });

//     return () => {
//       // Cleanup event listeners when the component is unmounted
//       document.removeEventListener('wheel', preventZoom);
//       document.removeEventListener('keydown', preventZoomKeyboard);
//       document.removeEventListener('touchstart', preventPinchZoom);
//       document.removeEventListener('touchmove', preventPinchZoom);
//     };
//   }, []);


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'

//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       // console.log("########### fetch response App.js ######### ");
//       // console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };


//   // const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state

//   const [gestureHandling, setGestureHandling] = useState('greedy'); // Gesture handling state


//   const mapStyle2 = [
//     {
//       "featureType": "landscape.natural",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#e3f1cc"  // Set the tree/forest area to the green color
//         }
//       ]
//     },
//     {
//       "featureType": "landscape.natural.terrain",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#a0d08b"  // Darker green for higher tree/land areas
//         }
//       ]
//     },
//     {
//       "elementType": "labels.text.fill",
//       "stylers": [
//         {
//           "color": "#8c8b7e"  // Set text color to #8c8b7e
//         }
//       ]
//     },
//     {
//       "featureType": "landscape.natural.terrain",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#fafcfb"  // Snow-capped mountains with white color
//         }
//       ]
//     },
//     {
//       "featureType": "road",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // All roads color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.arterial",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Arterial roads color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Highways color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway",
//       "elementType": "geometry.stroke",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Highway stroke color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway.controlled_access",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Controlled-access highways color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway.controlled_access",
//       "elementType": "geometry.stroke",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.local",
//       "elementType": "labels.text.fill",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Text color for local roads (optional, white)
//         }
//       ]
//     },
//   ];

//   const mapStylev1 = [
//     {
//         // Styling for country boundaries (e.g., nations)
//         "featureType": "administrative.country",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#ff0000"  // Red color for country areas
//             }
//         ]
//     },
//     {
//         // Styling for provinces or states within countries
//         "featureType": "administrative.province",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#7b3535"  // Dark red-brown color for provinces
//             }
//         ]
//     },
//     {
//         // Styling for cities (localities)
//         "featureType": "administrative.locality",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#794c4c"  // Medium red-brown for localities
//             }
//         ]
//     },
//     {
//         // Styling for neighborhoods within cities
//         "featureType": "administrative.neighborhood",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#531a1a"  // Dark red color for neighborhood areas
//             }
//         ]
//     },
//     {
//         // Display labels for neighborhoods
//         "featureType": "administrative.neighborhood",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "on"  // Make neighborhood labels visible
//             }
//         ]
//     },
//     {
//         // Display text labels for neighborhoods
//         "featureType": "administrative.neighborhood",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure text labels for neighborhoods are visible
//             }
//         ]
//     },
//     {
//         // Styling for land parcels (plots of land or property boundaries)
//         "featureType": "administrative.land_parcel",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#381313"  // Dark reddish-brown color for land parcels
//             }
//         ]
//     },
//     {
//         // Styling for man-made landscapes (buildings, streets, etc.)
//         "featureType": "landscape.man_made",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#e1e1e1"  // Light gray color for man-made features
//             }
//         ]
//     },
//     {
//         // Styling for arterial roads (main roads that connect major areas)
//         "featureType": "road.arterial",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure arterial roads are visible
//             }
//         ]
//     },
//     {
//         // Styling for arterial roads' borders (road strokes)
//         "featureType": "road.arterial",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "visibility": "on"  // Make the road borders (strokes) visible
//             }
//         ]
//     },
//     {
//         // Styling for local roads (smaller roads for neighborhoods)
//         "featureType": "road.local",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure local roads are visible
//             },
//             {
//                 "weight": "0.5"  // Thinner line weight for local roads
//             },
//             {
//                 "gamma": "1.65"  // Adjust brightness for local roads
//             }
//         ]
//     },
//     {
//         // Hide labels for local roads (e.g., road names or street signs)
//         "featureType": "road.local",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide text labels for local roads
//             }
//         ]
//     },
//     {
//         // Styling for water features (lakes, oceans, rivers)
//         "featureType": "water",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#cbe4ed"  // Light blue color for water areas
//             },
//             {
//                 "visibility": "simplified"  // Simplified representation for water
//             },
//             {
//                 "weight": "1.00"  // Set a specific weight for the water geometry
//             }
//         ]
//     },
//     {
//         // Fill styling for water areas (more detailed color adjustment)
//         "featureType": "water",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "saturation": "48"  // Adjust saturation for water color
//             },
//             {
//                 "visibility": "on"  // Ensure water areas are visible
//             }
//         ]
//     },
//     {
//         // Hide labels for water features (e.g., names of lakes/rivers)
//         "featureType": "water",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide labels for water areas
//             }
//         ]
//     },
//     {
//         // Hide text labels for water (names or descriptions)
//         "featureType": "water",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide text labels for water
//             }
//         ]
//     }
// ];

//   const mapStyle3 =
// [

// //     {
// //       "featureType": "landscape.natural",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //         {
// //           "color": "#e3f1cc"  // Set the tree/forest area to the green color
// //         }
// //       ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "geometry.stroke",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "labels.text",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     // {
// //     //     "featureType": "landscape",
// //     //     "elementType": "all",
// //     //     "stylers": [
// //     //         {
// //     //             "visibility": "on"
// //     //         }
// //     //     ]
// //     // },
// //     {
// //         "featureType": "poi",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "lightness": "0"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.medical",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "lightness": "-5"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.park",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             },
// //             {
// //                 "color": "#a7ce95"
// //             },
// //             {
// //                 "lightness": "45"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.school",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "color": "#be9b7b"
// //             },
// //             {
// //                 "lightness": "70"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.sports_complex",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "color": "#5d4b46"
// //             },
// //             {
// //                 "lightness": "60"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "road",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "transit.station",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "saturation": "23"
// //             },
// //             {
// //                 "lightness": "10"
// //             },
// //             {
// //                 "gamma": "0.8"
// //             },
// //             {
// //                 "hue": "#b000ff"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "water",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "water",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "color": "#cbe4ed"
// //             }
// //         ]
// //     },


// //     // country and state border
// //     {
// //       // Styling for country boundaries (e.g., nations) with a white border
// //       "featureType": "administrative.country",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#ff0000"  // Red color for country areas
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for the border of country boundaries (white border)
// //       "featureType": "administrative.country",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "color": "#8c857a"  // White color for the country boundary border
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for provinces (states) within countries (add dashed lines)
// //       "featureType": "administrative.province",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "color": "#8c857a",  // White color for the state border
// //               "weight": 2,  // Border width
// //               "visibility": "on",
// //               "strokeOpacity": 0.7,  // Slightly transparent to create a softer effect
// //               "strokeDasharray": "4, 2"  // Dashed effect (4px dashes with 2px gaps)
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for localities (cities)
// //       "featureType": "administrative.locality",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#794c4c"  // Medium red-brown for localities
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for neighborhoods within cities
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#531a1a"  // Dark red color for neighborhood areas
// //           }
// //       ]
// //   },
// //   {
// //       // Display labels for neighborhoods
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Make neighborhood labels visible
// //           }
// //       ]
// //   },
// //   {
// //       // Display text labels for neighborhoods
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "labels.text",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure text labels for neighborhoods are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for land parcels (plots of land or property boundaries)
// //       "featureType": "administrative.land_parcel",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#381313"  // Dark reddish-brown color for land parcels
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for man-made landscapes (buildings, streets, etc.)
// //       "featureType": "landscape.man_made",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#e1e1e1"  // Light gray color for man-made features
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for arterial roads (main roads that connect major areas)
// //       "featureType": "road.arterial",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure arterial roads are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for arterial roads' borders (road strokes)
// //       "featureType": "road.arterial",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Make the road borders (strokes) visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for local roads (smaller roads for neighborhoods)
// //       "featureType": "road.local",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure local roads are visible
// //           },
// //           {
// //               "weight": "0.5"  // Thinner line weight for local roads
// //           },
// //           {
// //               "gamma": "1.65"  // Adjust brightness for local roads
// //           }
// //       ]
// //   },
// //   {
// //       // Hide labels for local roads (e.g., road names or street signs)
// //       "featureType": "road.local",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide text labels for local roads
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for water features (lakes, oceans, rivers)
// //       "featureType": "water",
// //       "elementType": "geometry",
// //       "stylers": [
// //           {
// //               "color": "#add4dd"  // Light blue color for water areas
// //           },
// //           {
// //               "visibility": "simplified"  // Simplified representation for water
// //           },
// //           {
// //               "weight": "1.00"  // Set a specific weight for the water geometry
// //           }
// //       ]
// //   },
// //   {
// //       // Fill styling for water areas (more detailed color adjustment)
// //       "featureType": "water",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "saturation": "48"  // Adjust saturation for water color
// //           },
// //           {
// //               "visibility": "on"  // Ensure water areas are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Hide labels for water features (e.g., names of lakes/rivers)
// //       "featureType": "water",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide labels for water areas
// //           }
// //       ]
// //   },
// //   {
// //       // Hide text labels for water (names or descriptions)
// //       "featureType": "water",
// //       "elementType": "labels.text.fill",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide text labels for water
// //           }
// //       ]
// //   },


// // // the road
// // {
// //   "featureType": "road",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // All roads color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.arterial",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Arterial roads color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Highways color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway",
// //   "elementType": "geometry.stroke",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Highway stroke color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway.controlled_access",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Controlled-access highways color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway.controlled_access",
// //   "elementType": "geometry.stroke",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.local",
// //   "elementType": "labels.text.fill",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Text color for local roads (optional, white)
// //     }
// //   ]
// // },


// // land with diff height
// // {
// //   "featureType": "landscape.natural",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#e3f1cc"  // Light green for parks/green spaces
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural.wood",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#6b8e23"  // Darker green for woodlands (forest green)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural.earth",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#a8d08d"  // Medium green for grasslands or open areas
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#e3f1cc"  // Set the tree/forest area to the green color
// //     }
// //   ]
// // },

// ];

// const mapStyle=[
//   {
//     "featureType": "administrative.country",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "color": "#121212"
//       },
//       {
//         "lightness": 60
//       },
//       {
//         "weight": 0.5
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.natural.landcover",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "weight": 3.5
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.natural.terrain",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#e3f1cc"
//       }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "transit",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   }
// ];
// const mapStyle5= [
//   {
//       "featureType": "administrative.country",
//       "elementType": "labels.text.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#71757a",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.dry_crops",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#e7f2ca",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.mixed_crops",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#dbf2c4",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.mixed_forest",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#dbf2c4",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.water",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#cae8f2",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "transportation.road",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#ffffff",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "transportation.road",
//       "elementType": "geometry.stroke",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": null,
//               "weight": 0,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   }
// ];

// const mapStyle6 =[
//   {
//     "featureType": "land.land_cover.landsat_vegetation.dry_crops",
//     "elementType": "geometry.fill",
//     "stylers": [
//         {
//             "hue": null,
//             "lightness": null,
//             "saturation": null,
//             "gamma": null,
//             "invertLightness": null,
//             "visibility": null,
//             "color": "#e7f2ca",
//             "weight": null,
//             "textScale": null,
//             "zoom": null
//         }
//     ]
// },

// ];


// // mapbox
// // const [viewport, setViewport] = useState({
// //   latitude: 21.032275891056212,
// //   longitude: 105.81722252298444,
// //   zoom: 12,
// // });
// //   // Replace this with your Mapbox Access Token
// //   const mapboxAccessToken = 'pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA';

// //   // Customize your Mapbox style if needed
// //   const mapBoxStyle = 'mapbox://styles/mapbox/streets-v11';

// const [viewport, setViewport] = React.useState({
//   latitude: 21.03072,
//   longitude: 105.85239,
//   zoom: 12
// });




// // fix jiggling


//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/explore"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">

//                 <h1 className="userList" style={{zIndex: '1 !important'}}>
//                   <UserList className="userList" />
//                 </h1>


//                 {/* <ReactMapGL
//                   {...viewport}
//                   width="100%"
//                   height="100%"
//                   onViewportChange={(viewport) => setViewport(viewport)}
//                 /> */}

//                 <Map
//                   // style="mapbox://styles/mapbox/standard"
//                   style="mapbox://styles/nhan131998/cm5e0e8o100fp01rg8n9a0ws6"
//                   containerStyle={{
//                     height: "100vh",
//                     width: "100vw"
//                   }}
//                   center={[ 105.8000, 21.0381 ]} // Set the default center of the map to Hanoi, Vietnam (Longitude, Latitude)
//                   //     105.8542, 21.0285
//                   zoom={[12]} // Set the default zoom level
//                   pitch={[50]} // Set the default tilt (pitch) angle (45 degrees)
//                   // dragRotate={false} // Disable rotation (dragRotate set to false)
//                   // touchZoomRotate={false} // Disable zoom/rotate with touch gestures
//                   scrollZoom={true} // Enable zoom with scroll
//                   // doubleClickZoom={false} // Disable double click to zoom
//                   bearing={[60]}
//                 >
//                   <Marker coordinates={[ -73.9857, 40.7484 ]}>
//                     <div style={{ backgroundColor: 'red', padding: '5px', borderRadius: '50%' }}>
//                       <span style={{ color: 'white', fontSize: '16px' }}>📍</span>
//                     </div>
//                   </Marker>

//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => {
//                       const { coordinates } = marker.location; // Destructure coordinates
//                       const lat = coordinates[1]; // Latitude
//                       const lng = coordinates[0]; // Longitude

//                       // Log lat and lon for each marker
//                       console.log(`Marker ID: ${marker._id}, Latitude: ${lat}, Longitude: ${lng}`);

//                       return(
//                       <Marker coordinates={[lat, lng]}>
//                         {/* <span style={{ color: 'white', fontSize: '16px' }}>📍</span> */}
//                         <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     </Marker>
//                       )
//                     })
//                   )}

//                   {/* <FlyingCloud initialCoordinates={[105.8542, 21.0285]} speed={0.0005} /> */}
//                   {/* <RainAnimation numDrops={100} speed={0.0001} /> */}

//                 </Map>
//                  {/* <GoogleMapReact

//                   bootstrapURLKeys={{ key:
//                     //  "AIzaSyAvjN_L592ZgMDP6u_P39hhtqZ8wtNYE6s"
//                   "AIzaSyDfnY7GcBdHHFQTxRCSJGR-AGUEUnMBfqo"
//                   // ""
//                    }}
//                   // bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   // defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                     styles: mapStyle,
//                     // mapId: 'd9fa7933e82bb5fd'
//                   }}
//                   // onChange={handleMapChange}
//                   // center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1]+0.35 } : undefined}

//                   center={mapCenter}


//                   // zoom={selectedPlace ? 14 : 12}
//                   zoom={zoomLevel}
//                   onChange={({ center, zoom }) => {
//                     // Optional: You can log or do something when the map center or zoom changes
//                     console.log('Zoom changed to: ', zoom);
//                     console.log("############## App.js zoomLevel insdie onChange ###########")
//                     console.log(zoomLevel)
//                   }}
//                   onZoomAnimationEnd={() => {
//                     // Optional: You can handle the zoom completion if needed
//                     console.log('Zoom Animation Ended');
//                   }}
//                 >


//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact> */}
//                 {/* </ReactMapGL> */}

//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 {/* <div
//                     style={{
//                         position: "absolute",
//                         top: "0%", // Always at the bottom of the expanded area
//                         left: "0",
//                         width: "100%",
//                         height: "25px", // Fixed height for the swipeable bar
//                         backgroundColor: "#fff",
//                         cursor: "pointer",
//                         zIndex: 10,
//                     }}
//                     // onTouchStart={handleTouchStart}
//                     // onTouchMove={handleTouchMove}
//                     // onTouchEnd={handleTouchEnd}
//                 >

//                     <div
//                         style={{
//                             position: "absolute",
//                             width: "40px",
//                             height: "5px",
//                             backgroundColor: "#D3D3D3",
//                             borderRadius: "3px",
//                             top: "50%",
//                             left: "50%",
//                             transform: "translateX(-50%) translateY(-50%)",
//                         }}
//                     ></div>

//                 </div> */}
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                           setZoomLevel={setZoomLevel}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/" element={<MapComponent />} />
//         {/* <Route path="/" element={<GridLayout items={markers} />} /> */}
//         <Route path="/user/:id" element={<UserPage />} />
//         <Route path="/media/files" element={<MinioFileList />} />
//         <Route path="/homepage" element={<HomePage />} />
//         <Route path="/man" element={<GridAsymmetrical />} />



//       </Routes>
//     </Router>
//   );
// }

// // Raindrop Component
// const RainDrop = ({ initialCoordinates, speed = 0.0001, size = 5 }) => {
//   const [dropPosition, setDropPosition] = useState(initialCoordinates);
//   const [falling, setFalling] = useState(true);

//   useEffect(() => {
//     const animateRainDrop = () => {
//       let dropLongitude = dropPosition[0];
//       let dropLatitude = dropPosition[1];

//       // Move the raindrop downwards (latitude decreases)
//       if (falling) {
//         dropLatitude -= speed; // Move raindrop down
//         if (dropLatitude < 20.0) { // If the raindrop reaches the bottom, reset position
//           dropLatitude = 21.5; // Reset to top
//           dropLongitude = 105.85 + Math.random() * 0.2; // Randomize longitude
//         }
//       }

//       // Update raindrop position
//       setDropPosition([dropLongitude, dropLatitude]);
//     };

//     const interval = setInterval(animateRainDrop, 50);

//     return () => clearInterval(interval);
//   }, [dropPosition, falling, speed]);

//   return (
//     <Marker coordinates={dropPosition}>
//       <div
//         style={{
//           width: `${size}px`,
//           height: `${size}px`,
//           backgroundColor: 'rgba(0, 0, 255, 0.6)',  // Blue color for the raindrop
//           borderRadius: '50%', // Circle shape
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Optional: Shadow for floating effect
//         }}
//       />
//     </Marker>
//   );
// };

// // RainAnimation Component
// const RainAnimation = ({ numDrops = 50, speed = 0.0001 }) => {
//   const drops = Array.from({ length: numDrops }, () => ({
//     coordinates: [105.85 + Math.random() * 0.2, 21.5], // Randomize longitude positions
//   }));

//   return (
//     <div>
//       {drops.map((drop, index) => (
//         <RainDrop
//           key={index}
//           initialCoordinates={drop.coordinates}
//           speed={speed}
//         />
//       ))}
//     </div>
//   );
// };


// const FlyingCloud = ({ initialCoordinates, numberOfRaindrops = 100, rainOffsetX = 10 }) => {
//   const [raindrops, setRaindrops] = useState([]);

//   // Number of lumps the cloud has
//   const cloudLumps = [
//     { xOffset: 5, yOffset: 5 },
//     { xOffset: 25, yOffset: 15 },
//     { xOffset: 60, yOffset: 5 },
//     { xOffset: 75, yOffset: 20 },
//     { xOffset: 105, yOffset: 35 },
//   ];

//   // Generate initial raindrop positions and speeds
//   useEffect(() => {
//     const generateRaindrops = () => {
//       const newRaindrops = [];

//       for (let i = 0; i < numberOfRaindrops; i++) {
//         const randomLump = cloudLumps[Math.floor(Math.random() * cloudLumps.length)];

//         newRaindrops.push({
//           id: i,
//           startX: randomLump.xOffset + Math.random() * 20 - 10, // Random X offset around the lump
//           startY: randomLump.yOffset + 30, // Start slightly below the lump
//           endY: randomLump.yOffset + 80 + Math.random() * 50, // Random fall distance
//           positionX: randomLump.xOffset + Math.random() * 20 - 10 + rainOffsetX, // Apply the horizontal offset
//           positionY: randomLump.yOffset + 30, // Start slightly below the lump
//           speed: Math.random() * 2 + 2, // Random speed for each raindrop
//           isFalling: true,
//         });
//       }
//       setRaindrops(newRaindrops);
//     };

//     generateRaindrops();
//   }, [numberOfRaindrops, rainOffsetX]); // Re-run the generation if offset or number changes

//   // Move raindrops at a fixed interval
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRaindrops((prevRaindrops) =>
//         prevRaindrops.map((drop) => {
//           if (drop.isFalling) {
//             if (drop.positionY < drop.endY) {
//               return {
//                 ...drop,
//                 positionY: drop.positionY + drop.speed, // Move raindrop down with its own speed
//               };
//             } else {
//               // Reset raindrop to the starting position after falling for 2 seconds
//               return {
//                 ...drop,
//                 positionY: drop.startY,
//                 isFalling: true, // Set to falling state again
//               };
//             }
//           }
//           return drop;
//         })
//       );
//     }, 50); // 50ms interval for smoother animation

//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);

//   return (
//     <Marker coordinates={initialCoordinates}>
//       <div style={{
//         position: 'relative',
//         width: '120px',
//         height: '60px',
//         zIndex: 10,
//       }}>
//         {/* Cloud Lumps */}
//         <div style={{
//           position: 'absolute',
//           width: '40px',
//           height: '20px',
//           backgroundColor: 'rgba(255, 255, 255, 0.8)',
//           borderRadius: '50px',
//           top: '5px',
//           left: '5px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '50px',
//           height: '25px',
//           backgroundColor: 'rgba(255, 255, 255, 0.85)',
//           borderRadius: '50px',
//           top: '15px',
//           left: '25px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '45px',
//           height: '25px',
//           backgroundColor: 'rgba(255, 255, 255, 0.75)',
//           borderRadius: '50px',
//           top: '5px',
//           left: '60px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '60px',
//           height: '30px',
//           backgroundColor: 'rgba(255, 255, 255, 0.8)',
//           borderRadius: '50px',
//           top: '20px',
//           left: '75px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '50px',
//           height: '20px',
//           backgroundColor: 'rgba(255, 255, 255, 0.75)',
//           borderRadius: '50px',
//           top: '35px',
//           left: '105px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         {/* Raindrops */}
//         {raindrops.map((drop) => (
//           <div
//             key={drop.id}
//             style={{
//               position: 'absolute',
//               top: `${drop.positionY}px`,
//               left: `${drop.positionX}px`,
//               width: '2px',
//               height: '12px', // Thinner and longer raindrop
//               backgroundColor: 'rgb(196,230,248)', // Raindrop color
//               borderRadius: '2px', // Round edges for a smooth look
//               animation: 'fall 2s linear infinite', // Smooth falling effect
//               opacity: Math.random() * 0.6 + 0.4, // Random opacity for realism
//             }}
//           />
//         ))}
//       </div>
//     </Marker>
//   );
// };





// ok v5.3
// add some css animation

// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";
// import { store} from './redux/MyRedux'
// // import GridLayout from "./components/UserList"; // UserList -> in ExplorePage.js and imported as Gridlayout
// import UserPage from "./components/UserPage";
// // import MinioFileList from "./components";
// import HomePage from "./components/HomePage";
// import UserList from "./components/UserList";

// import { GridAsymmetrical } from "./components/MapComponent Mantine";


// // // map box
// // import ReactMapGL from 'react-map-gl'; // Import react-map-gl
// // import mapboxgl from 'mapbox-gl'; // Import Mapbox GL
// // import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox CSS

// // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
// // import mapboxgl from 'mapbox-gl'
// // import 'mapbox-gl/dist/mapbox-gl.css';

// //https://codesandbox.io/p/sandbox/react-mapbox-draw-circle-forked-ji8zgq?file=%2Fsrc%2Findex.js%3A3%2C1-4%2C1
// //https://stackoverflow.com/questions/75210457/mapbox-pins-dont-render
// import ReactMapboxGl, { Marker }  from "react-mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";


// const Map = ReactMapboxGl({
//   accessToken:
//     "pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA"
// });

// // https://docs.goong.io/goong-map-react/docs/get-started/get-started -> look ugly and slow
// // import ReactMapGL from '@goongmaps/goong-map-react';
// // import '@goongmaps/goong-js/dist/goong-js.css';


// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   const [mapCenter, setMapCenter] = useState(null);
//   const [zoomLevel, setZoomLevel] = useState(14);



//   useEffect(() => {
//     if (showPopup) {
//       // Dispatch the "expand" action when showPopup is true
//       store.dispatch({ type: "EXTEND" });
//       // console.log("####### redux app.js extend #########")
//       // console.log(store.getState().isExtented)
//     } else {
//       // Dispatch the "shrink" action when showPopup is false
//       store.dispatch({ type: "SHRINK" });
//       // console.log("####### redux app.js shrink #########")
//       // console.log(store.getState().isExtented)
//     }
//   }, [showPopup]);

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     // console.log("##### App.js _id #######");
//     // console.log(placeId);
//     // console.log(place._id);
//     // console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       // console.log("##### selectedPlace has changed #####");
//       // console.log(selectedPlace._id);
//       // console.log(selectedPlace);


//       const lat = selectedPlace.location.coordinates[0];
//       const lng = selectedPlace.location.coordinates[1];

//       const screenWidth = window.innerWidth;
//       const screenHeight = window.innerHeight;

//       // Check if the screen is mobile or PC
//       const isMobile = screenWidth <= 768;

//       let newCenter = {};

//       if (isMobile) {
//         // For mobile, offset vertically (1/3 of the screen height)
//         const offsetY = screenHeight / 3;  // 1/3 of the screen height
//         const latitudeOffset = offsetY / 110540;  // Approximate conversion factor (meters per degree of latitude)

//         newCenter = {
//           lat: lat - latitudeOffset*17,  // Adjust the latitude based on the offset
//           // lat: lat - 0.0185,  // Adjust the latitude based on the offset
//           lng: lng,  // Keep longitude the same
//         };
//       } else {
//         // For PC, offset horizontally (1/3 of the screen width)
//         const offsetX = screenWidth / 3;  // 1/3 of the screen width

//         // Approximate 1 degree of longitude change is about 111320 meters at the equator
//         const longitudeOffset = offsetX / 111320;  // Convert to degrees longitude

//         console.log("############## App.js longitudeOffset ###########")
//         console.log(longitudeOffset)

//         newCenter = {
//           lat: lat,  // Keep latitude the same
//           lng: lng + longitudeOffset*17,  // Adjust the longitude based on the offset
//           // lng: lng +0.06,  // Adjust the longitude based on the offset

//         };
//       }

//       // setZoomLevel(14)
//       // console.log("############## App.js zoomLevel ###########")
//       // console.log(zoomLevel)
//       setMapCenter(newCenter);


//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'

//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       // console.log("########### fetch response App.js ######### ");
//       // console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };


//   // const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state

//   const [gestureHandling, setGestureHandling] = useState('greedy'); // Gesture handling state


//   const mapStyle2 = [
//     {
//       "featureType": "landscape.natural",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#e3f1cc"  // Set the tree/forest area to the green color
//         }
//       ]
//     },
//     {
//       "featureType": "landscape.natural.terrain",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#a0d08b"  // Darker green for higher tree/land areas
//         }
//       ]
//     },
//     {
//       "elementType": "labels.text.fill",
//       "stylers": [
//         {
//           "color": "#8c8b7e"  // Set text color to #8c8b7e
//         }
//       ]
//     },
//     {
//       "featureType": "landscape.natural.terrain",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#fafcfb"  // Snow-capped mountains with white color
//         }
//       ]
//     },
//     {
//       "featureType": "road",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // All roads color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.arterial",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Arterial roads color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Highways color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway",
//       "elementType": "geometry.stroke",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Highway stroke color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway.controlled_access",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Controlled-access highways color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway.controlled_access",
//       "elementType": "geometry.stroke",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.local",
//       "elementType": "labels.text.fill",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Text color for local roads (optional, white)
//         }
//       ]
//     },
//   ];

//   const mapStylev1 = [
//     {
//         // Styling for country boundaries (e.g., nations)
//         "featureType": "administrative.country",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#ff0000"  // Red color for country areas
//             }
//         ]
//     },
//     {
//         // Styling for provinces or states within countries
//         "featureType": "administrative.province",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#7b3535"  // Dark red-brown color for provinces
//             }
//         ]
//     },
//     {
//         // Styling for cities (localities)
//         "featureType": "administrative.locality",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#794c4c"  // Medium red-brown for localities
//             }
//         ]
//     },
//     {
//         // Styling for neighborhoods within cities
//         "featureType": "administrative.neighborhood",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#531a1a"  // Dark red color for neighborhood areas
//             }
//         ]
//     },
//     {
//         // Display labels for neighborhoods
//         "featureType": "administrative.neighborhood",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "on"  // Make neighborhood labels visible
//             }
//         ]
//     },
//     {
//         // Display text labels for neighborhoods
//         "featureType": "administrative.neighborhood",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure text labels for neighborhoods are visible
//             }
//         ]
//     },
//     {
//         // Styling for land parcels (plots of land or property boundaries)
//         "featureType": "administrative.land_parcel",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#381313"  // Dark reddish-brown color for land parcels
//             }
//         ]
//     },
//     {
//         // Styling for man-made landscapes (buildings, streets, etc.)
//         "featureType": "landscape.man_made",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#e1e1e1"  // Light gray color for man-made features
//             }
//         ]
//     },
//     {
//         // Styling for arterial roads (main roads that connect major areas)
//         "featureType": "road.arterial",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure arterial roads are visible
//             }
//         ]
//     },
//     {
//         // Styling for arterial roads' borders (road strokes)
//         "featureType": "road.arterial",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "visibility": "on"  // Make the road borders (strokes) visible
//             }
//         ]
//     },
//     {
//         // Styling for local roads (smaller roads for neighborhoods)
//         "featureType": "road.local",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure local roads are visible
//             },
//             {
//                 "weight": "0.5"  // Thinner line weight for local roads
//             },
//             {
//                 "gamma": "1.65"  // Adjust brightness for local roads
//             }
//         ]
//     },
//     {
//         // Hide labels for local roads (e.g., road names or street signs)
//         "featureType": "road.local",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide text labels for local roads
//             }
//         ]
//     },
//     {
//         // Styling for water features (lakes, oceans, rivers)
//         "featureType": "water",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#cbe4ed"  // Light blue color for water areas
//             },
//             {
//                 "visibility": "simplified"  // Simplified representation for water
//             },
//             {
//                 "weight": "1.00"  // Set a specific weight for the water geometry
//             }
//         ]
//     },
//     {
//         // Fill styling for water areas (more detailed color adjustment)
//         "featureType": "water",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "saturation": "48"  // Adjust saturation for water color
//             },
//             {
//                 "visibility": "on"  // Ensure water areas are visible
//             }
//         ]
//     },
//     {
//         // Hide labels for water features (e.g., names of lakes/rivers)
//         "featureType": "water",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide labels for water areas
//             }
//         ]
//     },
//     {
//         // Hide text labels for water (names or descriptions)
//         "featureType": "water",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide text labels for water
//             }
//         ]
//     }
// ];

//   const mapStyle3 =
// [

// //     {
// //       "featureType": "landscape.natural",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //         {
// //           "color": "#e3f1cc"  // Set the tree/forest area to the green color
// //         }
// //       ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "geometry.stroke",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "labels.text",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     // {
// //     //     "featureType": "landscape",
// //     //     "elementType": "all",
// //     //     "stylers": [
// //     //         {
// //     //             "visibility": "on"
// //     //         }
// //     //     ]
// //     // },
// //     {
// //         "featureType": "poi",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "lightness": "0"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.medical",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "lightness": "-5"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.park",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             },
// //             {
// //                 "color": "#a7ce95"
// //             },
// //             {
// //                 "lightness": "45"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.school",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "color": "#be9b7b"
// //             },
// //             {
// //                 "lightness": "70"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.sports_complex",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "color": "#5d4b46"
// //             },
// //             {
// //                 "lightness": "60"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "road",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "transit.station",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "saturation": "23"
// //             },
// //             {
// //                 "lightness": "10"
// //             },
// //             {
// //                 "gamma": "0.8"
// //             },
// //             {
// //                 "hue": "#b000ff"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "water",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "water",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "color": "#cbe4ed"
// //             }
// //         ]
// //     },


// //     // country and state border
// //     {
// //       // Styling for country boundaries (e.g., nations) with a white border
// //       "featureType": "administrative.country",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#ff0000"  // Red color for country areas
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for the border of country boundaries (white border)
// //       "featureType": "administrative.country",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "color": "#8c857a"  // White color for the country boundary border
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for provinces (states) within countries (add dashed lines)
// //       "featureType": "administrative.province",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "color": "#8c857a",  // White color for the state border
// //               "weight": 2,  // Border width
// //               "visibility": "on",
// //               "strokeOpacity": 0.7,  // Slightly transparent to create a softer effect
// //               "strokeDasharray": "4, 2"  // Dashed effect (4px dashes with 2px gaps)
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for localities (cities)
// //       "featureType": "administrative.locality",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#794c4c"  // Medium red-brown for localities
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for neighborhoods within cities
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#531a1a"  // Dark red color for neighborhood areas
// //           }
// //       ]
// //   },
// //   {
// //       // Display labels for neighborhoods
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Make neighborhood labels visible
// //           }
// //       ]
// //   },
// //   {
// //       // Display text labels for neighborhoods
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "labels.text",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure text labels for neighborhoods are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for land parcels (plots of land or property boundaries)
// //       "featureType": "administrative.land_parcel",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#381313"  // Dark reddish-brown color for land parcels
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for man-made landscapes (buildings, streets, etc.)
// //       "featureType": "landscape.man_made",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#e1e1e1"  // Light gray color for man-made features
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for arterial roads (main roads that connect major areas)
// //       "featureType": "road.arterial",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure arterial roads are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for arterial roads' borders (road strokes)
// //       "featureType": "road.arterial",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Make the road borders (strokes) visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for local roads (smaller roads for neighborhoods)
// //       "featureType": "road.local",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure local roads are visible
// //           },
// //           {
// //               "weight": "0.5"  // Thinner line weight for local roads
// //           },
// //           {
// //               "gamma": "1.65"  // Adjust brightness for local roads
// //           }
// //       ]
// //   },
// //   {
// //       // Hide labels for local roads (e.g., road names or street signs)
// //       "featureType": "road.local",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide text labels for local roads
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for water features (lakes, oceans, rivers)
// //       "featureType": "water",
// //       "elementType": "geometry",
// //       "stylers": [
// //           {
// //               "color": "#add4dd"  // Light blue color for water areas
// //           },
// //           {
// //               "visibility": "simplified"  // Simplified representation for water
// //           },
// //           {
// //               "weight": "1.00"  // Set a specific weight for the water geometry
// //           }
// //       ]
// //   },
// //   {
// //       // Fill styling for water areas (more detailed color adjustment)
// //       "featureType": "water",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "saturation": "48"  // Adjust saturation for water color
// //           },
// //           {
// //               "visibility": "on"  // Ensure water areas are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Hide labels for water features (e.g., names of lakes/rivers)
// //       "featureType": "water",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide labels for water areas
// //           }
// //       ]
// //   },
// //   {
// //       // Hide text labels for water (names or descriptions)
// //       "featureType": "water",
// //       "elementType": "labels.text.fill",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide text labels for water
// //           }
// //       ]
// //   },


// // // the road
// // {
// //   "featureType": "road",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // All roads color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.arterial",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Arterial roads color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Highways color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway",
// //   "elementType": "geometry.stroke",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Highway stroke color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway.controlled_access",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Controlled-access highways color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway.controlled_access",
// //   "elementType": "geometry.stroke",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.local",
// //   "elementType": "labels.text.fill",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Text color for local roads (optional, white)
// //     }
// //   ]
// // },


// // land with diff height
// // {
// //   "featureType": "landscape.natural",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#e3f1cc"  // Light green for parks/green spaces
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural.wood",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#6b8e23"  // Darker green for woodlands (forest green)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural.earth",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#a8d08d"  // Medium green for grasslands or open areas
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#e3f1cc"  // Set the tree/forest area to the green color
// //     }
// //   ]
// // },

// ];

// const mapStyle=[
//   {
//     "featureType": "administrative.country",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "color": "#121212"
//       },
//       {
//         "lightness": 60
//       },
//       {
//         "weight": 0.5
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.natural.landcover",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "weight": 3.5
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.natural.terrain",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#e3f1cc"
//       }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "transit",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   }
// ];
// const mapStyle5= [
//   {
//       "featureType": "administrative.country",
//       "elementType": "labels.text.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#71757a",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.dry_crops",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#e7f2ca",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.mixed_crops",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#dbf2c4",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.mixed_forest",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#dbf2c4",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.water",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#cae8f2",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "transportation.road",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#ffffff",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "transportation.road",
//       "elementType": "geometry.stroke",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": null,
//               "weight": 0,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   }
// ];

// const mapStyle6 =[
//   {
//     "featureType": "land.land_cover.landsat_vegetation.dry_crops",
//     "elementType": "geometry.fill",
//     "stylers": [
//         {
//             "hue": null,
//             "lightness": null,
//             "saturation": null,
//             "gamma": null,
//             "invertLightness": null,
//             "visibility": null,
//             "color": "#e7f2ca",
//             "weight": null,
//             "textScale": null,
//             "zoom": null
//         }
//     ]
// },

// ];


// // mapbox
// // const [viewport, setViewport] = useState({
// //   latitude: 21.032275891056212,
// //   longitude: 105.81722252298444,
// //   zoom: 12,
// // });
// //   // Replace this with your Mapbox Access Token
// //   const mapboxAccessToken = 'pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA';

// //   // Customize your Mapbox style if needed
// //   const mapBoxStyle = 'mapbox://styles/mapbox/streets-v11';

// const [viewport, setViewport] = React.useState({
//   latitude: 21.03072,
//   longitude: 105.85239,
//   zoom: 12
// });
//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/explore"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">

//                 <h1 className="userList" style={{zIndex: '1 !important'}}>
//                   <UserList className="userList" />
//                 </h1>


//                 {/* <ReactMapGL
//                   {...viewport}
//                   width="100%"
//                   height="100%"
//                   onViewportChange={(viewport) => setViewport(viewport)}
//                 /> */}

//                 <Map
//                   // style="mapbox://styles/mapbox/standard"
//                   style="mapbox://styles/nhan131998/cm5e0e8o100fp01rg8n9a0ws6"
//                   containerStyle={{
//                     height: "100vh",
//                     width: "100vw"
//                   }}
//                   center={[105.8542, 21.0285]} // Set the default center of the map to Hanoi, Vietnam (Longitude, Latitude)
//                   zoom={[12]} // Set the default zoom level
//                 >
//                   <Marker coordinates={[ -73.9857, 40.7484 ]}>
//                     <div style={{ backgroundColor: 'red', padding: '5px', borderRadius: '50%' }}>
//                       <span style={{ color: 'white', fontSize: '16px' }}>📍</span>
//                     </div>
//                   </Marker>

//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => {
//                       const { coordinates } = marker.location; // Destructure coordinates
//                       const lat = coordinates[1]; // Latitude
//                       const lng = coordinates[0]; // Longitude

//                       // Log lat and lon for each marker
//                       console.log(`Marker ID: ${marker._id}, Latitude: ${lat}, Longitude: ${lng}`);

//                       return(
//                       <Marker coordinates={[lat, lng]}>
//                         {/* <span style={{ color: 'white', fontSize: '16px' }}>📍</span> */}
//                         <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     </Marker>
//                       )
//                     })
//                   )}

// <FlyingCloud initialCoordinates={[105.8542, 21.0285]} speed={0.0005} />
// {/* <RainAnimation numDrops={100} speed={0.0001} /> */}
//                 </Map>
//                  {/* <GoogleMapReact

//                   bootstrapURLKeys={{ key:
//                     //  "AIzaSyAvjN_L592ZgMDP6u_P39hhtqZ8wtNYE6s"
//                   "AIzaSyDfnY7GcBdHHFQTxRCSJGR-AGUEUnMBfqo"
//                   // ""
//                    }}
//                   // bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   // defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                     styles: mapStyle,
//                     // mapId: 'd9fa7933e82bb5fd'
//                   }}
//                   // onChange={handleMapChange}
//                   // center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1]+0.35 } : undefined}

//                   center={mapCenter}


//                   // zoom={selectedPlace ? 14 : 12}
//                   zoom={zoomLevel}
//                   onChange={({ center, zoom }) => {
//                     // Optional: You can log or do something when the map center or zoom changes
//                     console.log('Zoom changed to: ', zoom);
//                     console.log("############## App.js zoomLevel insdie onChange ###########")
//                     console.log(zoomLevel)
//                   }}
//                   onZoomAnimationEnd={() => {
//                     // Optional: You can handle the zoom completion if needed
//                     console.log('Zoom Animation Ended');
//                   }}
//                 >


//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact> */}
//                 {/* </ReactMapGL> */}

//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                           setZoomLevel={setZoomLevel}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/" element={<MapComponent />} />
//         {/* <Route path="/" element={<GridLayout items={markers} />} /> */}
//         <Route path="/user/:id" element={<UserPage />} />
//         <Route path="/media/files" element={<MinioFileList />} />
//         <Route path="/homepage" element={<HomePage />} />
//         <Route path="/man" element={<GridAsymmetrical />} />



//       </Routes>
//     </Router>
//   );
// }

// // Raindrop Component
// const RainDrop = ({ initialCoordinates, speed = 0.0001, size = 5 }) => {
//   const [dropPosition, setDropPosition] = useState(initialCoordinates);
//   const [falling, setFalling] = useState(true);

//   useEffect(() => {
//     const animateRainDrop = () => {
//       let dropLongitude = dropPosition[0];
//       let dropLatitude = dropPosition[1];

//       // Move the raindrop downwards (latitude decreases)
//       if (falling) {
//         dropLatitude -= speed; // Move raindrop down
//         if (dropLatitude < 20.0) { // If the raindrop reaches the bottom, reset position
//           dropLatitude = 21.5; // Reset to top
//           dropLongitude = 105.85 + Math.random() * 0.2; // Randomize longitude
//         }
//       }

//       // Update raindrop position
//       setDropPosition([dropLongitude, dropLatitude]);
//     };

//     const interval = setInterval(animateRainDrop, 50);

//     return () => clearInterval(interval);
//   }, [dropPosition, falling, speed]);

//   return (
//     <Marker coordinates={dropPosition}>
//       <div
//         style={{
//           width: `${size}px`,
//           height: `${size}px`,
//           backgroundColor: 'rgba(0, 0, 255, 0.6)',  // Blue color for the raindrop
//           borderRadius: '50%', // Circle shape
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Optional: Shadow for floating effect
//         }}
//       />
//     </Marker>
//   );
// };

// // RainAnimation Component
// const RainAnimation = ({ numDrops = 50, speed = 0.0001 }) => {
//   const drops = Array.from({ length: numDrops }, () => ({
//     coordinates: [105.85 + Math.random() * 0.2, 21.5], // Randomize longitude positions
//   }));

//   return (
//     <div>
//       {drops.map((drop, index) => (
//         <RainDrop
//           key={index}
//           initialCoordinates={drop.coordinates}
//           speed={speed}
//         />
//       ))}
//     </div>
//   );
// };


// const FlyingCloud = ({ initialCoordinates, numberOfRaindrops = 100, rainOffsetX = 10 }) => {
//   const [raindrops, setRaindrops] = useState([]);

//   // Number of lumps the cloud has
//   const cloudLumps = [
//     { xOffset: 5, yOffset: 5 },
//     { xOffset: 25, yOffset: 15 },
//     { xOffset: 60, yOffset: 5 },
//     { xOffset: 75, yOffset: 20 },
//     { xOffset: 105, yOffset: 35 },
//   ];

//   // Generate initial raindrop positions and speeds
//   useEffect(() => {
//     const generateRaindrops = () => {
//       const newRaindrops = [];

//       for (let i = 0; i < numberOfRaindrops; i++) {
//         const randomLump = cloudLumps[Math.floor(Math.random() * cloudLumps.length)];

//         newRaindrops.push({
//           id: i,
//           startX: randomLump.xOffset + Math.random() * 20 - 10, // Random X offset around the lump
//           startY: randomLump.yOffset + 30, // Start slightly below the lump
//           endY: randomLump.yOffset + 80 + Math.random() * 50, // Random fall distance
//           positionX: randomLump.xOffset + Math.random() * 20 - 10 + rainOffsetX, // Apply the horizontal offset
//           positionY: randomLump.yOffset + 30, // Start slightly below the lump
//           speed: Math.random() * 2 + 2, // Random speed for each raindrop
//           isFalling: true,
//         });
//       }
//       setRaindrops(newRaindrops);
//     };

//     generateRaindrops();
//   }, [numberOfRaindrops, rainOffsetX]); // Re-run the generation if offset or number changes

//   // Move raindrops at a fixed interval
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRaindrops((prevRaindrops) =>
//         prevRaindrops.map((drop) => {
//           if (drop.isFalling) {
//             if (drop.positionY < drop.endY) {
//               return {
//                 ...drop,
//                 positionY: drop.positionY + drop.speed, // Move raindrop down with its own speed
//               };
//             } else {
//               // Reset raindrop to the starting position after falling for 2 seconds
//               return {
//                 ...drop,
//                 positionY: drop.startY,
//                 isFalling: true, // Set to falling state again
//               };
//             }
//           }
//           return drop;
//         })
//       );
//     }, 50); // 50ms interval for smoother animation

//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);

//   return (
//     <Marker coordinates={initialCoordinates}>
//       <div style={{
//         position: 'relative',
//         width: '120px',
//         height: '60px',
//         zIndex: 10,
//       }}>
//         {/* Cloud Lumps */}
//         <div style={{
//           position: 'absolute',
//           width: '40px',
//           height: '20px',
//           backgroundColor: 'rgba(255, 255, 255, 0.8)',
//           borderRadius: '50px',
//           top: '5px',
//           left: '5px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '50px',
//           height: '25px',
//           backgroundColor: 'rgba(255, 255, 255, 0.85)',
//           borderRadius: '50px',
//           top: '15px',
//           left: '25px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '45px',
//           height: '25px',
//           backgroundColor: 'rgba(255, 255, 255, 0.75)',
//           borderRadius: '50px',
//           top: '5px',
//           left: '60px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '60px',
//           height: '30px',
//           backgroundColor: 'rgba(255, 255, 255, 0.8)',
//           borderRadius: '50px',
//           top: '20px',
//           left: '75px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         <div style={{
//           position: 'absolute',
//           width: '50px',
//           height: '20px',
//           backgroundColor: 'rgba(255, 255, 255, 0.75)',
//           borderRadius: '50px',
//           top: '35px',
//           left: '105px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
//         }}></div>

//         {/* Raindrops */}
//         {raindrops.map((drop) => (
//           <div
//             key={drop.id}
//             style={{
//               position: 'absolute',
//               top: `${drop.positionY}px`,
//               left: `${drop.positionX}px`,
//               width: '2px',
//               height: '12px', // Thinner and longer raindrop
//               backgroundColor: 'rgb(196,230,248)', // Raindrop color
//               borderRadius: '2px', // Round edges for a smooth look
//               animation: 'fall 2s linear infinite', // Smooth falling effect
//               opacity: Math.random() * 0.6 + 0.4, // Random opacity for realism
//             }}
//           />
//         ))}
//       </div>
//     </Marker>
//   );
// };





// ok (use mapbox with nice tile, cannot custom like gg though) v5.2 add the retractable area to this, change the map style
// recenter offset kind of work

// mapbox does load web but the marker is not at the correct potion, cannot drag, check this later

// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";
// import { store} from './redux/MyRedux'
// // import GridLayout from "./components/UserList"; // UserList -> in ExplorePage.js and imported as Gridlayout
// import UserPage from "./components/UserPage";
// // import MinioFileList from "./components";
// import HomePage from "./components/HomePage";
// import UserList from "./components/UserList";

// import { GridAsymmetrical } from "./components/MapComponent Mantine";


// // // map box
// // import ReactMapGL from 'react-map-gl'; // Import react-map-gl
// // import mapboxgl from 'mapbox-gl'; // Import Mapbox GL
// // import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox CSS

// // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
// // import mapboxgl from 'mapbox-gl'
// // import 'mapbox-gl/dist/mapbox-gl.css';

// //https://codesandbox.io/p/sandbox/react-mapbox-draw-circle-forked-ji8zgq?file=%2Fsrc%2Findex.js%3A3%2C1-4%2C1
// //https://stackoverflow.com/questions/75210457/mapbox-pins-dont-render
// import ReactMapboxGl, { Marker }  from "react-mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";


// const Map = ReactMapboxGl({
//   accessToken:
//     "pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA"
// });

// // https://docs.goong.io/goong-map-react/docs/get-started/get-started -> look ugly and slow
// // import ReactMapGL from '@goongmaps/goong-map-react';
// // import '@goongmaps/goong-js/dist/goong-js.css';


// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   const [mapCenter, setMapCenter] = useState(null);
//   const [zoomLevel, setZoomLevel] = useState(14);



//   useEffect(() => {
//     if (showPopup) {
//       // Dispatch the "expand" action when showPopup is true
//       store.dispatch({ type: "EXTEND" });
//       // console.log("####### redux app.js extend #########")
//       // console.log(store.getState().isExtented)
//     } else {
//       // Dispatch the "shrink" action when showPopup is false
//       store.dispatch({ type: "SHRINK" });
//       // console.log("####### redux app.js shrink #########")
//       // console.log(store.getState().isExtented)
//     }
//   }, [showPopup]);

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     // console.log("##### App.js _id #######");
//     // console.log(placeId);
//     // console.log(place._id);
//     // console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       // console.log("##### selectedPlace has changed #####");
//       // console.log(selectedPlace._id);
//       // console.log(selectedPlace);


//       const lat = selectedPlace.location.coordinates[0];
//       const lng = selectedPlace.location.coordinates[1];

//       const screenWidth = window.innerWidth;
//       const screenHeight = window.innerHeight;

//       // Check if the screen is mobile or PC
//       const isMobile = screenWidth <= 768;

//       let newCenter = {};

//       if (isMobile) {
//         // For mobile, offset vertically (1/3 of the screen height)
//         const offsetY = screenHeight / 3;  // 1/3 of the screen height
//         const latitudeOffset = offsetY / 110540;  // Approximate conversion factor (meters per degree of latitude)

//         newCenter = {
//           lat: lat - latitudeOffset*17,  // Adjust the latitude based on the offset
//           // lat: lat - 0.0185,  // Adjust the latitude based on the offset
//           lng: lng,  // Keep longitude the same
//         };
//       } else {
//         // For PC, offset horizontally (1/3 of the screen width)
//         const offsetX = screenWidth / 3;  // 1/3 of the screen width

//         // Approximate 1 degree of longitude change is about 111320 meters at the equator
//         const longitudeOffset = offsetX / 111320;  // Convert to degrees longitude

//         console.log("############## App.js longitudeOffset ###########")
//         console.log(longitudeOffset)

//         newCenter = {
//           lat: lat,  // Keep latitude the same
//           lng: lng + longitudeOffset*17,  // Adjust the longitude based on the offset
//           // lng: lng +0.06,  // Adjust the longitude based on the offset

//         };
//       }

//       // setZoomLevel(14)
//       // console.log("############## App.js zoomLevel ###########")
//       // console.log(zoomLevel)
//       setMapCenter(newCenter);


//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'

//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       // console.log("########### fetch response App.js ######### ");
//       // console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };


//   // const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state

//   const [gestureHandling, setGestureHandling] = useState('greedy'); // Gesture handling state


//   const mapStyle2 = [
//     {
//       "featureType": "landscape.natural",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#e3f1cc"  // Set the tree/forest area to the green color
//         }
//       ]
//     },
//     {
//       "featureType": "landscape.natural.terrain",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#a0d08b"  // Darker green for higher tree/land areas
//         }
//       ]
//     },
//     {
//       "elementType": "labels.text.fill",
//       "stylers": [
//         {
//           "color": "#8c8b7e"  // Set text color to #8c8b7e
//         }
//       ]
//     },
//     {
//       "featureType": "landscape.natural.terrain",
//       "elementType": "geometry.fill",
//       "stylers": [
//         {
//           "color": "#fafcfb"  // Snow-capped mountains with white color
//         }
//       ]
//     },
//     {
//       "featureType": "road",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // All roads color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.arterial",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Arterial roads color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Highways color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway",
//       "elementType": "geometry.stroke",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Highway stroke color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway.controlled_access",
//       "elementType": "geometry",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Controlled-access highways color (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.highway.controlled_access",
//       "elementType": "geometry.stroke",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
//         }
//       ]
//     },
//     {
//       "featureType": "road.local",
//       "elementType": "labels.text.fill",
//       "stylers": [
//         {
//           "color": "#FFFFFF"  // Text color for local roads (optional, white)
//         }
//       ]
//     },
//   ];

//   const mapStylev1 = [
//     {
//         // Styling for country boundaries (e.g., nations)
//         "featureType": "administrative.country",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#ff0000"  // Red color for country areas
//             }
//         ]
//     },
//     {
//         // Styling for provinces or states within countries
//         "featureType": "administrative.province",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#7b3535"  // Dark red-brown color for provinces
//             }
//         ]
//     },
//     {
//         // Styling for cities (localities)
//         "featureType": "administrative.locality",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#794c4c"  // Medium red-brown for localities
//             }
//         ]
//     },
//     {
//         // Styling for neighborhoods within cities
//         "featureType": "administrative.neighborhood",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#531a1a"  // Dark red color for neighborhood areas
//             }
//         ]
//     },
//     {
//         // Display labels for neighborhoods
//         "featureType": "administrative.neighborhood",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "on"  // Make neighborhood labels visible
//             }
//         ]
//     },
//     {
//         // Display text labels for neighborhoods
//         "featureType": "administrative.neighborhood",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure text labels for neighborhoods are visible
//             }
//         ]
//     },
//     {
//         // Styling for land parcels (plots of land or property boundaries)
//         "featureType": "administrative.land_parcel",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#381313"  // Dark reddish-brown color for land parcels
//             }
//         ]
//     },
//     {
//         // Styling for man-made landscapes (buildings, streets, etc.)
//         "featureType": "landscape.man_made",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#e1e1e1"  // Light gray color for man-made features
//             }
//         ]
//     },
//     {
//         // Styling for arterial roads (main roads that connect major areas)
//         "featureType": "road.arterial",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure arterial roads are visible
//             }
//         ]
//     },
//     {
//         // Styling for arterial roads' borders (road strokes)
//         "featureType": "road.arterial",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "visibility": "on"  // Make the road borders (strokes) visible
//             }
//         ]
//     },
//     {
//         // Styling for local roads (smaller roads for neighborhoods)
//         "featureType": "road.local",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"  // Ensure local roads are visible
//             },
//             {
//                 "weight": "0.5"  // Thinner line weight for local roads
//             },
//             {
//                 "gamma": "1.65"  // Adjust brightness for local roads
//             }
//         ]
//     },
//     {
//         // Hide labels for local roads (e.g., road names or street signs)
//         "featureType": "road.local",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide text labels for local roads
//             }
//         ]
//     },
//     {
//         // Styling for water features (lakes, oceans, rivers)
//         "featureType": "water",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#cbe4ed"  // Light blue color for water areas
//             },
//             {
//                 "visibility": "simplified"  // Simplified representation for water
//             },
//             {
//                 "weight": "1.00"  // Set a specific weight for the water geometry
//             }
//         ]
//     },
//     {
//         // Fill styling for water areas (more detailed color adjustment)
//         "featureType": "water",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "saturation": "48"  // Adjust saturation for water color
//             },
//             {
//                 "visibility": "on"  // Ensure water areas are visible
//             }
//         ]
//     },
//     {
//         // Hide labels for water features (e.g., names of lakes/rivers)
//         "featureType": "water",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide labels for water areas
//             }
//         ]
//     },
//     {
//         // Hide text labels for water (names or descriptions)
//         "featureType": "water",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "visibility": "off"  // Hide text labels for water
//             }
//         ]
//     }
// ];

//   const mapStyle3 =
// [

// //     {
// //       "featureType": "landscape.natural",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //         {
// //           "color": "#e3f1cc"  // Set the tree/forest area to the green color
// //         }
// //       ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "geometry.stroke",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "administrative",
// //         "elementType": "labels.text",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     // {
// //     //     "featureType": "landscape",
// //     //     "elementType": "all",
// //     //     "stylers": [
// //     //         {
// //     //             "visibility": "on"
// //     //         }
// //     //     ]
// //     // },
// //     {
// //         "featureType": "poi",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "lightness": "0"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.medical",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "lightness": "-5"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.park",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             },
// //             {
// //                 "color": "#a7ce95"
// //             },
// //             {
// //                 "lightness": "45"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.school",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "color": "#be9b7b"
// //             },
// //             {
// //                 "lightness": "70"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "poi.sports_complex",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "color": "#5d4b46"
// //             },
// //             {
// //                 "lightness": "60"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "road",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "transit.station",
// //         "elementType": "geometry",
// //         "stylers": [
// //             {
// //                 "saturation": "23"
// //             },
// //             {
// //                 "lightness": "10"
// //             },
// //             {
// //                 "gamma": "0.8"
// //             },
// //             {
// //                 "hue": "#b000ff"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "water",
// //         "elementType": "all",
// //         "stylers": [
// //             {
// //                 "visibility": "on"
// //             }
// //         ]
// //     },
// //     {
// //         "featureType": "water",
// //         "elementType": "geometry.fill",
// //         "stylers": [
// //             {
// //                 "color": "#cbe4ed"
// //             }
// //         ]
// //     },


// //     // country and state border
// //     {
// //       // Styling for country boundaries (e.g., nations) with a white border
// //       "featureType": "administrative.country",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#ff0000"  // Red color for country areas
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for the border of country boundaries (white border)
// //       "featureType": "administrative.country",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "color": "#8c857a"  // White color for the country boundary border
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for provinces (states) within countries (add dashed lines)
// //       "featureType": "administrative.province",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "color": "#8c857a",  // White color for the state border
// //               "weight": 2,  // Border width
// //               "visibility": "on",
// //               "strokeOpacity": 0.7,  // Slightly transparent to create a softer effect
// //               "strokeDasharray": "4, 2"  // Dashed effect (4px dashes with 2px gaps)
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for localities (cities)
// //       "featureType": "administrative.locality",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#794c4c"  // Medium red-brown for localities
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for neighborhoods within cities
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#531a1a"  // Dark red color for neighborhood areas
// //           }
// //       ]
// //   },
// //   {
// //       // Display labels for neighborhoods
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Make neighborhood labels visible
// //           }
// //       ]
// //   },
// //   {
// //       // Display text labels for neighborhoods
// //       "featureType": "administrative.neighborhood",
// //       "elementType": "labels.text",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure text labels for neighborhoods are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for land parcels (plots of land or property boundaries)
// //       "featureType": "administrative.land_parcel",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#381313"  // Dark reddish-brown color for land parcels
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for man-made landscapes (buildings, streets, etc.)
// //       "featureType": "landscape.man_made",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "color": "#e1e1e1"  // Light gray color for man-made features
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for arterial roads (main roads that connect major areas)
// //       "featureType": "road.arterial",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure arterial roads are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for arterial roads' borders (road strokes)
// //       "featureType": "road.arterial",
// //       "elementType": "geometry.stroke",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Make the road borders (strokes) visible
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for local roads (smaller roads for neighborhoods)
// //       "featureType": "road.local",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "visibility": "on"  // Ensure local roads are visible
// //           },
// //           {
// //               "weight": "0.5"  // Thinner line weight for local roads
// //           },
// //           {
// //               "gamma": "1.65"  // Adjust brightness for local roads
// //           }
// //       ]
// //   },
// //   {
// //       // Hide labels for local roads (e.g., road names or street signs)
// //       "featureType": "road.local",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide text labels for local roads
// //           }
// //       ]
// //   },
// //   {
// //       // Styling for water features (lakes, oceans, rivers)
// //       "featureType": "water",
// //       "elementType": "geometry",
// //       "stylers": [
// //           {
// //               "color": "#add4dd"  // Light blue color for water areas
// //           },
// //           {
// //               "visibility": "simplified"  // Simplified representation for water
// //           },
// //           {
// //               "weight": "1.00"  // Set a specific weight for the water geometry
// //           }
// //       ]
// //   },
// //   {
// //       // Fill styling for water areas (more detailed color adjustment)
// //       "featureType": "water",
// //       "elementType": "geometry.fill",
// //       "stylers": [
// //           {
// //               "saturation": "48"  // Adjust saturation for water color
// //           },
// //           {
// //               "visibility": "on"  // Ensure water areas are visible
// //           }
// //       ]
// //   },
// //   {
// //       // Hide labels for water features (e.g., names of lakes/rivers)
// //       "featureType": "water",
// //       "elementType": "labels",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide labels for water areas
// //           }
// //       ]
// //   },
// //   {
// //       // Hide text labels for water (names or descriptions)
// //       "featureType": "water",
// //       "elementType": "labels.text.fill",
// //       "stylers": [
// //           {
// //               "visibility": "off"  // Hide text labels for water
// //           }
// //       ]
// //   },


// // // the road
// // {
// //   "featureType": "road",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // All roads color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.arterial",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Arterial roads color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Highways color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway",
// //   "elementType": "geometry.stroke",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Highway stroke color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway.controlled_access",
// //   "elementType": "geometry",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Controlled-access highways color (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.highway.controlled_access",
// //   "elementType": "geometry.stroke",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Stroke for controlled-access highways (white)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "road.local",
// //   "elementType": "labels.text.fill",
// //   "stylers": [
// //     {
// //       "color": "#FFFFFF"  // Text color for local roads (optional, white)
// //     }
// //   ]
// // },


// // land with diff height
// // {
// //   "featureType": "landscape.natural",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#e3f1cc"  // Light green for parks/green spaces
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural.wood",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#6b8e23"  // Darker green for woodlands (forest green)
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural.earth",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#a8d08d"  // Medium green for grasslands or open areas
// //     }
// //   ]
// // },
// // {
// //   "featureType": "landscape.natural",
// //   "elementType": "geometry.fill",
// //   "stylers": [
// //     {
// //       "color": "#e3f1cc"  // Set the tree/forest area to the green color
// //     }
// //   ]
// // },

// ];

// const mapStyle=[
//   {
//     "featureType": "administrative.country",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "color": "#121212"
//       },
//       {
//         "lightness": 60
//       },
//       {
//         "weight": 0.5
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.natural.landcover",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "weight": 3.5
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.natural.terrain",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#e3f1cc"
//       }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#ffffff"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "transit",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   }
// ];
// const mapStyle5= [
//   {
//       "featureType": "administrative.country",
//       "elementType": "labels.text.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#71757a",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.dry_crops",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#e7f2ca",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.mixed_crops",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#dbf2c4",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.land_cover.landsat_vegetation.mixed_forest",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#dbf2c4",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "land.water",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#cae8f2",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "transportation.road",
//       "elementType": "geometry.fill",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": "#ffffff",
//               "weight": null,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   },
//   {
//       "featureType": "transportation.road",
//       "elementType": "geometry.stroke",
//       "stylers": [
//           {
//               "hue": null,
//               "lightness": null,
//               "saturation": null,
//               "gamma": null,
//               "invertLightness": null,
//               "visibility": null,
//               "color": null,
//               "weight": 0,
//               "textScale": null,
//               "zoom": null
//           }
//       ]
//   }
// ];

// const mapStyle6 =[
//   {
//     "featureType": "land.land_cover.landsat_vegetation.dry_crops",
//     "elementType": "geometry.fill",
//     "stylers": [
//         {
//             "hue": null,
//             "lightness": null,
//             "saturation": null,
//             "gamma": null,
//             "invertLightness": null,
//             "visibility": null,
//             "color": "#e7f2ca",
//             "weight": null,
//             "textScale": null,
//             "zoom": null
//         }
//     ]
// },

// ];


// // mapbox
// // const [viewport, setViewport] = useState({
// //   latitude: 21.032275891056212,
// //   longitude: 105.81722252298444,
// //   zoom: 12,
// // });
// //   // Replace this with your Mapbox Access Token
// //   const mapboxAccessToken = 'pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA';

// //   // Customize your Mapbox style if needed
// //   const mapBoxStyle = 'mapbox://styles/mapbox/streets-v11';

// const [viewport, setViewport] = React.useState({
//   latitude: 21.03072,
//   longitude: 105.85239,
//   zoom: 12
// });
//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/explore"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">

//                 <h1 className="userList" style={{zIndex: '1 !important'}}>
//                   <UserList className="userList" />
//                 </h1>


//                 {/* <ReactMapGL
//                   {...viewport}
//                   width="100%"
//                   height="100%"
//                   onViewportChange={(viewport) => setViewport(viewport)}
//                 /> */}

//                 <Map
//                   // style="mapbox://styles/mapbox/standard"
//                   style="mapbox://styles/nhan131998/cm5e0e8o100fp01rg8n9a0ws6"
//                   containerStyle={{
//                     height: "100vh",
//                     width: "100vw"
//                   }}
//                   center={[105.8542, 21.0285]} // Set the default center of the map to Hanoi, Vietnam (Longitude, Latitude)
//                   zoom={[12]} // Set the default zoom level
//                 >
//                   <Marker coordinates={[ -73.9857, 40.7484 ]}>
//                     <div style={{ backgroundColor: 'red', padding: '5px', borderRadius: '50%' }}>
//                       <span style={{ color: 'white', fontSize: '16px' }}>📍</span>
//                     </div>
//                   </Marker>

//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => {
//                       const { coordinates } = marker.location; // Destructure coordinates
//                       const lat = coordinates[1]; // Latitude
//                       const lng = coordinates[0]; // Longitude

//                       // Log lat and lon for each marker
//                       console.log(`Marker ID: ${marker._id}, Latitude: ${lat}, Longitude: ${lng}`);

//                       return(
//                       <Marker coordinates={[lat, lng]}>
//                         {/* <span style={{ color: 'white', fontSize: '16px' }}>📍</span> */}
//                         <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     </Marker>
//                       )
//                     })
//                   )}
//                 </Map>
//                  {/* <GoogleMapReact

//                   bootstrapURLKeys={{ key:
//                     //  "AIzaSyAvjN_L592ZgMDP6u_P39hhtqZ8wtNYE6s"
//                   "AIzaSyDfnY7GcBdHHFQTxRCSJGR-AGUEUnMBfqo"
//                   // ""
//                    }}
//                   // bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   // defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                     styles: mapStyle,
//                     // mapId: 'd9fa7933e82bb5fd'
//                   }}
//                   // onChange={handleMapChange}
//                   // center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1]+0.35 } : undefined}

//                   center={mapCenter}


//                   // zoom={selectedPlace ? 14 : 12}
//                   zoom={zoomLevel}
//                   onChange={({ center, zoom }) => {
//                     // Optional: You can log or do something when the map center or zoom changes
//                     console.log('Zoom changed to: ', zoom);
//                     console.log("############## App.js zoomLevel insdie onChange ###########")
//                     console.log(zoomLevel)
//                   }}
//                   onZoomAnimationEnd={() => {
//                     // Optional: You can handle the zoom completion if needed
//                     console.log('Zoom Animation Ended');
//                   }}
//                 >


//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact> */}
//                 {/* </ReactMapGL> */}

//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                           setZoomLevel={setZoomLevel}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/" element={<MapComponent />} />
//         {/* <Route path="/" element={<GridLayout items={markers} />} /> */}
//         <Route path="/user/:id" element={<UserPage />} />
//         <Route path="/media/files" element={<MinioFileList />} />
//         <Route path="/homepage" element={<HomePage />} />
//         <Route path="/man" element={<GridAsymmetrical />} />



//       </Routes>
//     </Router>
//   );
// }


// ok v5.1 add Userlist, fix one hand move map

// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";
// import { store} from './redux/MyRedux'
// // import GridLayout from "./components/UserList"; // UserList -> in ExplorePage.js and imported as Gridlayout
// import UserPage from "./components/UserPage";
// // import MinioFileList from "./components";
// import HomePage from "./components/HomePage";
// import UserList from "./components/UserList";
// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   useEffect(() => {
//     if (showPopup) {
//       // Dispatch the "expand" action when showPopup is true
//       store.dispatch({ type: "EXTEND" });
//       // console.log("####### redux app.js extend #########")
//       // console.log(store.getState().isExtented)
//     } else {
//       // Dispatch the "shrink" action when showPopup is false
//       store.dispatch({ type: "SHRINK" });
//       // console.log("####### redux app.js shrink #########")
//       // console.log(store.getState().isExtented)
//     }
//   }, [showPopup]);

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     // console.log("##### App.js _id #######");
//     // console.log(placeId);
//     // console.log(place._id);
//     // console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       // console.log("##### selectedPlace has changed #####");
//       // console.log(selectedPlace._id);
//       // console.log(selectedPlace);
//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'

//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       // console.log("########### fetch response App.js ######### ");
//       // console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };


//   // const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state

//   const [gestureHandling, setGestureHandling] = useState('greedy'); // Gesture handling state


//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/explore"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">

//                 <h1 className="userList" style={{zIndex: '1 !important'}}>
//                   <UserList className="userList" />
//                 </h1>

//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                   }}
//                   // onChange={handleMapChange}
//                   center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1] } : undefined}
//                   zoom={selectedPlace ? 14 : 12}
//                 >



//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/" element={<MapComponent />} />
//         {/* <Route path="/" element={<GridLayout items={markers} />} /> */}
//         <Route path="/user/:id" element={<UserPage />} />
//         <Route path="/media/files" element={<MinioFileList />} />
//         <Route path="/homepage" element={<HomePage />} />


//       </Routes>
//     </Router>
//   );
// }



// //ok v5 add explore page
// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";
// import { store} from './redux/MyRedux'
// // import GridLayout from "./components/UserList"; // UserList -> in ExplorePage.js and imported as Gridlayout
// import UserPage from "./components/UserPage";
// // import MinioFileList from "./components";
// import HomePage from "./components/HomePage";

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   useEffect(() => {
//     if (showPopup) {
//       // Dispatch the "expand" action when showPopup is true
//       store.dispatch({ type: "EXTEND" });
//       // console.log("####### redux app.js extend #########")
//       // console.log(store.getState().isExtented)
//     } else {
//       // Dispatch the "shrink" action when showPopup is false
//       store.dispatch({ type: "SHRINK" });
//       // console.log("####### redux app.js shrink #########")
//       // console.log(store.getState().isExtented)
//     }
//   }, [showPopup]);

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     // console.log("##### App.js _id #######");
//     // console.log(placeId);
//     // console.log(place._id);
//     // console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       // console.log("##### selectedPlace has changed #####");
//       // console.log(selectedPlace._id);
//       // console.log(selectedPlace);
//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'

//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       // console.log("########### fetch response App.js ######### ");
//       // console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };


//   const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state


//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                   }}
//                   // onChange={handleMapChange}
//                   center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1] } : undefined}
//                   zoom={selectedPlace ? 14 : 12}
//                 >
//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/current" element={<MapComponent />} />
//         {/* <Route path="/" element={<GridLayout items={markers} />} /> */}
//         <Route path="/user/:id" element={<UserPage />} />
//         <Route path="/media/files" element={<MinioFileList />} />
//         <Route path="/homepage" element={<HomePage />} />


//       </Routes>
//     </Router>
//   );
// }




// ok v4.3 add redux -> add to index.js

// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";
// import { store} from './redux/MyRedux'


// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   useEffect(() => {
//     if (showPopup) {
//       // Dispatch the "expand" action when showPopup is true
//       store.dispatch({ type: "EXTEND" });
//       console.log("####### redux app.js extend #########")
//       console.log(store.getState().isExtented)
//     } else {
//       // Dispatch the "shrink" action when showPopup is false
//       store.dispatch({ type: "SHRINK" });
//       console.log("####### redux app.js shrink #########")
//       console.log(store.getState().isExtented)
//     }
//   }, [showPopup]);

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     console.log("##### App.js _id #######");
//     console.log(placeId);
//     console.log(place._id);
//     console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       console.log("##### selectedPlace has changed #####");
//       console.log(selectedPlace._id);
//       console.log(selectedPlace);
//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       console.log("########### fetch response App.js ######### ");
//       console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };


//   const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state


//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                   }}
//                   // onChange={handleMapChange}
//                   center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1] } : undefined}
//                   zoom={selectedPlace ? 14 : 12}
//                 >
//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/current" element={<MapComponent />} />
//         <Route path="/explore" element={<h1>TEST PAGE</h1>} />
//       </Routes>
//     </Router>
//   );
// }



// ok v4.2 disable map pointer on Marker click
// pass in map Ref to disable map drag of sidebar open, and link Cart and Marker
// pass in the none/auto to gesturehanding from Fullmapsidebar
// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     console.log("##### App.js _id #######");
//     console.log(placeId);
//     console.log(place._id);
//     console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       console.log("##### selectedPlace has changed #####");
//       console.log(selectedPlace._id);
//       console.log(selectedPlace);
//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       console.log("########### fetch response App.js ######### ");
//       console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };


//   const [gestureHandling, setGestureHandling] = useState('auto'); // Gesture handling state


//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: gestureHandling, // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                   }}
//                   // onChange={handleMapChange}
//                   center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1] } : undefined}
//                   zoom={selectedPlace ? 14 : 12}
//                 >
//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                         setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/current" element={<MapComponent />} />
//         <Route path="/test" element={<h1>TEST PAGE</h1>} />
//       </Routes>
//     </Router>
//   );
// }






// ok v4.1 auto move map to Marker location on Cart click
// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   // const handleCartItemClick = (placeId) => {
//   //   const place = markers.find((p) => p._id === placeId);
//   //   setSelectedPlace(place);
//   //   console.log("##### App.js _id #######")
//   //   console.log(placeId)
//   //   console.log(place._id)
//   //   console.log(place)
//   //   console.log(selectedPlace)
//   //   console.log(selectedPlace._id)
//   //   console.log("### done ###")

//   // };

//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     console.log("##### App.js _id #######");
//     console.log(placeId);
//     console.log(place._id);
//     console.log(place);
//   };

//   // Use `useEffect` to track changes to `selectedPlace`
//   useEffect(() => {
//     if (selectedPlace) {
//       console.log("##### selectedPlace has changed #####");
//       console.log(selectedPlace._id);
//       console.log(selectedPlace);
//     }
//   }, [selectedPlace]); // This effect will run every time `selectedPlace` changes


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       console.log("########### fetch response App.js ######### ");
//       console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: 'auto',  // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                   }}
//                   // onChange={handleMapChange}
//                   center={selectedPlace ? { lat: selectedPlace.location.coordinates[0], lng: selectedPlace.location.coordinates[1] } : undefined}
//                   zoom={selectedPlace ? 14 : 12}
//                 >
//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/current" element={<MapComponent />} />
//         <Route path="/test" element={<h1>TEST PAGE</h1>} />
//       </Routes>
//     </Router>
//   );
// }





// ok v4 link Cart and Marker
// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import { TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import Marker2 from "./components/Marker2";
// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// export default function MapContainer() {
//   const mapRef = useRef();
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const [places, setPlaces] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   const handleCartItemClick = (placeId) => {
//     const place = markers.find((p) => p._id === placeId);
//     setSelectedPlace(place);
//     console.log("##### App.js _id #######")
//     console.log(place._id)

//   };


//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   const [handleFilter, setHandleFilter] = useState({
//     filterBedrooms: 'any',
//     filterBathrooms: 'any',
//     filterCars: 'any',
//     filterSort: 'any',
//     priceFrom: '0',
//     priceTo: '1000001',
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
//   const [markers, setMarkers] = useState([]);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//       // Include the token in the Authorization header for the API request
//       const response = await axios.get(apiBaseUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token to the Authorization header
//         },
//       });

//       // setPlaces(response.data)
//       console.log("########### fetch response App.js ######### ");
//       console.log(response.data);
//       setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//       setError('Error fetching markers');
//       console.error('Error fetching markers:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   const propertiesList = isFiltering ? filteredProperties : properties;

//   // Function to toggle filter visibility
//   function toggleFilter(e) {
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
//   }

//   function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter((prevState) => ({ ...prevState, [name]: value }));
//   }

//   function clearFilter(e, form) {
//     e.preventDefault();
//     setProperties(properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter((prevState) => ({
//       ...prevState,
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001',
//     }));

//     form.current.reset();
//   }

//   const handleMarkerClick = () => {
//     // Disable pointer events on the map when a marker is clicked
//     if (mapRef?.current && mapRef.current.googleMap) {
//       const mapContainer = mapRef.current.googleMap.getDiv();
//       mapContainer.style.pointerEvents = 'none'; // Disable pointer events
//       setIsClicked(true);

//       // Optionally, reset pointer events after a set time or an action
//       setTimeout(() => {
//         mapContainer.style.pointerEvents = 'auto'; // Enable pointer events again
//         setIsClicked(false); // Reset the state
//       }, 3000); // Adjust timeout duration as needed
//     }
//   };

//   const [visibleMarkers, setVisibleMarkers] = useState([]);
//   const [mapBounds, setMapBounds] = useState(null);
//   const handleMapChange = ({ center, zoom, bounds }) => {
//     setMapBounds(bounds);
//     // Filter places that are within the bounds of the map
//     const visible = markers.filter((place) =>
//       bounds.contains({ lat: place.lat, lng: place.lng })
//     );
//     setVisibleMarkers(visible);
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                   ref={mapRef}
//                   options={{
//                     gestureHandling: 'auto',  // Disable map pointer and interactions (e.g., drag, zoom)
//                     // scrollwheel: false,       // Disable scroll zoom
//                     // draggable: false,         // Disable dragging
//                     // disableDoubleClickZoom: true, // Disable double-click zoom
//                   }}
//                   // onChange={handleMapChange}
//                   center={selectedPlace ? { lat: selectedPlace.lat, lng: selectedPlace.lng } : undefined}
//                   zoom={selectedPlace ? 14 : 12}
//                 >
//                   {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker2
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         property={marker}
//                         mapRef={mapRef}
//                         onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         selectedPlace={selectedPlace}
//                       />
//                     ))
//                   )}
//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map((marker, index) => {
//                       const rowIndex = Math.floor(index / 4);  // Calculate which row this card is in
//                       const isDoubleHeight = (rowIndex % 2 === 0 && index % 4 === 0) || (rowIndex % 2 !== 0 && index % 4 === 3);

//                       return (
//                         <Card
//                           key={marker._id}
//                           property={marker}
//                           activeProperty={activeProperty}
//                           setActiveProperty={setActiveProperty}
//                           setShowPopup={setShowPopup}
//                           isDoubleHeight={isDoubleHeight}  // Pass the calculated value
//                           onCartItemClick={handleCartItemClick}
//                         />
//                       );
//                     })}
//                   </div>

//                   {isFiltering && propertiesList.length === 0 && (
//                     <p className="warning">
//                       <img src={image} alt="No properties were found." />
//                       <br />
//                       No properties were found.
//                     </p>
//                   )}

//                   {showPopup && activeProperty && (
//                     <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                   )}
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />
//         <Route path="/current" element={<MapComponent />} />
//         <Route path="/test" element={<h1>TEST PAGE</h1>} />
//       </Routes>
//     </Router>
//   );
// }




// ok v3 use token, add new full screen
// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// // import MapComponent from "./MapComponent";
// // import { AppContainer } from './App.styles';

// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// // import React from 'react';
// // import MapComponent from './LeafletMapComponent';
// import EmbedMapComponent from './GoogleStaticMap';
// // import React, { Component,  useState, useEffect } from 'react';
// // import GoogleMapReact from 'google-map-react';
// // import Flat from './components/flat';
// // import Marker from './components/marker';
// // import './App.css';
// import React, { useState, useEffect, useRef  } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// // import S3VideoPlayer from './S3VideoPlayer';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import {  TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// // import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import { marker } from "leaflet";
// // import Login from "./components/Login";
// import Login from "./components/LoginClass";
// import  Marker2  from "./components/Marker2";

// import Popup from "./components/Card/Popup";
// import FullScreenSidebar from "./components/FullScreenSidebar";

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };
// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// function Marker() {
//   return <i className="fa fa-map-marker fa-3x text-danger" />;
// }



// export default function MapContainer() {
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker

//   const [isClicked, setIsClicked] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };


//   const [handleFilter, setHandleFilter] = useState({
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001'
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
//   const [markers, setMarkers] = useState([]);

//   console.log('########## App.js apiBaseUrl #######')
//   // console.log(apiBaseUrl)
//   console.log(process.env.REACT_APP_API_BASE_URL);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);

//     // Retrieve token from localStorage
//     const token = localStorage.getItem('token');

//     try {
//         // Include the token in the Authorization header for the API request
//         const response = await axios.get(apiBaseUrl, {
//             headers: {
//                 Authorization: `Bearer ${token}` // Add token to the Authorization header
//             }
//         });

//         console.log("########### fetch response App.js ######### ");
//         console.log(response.data);
//         setMarkers(response.data);  // Update the state with fetched markers
//     } catch (error) {
//         setError('Error fetching markers');
//         console.error('Error fetching markers:', error);
//     } finally {
//         setLoading(false);
//     }
//   };


//   const v1fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//         const response = await axios.get(apiBaseUrl);
//         console.log("########### fetch response App.js ######### ")
//         console.log( response.data )
//         setMarkers(response.data);
//     } catch (error) {
//         setError('Error fetching markers');
//         console.error('Error fetching markers:', error);
//     } finally {
//         setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   function toggleFilter(e){
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
// }

// function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter(prevState => ({ ...prevState, [name]: value }));
// }

// function clearFilter(e, form){
//     e.preventDefault();
//     setProperties (properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter(prevState => ({ ...prevState,
//         filterBedrooms: 'any',
//         filterBathrooms: 'any',
//         filterCars: 'any',
//         filterSort: 'any',
//         priceFrom: '0',
//         priceTo: '1000001'
//     }));

//     form.current.reset();
// }

//   let coordinates = []

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src =
//       "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
//     script.async = true;
//     document.body.appendChild(script);
//   });

//   const setGoogleMapRef = (map, maps) => {
//     let googleMapRef = map;
//     let googleRef = maps;
//     const infoWindow = new googleRef.InfoWindow({
//       content: "<p>Hello</p>",
//     });
//     let markers =
//       coordinates &&
//       coordinates.map((marker, index) => {
//         const refMarker = new googleRef.Marker({
//           position: marker,
//           label: labels[index % labels.length],
//           title: "Car",
//         });

//         // googleRef.event.addEventListener(refMarker, 'click', () => {
//         //   infoWindow.open(map, googleMapRef)
//         // })

//         // refMarker.addEventListener('click', () => {
//         //   infoWindow.open(map, refMarker)
//         // })

//         return refMarker;
//       });

//     let markerCluster = new MarkerClusterer(map, markers, {
//       imagePath: "/marker_images/m",
//       minimumClusterSize: 4,
//     });
//   };


//   const propertiesList = isFiltering ? filteredProperties : properties;
//   return (
//     <>
//       <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                 >
//                   {/* <Marker2 lat={37.7749} lng={-122.4194} text="San Francisco" />
//                   <Marker2 lat={37.8049} lng={-122.2711} text="Oakland" />
//                   <Marker2 lat={37.8715} lng={-122.2730} text="Berkeley" />
//                   <Marker2 lat={37.3382} lng={-121.8863} text="San Jose" />
//                   <Marker2 lat={37.6879} lng={-122.4692} text="Daly City" />
//                   <Marker2 lat={37.6541} lng={-122.4076} text="South San Francisco" />
//                   <Marker2 lat={37.8591} lng={-122.4855} text="Sausalito" />
//                   <Marker2 lat={37.5623} lng={-122.2711} text="San Mateo" />
//                   <Marker2 lat={37.4419} lng={-122.1430} text="Palo Alto" />
//                   <Marker2 lat={37.5483} lng={-121.9886} text="Fremont" />
//                   <Marker2 lat={37.9358} lng={-122.3478} text="Richmond" /> */}

//                   {loading ? (
//                       <p>Loading markers...</p>
//                   ) : (
//                       markers.map(marker => (
//                           <Marker2
//                               key={marker._id} // Ensure each marker has a unique key
//                               lat={marker.location.coordinates[0]} // Latitude at index 1
//                               lng={marker.location.coordinates[1]} // Longitude at index 0
//                               text={marker.title} // Title of the marker
//                               property={marker}
//                           />
//                       ))
//                   )}


//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map(marker => (
//                       <Card
//                         key={marker._id}
//                         // property={property}
//                         property={marker}
//                         activeProperty={activeProperty}
//                         setActiveProperty={setActiveProperty}
//                         setShowPopup={setShowPopup}
//                       />
//                     ))}


//                     {isFiltering && propertiesList.length === 0 && (
//                       <p className="warning">
//                         <img src={image} alt="No properties were found." /><br />
//                         No properties were found.
//                       </p>
//                     )}

//                     {showPopup && activeProperty && (
//                         <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                     )}

//                     {/* <FullScreenSidebar
//                     title={activeProperty.title}
//                     content={
//                         <div>
//                           </div>

//                     }
//                     onClose={handleClosePopup} // Pass close handler to the sidebar

//                     /> */}

//                   </div>
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />

//         <Route path="/current" element={<MapComponent />} />
//         {/* <Route path="/current" element={<SubMapComponent />} /> */}
//         <Route path="/test" element={<h1>TEST PAGE</h1>} />
//         {/* <Route path="/login" element={<Login />}  /> */}
//         {/* <Route path="/login" element={<h1>TEST Login PAGE </h1>}   /> */}
//           {/* <Login /> */}

//       </Routes>
//       </Router>

//     </>
//   );
// }




// ok (fix css for phone) v2 clean up, add Popup
// MapComponent is used directly in index.js
// import React, { Component, useEffect, useState } from "react";
// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// // import MapComponent from "./MapComponent";
// // import { AppContainer } from './App.styles';

// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// // import React from 'react';
// // import MapComponent from './LeafletMapComponent';
// import EmbedMapComponent from './GoogleStaticMap';
// // import React, { Component,  useState, useEffect } from 'react';
// // import GoogleMapReact from 'google-map-react';
// // import Flat from './components/flat';
// // import Marker from './components/marker';
// // import './App.css';
// import React, { useState, useEffect, useRef  } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// // import S3VideoPlayer from './S3VideoPlayer';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import {  TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// // import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import { marker } from "leaflet";
// // import Login from "./components/Login";
// import Login from "./components/LoginClass";
// import  Marker2  from "./components/Marker2";

// import Popup from "./components/Card/Popup";

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };
// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// function Marker() {
//   return <i className="fa fa-map-marker fa-3x text-danger" />;
// }



// export default function MapContainer() {
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);
//   const [highlightedMarker, setHighlightedMarker] = useState(null); // State to track the highlighted marker


//   const [handleFilter, setHandleFilter] = useState({
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001'
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
//   const [markers, setMarkers] = useState([]);

//   console.log('########## App.js apiBaseUrl #######')
//   // console.log(apiBaseUrl)
//   console.log(process.env.REACT_APP_API_BASE_URL);

//   const handleCardClick = (lat, lng) => {
//     // Update the highlighted marker coordinates based on the card clicked
//     setHighlightedMarker({ lat, lng });
//   };

//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//         const response = await axios.get(apiBaseUrl);
//         console.log("########### fetch response App.js ######### ")
//         console.log( response.data )
//         setMarkers(response.data);
//     } catch (error) {
//         setError('Error fetching markers');
//         console.error('Error fetching markers:', error);
//     } finally {
//         setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   function toggleFilter(e){
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
// }

// function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter(prevState => ({ ...prevState, [name]: value }));
// }

// function clearFilter(e, form){
//     e.preventDefault();
//     setProperties (properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter(prevState => ({ ...prevState,
//         filterBedrooms: 'any',
//         filterBathrooms: 'any',
//         filterCars: 'any',
//         filterSort: 'any',
//         priceFrom: '0',
//         priceTo: '1000001'
//     }));

//     form.current.reset();
// }

//   let coordinates = []

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src =
//       "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
//     script.async = true;
//     document.body.appendChild(script);
//   });

//   const setGoogleMapRef = (map, maps) => {
//     let googleMapRef = map;
//     let googleRef = maps;
//     const infoWindow = new googleRef.InfoWindow({
//       content: "<p>Hello</p>",
//     });
//     let markers =
//       coordinates &&
//       coordinates.map((marker, index) => {
//         const refMarker = new googleRef.Marker({
//           position: marker,
//           label: labels[index % labels.length],
//           title: "Car",
//         });

//         // googleRef.event.addEventListener(refMarker, 'click', () => {
//         //   infoWindow.open(map, googleMapRef)
//         // })

//         // refMarker.addEventListener('click', () => {
//         //   infoWindow.open(map, refMarker)
//         // })

//         return refMarker;
//       });

//     let markerCluster = new MarkerClusterer(map, markers, {
//       imagePath: "/marker_images/m",
//       minimumClusterSize: 4,
//     });
//   };


//   const propertiesList = isFiltering ? filteredProperties : properties;
//   return (
//     <>
//       <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                 >
//                   {/* <Marker2 lat={37.7749} lng={-122.4194} text="San Francisco" />
//                   <Marker2 lat={37.8049} lng={-122.2711} text="Oakland" />
//                   <Marker2 lat={37.8715} lng={-122.2730} text="Berkeley" />
//                   <Marker2 lat={37.3382} lng={-121.8863} text="San Jose" />
//                   <Marker2 lat={37.6879} lng={-122.4692} text="Daly City" />
//                   <Marker2 lat={37.6541} lng={-122.4076} text="South San Francisco" />
//                   <Marker2 lat={37.8591} lng={-122.4855} text="Sausalito" />
//                   <Marker2 lat={37.5623} lng={-122.2711} text="San Mateo" />
//                   <Marker2 lat={37.4419} lng={-122.1430} text="Palo Alto" />
//                   <Marker2 lat={37.5483} lng={-121.9886} text="Fremont" />
//                   <Marker2 lat={37.9358} lng={-122.3478} text="Richmond" /> */}

//                   {loading ? (
//                       <p>Loading markers...</p>
//                   ) : (
//                       markers.map(marker => (
//                           <Marker2
//                               key={marker._id} // Ensure each marker has a unique key
//                               lat={marker.location.coordinates[0]} // Latitude at index 1
//                               lng={marker.location.coordinates[1]} // Longitude at index 0
//                               text={marker.title} // Title of the marker
//                               property={marker}
//                           />
//                       ))
//                   )}


//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map(marker => (
//                       <Card
//                         key={marker._id}
//                         // property={property}
//                         property={marker}
//                         activeProperty={activeProperty}
//                         setActiveProperty={setActiveProperty}
//                         setShowPopup={setShowPopup}
//                       />
//                     ))}
//                     {isFiltering && propertiesList.length === 0 && (
//                       <p className="warning">
//                         <img src={image} alt="No properties were found." /><br />
//                         No properties were found.
//                       </p>
//                     )}

//                     {showPopup && activeProperty && (
//                         <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                     )}
//                   </div>
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />

//         <Route path="/current" element={<MapComponent />} />
//         {/* <Route path="/current" element={<SubMapComponent />} /> */}
//         <Route path="/test" element={<h1>TEST PAGE</h1>} />
//         <Route path="/login" element={<Login />}  />
//         {/* <Route path="/login" element={<h1>TEST Login PAGE </h1>}   /> */}


//       </Routes>
//       </Router>

//     </>
//   );
// }



// ok v1
// // MapComponent is used directly in index.js
// // import React, { Component, useEffect, useState } from "react";
// import GoogleMapReact from "google-map-react";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
// // import MapComponent from "./MapComponent";
// // import { AppContainer } from './App.styles';

// import logo from './logo.svg';
// import './App.css';
// import 'leaflet/dist/leaflet.css';
// // import React from 'react';
// // import MapComponent from './LeafletMapComponent';
// import EmbedMapComponent from './GoogleStaticMap';
// // import React, { Component,  useState, useEffect } from 'react';
// // import GoogleMapReact from 'google-map-react';
// // import Flat from './components/flat';
// // import Marker from './components/marker';
// // import './App.css';
// import React, { useState, useEffect, useRef  } from 'react';
// import { AppContainer } from './App.styles2';
// import image from './images/location-map.svg';
// import { Header, Card, GoogleMap, MinioFileList } from './components';
// import data from './data';
// // import S3VideoPlayer from './S3VideoPlayer';
// import Cookies from 'js-cookie'; // For easier cookie handling
// import axios from "axios";
// import {  TileLayer, useMapEvents } from "react-leaflet";
// import MarkerForm from "./components/MarkerForm";
// import Upload from "./components/Upload";
// // import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MapComponent from "./components/MapComponent";
// import { marker } from "leaflet";
// // import Login from "./components/Login";
// import Login from "./components/LoginClass";
// import  Marker2  from "./components/Marker2";

// import Popup from "./components/Card/Popup";

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };
// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// function Marker() {
//   return <i className="fa fa-map-marker fa-3x text-danger" />;
// }



// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={markerPosition}
//         zoom={5}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         {/* <button onClick={saveLocation}>Save Final Marker Location</button> */}
//       </div>
//       {/* <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div> */}
//       <MarkerForm/>
//       <Upload/>
//     </div>
//   );
// };

// export default function MapContainer() {
//   let markersArray = [];
//   const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   const [filterIsVisible, setFilterIsVisible] = useState(false);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [isFiltering, setIsFiltering] = useState(false);

//   const [showPopup, setShowPopup] = useState(false);

//   const [handleFilter, setHandleFilter] = useState({
//       filterBedrooms: 'any',
//       filterBathrooms: 'any',
//       filterCars: 'any',
//       filterSort: 'any',
//       priceFrom: '0',
//       priceTo: '1000001'
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
//   const [markers, setMarkers] = useState([]);

//   console.log('########## App.js apiBaseUrl #######')
//   // console.log(apiBaseUrl)
//   console.log(process.env.REACT_APP_API_BASE_URL);


//   const fetchMarkers = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//         const response = await axios.get(apiBaseUrl);
//         console.log("########### fetch response App.js ######### ")
//         console.log( response.data )
//         setMarkers(response.data);
//     } catch (error) {
//         setError('Error fetching markers');
//         console.error('Error fetching markers:', error);
//     } finally {
//         setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchMarkers();
//   }, []);

//   function toggleFilter(e){
//     e.preventDefault();
//     setFilterIsVisible(!filterIsVisible);
// }

// function handleFilterChange(e) {
//     const { name, value } = e.target;
//     setHandleFilter(prevState => ({ ...prevState, [name]: value }));
// }

// function clearFilter(e, form){
//     e.preventDefault();
//     setProperties (properties.sort((a, b) => a.index - b.index));
//     setFilteredProperties([]);
//     setActiveProperty(properties[0]);
//     setIsFiltering(false);
//     setHandleFilter(prevState => ({ ...prevState,
//         filterBedrooms: 'any',
//         filterBathrooms: 'any',
//         filterCars: 'any',
//         filterSort: 'any',
//         priceFrom: '0',
//         priceTo: '1000001'
//     }));

//     form.current.reset();
// }

//   let coordinates = [
//     {
//       lat: 53.1408926,
//       lng: -3.3684601,
//     },
//     {
//       lat: 40.8532681,
//       lng: -8.4095847,
//     },
//     {
//       lat: 52.3677552,
//       lng: 4.9066119,
//     },
//     {
//       lat: 54.66641,
//       lng: 25.2746179,
//     },
//     {
//       lat: 52.3260381,
//       lng: 6.6500213,
//     },
//     {
//       lat: 54.1269985,
//       lng: -0.8123949,
//     },
//     {
//       lat: 45.4642035,
//       lng: 9.189982,
//     },
//     {
//       lat: 51.6297985,
//       lng: 5.9058843,
//     },
//     {
//       lat: 41.9560319,
//       lng: 12.7115568,
//     },
//     {
//       lat: 51.2155549,
//       lng: 3.1943421,
//     },
//     {
//       lat: 55.953252,
//       lng: -3.188267,
//     },
//     {
//       lat: 55.3495423,
//       lng: 10.5810967,
//     },
//     {
//       lat: 40.6324761,
//       lng: -3.160153,
//     },
//     {
//       lat: 38.8907653,
//       lng: -9.0407712,
//     },
//     {
//       lat: 51.9763277,
//       lng: 4.2606391,
//     },
//     {
//       lat: 52.358833,
//       lng: 6.5855105,
//     },
//     {
//       lat: 51.585456,
//       lng: 4.6654572,
//     },
//     {
//       lat: 40.8408876,
//       lng: -8.4802242,
//     },
//     {
//       lat: 51.8418584,
//       lng: 6.2698593,
//     },
//     {
//       lat: 53.337242,
//       lng: -2.817911,
//     },
//     {
//       lat: 51.9806322,
//       lng: 4.1341847,
//     },
//     {
//       lat: 48.1351253,
//       lng: 11.5819805,
//     },
//     {
//       lat: 39.4746012,
//       lng: -0.3969425,
//     },
//     {
//       lat: 39.4746012,
//       lng: -0.3969425,
//     },
//     {
//       lat: 51.0300433,
//       lng: 4.1830848,
//     },
//     {
//       lat: 52.8223137,
//       lng: 5.9619874,
//     },
//     {
//       lat: 52.033157,
//       lng: 5.60933,
//     },
//     {
//       lat: 54.6871555,
//       lng: 25.2796514,
//     },
//     {
//       lat: 48.35087,
//       lng: 10.15824,
//     },
//     {
//       lat: 52.076055,
//       lng: 5.0569509,
//     },
//     {
//       lat: 51.150719,
//       lng: -0.973177,
//     },
//     {
//       lat: 51.692084,
//       lng: 5.0467203,
//     },
//     {
//       lat: 38.7222524,
//       lng: -9.1393366,
//     },
//     {
//       lat: 51.6960163,
//       lng: 5.0474343,
//     },
//     {
//       lat: 45.5842412,
//       lng: 10.1803102,
//     },
//     {
//       lat: 52.3148847,
//       lng: 6.5035747,
//     },
//     {
//       lat: 46.3574807,
//       lng: 14.1605621,
//     },
//     {
//       lat: 52.547351,
//       lng: 4.6873946,
//     },
//     {
//       lat: 51.1707221,
//       lng: 5.9717331,
//     },
//     {
//       lat: 51.2299337,
//       lng: 3.2028416,
//     },
//     {
//       lat: 52.4766147,
//       lng: 10.3280491,
//     },
//     {
//       lat: 52.083938,
//       lng: 6.1564633,
//     },
//     {
//       lat: 51.0621734,
//       lng: 3.4590235,
//     },
//     {
//       lat: 48.2202322,
//       lng: 15.2188862,
//     },
//     {
//       lat: 41.2594683,
//       lng: -8.2102867,
//     },
//     {
//       lat: 41.3850639,
//       lng: 2.1734035,
//     },
//     {
//       lat: 50.905476,
//       lng: 5.6736566,
//     },
//     {
//       lat: 51.1339214,
//       lng: 4.8650422,
//     },
//     {
//       lat: 51.4789568,
//       lng: 0.3364424,
//     },
//     {
//       lat: 56.9496487,
//       lng: 24.1051864,
//     },
//     {
//       lat: 51.2298182,
//       lng: 4.5108974,
//     },
//     {
//       lat: 55.8368859,
//       lng: 12.9303544,
//     },
//     {
//       lat: 51.6912445,
//       lng: 4.2722818,
//     },
//     {
//       lat: 52.014642,
//       lng: -0.676008,
//     },
//     {
//       lat: 38.6307459,
//       lng: -0.5446912,
//     },
//     {
//       lat: 51.5640036,
//       lng: 5.1343517,
//     },
//     {
//       lat: 44.1488394,
//       lng: 4.1009628,
//     },
//     {
//       lat: 38.7590029,
//       lng: -9.1380785,
//     },
//     {
//       lat: 47.5881273,
//       lng: 8.2835612,
//     },
//     {
//       lat: 41.1579438,
//       lng: -8.6291053,
//     },
//     {
//       lat: 51.5676759,
//       lng: 5.0852389,
//     },
//     {
//       lat: 45.5415526,
//       lng: 10.2118019,
//     },
//     {
//       lat: 47.6795337,
//       lng: 19.0668602,
//     },
//     {
//       lat: 52.9264477,
//       lng: 7.0184251,
//     },
//     {
//       lat: 52.9264477,
//       lng: 7.0184251,
//     },
//     {
//       lat: 52.0878643,
//       lng: 4.7018183,
//     },
//     {
//       lat: 51.2846533,
//       lng: -0.5089219,
//     },
//     {
//       lat: 51.721143,
//       lng: 0.133049,
//     },
//     {
//       lat: 51.8486514,
//       lng: 4.9852827,
//     },
//     {
//       lat: 45.5454787,
//       lng: 11.5354214,
//     },
//     {
//       lat: 38.7379621,
//       lng: -9.1403984,
//     },
//     {
//       lat: 44.4056499,
//       lng: 8.946256,
//     },
//     {
//       lat: 50.8478098,
//       lng: 5.6820524,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 51.0576948,
//       lng: -1.3080629,
//     },
//     {
//       lat: 40.6400629,
//       lng: 22.9444191,
//     },
//     {
//       lat: 50.8157248,
//       lng: 5.1862508,
//     },
//     {
//       lat: 41.1579438,
//       lng: -8.6291053,
//     },
//     {
//       lat: 42.3421183,
//       lng: -7.8642353,
//     },
//     {
//       lat: 51.5676759,
//       lng: 5.0852389,
//     },
//     {
//       lat: 51.031577,
//       lng: 0.05518,
//     },
//     {
//       lat: 37.6525404,
//       lng: 22.858217,
//     },
//     {
//       lat: 52.2612215,
//       lng: 6.8094445,
//     },
//     {
//       lat: 51.5017996,
//       lng: 5.3312406,
//     },
//     {
//       lat: 38.1441496,
//       lng: 13.3314479,
//     },
//     {
//       lat: 50.9806151,
//       lng: 4.827381,
//     },
//     {
//       lat: 44.9943588,
//       lng: 7.6946231,
//     },
//     {
//       lat: 51.2820584,
//       lng: 4.7211872,
//     },
//     {
//       lat: 55.8275387,
//       lng: 13.3325524,
//     },
//     {
//       lat: 52.299383,
//       lng: 5.1575015,
//     },
//     {
//       lat: 51.9778185,
//       lng: 5.7346845,
//     },
//     {
//       lat: 45.0703393,
//       lng: 7.686864,
//     },
//     {
//       lat: 50.942539,
//       lng: 6.9375935,
//     },
//     {
//       lat: 50.83761,
//       lng: -0.774936,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 55.7032948,
//       lng: 21.1442795,
//     },
//     {
//       lat: 52.8583012,
//       lng: -8.9668687,
//     },
//     {
//       lat: 51.0258761,
//       lng: 4.4775362,
//     },
//     {
//       lat: 39.074208,
//       lng: 21.824312,
//     },
//     {
//       lat: 44.7269585,
//       lng: 20.4866609,
//     },
//     {
//       lat: 39.245204,
//       lng: -77.266725,
//     },
//     {
//       lat: 40.7672759,
//       lng: -73.906176,
//     },
//     {
//       lat: 29.3062365,
//       lng: 47.9414054,
//     },
//     {
//       lat: 51.7216367,
//       lng: 0.1332466,
//     },
//     {
//       lat: 51.8418584,
//       lng: 6.2698593,
//     },
//     {
//       lat: 52.014493,
//       lng: -0.6757654,
//     },
//     {
//       lat: -31.433333,
//       lng: 152.9,
//     },
//     {
//       lat: 52.1835206,
//       lng: 4.4485359,
//     },
//     {
//       lat: 3.4516467,
//       lng: -76.5319854,
//     },
//     {
//       lat: 45.5016889,
//       lng: -73.567256,
//     },
//     {
//       lat: 45.5016889,
//       lng: -73.567256,
//     },
//     {
//       lat: 40.7672759,
//       lng: -73.906176,
//     },
//     {
//       lat: 33.8462557,
//       lng: 35.9019519,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: -33.9248685,
//       lng: 18.4240553,
//     },
//     {
//       lat: 40.3212191,
//       lng: -78.3466645,
//     },
//     {
//       lat: 52.014493,
//       lng: -0.6757654,
//     },
//     {
//       lat: 34.0006293,
//       lng: -83.9092829,
//     },
//     {
//       lat: 33.2822878,
//       lng: -117.185294,
//     },
//     {
//       lat: 42.3149367,
//       lng: -83.0363633,
//     },
//     {
//       lat: -6.8226625,
//       lng: 39.3024465,
//     },
//     {
//       lat: 45.766821,
//       lng: 5.003658,
//     },
//     {
//       lat: 52.4715742,
//       lng: -0.930006,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 52.3666969,
//       lng: 4.8945398,
//     },
//     {
//       lat: 42.0133722,
//       lng: 24.8784393,
//     },
//     {
//       lat: 50.937531,
//       lng: 6.9602786,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 51.5852793,
//       lng: -0.1621969,
//     },
//     {
//       lat: 51.5852793,
//       lng: -0.1621969,
//     },
//     {
//       lat: 42.9624819,
//       lng: 13.485862,
//     },
//     {
//       lat: -33.9248685,
//       lng: 18.4240553,
//     },
//     {
//       lat: -34.6036844,
//       lng: -58.3815591,
//     },
//     {
//       lat: -37.4713077,
//       lng: 144.7851531,
//     },
//     {
//       lat: 52.3330862,
//       lng: 6.6523623,
//     },
//     {
//       lat: 43.2547731,
//       lng: 6.6378577,
//     },
//     {
//       lat: 40.7276419,
//       lng: -7.9157099,
//     },
//     {
//       lat: 53.6180233,
//       lng: -113.5541532,
//     },
//     {
//       lat: 38.9076089,
//       lng: -77.0722585,
//     },
//     {
//       lat: 30.6372918,
//       lng: -97.5750951,
//     },
//     {
//       lat: 51.5852793,
//       lng: -0.1621969,
//     },
//     {
//       lat: 40.8396304,
//       lng: 14.3648209,
//     },
//     {
//       lat: 54.720642,
//       lng: 25.2219079,
//     },
//     {
//       lat: 51.5546176,
//       lng: 4.4554315,
//     },
//     {
//       lat: 33.8746495,
//       lng: -84.8192499,
//     },
//     {
//       lat: 46.910567,
//       lng: 4.753218,
//     },
//     {
//       lat: 39.717625,
//       lng: -105.1470214,
//     },
//     {
//       lat: 40.4167754,
//       lng: -3.7037902,
//     },
//     {
//       lat: 51.2787075,
//       lng: 0.5217254,
//     },
//     {
//       lat: 45.5495339,
//       lng: 10.1334448,
//     },
//     {
//       lat: 51.5139131,
//       lng: -0.0706678,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 38.6446264,
//       lng: -121.2721718,
//     },
//     {
//       lat: 48.5734053,
//       lng: 7.7521113,
//     },
//     {
//       lat: 51.6101766,
//       lng: 5.4670505,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 52.1303931,
//       lng: 4.4357615,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 54.720642,
//       lng: 25.2219079,
//     },
//     {
//       lat: -23.5505199,
//       lng: -46.6333094,
//     },
//     {
//       lat: 54.1461082,
//       lng: -1.5163133,
//     },
//     {
//       lat: 36.045857,
//       lng: -94.227492,
//     },
//     {
//       lat: 52.4025768,
//       lng: 5.2644645,
//     },
//     {
//       lat: 50.8503396,
//       lng: 4.3517103,
//     },
//     {
//       lat: -33.9248685,
//       lng: 18.4240553,
//     },
//     {
//       lat: 43.4902927,
//       lng: -80.3420648,
//     },
//     {
//       lat: 43.4902927,
//       lng: -80.3420648,
//     },
//     {
//       lat: 36.328215,
//       lng: -119.250352,
//     },
//     {
//       lat: 64.146582,
//       lng: -21.9426354,
//     },
//     {
//       lat: -3.3186067,
//       lng: 114.5943784,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 45.627852,
//       lng: 9.136397,
//     },
//     {
//       lat: 55.169438,
//       lng: 23.881275,
//     },
//     {
//       lat: 54.8985207,
//       lng: 23.9035965,
//     },
//     {
//       lat: 51.233566,
//       lng: 4.4982073,
//     },
//     {
//       lat: 42.0700305,
//       lng: -88.0461658,
//     },
//     {
//       lat: 42.0700305,
//       lng: -88.0461658,
//     },
//     {
//       lat: 34.7540524,
//       lng: -77.4302414,
//     },
//     {
//       lat: 34.7540524,
//       lng: -77.4302414,
//     },
//     {
//       lat: 45.458626,
//       lng: 9.181873,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 45.458626,
//       lng: 9.181873,
//     },
//     {
//       lat: 40.9731743,
//       lng: 14.2076688,
//     },
//     {
//       lat: 34.145361,
//       lng: -84.092128,
//     },
//     {
//       lat: 38.8175778,
//       lng: 0.0196406,
//     },
//     {
//       lat: 38.8175778,
//       lng: 0.0196406,
//     },
//     {
//       lat: 38.8175778,
//       lng: 0.0196406,
//     },
//     {
//       lat: 41.3330567,
//       lng: -75.9552259,
//     },
//     {
//       lat: 33.4941704,
//       lng: -111.9260519,
//     },
//     {
//       lat: 41.3531722,
//       lng: -95.9847242,
//     },
//     {
//       lat: 28.0341847,
//       lng: -82.6650992,
//     },
//     {
//       lat: 29.4241219,
//       lng: -98.4936282,
//     },
//     {
//       lat: 28.5383355,
//       lng: -81.3792365,
//     },
//     {
//       lat: 43.289005,
//       lng: -123.467712,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 53.7650715,
//       lng: -1.5582852,
//     },
//     {
//       lat: 53.7559643,
//       lng: -1.5972041,
//     },
//     {
//       lat: 32.7554883,
//       lng: -97.3307658,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 37.2704694,
//       lng: -79.8767366,
//     },
//     {
//       lat: 35.0526641,
//       lng: -78.8783585,
//     },
//     {
//       lat: 32.7554883,
//       lng: -97.3307658,
//     },
//     {
//       lat: 32.7554883,
//       lng: -97.3307658,
//     },
//     {
//       lat: 28.5652787,
//       lng: -81.5861847,
//     },
//     {
//       lat: 44.6718605,
//       lng: 10.6414492,
//     },
//     {
//       lat: 33.5185892,
//       lng: -86.8103567,
//     },
//     {
//       lat: 43.3518607,
//       lng: -79.8153384,
//     },
//     {
//       lat: 37.6574552,
//       lng: -122.4795176,
//     },
//     {
//       lat: 26.3671072,
//       lng: -80.0885603,
//     },
//     {
//       lat: 37.6574552,
//       lng: -122.4795176,
//     },
//     {
//       lat: 34.1918215,
//       lng: -92.0848686,
//     },
//     {
//       lat: 54.6922529,
//       lng: 25.2717468,
//     },
//     {
//       lat: 41.079273,
//       lng: -85.1393513,
//     },
//     {
//       lat: 41.4787913,
//       lng: -112.0198131,
//     },
//     {
//       lat: 35.9786464,
//       lng: -95.9658897,
//     },
//     {
//       lat: 43.0941957,
//       lng: -89.3286064,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 35.0526641,
//       lng: -78.8783585,
//     },
//     {
//       lat: 38.3031837,
//       lng: -77.4605399,
//     },
//     {
//       lat: 41.1579438,
//       lng: -8.6291053,
//     },
//     {
//       lat: 30.9842977,
//       lng: -91.9623327,
//     },
//     {
//       lat: 35.20105,
//       lng: -91.8318334,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 42.7750481,
//       lng: -73.9524953,
//     },
//     {
//       lat: 39.2190608,
//       lng: -121.0610606,
//     },
//     {
//       lat: 41.3531722,
//       lng: -95.9847242,
//     },
//     {
//       lat: 41.3531722,
//       lng: -95.9847242,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: -33.8912486,
//       lng: 151.1760256,
//     },
//     {
//       lat: -33.8912486,
//       lng: 151.1760256,
//     },
//     {
//       lat: 40.1124839,
//       lng: -104.9366436,
//     },
//     {
//       lat: 33.8302961,
//       lng: -116.5452921,
//     },
//     {
//       lat: 41.2842457,
//       lng: -72.517425,
//     },
//     {
//       lat: 38.81144,
//       lng: -90.8529107,
//     },
//     {
//       lat: 38.81144,
//       lng: -90.8529107,
//     },
//     {
//       lat: 53.1699432,
//       lng: 6.3741261,
//     },
//     {
//       lat: 34.8028661,
//       lng: -86.9716741,
//     },
//     {
//       lat: 35.605951,
//       lng: -84.190434,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 41.6619927,
//       lng: -86.1586156,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 38.5347193,
//       lng: -105.9989022,
//     },
//     {
//       lat: -33.8688197,
//       lng: 151.2092955,
//     },
//     {
//       lat: 39.629526,
//       lng: -79.9558968,
//     },
//     {
//       lat: 40.8837789,
//       lng: -74.047523,
//     },
//     {
//       lat: 40.4417682,
//       lng: -75.3415667,
//     },
//     {
//       lat: 33.836081,
//       lng: -81.1637245,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 45.203171,
//       lng: -122.623294,
//     },
//     {
//       lat: 47.6062095,
//       lng: -122.3320708,
//     },
//     {
//       lat: 36.1699412,
//       lng: -115.1398296,
//     },
//     {
//       lat: 27.950575,
//       lng: -82.4571776,
//     },
//     {
//       lat: 39.5297824,
//       lng: -84.086601,
//     },
//     {
//       lat: 37.6682208,
//       lng: -121.0092068,
//     },
//     {
//       lat: 34.277953,
//       lng: -87.8299601,
//     },
//     {
//       lat: 46.7866719,
//       lng: -92.1004852,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 33.8292663,
//       lng: -84.5300418,
//     },
//     {
//       lat: 33.8292663,
//       lng: -84.5300418,
//     },
//     {
//       lat: 37.6697463,
//       lng: -120.9991032,
//     },
//     {
//       lat: 36.1631574,
//       lng: -82.8309861,
//     },
//     {
//       lat: 36.1631574,
//       lng: -82.8309861,
//     },
//     {
//       lat: 36.1631574,
//       lng: -82.8309861,
//     },
//     {
//       lat: 33.1578929,
//       lng: -97.1548275,
//     },
//     {
//       lat: 29.9716905,
//       lng: -95.6937856,
//     },
//     {
//       lat: 33.5539143,
//       lng: -117.2139232,
//     },
//     {
//       lat: 45.4642035,
//       lng: 9.189982,
//     },
//     {
//       lat: 45.5454787,
//       lng: 11.5354214,
//     },
//     {
//       lat: 60.3900183,
//       lng: 23.1171797,
//     },
//     {
//       lat: 27.1569007,
//       lng: -80.2105829,
//     },
//     {
//       lat: 27.1569007,
//       lng: -80.2105829,
//     },
//     {
//       lat: 42.0629915,
//       lng: -88.1227199,
//     },
//     {
//       lat: 42.0629915,
//       lng: -88.1227199,
//     },
//     {
//       lat: 33.4151843,
//       lng: -111.8314724,
//     },
//     {
//       lat: 26.3671072,
//       lng: -80.0885603,
//     },
//     {
//       lat: 30.9842977,
//       lng: -91.9623327,
//     },
//     {
//       lat: 41.6763545,
//       lng: -86.2519898,
//     },
//     {
//       lat: 40.4167754,
//       lng: -3.7037902,
//     },
//     {
//       lat: 36.1699412,
//       lng: -115.1398296,
//     },
//     {
//       lat: 44.3106241,
//       lng: -69.7794897,
//     },
//     {
//       lat: 42.0925258,
//       lng: -88.8512063,
//     },
//     {
//       lat: 28.2704969,
//       lng: -82.4628154,
//     },
//     {
//       lat: 49.1579401,
//       lng: -121.9514666,
//     },
//     {
//       lat: 47.3031293,
//       lng: 9.0880638,
//     },
//     {
//       lat: 41.2137366,
//       lng: -111.9773303,
//     },
//     {
//       lat: 40.0346977,
//       lng: -74.2574331,
//     },
//     {
//       lat: 44.3106241,
//       lng: -69.7794897,
//     },
//     {
//       lat: 40.4430791,
//       lng: -86.0867459,
//     },
//     {
//       lat: 40.4430791,
//       lng: -86.0867459,
//     },
//     {
//       lat: 40.4430791,
//       lng: -86.0867459,
//     },
//     {
//       lat: 32.9758022,
//       lng: -97.1345495,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 31.3062874,
//       lng: -96.8980439,
//     },
//     {
//       lat: 40.4410965,
//       lng: -111.8029546,
//     },
//     {
//       lat: 34.250655,
//       lng: -118.595048,
//     },
//     {
//       lat: 45.0724642,
//       lng: -93.4557877,
//     },
//     {
//       lat: 41.0339862,
//       lng: -73.7629097,
//     },
//     {
//       lat: 45.1878015,
//       lng: -93.5525206,
//     },
//     {
//       lat: 40.4167754,
//       lng: -3.7037902,
//     },
//     {
//       lat: 33.6986722,
//       lng: -84.1745859,
//     },
//     {
//       lat: 33.6986722,
//       lng: -84.1745859,
//     },
//     {
//       lat: 33.6986722,
//       lng: -84.1745859,
//     },
//     {
//       lat: 53.5461245,
//       lng: -113.4938229,
//     },
//     {
//       lat: 55.169438,
//       lng: 23.881275,
//     },
//     {
//       lat: 31.9973456,
//       lng: -102.0779146,
//     },
//     {
//       lat: 39.6968796,
//       lng: -104.9637786,
//     },
//     {
//       lat: 43.653226,
//       lng: -79.3831843,
//     },
//     {
//       lat: 34.373361,
//       lng: -118.5400404,
//     },
//     {
//       lat: 30.421309,
//       lng: -87.2169149,
//     },
//     {
//       lat: -36.7010243,
//       lng: 174.7520698,
//     },
//     {
//       lat: -36.7010243,
//       lng: 174.7520698,
//     },
//     {
//       lat: -36.7010243,
//       lng: 174.7520698,
//     },
//     {
//       lat: 40.7672759,
//       lng: -73.906176,
//     },
//     {
//       lat: 41.256843,
//       lng: -74.6166192,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 38.2257141,
//       lng: -85.7553992,
//     },
//     {
//       lat: 38.2257141,
//       lng: -85.7553992,
//     },
//     {
//       lat: 31.9685988,
//       lng: -99.9018131,
//     },
//     {
//       lat: 51.209348,
//       lng: 3.2246995,
//     },
//     {
//       lat: 35.5328838,
//       lng: -82.8373558,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 35.0236659,
//       lng: -85.1112007,
//     },
//     {
//       lat: 53.8344567,
//       lng: -113.3254329,
//     },
//     {
//       lat: 37.7974273,
//       lng: -121.2160526,
//     },
//     {
//       lat: 34.7683142,
//       lng: -86.8954349,
//     },
//     {
//       lat: 41.1399814,
//       lng: -104.8202462,
//     },
//     {
//       lat: 30.267153,
//       lng: -97.7430608,
//     },
//     {
//       lat: 33.4483771,
//       lng: -112.0740373,
//     },
//     {
//       lat: 31.5942991,
//       lng: -102.8926536,
//     },
//     {
//       lat: 51.919438,
//       lng: 19.145136,
//     },
//     {
//       lat: 51.919438,
//       lng: 19.145136,
//     },
//     {
//       lat: 51.919438,
//       lng: 19.145136,
//     },
//     {
//       lat: 54.9164671,
//       lng: 23.9916606,
//     },
//     {
//       lat: 40.4406248,
//       lng: -79.9958864,
//     },
//     {
//       lat: 39.6581424,
//       lng: -78.928357,
//     },
//     {
//       lat: 43.7229885,
//       lng: -111.1110872,
//     },
//     {
//       lat: 35.4798757,
//       lng: -79.1802994,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 37.8217551,
//       lng: -89.2326762,
//     },
//     {
//       lat: 39.5807452,
//       lng: -104.8771726,
//     },
//     {
//       lat: 43.7229885,
//       lng: -111.1110872,
//     },
//     {
//       lat: 42.5047161,
//       lng: -71.1956205,
//     },
//     {
//       lat: 42.752589,
//       lng: -83.104918,
//     },
//     {
//       lat: 42.9275277,
//       lng: -83.6299518,
//     },
//     {
//       lat: 33.3061605,
//       lng: -111.8412502,
//     },
//     {
//       lat: 51.165691,
//       lng: 10.451526,
//     },
//     {
//       lat: 27.5214269,
//       lng: -82.5723193,
//     },
//     {
//       lat: 51.919438,
//       lng: 19.145136,
//     },
//     {
//       lat: 29.8734354,
//       lng: -95.4467211,
//     },
//     {
//       lat: 39.9611755,
//       lng: -82.9987942,
//     },
//     {
//       lat: 41.1483902,
//       lng: -96.249396,
//     },
//     {
//       lat: 40.7700703,
//       lng: -73.9580246,
//     },
//     {
//       lat: 47.6587802,
//       lng: -117.4260465,
//     },
//     {
//       lat: 37.2608936,
//       lng: -85.4988548,
//     },
//     {
//       lat: 6.6666004,
//       lng: -1.6162709,
//     },
//     {
//       lat: 42.0155335,
//       lng: 12.3764519,
//     },
//     {
//       lat: 41.9604014,
//       lng: 12.4520484,
//     },
//     {
//       lat: 52.9264477,
//       lng: 7.0184251,
//     },
//     {
//       lat: 42.0883603,
//       lng: -87.9806265,
//     },
//     {
//       lat: 42.0883603,
//       lng: -87.9806265,
//     },
//     {
//       lat: 29.5844524,
//       lng: -81.2078699,
//     },
//     {
//       lat: 38.8997323,
//       lng: 22.4336668,
//     },
//     {
//       lat: 32.651719,
//       lng: -97.2691134,
//     },
//     {
//       lat: 32.7554883,
//       lng: -97.3307658,
//     },
//     {
//       lat: 26.0112014,
//       lng: -80.1494901,
//     },
//     {
//       lat: 37.3993816,
//       lng: -122.001092,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 41.801701,
//       lng: 1.8229388,
//     },
//     {
//       lat: 45.9764655,
//       lng: -94.3914492,
//     },
//     {
//       lat: 34.0790531,
//       lng: -118.4347048,
//     },
//     {
//       lat: 39.7047095,
//       lng: -105.0813734,
//     },
//     {
//       lat: 47.6587802,
//       lng: -117.4260465,
//     },
//     {
//       lat: 64.963051,
//       lng: -19.020835,
//     },
//     {
//       lat: 32.1377868,
//       lng: -101.7993211,
//     },
//     {
//       lat: -30.559482,
//       lng: 22.937506,
//     },
//     {
//       lat: 38.7682107,
//       lng: 0.0653341,
//     },
//     {
//       lat: 44.977753,
//       lng: -93.2650108,
//     },
//     {
//       lat: 44.977753,
//       lng: -93.2650108,
//     },
//     {
//       lat: 38.6588637,
//       lng: -90.3327076,
//     },
//     {
//       lat: 41.0545292,
//       lng: -76.2332676,
//     },
//     {
//       lat: 31.6035129,
//       lng: -94.6554874,
//     },
//     {
//       lat: -34.8512357,
//       lng: 138.566669,
//     },
//     {
//       lat: 33.8544823,
//       lng: -112.09016,
//     },
//     {
//       lat: 40.5795317,
//       lng: -74.1502007,
//     },
//     {
//       lat: 38.8884312,
//       lng: -3.7117806,
//     },
//     {
//       lat: 41.7463142,
//       lng: -71.3877488,
//     },
//     {
//       lat: 43.5788175,
//       lng: -116.55978,
//     },
//     {
//       lat: 41.2712056,
//       lng: -73.2966405,
//     },
//     {
//       lat: 39.1387091,
//       lng: -76.8525076,
//     },
//     {
//       lat: 39.6499167,
//       lng: -74.3060233,
//     },
//     {
//       lat: 44.8409548,
//       lng: -122.8709243,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 37.9922399,
//       lng: -1.1306544,
//     },
//     {
//       lat: 50.9806935,
//       lng: 4.8095547,
//     },
//     {
//       lat: 41.2509494,
//       lng: -73.9486995,
//     },
//     {
//       lat: 38.687894,
//       lng: -77.2977618,
//     },
//     {
//       lat: 36.9078523,
//       lng: -76.0954533,
//     },
//     {
//       lat: 40.463667,
//       lng: -3.74922,
//     },
//     {
//       lat: 40.463667,
//       lng: -3.74922,
//     },
//     {
//       lat: 40.5852602,
//       lng: -105.084423,
//     },
//     {
//       lat: 43.214515,
//       lng: -86.320412,
//     },
//     {
//       lat: 32.6926512,
//       lng: -114.6276916,
//     },
//     {
//       lat: 32.6926512,
//       lng: -114.6276916,
//     },
//     {
//       lat: 33.9802893,
//       lng: -118.4517449,
//     },
//     {
//       lat: 32.8088786,
//       lng: -96.992515,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 32.8088786,
//       lng: -96.992515,
//     },
//     {
//       lat: 46.227638,
//       lng: 2.213749,
//     },
//     {
//       lat: 32.8140177,
//       lng: -96.9488945,
//     },
//     {
//       lat: 36.3294305,
//       lng: -78.399201,
//     },
//     {
//       lat: 36.3294305,
//       lng: -78.399201,
//     },
//     {
//       lat: 36.1699412,
//       lng: -115.1398296,
//     },
//     {
//       lat: 32.5156971,
//       lng: -95.4093998,
//     },
//     {
//       lat: 37.2481484,
//       lng: -76.6513705,
//     },
//     {
//       lat: 43.6150186,
//       lng: -116.2023137,
//     },
//     {
//       lat: 44.6704998,
//       lng: -70.1512169,
//     },
//     {
//       lat: 38.3459963,
//       lng: -0.4906855,
//     },
//     {
//       lat: 28.572814,
//       lng: -81.878987,
//     },
//     {
//       lat: 37.497072,
//       lng: -77.175304,
//     },
//     {
//       lat: 36.8433771,
//       lng: -87.3038926,
//     },
//     {
//       lat: 47.097133,
//       lng: 37.543367,
//     },
//     {
//       lat: 50.84726,
//       lng: 5.20578,
//     },
//     {
//       lat: 50.84726,
//       lng: 5.20578,
//     },
//     {
//       lat: 50.84726,
//       lng: 5.20578,
//     },
//     {
//       lat: 42.8864468,
//       lng: -78.8783689,
//     },
//     {
//       lat: 51.7612056,
//       lng: -1.2464674,
//     },
//     {
//       lat: 52.0433857,
//       lng: 4.3235744,
//     },
//     {
//       lat: 39.766098,
//       lng: -105.0772063,
//     },
//     {
//       lat: 30.823243,
//       lng: -88.3614002,
//     },
//     {
//       lat: 40.6659344,
//       lng: -73.4881809,
//     },
//     {
//       lat: 44.6704998,
//       lng: -70.1512169,
//     },
//     {
//       lat: 42.4142866,
//       lng: -71.1582893,
//     },
//     {
//       lat: 32.1532156,
//       lng: -94.7993802,
//     },
//     {
//       lat: 38.81144,
//       lng: -90.8529107,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 43.7551145,
//       lng: -71.3965306,
//     },
//     {
//       lat: 53.4807593,
//       lng: -2.2426305,
//     },
//     {
//       lat: 31.7878972,
//       lng: -81.6706377,
//     },
//     {
//       lat: 33.6971468,
//       lng: -117.185294,
//     },
//     {
//       lat: 34.0238187,
//       lng: -118.153228,
//     },
//     {
//       lat: 38.845746,
//       lng: -81.701602,
//     },
//     {
//       lat: 38.845746,
//       lng: -81.701602,
//     },
//     {
//       lat: 31.9382652,
//       lng: -81.3034433,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 56.130366,
//       lng: -106.346771,
//     },
//     {
//       lat: 30.449883,
//       lng: -87.247502,
//     },
//     {
//       lat: 41.1208668,
//       lng: -88.835352,
//     },
//     {
//       lat: 51.0576948,
//       lng: -1.3080629,
//     },
//     {
//       lat: 45.6769979,
//       lng: -111.0429339,
//     },
//     {
//       lat: 45.6769979,
//       lng: -111.0429339,
//     },
//     {
//       lat: 33.7517854,
//       lng: -93.9026867,
//     },
//     {
//       lat: 34.1495068,
//       lng: -118.4086616,
//     },
//     {
//       lat: 42.6035976,
//       lng: -71.0499857,
//     },
//     {
//       lat: 38.9044265,
//       lng: -77.4808838,
//     },
//     {
//       lat: 37.12475,
//       lng: -76.470656,
//     },
//     {
//       lat: 30.0799405,
//       lng: -95.4171601,
//     },
//     {
//       lat: 40.7862871,
//       lng: -74.3300842,
//     },
//     {
//       lat: 35.8909406,
//       lng: -78.6263279,
//     },
//     {
//       lat: 46.2086683,
//       lng: -119.119948,
//     },
//     {
//       lat: 46.2086683,
//       lng: -119.119948,
//     },
//     {
//       lat: 43.2547731,
//       lng: 6.6378577,
//     },
//     {
//       lat: 56.38418,
//       lng: 10.5143489,
//     },
//     {
//       lat: 33.4205229,
//       lng: -117.6160483,
//     },
//     {
//       lat: 26.1224386,
//       lng: -80.1373174,
//     },
//     {
//       lat: 30.2385294,
//       lng: -90.9200987,
//     },
//     {
//       lat: 34.7698021,
//       lng: -84.9702228,
//     },
//     {
//       lat: 40.3880528,
//       lng: -80.0786359,
//     },
//     {
//       lat: 45.4642035,
//       lng: 9.189982,
//     },
//     {
//       lat: 52.132633,
//       lng: 5.291266,
//     },
//     {
//       lat: 47.0227111,
//       lng: -91.6707322,
//     },
//     {
//       lat: 51.9808031,
//       lng: -0.2179813,
//     },
//     {
//       lat: 28.669997,
//       lng: -81.2081203,
//     },
//     {
//       lat: 32.9934596,
//       lng: -117.2693251,
//     },
//     {
//       lat: 27.1678268,
//       lng: -80.2661591,
//     },
//     {
//       lat: 39.9537358,
//       lng: -74.1979458,
//     },
//     {
//       lat: 51.4453639,
//       lng: -0.7625944,
//     },
//     {
//       lat: 37.574146,
//       lng: 15.0506429,
//     },
//     {
//       lat: 26.7153424,
//       lng: -80.0533746,
//     },
//     {
//       lat: 10.5089259,
//       lng: -61.3822726,
//     },
//     {
//       lat: 32.8194156,
//       lng: -116.9643158,
//     },
//     {
//       lat: 39.6956417,
//       lng: -78.8797442,
//     },
//     {
//       lat: 42.6967062,
//       lng: 23.3511446,
//     },
//     {
//       lat: 39.5716059,
//       lng: 2.6194306,
//     },
//     {
//       lat: 40.8410731,
//       lng: -91.2217459,
//     },
//     {
//       lat: 39.090891,
//       lng: -84.8499504,
//     },
//     {
//       lat: 39.090891,
//       lng: -84.8499504,
//     },
//     {
//       lat: 41.5356492,
//       lng: -73.8990252,
//     },
//     {
//       lat: 41.5356492,
//       lng: -73.8990252,
//     },
//     {
//       lat: 28.0222435,
//       lng: -81.7328567,
//     },
//     {
//       lat: 26.0542283,
//       lng: -80.2527798,
//     },
//     {
//       lat: 38.0990233,
//       lng: -78.9691923,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 49.0504377,
//       lng: -122.3044697,
//     },
//     {
//       lat: 39.6558521,
//       lng: -75.3183504,
//     },
//     {
//       lat: 33.4353394,
//       lng: -112.3576567,
//     },
//     {
//       lat: 33.0198431,
//       lng: -96.6988856,
//     },
//     {
//       lat: 35.1321877,
//       lng: -118.4489739,
//     },
//     {
//       lat: 35.6549731,
//       lng: -97.5201068,
//     },
//     {
//       lat: 38.8939742,
//       lng: -121.0935957,
//     },
//     {
//       lat: 38.8965654,
//       lng: -121.0768901,
//     },
//     {
//       lat: 38.4274315,
//       lng: -122.3943299,
//     },
//     {
//       lat: 38.159095,
//       lng: -85.4839201,
//     },
//     {
//       lat: 26.7153424,
//       lng: -80.0533746,
//     },
//     {
//       lat: 25.9120848,
//       lng: -97.44665,
//     },
//     {
//       lat: 27.876524,
//       lng: -82.72785,
//     },
//     {
//       lat: 39.1031182,
//       lng: -84.5120196,
//     },
//     {
//       lat: 33.1958696,
//       lng: -117.3794834,
//     },
//     {
//       lat: 32.3909071,
//       lng: -110.966488,
//     },
//     {
//       lat: 25.9017472,
//       lng: -97.4974838,
//     },
//     {
//       lat: 41.764099,
//       lng: -88.4934326,
//     },
//     {
//       lat: 30.4760499,
//       lng: -98.156574,
//     },
//     {
//       lat: 38.9033897,
//       lng: -77.0406884,
//     },
//     {
//       lat: 41.2033216,
//       lng: -77.1945247,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 26.0656465,
//       lng: -80.3169148,
//     },
//     {
//       lat: 26.0284666,
//       lng: -80.2912536,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 36.7265745,
//       lng: -93.3834623,
//     },
//     {
//       lat: 36.6873939,
//       lng: -6.1354683,
//     },
//     {
//       lat: 48.9588468,
//       lng: 9.012917,
//     },
//     {
//       lat: 54.6871555,
//       lng: 25.2796514,
//     },
//     {
//       lat: 41.2565369,
//       lng: -95.9345034,
//     },
//     {
//       lat: 23.5880307,
//       lng: 58.3828717,
//     },
//     {
//       lat: 41.7614181,
//       lng: -88.443686,
//     },
//     {
//       lat: 41.1579438,
//       lng: -8.6291053,
//     },
//     {
//       lat: 32.905353,
//       lng: -111.7306077,
//     },
//     {
//       lat: 25.2048493,
//       lng: 55.2707828,
//     },
//     {
//       lat: 38.7328953,
//       lng: -86.4891119,
//     },
//     {
//       lat: 35.1532655,
//       lng: -78.8979911,
//     },
//     {
//       lat: 29.7425907,
//       lng: -95.4745249,
//     },
//     {
//       lat: 46.40057,
//       lng: 6.17475,
//     },
//     {
//       lat: 38.9033897,
//       lng: -77.0406884,
//     },
//     {
//       lat: 46.2397495,
//       lng: 15.2677063,
//     },
//     {
//       lat: 35.1575877,
//       lng: -89.3684058,
//     },
//     {
//       lat: 35.1575877,
//       lng: -89.3684058,
//     },
//     {
//       lat: 42.1920919,
//       lng: -88.0901716,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 43.5197167,
//       lng: -79.8409875,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 48.1351253,
//       lng: 11.5819805,
//     },
//     {
//       lat: 52.336916,
//       lng: -6.4633381,
//     },
//     {
//       lat: 38.9033897,
//       lng: -77.0406884,
//     },
//     {
//       lat: 33.636413,
//       lng: -117.9277835,
//     },
//     {
//       lat: 26.1224386,
//       lng: -80.1373174,
//     },
//     {
//       lat: 35.4675602,
//       lng: -97.5164276,
//     },
//     {
//       lat: 47.2031566,
//       lng: -122.2403966,
//     },
//     {
//       lat: 40.031183,
//       lng: -81.5884561,
//     },
//     {
//       lat: 29.1383165,
//       lng: -80.9956105,
//     },
//     {
//       lat: 40.7585569,
//       lng: -73.7654367,
//     },
//     {
//       lat: 33.3241131,
//       lng: -111.8154273,
//     },
//     {
//       lat: 25.2048493,
//       lng: 55.2707828,
//     },
//     {
//       lat: 42.2711311,
//       lng: -89.0939952,
//     },
//     {
//       lat: 36.2780239,
//       lng: -119.7803992,
//     },
//     {
//       lat: 50.9088216,
//       lng: 6.9647875,
//     },
//     {
//       lat: 39.1285076,
//       lng: -76.5248652,
//     },
//     {
//       lat: 50.9294023,
//       lng: 6.8200206,
//     },
//     {
//       lat: 44.1317459,
//       lng: -70.4931557,
//     },
//     {
//       lat: 32.4709519,
//       lng: -100.4059384,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 33.8937913,
//       lng: 35.5017767,
//     },
//     {
//       lat: 51.003193,
//       lng: -114.113405,
//     },
//     {
//       lat: 39.3226548,
//       lng: -76.9181854,
//     },
//     {
//       lat: 37.3541079,
//       lng: -121.9552356,
//     },
//     {
//       lat: 32.1051567,
//       lng: -92.0779129,
//     },
//     {
//       lat: 37.422461,
//       lng: -76.9771537,
//     },
//     {
//       lat: 32.2919854,
//       lng: -95.32518,
//     },
//     {
//       lat: 41.0338672,
//       lng: -111.6654903,
//     },
//     {
//       lat: 41.2504884,
//       lng: -73.017333,
//     },
//     {
//       lat: 54.0028102,
//       lng: -1.5771371,
//     },
//     {
//       lat: 34.2542084,
//       lng: -110.0298327,
//     },
//     {
//       lat: 38.2514738,
//       lng: -85.9276809,
//     },
//     {
//       lat: 36.1499507,
//       lng: -115.1684896,
//     },
//     {
//       lat: 40.7933949,
//       lng: -77.8600012,
//     },
//     {
//       lat: 44.115135,
//       lng: -79.5555147,
//     },
//     {
//       lat: 42.074673,
//       lng: -70.9288938,
//     },
//     {
//       lat: 32.8460631,
//       lng: -117.2767529,
//     },
//     {
//       lat: 39.2713593,
//       lng: -76.7192446,
//     },
//     {
//       lat: 42.3600825,
//       lng: -71.0588801,
//     },
//     {
//       lat: 37.6222547,
//       lng: -96.2280527,
//     },
//     {
//       lat: 39.8027644,
//       lng: -105.0874842,
//     },
//     {
//       lat: 28.3644485,
//       lng: -82.6934343,
//     },
//     {
//       lat: 40.4811721,
//       lng: -82.5407213,
//     },
//     {
//       lat: 35.9651282,
//       lng: -80.2910139,
//     },
//     {
//       lat: 35.9651282,
//       lng: -80.2910139,
//     },
//     {
//       lat: 38.0825837,
//       lng: -122.1623424,
//     },
//     {
//       lat: 44.4267674,
//       lng: 26.1025384,
//     },
//     {
//       lat: 51.8372942,
//       lng: 0.5121936,
//     },
//     {
//       lat: 28.0394654,
//       lng: -81.9498042,
//     },
//     {
//       lat: 40.8956822,
//       lng: -81.0786966,
//     },
//     {
//       lat: 34.2694474,
//       lng: -118.781482,
//     },
//     {
//       lat: 41.8875249,
//       lng: -87.9396268,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 54.6922529,
//       lng: 25.2717468,
//     },
//     {
//       lat: 30.0688512,
//       lng: -81.8603778,
//     },
//     {
//       lat: 39.6462733,
//       lng: -121.7999767,
//     },
//     {
//       lat: 39.5500507,
//       lng: -105.7820674,
//     },
//     {
//       lat: 34.1351931,
//       lng: -118.3447746,
//     },
//     {
//       lat: 49.895136,
//       lng: -97.1383744,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 32.7764749,
//       lng: -79.9310512,
//     },
//     {
//       lat: 41.9986073,
//       lng: -88.1426156,
//     },
//     {
//       lat: 41.9994722,
//       lng: -88.1450735,
//     },
//     {
//       lat: 49.895136,
//       lng: -97.1383744,
//     },
//     {
//       lat: 40.6460622,
//       lng: -111.4979729,
//     },
//     {
//       lat: 43.1566927,
//       lng: -83.4105053,
//     },
//     {
//       lat: 40.497604,
//       lng: -74.4884868,
//     },
//     {
//       lat: 40.9005541,
//       lng: -73.7841662,
//     },
//     {
//       lat: 41.2459149,
//       lng: -75.8813075,
//     },
//     {
//       lat: 41.3625794,
//       lng: -75.8101945,
//     },
//     {
//       lat: 41.353413,
//       lng: -75.7382478,
//     },
//     {
//       lat: 42.4271068,
//       lng: -123.3721216,
//     },
//     {
//       lat: 32.4487364,
//       lng: -99.7331439,
//     },
//     {
//       lat: 27.4989278,
//       lng: -82.5748194,
//     },
//     {
//       lat: 33.5916433,
//       lng: -117.6986604,
//     },
//     {
//       lat: 41.5454486,
//       lng: -8.426507,
//     },
//     {
//       lat: 41.1579438,
//       lng: -8.6291053,
//     },
//     {
//       lat: 39.8027644,
//       lng: -105.0874842,
//     },
//     {
//       lat: 41.1787932,
//       lng: -8.5339362,
//     },
//     {
//       lat: 43.0897964,
//       lng: -73.9179022,
//     },
//     {
//       lat: 43.4445327,
//       lng: -71.4776054,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 38.2181532,
//       lng: -78.3871112,
//     },
//     {
//       lat: 41.4834941,
//       lng: -73.4402348,
//     },
//     {
//       lat: 41.4834941,
//       lng: -73.4402348,
//     },
//     {
//       lat: 46.1367899,
//       lng: -60.194224,
//     },
//     {
//       lat: 40.789142,
//       lng: -73.134961,
//     },
//     {
//       lat: 45.5408943,
//       lng: 8.8480238,
//     },
//     {
//       lat: 38.9108325,
//       lng: -75.5276699,
//     },
//     {
//       lat: 52.7095702,
//       lng: 4.8076765,
//     },
//     {
//       lat: 51.253775,
//       lng: -85.323214,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 51.253775,
//       lng: -85.323214,
//     },
//     {
//       lat: 41.1029786,
//       lng: -112.0863331,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 51.12955,
//       lng: 4.21275,
//     },
//     {
//       lat: 38.5564895,
//       lng: -92.1353957,
//     },
//     {
//       lat: 51.8480556,
//       lng: -0.4480556,
//     },
//     {
//       lat: 23.424076,
//       lng: 53.847818,
//     },
//     {
//       lat: 43.216505,
//       lng: -123.3417381,
//     },
//     {
//       lat: -23.5505199,
//       lng: -46.6333094,
//     },
//     {
//       lat: 30.6238069,
//       lng: -84.414629,
//     },
//     {
//       lat: 33.3401179,
//       lng: -96.357778,
//     },
//     {
//       lat: 44.8845906,
//       lng: 7.3306676,
//     },
//     {
//       lat: 32.2794079,
//       lng: -83.4172615,
//     },
//     {
//       lat: 51.9716388,
//       lng: 5.6693524,
//     },
//     {
//       lat: 33.6439926,
//       lng: -86.6653805,
//     },
//     {
//       lat: 40.1573169,
//       lng: -76.3069014,
//     },
//     {
//       lat: 45.5016889,
//       lng: -73.567256,
//     },
//     {
//       lat: 18.220833,
//       lng: -66.590149,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 32.7099369,
//       lng: -96.9799545,
//     },
//     {
//       lat: 39.9992217,
//       lng: -105.1158684,
//     },
//     {
//       lat: 38.6661303,
//       lng: -121.1089645,
//     },
//     {
//       lat: 53.85564,
//       lng: -2.176991,
//     },
//     {
//       lat: 52.3633325,
//       lng: 0.0173873,
//     },
//     {
//       lat: 59.182401,
//       lng: 9.6108493,
//     },
//     {
//       lat: 40.1745538,
//       lng: -74.9226665,
//     },
//     {
//       lat: 26.2378597,
//       lng: -80.1247667,
//     },
//     {
//       lat: 55.8409556,
//       lng: 9.8353529,
//     },
//     {
//       lat: 59.3544447,
//       lng: 18.0881469,
//     },
//     {
//       lat: 38.4633813,
//       lng: -107.8475712,
//     },
//     {
//       lat: 44.3106241,
//       lng: -69.7794897,
//     },
//     {
//       lat: 48.1191353,
//       lng: -96.1811473,
//     },
//     {
//       lat: 34.353189,
//       lng: -85.16372,
//     },
//     {
//       lat: 37.7795941,
//       lng: -3.7849057,
//     },
//     {
//       lat: 34.0928092,
//       lng: -118.3286614,
//     },
//     {
//       lat: 30.6238069,
//       lng: -84.414629,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 48.8726719,
//       lng: 2.2845163,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 50.88,
//       lng: 12.07751,
//     },
//     {
//       lat: 42.6465119,
//       lng: -82.823773,
//     },
//     {
//       lat: 43.106456,
//       lng: -76.2177046,
//     },
//     {
//       lat: 49.8223768,
//       lng: 19.0583845,
//     },
//     {
//       lat: 32.2226066,
//       lng: -110.9747108,
//     },
//     {
//       lat: 44.840798,
//       lng: -93.2982799,
//     },
//     {
//       lat: 51.1309444,
//       lng: -114.2506103,
//     },
//     {
//       lat: 42.460075,
//       lng: -82.890608,
//     },
//     {
//       lat: 35.4675602,
//       lng: -97.5164276,
//     },
//     {
//       lat: 53.55354,
//       lng: 9.99935,
//     },
//     {
//       lat: 24.1301619,
//       lng: 55.8023118,
//     },
//     {
//       lat: 39.7392358,
//       lng: -104.990251,
//     },
//     {
//       lat: 48.0112265,
//       lng: 12.963011,
//     },
//     {
//       lat: 52.6739821,
//       lng: 6.7305048,
//     },
//     {
//       lat: 44.0538178,
//       lng: -123.0598664,
//     },
//     {
//       lat: 25.2048493,
//       lng: 55.2707828,
//     },
//     {
//       lat: 41.341412,
//       lng: -89.009716,
//     },
//     {
//       lat: 34.0393306,
//       lng: -118.1768054,
//     },
//     {
//       lat: 52.4162036,
//       lng: 4.6518378,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 39.399872,
//       lng: -8.224454,
//     },
//     {
//       lat: 48.2013542,
//       lng: 13.4952492,
//     },
//     {
//       lat: 47.6062095,
//       lng: -122.3320708,
//     },
//     {
//       lat: 40.2132692,
//       lng: -77.0080309,
//     },
//     {
//       lat: 40.815998,
//       lng: -72.825251,
//     },
//     {
//       lat: 40.807321,
//       lng: -72.8212131,
//     },
//     {
//       lat: 52.3700345,
//       lng: 4.8913597,
//     },
//     {
//       lat: 38.7055652,
//       lng: -8.9741183,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 41.2428563,
//       lng: -73.2006639,
//     },
//     {
//       lat: 48.7308469,
//       lng: 1.365186,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 39.2075584,
//       lng: -8.6259188,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 49.817492,
//       lng: 15.472962,
//     },
//     {
//       lat: 46.52011,
//       lng: 6.6604,
//     },
//     {
//       lat: 47.5006327,
//       lng: 18.9197768,
//     },
//     {
//       lat: 40.5399218,
//       lng: -3.6311709,
//     },
//     {
//       lat: 40.876711,
//       lng: -74.530385,
//     },
//     {
//       lat: 33.1580933,
//       lng: -117.3505939,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 33.6638439,
//       lng: -117.9047429,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 42.9791115,
//       lng: -81.0402939,
//     },
//     {
//       lat: 45.636423,
//       lng: -122.534873,
//     },
//     {
//       lat: 51.840167,
//       lng: 5.006129,
//     },
//     {
//       lat: 32.715738,
//       lng: -117.1610838,
//     },
//     {
//       lat: 46.227638,
//       lng: 2.213749,
//     },
//     {
//       lat: 46.818188,
//       lng: 8.227512,
//     },
//     {
//       lat: 47.516231,
//       lng: 14.550072,
//     },
//     {
//       lat: 40.463667,
//       lng: -3.74922,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 48.7758459,
//       lng: 9.1829321,
//     },
//     {
//       lat: 55.378051,
//       lng: -3.435973,
//     },
//     {
//       lat: 46.227638,
//       lng: 2.213749,
//     },
//     {
//       lat: 42.279286,
//       lng: -71.4161565,
//     },
//     {
//       lat: 53.8150281,
//       lng: -1.5116435,
//     },
//     {
//       lat: 46.8450694,
//       lng: 15.8184298,
//     },
//     {
//       lat: 40.1389964,
//       lng: -76.3069412,
//     },
//     {
//       lat: 53.590684,
//       lng: -116.46033,
//     },
//     {
//       lat: 45.0791325,
//       lng: -93.1471667,
//     },
//     {
//       lat: 43.7695604,
//       lng: 11.2558136,
//     },
//     {
//       lat: -30.559482,
//       lng: 22.937506,
//     },
//     {
//       lat: -30.559482,
//       lng: 22.937506,
//     },
//     {
//       lat: 28.2441768,
//       lng: -82.7192671,
//     },
//     {
//       lat: 0.3475964,
//       lng: 32.5825197,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 37.2220383,
//       lng: -77.2856305,
//     },
//     {
//       lat: 41.6042439,
//       lng: -81.3656561,
//     },
//     {
//       lat: 28.4719124,
//       lng: -81.4815759,
//     },
//     {
//       lat: 34.7067926,
//       lng: -86.5938474,
//     },
//     {
//       lat: 34.7067926,
//       lng: -86.5938474,
//     },
//     {
//       lat: 34.7067926,
//       lng: -86.5938474,
//     },
//     {
//       lat: 34.7067926,
//       lng: -86.5938474,
//     },
//     {
//       lat: 34.7067926,
//       lng: -86.5938474,
//     },
//     {
//       lat: 34.7067926,
//       lng: -86.5938474,
//     },
//     {
//       lat: 56.105831,
//       lng: 10.14536,
//     },
//     {
//       lat: 52.9402159,
//       lng: -1.1154881,
//     },
//     {
//       lat: -29.8586804,
//       lng: 31.0218404,
//     },
//     {
//       lat: 55.378051,
//       lng: -3.435973,
//     },
//     {
//       lat: 55.169438,
//       lng: 23.881275,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 52.9547832,
//       lng: -1.1581086,
//     },
//     {
//       lat: 44.462072,
//       lng: -80.0984954,
//     },
//     {
//       lat: 38.8119527,
//       lng: -94.5318982,
//     },
//     {
//       lat: 32.8331073,
//       lng: -116.8896079,
//     },
//     {
//       lat: 12.0021794,
//       lng: 8.5919561,
//     },
//     {
//       lat: 53.5602185,
//       lng: 10.0296923,
//     },
//     {
//       lat: 55.378051,
//       lng: -3.435973,
//     },
//     {
//       lat: 55.378051,
//       lng: -3.435973,
//     },
//     {
//       lat: 55.378051,
//       lng: -3.435973,
//     },
//     {
//       lat: 55.378051,
//       lng: -3.435973,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 47.516231,
//       lng: 14.550072,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 51.919438,
//       lng: 19.145136,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 38.612456,
//       lng: -77.279595,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 47.516231,
//       lng: 14.550072,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 39.8314007,
//       lng: -75.2116232,
//     },
//     {
//       lat: 47.0744016,
//       lng: 2.3868848,
//     },
//     {
//       lat: 34.4263886,
//       lng: -117.3008784,
//     },
//     {
//       lat: 45.3563261,
//       lng: -122.8402749,
//     },
//     {
//       lat: 46.4917317,
//       lng: -80.993029,
//     },
//     {
//       lat: 32.715738,
//       lng: -117.1610838,
//     },
//     {
//       lat: 30.234925,
//       lng: -92.2684617,
//     },
//     {
//       lat: 33.6686461,
//       lng: -112.1050546,
//     },
//     {
//       lat: 34.1280221,
//       lng: -118.2583572,
//     },
//     {
//       lat: 51.4560065,
//       lng: 5.4767797,
//     },
//     {
//       lat: 42.3091216,
//       lng: -83.1812287,
//     },
//     {
//       lat: 50.3950513,
//       lng: 3.7582348,
//     },
//     {
//       lat: 41.3986081,
//       lng: 2.157062,
//     },
//     {
//       lat: 39.6477653,
//       lng: -104.9877597,
//     },
//     {
//       lat: 42.5832314,
//       lng: -87.9272398,
//     },
//     {
//       lat: 32.9029017,
//       lng: -96.56388,
//     },
//     {
//       lat: 33.6111461,
//       lng: -111.8733937,
//     },
//     {
//       lat: 37.0995878,
//       lng: -113.5253714,
//     },
//     {
//       lat: 34.4154842,
//       lng: -93.1128886,
//     },
//     {
//       lat: 39.4259297,
//       lng: -85.0129712,
//     },
//     {
//       lat: 35.0875836,
//       lng: -84.0346315,
//     },
//     {
//       lat: 52.0804471,
//       lng: 4.7732263,
//     },
//     {
//       lat: 41.4645791,
//       lng: -87.5798235,
//     },
//     {
//       lat: 33.6427544,
//       lng: -83.8263525,
//     },
//     {
//       lat: 37.6392595,
//       lng: -120.9970014,
//     },
//     {
//       lat: 36.2179287,
//       lng: -119.3093115,
//     },
//     {
//       lat: 39.9275462,
//       lng: -86.2360157,
//     },
//     {
//       lat: 43.7649077,
//       lng: -79.7282236,
//     },
//     {
//       lat: 41.5581525,
//       lng: -73.0514965,
//     },
//     {
//       lat: 36.8002111,
//       lng: -78.4586541,
//     },
//     {
//       lat: 45.4383842,
//       lng: 10.9916215,
//     },
//     {
//       lat: 33.8579646,
//       lng: -118.3917351,
//     },
//     {
//       lat: 33.8622366,
//       lng: -118.3995194,
//     },
//     {
//       lat: 48.856614,
//       lng: 2.3522219,
//     },
//     {
//       lat: 45.7687238,
//       lng: 4.8777021,
//     },
//     {
//       lat: 41.3648171,
//       lng: -72.4849861,
//     },
//     {
//       lat: 48.6426166,
//       lng: 15.8176984,
//     },
//     {
//       lat: 44.7355138,
//       lng: -93.1867696,
//     },
//     {
//       lat: 38.485658,
//       lng: -85.7689679,
//     },
//     {
//       lat: 36.9932849,
//       lng: -93.6263481,
//     },
//     {
//       lat: 47.7884487,
//       lng: -122.1783358,
//     },
//     {
//       lat: 35.1774652,
//       lng: -106.5748606,
//     },
//     {
//       lat: 52.5030467,
//       lng: 13.3251479,
//     },
//     {
//       lat: 38.58131,
//       lng: -89.938074,
//     },
//     {
//       lat: 52.2271216,
//       lng: 5.3435532,
//     },
//     {
//       lat: 40.6955184,
//       lng: -3.5129811,
//     },
//     {
//       lat: 35.996237,
//       lng: -86.5856501,
//     },
//     {
//       lat: 34.703375,
//       lng: -84.967659,
//     },
//     {
//       lat: 38.2664512,
//       lng: -77.1807842,
//     },
//     {
//       lat: 34.1016293,
//       lng: -84.2171756,
//     },
//     {
//       lat: 55.6190199,
//       lng: 12.6065152,
//     },
//     {
//       lat: 38.8402805,
//       lng: -97.6114237,
//     },
//     {
//       lat: 32.756421,
//       lng: -84.857615,
//     },
//     {
//       lat: 31.7607974,
//       lng: -93.0723141,
//     },
//     {
//       lat: 45.7529003,
//       lng: -94.2223283,
//     },
//     {
//       lat: 32.7554883,
//       lng: -97.3307658,
//     },
//     {
//       lat: 41.3838782,
//       lng: -72.9026064,
//     },
//     {
//       lat: 31.9845231,
//       lng: -110.7151566,
//     },
//     {
//       lat: 25.2048493,
//       lng: 55.2707828,
//     },
//     {
//       lat: 51.6723715,
//       lng: 7.814899,
//     },
//     {
//       lat: 32.809539,
//       lng: -97.3531434,
//     },
//     {
//       lat: 32.809539,
//       lng: -97.3531434,
//     },
//     {
//       lat: 31.7607367,
//       lng: -93.0718608,
//     },
//     {
//       lat: 31.7607974,
//       lng: -93.0723141,
//     },
//     {
//       lat: 31.7607974,
//       lng: -93.0723141,
//     },
//     {
//       lat: 31.7607974,
//       lng: -93.0723141,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 38.6500473,
//       lng: -90.2538264,
//     },
//     {
//       lat: 39.202771,
//       lng: -120.953826,
//     },
//     {
//       lat: 38.8647349,
//       lng: -94.8328014,
//     },
//     {
//       lat: 53.014673,
//       lng: 9.0215548,
//     },
//     {
//       lat: 53.2131031,
//       lng: 8.2283261,
//     },
//     {
//       lat: 51.0339292,
//       lng: 4.5107038,
//     },
//     {
//       lat: 47.7125882,
//       lng: 8.6241366,
//     },
//     {
//       lat: 44.5727786,
//       lng: 10.84784,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.1672881,
//       lng: -118.153228,
//     },
//     {
//       lat: 64.8499021,
//       lng: -147.6958315,
//     },
//     {
//       lat: 64.8499021,
//       lng: -147.6958315,
//     },
//     {
//       lat: 46.0066117,
//       lng: -92.3713049,
//     },
//     {
//       lat: 34.1396416,
//       lng: -84.8058266,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 41.3503671,
//       lng: -72.405239,
//     },
//     {
//       lat: 39.0638705,
//       lng: -108.5506486,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 42.0281396,
//       lng: 13.4255637,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 53.215393,
//       lng: 5.7945174,
//     },
//     {
//       lat: 54.8985207,
//       lng: 23.9035965,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 54.07263,
//       lng: 12.07682,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 38.861159,
//       lng: -90.0976069,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 41.5786639,
//       lng: -81.2133262,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 38.8048355,
//       lng: -77.0469214,
//     },
//     {
//       lat: 33.7463089,
//       lng: -116.870458,
//     },
//     {
//       lat: 51.718745,
//       lng: -0.5450539,
//     },
//     {
//       lat: 45.3733961,
//       lng: -72.6341225,
//     },
//     {
//       lat: 51.4754296,
//       lng: 4.281076,
//     },
//     {
//       lat: 48.2036348,
//       lng: 16.2716645,
//     },
//     {
//       lat: 42.8651298,
//       lng: -71.3739272,
//     },
//     {
//       lat: 41.9027835,
//       lng: 12.4963655,
//     },
//     {
//       lat: 26.9761707,
//       lng: -82.0906448,
//     },
//     {
//       lat: 33.7463089,
//       lng: -116.870458,
//     },
//     {
//       lat: 36.0360462,
//       lng: -115.0703692,
//     },
//     {
//       lat: 52.27544,
//       lng: 7.71287,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 42.7261309,
//       lng: -87.7828523,
//     },
//     {
//       lat: 41.1241177,
//       lng: -73.3916608,
//     },
//     {
//       lat: 51.0323649,
//       lng: 0.0553371,
//     },
//     {
//       lat: 49.895136,
//       lng: -97.1383744,
//     },
//     {
//       lat: 38.5229229,
//       lng: -77.2900787,
//     },
//     {
//       lat: 41.8278033,
//       lng: -70.5789687,
//     },
//     {
//       lat: 36.8210144,
//       lng: 5.7634126,
//     },
//     {
//       lat: 43.1565779,
//       lng: -77.6088465,
//     },
//     {
//       lat: 41.7282645,
//       lng: 13.0064437,
//     },
//     {
//       lat: 40.0449271,
//       lng: -84.1944234,
//     },
//     {
//       lat: 45.478838,
//       lng: -73.166284,
//     },
//     {
//       lat: 32.4831207,
//       lng: -93.7236827,
//     },
//     {
//       lat: 33.8937913,
//       lng: 35.5017767,
//     },
//     {
//       lat: 42.2085338,
//       lng: -122.7101345,
//     },
//     {
//       lat: 49.6134907,
//       lng: 6.1283249,
//     },
//     {
//       lat: 33.5185892,
//       lng: -86.8103567,
//     },
//     {
//       lat: 46.424302,
//       lng: -63.7923931,
//     },
//     {
//       lat: 43.6150186,
//       lng: -116.2023137,
//     },
//     {
//       lat: 30.3321838,
//       lng: -81.655651,
//     },
//     {
//       lat: 39.768403,
//       lng: -86.158068,
//     },
//     {
//       lat: 41.2712056,
//       lng: -73.2966405,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 41.6811584,
//       lng: -91.5606718,
//     },
//     {
//       lat: 40.600015,
//       lng: -111.795314,
//     },
//     {
//       lat: 43.2140504,
//       lng: 27.9147333,
//     },
//     {
//       lat: 48.8196955,
//       lng: 2.2360703,
//     },
//     {
//       lat: 47.7769994,
//       lng: -122.0883588,
//     },
//     {
//       lat: 36.8210144,
//       lng: 5.7634126,
//     },
//     {
//       lat: 47.7769994,
//       lng: -122.0883588,
//     },
//     {
//       lat: 37.6871761,
//       lng: -97.330053,
//     },
//     {
//       lat: 31.9685988,
//       lng: -99.9018131,
//     },
//     {
//       lat: 33.846216,
//       lng: -118.3915267,
//     },
//     {
//       lat: 40.306536,
//       lng: -74.2636681,
//     },
//     {
//       lat: 28.775436,
//       lng: -81.3518009,
//     },
//     {
//       lat: 38.9071923,
//       lng: -77.0368707,
//     },
//     {
//       lat: 37.9917061,
//       lng: -92.0937783,
//     },
//     {
//       lat: 26.330587,
//       lng: -80.1207655,
//     },
//     {
//       lat: 39.5640279,
//       lng: -104.8259976,
//     },
//     {
//       lat: 50.0646501,
//       lng: 19.9449799,
//     },
//     {
//       lat: 54.3284745,
//       lng: 10.1055788,
//     },
//     {
//       lat: 52.3915814,
//       lng: 4.8906161,
//     },
//     {
//       lat: 53.8014283,
//       lng: 20.3194424,
//     },
//     {
//       lat: 33.7922392,
//       lng: -118.3150722,
//     },
//     {
//       lat: 32.8275366,
//       lng: -116.9993606,
//     },
//     {
//       lat: 40.3064774,
//       lng: -80.128901,
//     },
//     {
//       lat: 33.6601033,
//       lng: -86.8127702,
//     },
//     {
//       lat: 41.4090402,
//       lng: 2.1327491,
//     },
//     {
//       lat: 55.721887,
//       lng: 8.319886,
//     },
//     {
//       lat: 42.7158927,
//       lng: -78.8294768,
//     },
//     {
//       lat: 43.2979309,
//       lng: 5.3729777,
//     },
//     {
//       lat: 39.3738173,
//       lng: -77.0569038,
//     },
//     {
//       lat: 36.548434,
//       lng: -82.5618186,
//     },
//     {
//       lat: 38.7096291,
//       lng: -90.3080589,
//     },
//     {
//       lat: 38.7109332,
//       lng: -90.3202592,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 39.9856803,
//       lng: -105.0284599,
//     },
//     {
//       lat: 26.2378597,
//       lng: -80.1247667,
//     },
//     {
//       lat: 41.5623209,
//       lng: -72.6506488,
//     },
//     {
//       lat: 41.5381535,
//       lng: -72.8070435,
//     },
//     {
//       lat: 49.1902399,
//       lng: -122.5492118,
//     },
//     {
//       lat: 51.165691,
//       lng: 10.451526,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 47.8388,
//       lng: 35.139567,
//     },
//     {
//       lat: 49.5011366,
//       lng: 11.7080309,
//     },
//     {
//       lat: 33.7700504,
//       lng: -118.1937395,
//     },
//     {
//       lat: 36.2707514,
//       lng: -94.1786753,
//     },
//     {
//       lat: 36.3320196,
//       lng: -94.1185366,
//     },
//     {
//       lat: 39.0638705,
//       lng: -108.5506486,
//     },
//     {
//       lat: 42.0925027,
//       lng: -75.9337934,
//     },
//     {
//       lat: 32.7291993,
//       lng: -117.249929,
//     },
//     {
//       lat: 37.2317588,
//       lng: -121.8991501,
//     },
//     {
//       lat: 41.1220233,
//       lng: -73.3622348,
//     },
//     {
//       lat: 32.715738,
//       lng: -117.1610838,
//     },
//     {
//       lat: 31.7618778,
//       lng: -106.4850217,
//     },
//     {
//       lat: 42.7758301,
//       lng: -73.8705519,
//     },
//     {
//       lat: 43.5855862,
//       lng: -79.6505395,
//     },
//     {
//       lat: 40.7127753,
//       lng: -74.0059728,
//     },
//     {
//       lat: 43.5930784,
//       lng: -79.6424911,
//     },
//     {
//       lat: 41.552969,
//       lng: -87.1840559,
//     },
//     {
//       lat: 44.8130043,
//       lng: -93.1929713,
//     },
//     {
//       lat: 41.3074191,
//       lng: -81.5668622,
//     },
//     {
//       lat: 27.7216981,
//       lng: -82.3931875,
//     },
//     {
//       lat: 40.6417683,
//       lng: -74.8359987,
//     },
//     {
//       lat: 61.599415,
//       lng: -149.1146334,
//     },
//     {
//       lat: 46.5160578,
//       lng: 6.6099121,
//     },
//     {
//       lat: 26.2823365,
//       lng: -80.2950195,
//     },
//     {
//       lat: 3.606993,
//       lng: 98.6651154,
//     },
//     {
//       lat: 23.5880307,
//       lng: 58.3828717,
//     },
//     {
//       lat: 50.7483858,
//       lng: 7.0554421,
//     },
//     {
//       lat: 41.0251354,
//       lng: -80.6019662,
//     },
//     {
//       lat: 41.0251354,
//       lng: -80.6019662,
//     },
//     {
//       lat: 34.0633443,
//       lng: -117.6508876,
//     },
//     {
//       lat: 42.0378703,
//       lng: -82.7396377,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 37.7593537,
//       lng: -120.8750593,
//     },
//     {
//       lat: 47.680689,
//       lng: -122.104458,
//     },
//     {
//       lat: 34.2534118,
//       lng: -116.4367475,
//     },
//     {
//       lat: 32.715738,
//       lng: -117.1610838,
//     },
//     {
//       lat: 32.9169878,
//       lng: -97.0745511,
//     },
//     {
//       lat: 47.6445386,
//       lng: -122.6948697,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 33.6972367,
//       lng: -112.1087329,
//     },
//     {
//       lat: 46.5411016,
//       lng: 13.654964,
//     },
//     {
//       lat: 41.5663035,
//       lng: -88.2729961,
//     },
//     {
//       lat: 40.1872023,
//       lng: 44.515209,
//     },
//     {
//       lat: 38.5904667,
//       lng: -121.4183185,
//     },
//     {
//       lat: 35.9940329,
//       lng: -78.898619,
//     },
//     {
//       lat: 27.9376892,
//       lng: -97.1310688,
//     },
//     {
//       lat: 40.6331249,
//       lng: -89.3985283,
//     },
//     {
//       lat: 40.6331249,
//       lng: -89.3985283,
//     },
//     {
//       lat: 33.9282453,
//       lng: -84.2694009,
//     },
//     {
//       lat: 36.0708854,
//       lng: -115.2156291,
//     },
//     {
//       lat: 54.720642,
//       lng: 25.2219079,
//     },
//     {
//       lat: 54.720642,
//       lng: 25.2219079,
//     },
//     {
//       lat: 30.011316,
//       lng: -95.4322728,
//     },
//     {
//       lat: 44.837789,
//       lng: -0.57918,
//     },
//     {
//       lat: 53.095573,
//       lng: 6.9391228,
//     },
//     {
//       lat: 40.4406248,
//       lng: -79.9958864,
//     },
//     {
//       lat: 36.0395247,
//       lng: -114.9817213,
//     },
//     {
//       lat: 43.4610075,
//       lng: -86.2507397,
//     },
//     {
//       lat: 34.1477849,
//       lng: -118.1445155,
//     },
//     {
//       lat: 48.9861435,
//       lng: 2.4401597,
//     },
//     {
//       lat: 37.9922399,
//       lng: -1.1306544,
//     },
//     {
//       lat: 42.0329453,
//       lng: -82.7194964,
//     },
//     {
//       lat: 42.0378703,
//       lng: -82.7396377,
//     },
//     {
//       lat: 51.360163,
//       lng: 1.432038,
//     },
//     {
//       lat: 47.480291,
//       lng: 8.1968154,
//     },
//     {
//       lat: 41.1208668,
//       lng: -88.835352,
//     },
//     {
//       lat: 39.1242444,
//       lng: -81.7354088,
//     },
//     {
//       lat: 32.8385295,
//       lng: -117.2785298,
//     },
//     {
//       lat: 32.715738,
//       lng: -117.1610838,
//     },
//     {
//       lat: 41.5054452,
//       lng: -82.0088231,
//     },
//     {
//       lat: 34.2804923,
//       lng: -119.2945199,
//     },
//     {
//       lat: 52.1217616,
//       lng: 5.2792627,
//     },
//     {
//       lat: 36.0643219,
//       lng: -115.0218541,
//     },
//     {
//       lat: 52.3742339,
//       lng: 4.8795359,
//     },
//     {
//       lat: 48.1177472,
//       lng: 11.4756648,
//     },
//     {
//       lat: 32.8477388,
//       lng: -80.0719797,
//     },
//     {
//       lat: 32.7764749,
//       lng: -79.9310512,
//     },
//     {
//       lat: 54.720642,
//       lng: 25.2219079,
//     },
//     {
//       lat: 32.7243924,
//       lng: -97.1378282,
//     },
//     {
//       lat: 47.218371,
//       lng: -1.553621,
//     },
//     {
//       lat: 40.8982809,
//       lng: -74.1522169,
//     },
//     {
//       lat: 35.3732921,
//       lng: -119.0187125,
//     },
//     {
//       lat: 39.7027607,
//       lng: -105.0137137,
//     },
//     {
//       lat: 51.165691,
//       lng: 10.451526,
//     },
//     {
//       lat: 50.1012797,
//       lng: 8.6782609,
//     },
//     {
//       lat: 26.9620053,
//       lng: -82.3525952,
//     },
//     {
//       lat: 45.8443379,
//       lng: 8.8098847,
//     },
//     {
//       lat: 40.5649781,
//       lng: -111.8389726,
//     },
//     {
//       lat: 39.1192309,
//       lng: -96.7141351,
//     },
//     {
//       lat: 33.8010449,
//       lng: -118.1100047,
//     },
//     {
//       lat: 34.1448981,
//       lng: -84.8174009,
//     },
//     {
//       lat: 40.5852602,
//       lng: -105.084423,
//     },
//     {
//       lat: 44.9537029,
//       lng: -93.0899578,
//     },
//     {
//       lat: 40.0583238,
//       lng: -74.4056612,
//     },
//     {
//       lat: 33.8010449,
//       lng: -118.1100047,
//     },
//     {
//       lat: 47.6062095,
//       lng: -122.3320708,
//     },
//     {
//       lat: 25.2048493,
//       lng: 55.2707828,
//     },
//     {
//       lat: 41.1164744,
//       lng: 1.250251,
//     },
//     {
//       lat: 40.0378755,
//       lng: -76.3055144,
//     },
//     {
//       lat: 47.4465886,
//       lng: 19.0477463,
//     },
//     {
//       lat: 28.927019,
//       lng: -82.0037608,
//     },
//     {
//       lat: 38.7222524,
//       lng: -9.1393366,
//     },
//     {
//       lat: 50.6385378,
//       lng: 3.2785761,
//     },
//     {
//       lat: 49.684973,
//       lng: 18.321717,
//     },
//     {
//       lat: 33.6057389,
//       lng: -117.7024648,
//     },
//     {
//       lat: 18.3807819,
//       lng: -65.9573872,
//     },
//     {
//       lat: 18.3807819,
//       lng: -65.9573872,
//     },
//     {
//       lat: 52.9688194,
//       lng: -113.3660794,
//     },
//     {
//       lat: 33.4151843,
//       lng: -111.8314724,
//     },
//     {
//       lat: 44.2915411,
//       lng: 11.8803737,
//     },
//     {
//       lat: 45.9422611,
//       lng: 6.1283137,
//     },
//     {
//       lat: 51.382208,
//       lng: -0.315263,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 33.9287623,
//       lng: -85.648746,
//     },
//     {
//       lat: 34.4304419,
//       lng: -119.7361341,
//     },
//     {
//       lat: 43.6590993,
//       lng: -70.2568189,
//     },
//     {
//       lat: 41.632223,
//       lng: -88.2120315,
//     },
//     {
//       lat: 38.1373187,
//       lng: -105.931777,
//     },
//     {
//       lat: 41.8942715,
//       lng: 12.4575431,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 49.0520962,
//       lng: 8.3833032,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 50.89338,
//       lng: 6.99542,
//     },
//     {
//       lat: 42.6977082,
//       lng: 23.3218675,
//     },
//     {
//       lat: 33.6845673,
//       lng: -117.8265049,
//     },
//     {
//       lat: 35.4854,
//       lng: -80.540306,
//     },
//     {
//       lat: 48.6505297,
//       lng: -123.5571899,
//     },
//     {
//       lat: 30.4298653,
//       lng: -88.9688744,
//     },
//     {
//       lat: 5.4853253,
//       lng: 7.0028091,
//     },
//     {
//       lat: 48.9236366,
//       lng: 11.8881583,
//     },
//     {
//       lat: 52.1561113,
//       lng: 5.3878266,
//     },
//     {
//       lat: 41.0892446,
//       lng: -74.0180544,
//     },
//     {
//       lat: 35.2327544,
//       lng: -106.6630437,
//     },
//     {
//       lat: 41.0887073,
//       lng: -74.013473,
//     },
//     {
//       lat: 52.5200066,
//       lng: 13.404954,
//     },
//     {
//       lat: 46.7323875,
//       lng: -117.000165,
//     },
//     {
//       lat: 33.6371785,
//       lng: -96.6337915,
//     },
//     {
//       lat: 42.5357428,
//       lng: -113.7927948,
//     },
//     {
//       lat: 43.5907558,
//       lng: -116.5067094,
//     },
//     {
//       lat: 38.963745,
//       lng: 35.243322,
//     },
//     {
//       lat: 43.5788175,
//       lng: -116.55978,
//     },
//     {
//       lat: 40.3306656,
//       lng: -74.1209748,
//     },
//     {
//       lat: 33.6292337,
//       lng: -112.3679279,
//     },
//     {
//       lat: 34.1314478,
//       lng: -117.8705881,
//     },
//     {
//       lat: 47.6778614,
//       lng: 15.9616401,
//     },
//     {
//       lat: 43.9208961,
//       lng: -79.4614672,
//     },
//     {
//       lat: 32.715738,
//       lng: -117.1610838,
//     },
//     {
//       lat: 25.6772606,
//       lng: -80.3080137,
//     },
//     {
//       lat: 40.8865459,
//       lng: 14.2482506,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 26.1224386,
//       lng: -80.1373174,
//     },
//     {
//       lat: 43.6664122,
//       lng: -79.4317306,
//     },
//     {
//       lat: 35.679244,
//       lng: -77.644003,
//     },
//     {
//       lat: 45.3343198,
//       lng: -118.0645804,
//     },
//     {
//       lat: 45.885825,
//       lng: -89.158123,
//     },
//     {
//       lat: 45.9171763,
//       lng: -89.2442988,
//     },
//     {
//       lat: 52.5378475,
//       lng: 1.7239138,
//     },
//     {
//       lat: 52.5378475,
//       lng: 1.7239138,
//     },
//     {
//       lat: 50.08496,
//       lng: 8.22872,
//     },
//     {
//       lat: 52.3161045,
//       lng: 6.9387612,
//     },
//     {
//       lat: 48.5069694,
//       lng: 11.1834578,
//     },
//     {
//       lat: 42.3238901,
//       lng: -122.8115317,
//     },
//     {
//       lat: 55.3270859,
//       lng: 8.7555962,
//     },
//     {
//       lat: 25.4646343,
//       lng: -80.4297122,
//     },
//     {
//       lat: 25.4687224,
//       lng: -80.4775569,
//     },
//     {
//       lat: 32.3717878,
//       lng: -81.3273602,
//     },
//     {
//       lat: 39.0547915,
//       lng: -84.5681712,
//     },
//     {
//       lat: 36.7580889,
//       lng: -119.7898746,
//     },
//     {
//       lat: 27.6388282,
//       lng: -80.3832954,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 43.653226,
//       lng: -79.3831843,
//     },
//     {
//       lat: 21.330908,
//       lng: -157.888302,
//     },
//     {
//       lat: 39.1004552,
//       lng: -76.9644186,
//     },
//     {
//       lat: 21.330908,
//       lng: -157.888302,
//     },
//     {
//       lat: 39.5062108,
//       lng: -77.6524907,
//     },
//     {
//       lat: 39.5062108,
//       lng: -77.6524907,
//     },
//     {
//       lat: 38.5897711,
//       lng: -88.056703,
//     },
//     {
//       lat: 38.5897711,
//       lng: -88.056703,
//     },
//     {
//       lat: 38.5897711,
//       lng: -88.056703,
//     },
//     {
//       lat: 50.7751939,
//       lng: 3.2431555,
//     },
//     {
//       lat: 46.3574807,
//       lng: 14.1605621,
//     },
//     {
//       lat: 40.6465304,
//       lng: -111.9506422,
//     },
//     {
//       lat: 49.8879519,
//       lng: -119.4960106,
//     },
//     {
//       lat: 45.8599267,
//       lng: -111.9524875,
//     },
//     {
//       lat: 33.969188,
//       lng: -116.954773,
//     },
//     {
//       lat: 50.8868949,
//       lng: 3.4231675,
//     },
//     {
//       lat: 50.8868949,
//       lng: 3.4231675,
//     },
//     {
//       lat: 47.9824791,
//       lng: 11.3617515,
//     },
//     {
//       lat: 50.0755381,
//       lng: 14.4378005,
//     },
//     {
//       lat: 50.4542408,
//       lng: 3.956659,
//     },
//     {
//       lat: 50.7291276,
//       lng: 15.4486873,
//     },
//     {
//       lat: 50.7220528,
//       lng: 15.1703135,
//     },
//     {
//       lat: 50.5015549,
//       lng: 13.6329122,
//     },
//     {
//       lat: 30.267153,
//       lng: -97.7430608,
//     },
//     {
//       lat: 42.3369816,
//       lng: -83.2732627,
//     },
//     {
//       lat: 32.7781761,
//       lng: -97.0334343,
//     },
//     {
//       lat: 32.7459645,
//       lng: -96.9977846,
//     },
//     {
//       lat: 40.8866221,
//       lng: -85.5140285,
//     },
//     {
//       lat: 43.4784246,
//       lng: -85.6458768,
//     },
//     {
//       lat: 43.548473,
//       lng: 10.3105674,
//     },
//     {
//       lat: 44.9858453,
//       lng: 10.4141436,
//     },
//     {
//       lat: 28.4966725,
//       lng: -81.7791507,
//     },
//     {
//       lat: 44.4899473,
//       lng: -80.203823,
//     },
//     {
//       lat: 32.6719423,
//       lng: -79.9454361,
//     },
//     {
//       lat: 40.1573169,
//       lng: -76.3069014,
//     },
//     {
//       lat: 33.6867135,
//       lng: -112.3440686,
//     },
//     {
//       lat: 41.7634219,
//       lng: -72.6128339,
//     },
//     {
//       lat: 45.5016889,
//       lng: -73.567256,
//     },
//     {
//       lat: 33.6111461,
//       lng: -111.8733937,
//     },
//     {
//       lat: 26.7153424,
//       lng: -80.0533746,
//     },
//     {
//       lat: 42.4842392,
//       lng: -76.4799397,
//     },
//     {
//       lat: 34.8406308,
//       lng: -82.3584756,
//     },
//     {
//       lat: 36.1699412,
//       lng: -115.1398296,
//     },
//     {
//       lat: 45.7807959,
//       lng: -122.5477298,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 20.7983626,
//       lng: -156.3319253,
//     },
//     {
//       lat: 44.6706762,
//       lng: -123.2178759,
//     },
//     {
//       lat: 47.4156824,
//       lng: -120.2931263,
//     },
//     {
//       lat: 34.6654803,
//       lng: -92.3405383,
//     },
//     {
//       lat: 39.399872,
//       lng: -8.224454,
//     },
//     {
//       lat: 40.8288656,
//       lng: 14.5898282,
//     },
//     {
//       lat: 39.8331786,
//       lng: -75.1444366,
//     },
//     {
//       lat: 45.6242491,
//       lng: 9.0359607,
//     },
//     {
//       lat: 31.8782162,
//       lng: -85.4496627,
//     },
//     {
//       lat: 10.4805937,
//       lng: -66.9036063,
//     },
//     {
//       lat: 41.828345,
//       lng: -71.493581,
//     },
//     {
//       lat: 25.7360194,
//       lng: -80.3157397,
//     },
//     {
//       lat: 40.8457193,
//       lng: 25.873962,
//     },
//     {
//       lat: 52.1807282,
//       lng: 0.1486511,
//     },
//     {
//       lat: 46.8875979,
//       lng: 14.1134085,
//     },
//     {
//       lat: 51.8414722,
//       lng: 4.9523877,
//     },
//     {
//       lat: 35.5753413,
//       lng: -78.7533991,
//     },
//     {
//       lat: 35.5843235,
//       lng: -78.8000128,
//     },
//     {
//       lat: 34.4398202,
//       lng: -88.1408747,
//     },
//     {
//       lat: 39.276489,
//       lng: -94.574134,
//     },
//     {
//       lat: 33.9379192,
//       lng: -84.458245,
//     },
//     {
//       lat: 33.9262991,
//       lng: -83.3268356,
//     },
//     {
//       lat: 43.4430823,
//       lng: -88.2000606,
//     },
//     {
//       lat: 40.3356483,
//       lng: -75.9268747,
//     },
//     {
//       lat: 42.732535,
//       lng: -84.5555347,
//     },
//     {
//       lat: 37.5935986,
//       lng: -122.4869904,
//     },
//     {
//       lat: 38.9621128,
//       lng: -76.7914685,
//     },
//     {
//       lat: 44.8036093,
//       lng: 11.0279484,
//     },
//     {
//       lat: 51.7597897,
//       lng: 8.719916,
//     },
//     {
//       lat: 54.720642,
//       lng: 25.2219079,
//     },
//     {
//       lat: 42.5887758,
//       lng: -89.5041158,
//     },
//     {
//       lat: 42.5887758,
//       lng: -89.5041158,
//     },
//     {
//       lat: 51.9700058,
//       lng: 7.6127873,
//     },
//     {
//       lat: 50.7220528,
//       lng: 15.1703135,
//     },
//     {
//       lat: 59.4369608,
//       lng: 24.7535747,
//     },
//     {
//       lat: 33.8919236,
//       lng: -118.1650662,
//     },
//     {
//       lat: 38.7665005,
//       lng: -104.8804893,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 35.4333622,
//       lng: -82.5293008,
//     },
//     {
//       lat: 39.4635398,
//       lng: -119.8237232,
//     },
//     {
//       lat: 40.9731743,
//       lng: 14.2076688,
//     },
//     {
//       lat: 47.6541563,
//       lng: -2.0935795,
//     },
//     {
//       lat: 50.7087187,
//       lng: 7.1098722,
//     },
//     {
//       lat: 40.1424847,
//       lng: -80.227975,
//     },
//     {
//       lat: 36.3305163,
//       lng: -84.1602892,
//     },
//     {
//       lat: 36.3305163,
//       lng: -84.1602892,
//     },
//     {
//       lat: 40.5880814,
//       lng: -80.0311364,
//     },
//     {
//       lat: 47.0528789,
//       lng: -122.2942839,
//     },
//     {
//       lat: 29.7049806,
//       lng: -98.068343,
//     },
//     {
//       lat: 39.9276572,
//       lng: -122.1791556,
//     },
//     {
//       lat: 41.3422754,
//       lng: -80.3937658,
//     },
//     {
//       lat: 32.7368407,
//       lng: -117.1663865,
//     },
//     {
//       lat: 27.6386434,
//       lng: -80.3972736,
//     },
//     {
//       lat: 50.0755381,
//       lng: 14.4378005,
//     },
//     {
//       lat: 46.762458,
//       lng: -100.767041,
//     },
//     {
//       lat: 41.6032207,
//       lng: -73.087749,
//     },
//     {
//       lat: 36.204824,
//       lng: 138.252924,
//     },
//     {
//       lat: 39.0275659,
//       lng: -83.919652,
//     },
//     {
//       lat: 26.1947628,
//       lng: -98.0972961,
//     },
//     {
//       lat: 37.3667056,
//       lng: -82.4053264,
//     },
//     {
//       lat: 41.2313745,
//       lng: -76.1508619,
//     },
//     {
//       lat: 44.2726203,
//       lng: -121.1739212,
//     },
//     {
//       lat: 27.080784,
//       lng: -82.390771,
//     },
//     {
//       lat: 27.1317258,
//       lng: -82.4299509,
//     },
//     {
//       lat: 27.080784,
//       lng: -82.390771,
//     },
//     {
//       lat: 26.633759,
//       lng: -80.143113,
//     },
//     {
//       lat: 38.706358,
//       lng: -121.343667,
//     },
//     {
//       lat: 33.7489954,
//       lng: -84.3879824,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 43.8843375,
//       lng: 10.5644986,
//     },
//     {
//       lat: 50.1042663,
//       lng: 13.7455855,
//     },
//     {
//       lat: 52.2574121,
//       lng: 6.7927725,
//     },
//     {
//       lat: 35.7065217,
//       lng: -81.2186933,
//     },
//     {
//       lat: 43.3209022,
//       lng: 21.8957589,
//     },
//     {
//       lat: 52.3549639,
//       lng: 4.9229501,
//     },
//     {
//       lat: 37.6697463,
//       lng: -120.9991032,
//     },
//     {
//       lat: 39.5666649,
//       lng: 2.5001079,
//     },
//     {
//       lat: 40.7989473,
//       lng: -81.378447,
//     },
//     {
//       lat: 45.3364465,
//       lng: -122.6050422,
//     },
//     {
//       lat: 38.0316988,
//       lng: -84.4518068,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 38.049365,
//       lng: -122.1585777,
//     },
//     {
//       lat: 50.937531,
//       lng: 6.9602786,
//     },
//     {
//       lat: 25.2048493,
//       lng: 55.2707828,
//     },
//     {
//       lat: 50.0755381,
//       lng: 14.4378005,
//     },
//     {
//       lat: 45.5579451,
//       lng: -94.1632404,
//     },
//     {
//       lat: 34.1577811,
//       lng: -84.8776392,
//     },
//     {
//       lat: 34.1650972,
//       lng: -84.7999382,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 50.0147576,
//       lng: 8.3558664,
//     },
//     {
//       lat: 41.1188827,
//       lng: 1.2444909,
//     },
//     {
//       lat: 51.165691,
//       lng: 10.451526,
//     },
//     {
//       lat: 40.4842027,
//       lng: -88.9936873,
//     },
//     {
//       lat: 43.687571,
//       lng: 7.212095,
//     },
//     {
//       lat: 40.4167754,
//       lng: -3.7037902,
//     },
//     {
//       lat: 33.0376384,
//       lng: -116.8765386,
//     },
//     {
//       lat: 40.4172871,
//       lng: -82.907123,
//     },
//     {
//       lat: 34.208254,
//       lng: -118.6058609,
//     },
//     {
//       lat: 43.8226664,
//       lng: -79.1300091,
//     },
//     {
//       lat: 44.9428975,
//       lng: -123.0350963,
//     },
//     {
//       lat: 41.1578377,
//       lng: -80.0886703,
//     },
//     {
//       lat: 39.6976652,
//       lng: -104.8980734,
//     },
//     {
//       lat: 41.4470241,
//       lng: 1.9728327,
//     },
//     {
//       lat: 46.8138783,
//       lng: -71.2079809,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 42.360091,
//       lng: -71.09416,
//     },
//     {
//       lat: 52.8329703,
//       lng: -1.6193642,
//     },
//     {
//       lat: 46.7580213,
//       lng: 14.0450879,
//     },
//     {
//       lat: 41.381265,
//       lng: -83.3270622,
//     },
//     {
//       lat: 29.7745701,
//       lng: -95.8063797,
//     },
//     {
//       lat: 41.381265,
//       lng: -83.3270622,
//     },
//     {
//       lat: 37.3201112,
//       lng: -77.868084,
//     },
//     {
//       lat: 40.7043858,
//       lng: -73.6882711,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 39.7392358,
//       lng: -104.990251,
//     },
//     {
//       lat: 39.208416,
//       lng: -96.365261,
//     },
//     {
//       lat: 41.4047742,
//       lng: -81.7229086,
//     },
//     {
//       lat: 47.6061031,
//       lng: -122.3320534,
//     },
//     {
//       lat: 47.6061031,
//       lng: -122.3320534,
//     },
//     {
//       lat: 47.6061031,
//       lng: -122.3320534,
//     },
//     {
//       lat: 34.9592083,
//       lng: -116.419389,
//     },
//     {
//       lat: 27.6648274,
//       lng: -81.5157535,
//     },
//     {
//       lat: 44.0682019,
//       lng: -114.7420408,
//     },
//     {
//       lat: 47.6587802,
//       lng: -117.4260465,
//     },
//     {
//       lat: 47.6587802,
//       lng: -117.4260465,
//     },
//     {
//       lat: 34.9592083,
//       lng: -116.419389,
//     },
//     {
//       lat: 42.0551488,
//       lng: -86.06393,
//     },
//     {
//       lat: 40.8042674,
//       lng: -74.012084,
//     },
//     {
//       lat: 52.6652093,
//       lng: 13.5226206,
//     },
//     {
//       lat: 43.318809,
//       lng: 11.3307574,
//     },
//     {
//       lat: 52.1258118,
//       lng: 5.5619398,
//     },
//     {
//       lat: 25.6463263,
//       lng: -80.3346707,
//     },
//     {
//       lat: 28.1947477,
//       lng: -82.6326019,
//     },
//     {
//       lat: 28.1947477,
//       lng: -82.6326019,
//     },
//     {
//       lat: 38.1512528,
//       lng: 13.3335432,
//     },
//     {
//       lat: 47.6062095,
//       lng: -122.3320708,
//     },
//     {
//       lat: 31.1432351,
//       lng: -89.4097866,
//     },
//     {
//       lat: 41.1089479,
//       lng: -85.0444754,
//     },
//     {
//       lat: 41.7122046,
//       lng: -71.5307103,
//     },
//     {
//       lat: 36.1626638,
//       lng: -86.7816016,
//     },
//     {
//       lat: 43.1278802,
//       lng: -88.0582533,
//     },
//     {
//       lat: 35.1954345,
//       lng: -79.4694767,
//     },
//     {
//       lat: 52.2246479,
//       lng: 4.5200498,
//     },
//     {
//       lat: 29.5647851,
//       lng: -81.1841158,
//     },
//     {
//       lat: 31.1432351,
//       lng: -89.4097866,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 38.1839705,
//       lng: -83.4326841,
//     },
//     {
//       lat: 46.2488031,
//       lng: 7.4249909,
//     },
//     {
//       lat: 33.9562149,
//       lng: -83.9879625,
//     },
//     {
//       lat: 51.3127262,
//       lng: 1.1657904,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 10.147904,
//       lng: -67.9901066,
//     },
//     {
//       lat: 41.4660715,
//       lng: 12.8933794,
//     },
//     {
//       lat: 33.7870528,
//       lng: -84.3872705,
//     },
//     {
//       lat: 51.5180195,
//       lng: 7.36739,
//     },
//     {
//       lat: 34.0264586,
//       lng: -118.2219165,
//     },
//     {
//       lat: 34.0151215,
//       lng: -85.2559757,
//     },
//     {
//       lat: 43.7242903,
//       lng: -79.4645891,
//     },
//     {
//       lat: 42.9697186,
//       lng: -77.3821793,
//     },
//     {
//       lat: 38.9643531,
//       lng: -95.8914709,
//     },
//     {
//       lat: 42.9697186,
//       lng: -77.3821793,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 34.6206827,
//       lng: -112.3077216,
//     },
//     {
//       lat: 34.6100243,
//       lng: -112.315721,
//     },
//     {
//       lat: 39.248304,
//       lng: -84.170019,
//     },
//     {
//       lat: 46.7866719,
//       lng: -92.1004852,
//     },
//     {
//       lat: 42.9874328,
//       lng: -84.1634579,
//     },
//     {
//       lat: 26.6530122,
//       lng: -81.7787021,
//     },
//     {
//       lat: 46.7866719,
//       lng: -92.1004852,
//     },
//     {
//       lat: 46.7730021,
//       lng: -92.1542007,
//     },
//     {
//       lat: 36.1699412,
//       lng: -115.1398296,
//     },
//     {
//       lat: 43.0389025,
//       lng: -87.9064736,
//     },
//     {
//       lat: 31.7643793,
//       lng: -106.2798661,
//     },
//     {
//       lat: 41.7641723,
//       lng: -87.7812524,
//     },
//     {
//       lat: 37.326223,
//       lng: -93.15105,
//     },
//     {
//       lat: 33.8710729,
//       lng: -117.8627548,
//     },
//     {
//       lat: 35.3732921,
//       lng: -119.0187125,
//     },
//     {
//       lat: 48.3837895,
//       lng: -115.5616207,
//     },
//     {
//       lat: 45.073513,
//       lng: -64.524728,
//     },
//     {
//       lat: 39.549544,
//       lng: -76.348381,
//     },
//     {
//       lat: 39.549544,
//       lng: -76.348381,
//     },
//     {
//       lat: 39.5500507,
//       lng: -105.7820674,
//     },
//     {
//       lat: 52.2877271,
//       lng: 8.9323197,
//     },
//     {
//       lat: 39.6581424,
//       lng: -78.928357,
//     },
//     {
//       lat: 42.170109,
//       lng: -88.3763794,
//     },
//     {
//       lat: 33.5386523,
//       lng: -112.1859866,
//     },
//     {
//       lat: 44.4294089,
//       lng: -93.2018826,
//     },
//     {
//       lat: 35.9420431,
//       lng: -95.8833235,
//     },
//     {
//       lat: 42.4651421,
//       lng: -71.0110473,
//     },
//     {
//       lat: 43.9883462,
//       lng: -78.1866585,
//     },
//     {
//       lat: 38.7679772,
//       lng: -104.7327936,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 39.0997265,
//       lng: -94.5785667,
//     },
//     {
//       lat: 29.9705211,
//       lng: -95.1840661,
//     },
//     {
//       lat: 38.8517761,
//       lng: -82.8966547,
//     },
//     {
//       lat: 38.8517761,
//       lng: -82.8966547,
//     },
//     {
//       lat: 38.8517761,
//       lng: -82.8966547,
//     },
//     {
//       lat: 36.4976139,
//       lng: -4.9891696,
//     },
//     {
//       lat: 36.486704,
//       lng: -4.9920468,
//     },
//     {
//       lat: 50.93608,
//       lng: 13.92562,
//     },
//     {
//       lat: 45.4215296,
//       lng: -75.6971931,
//     },
//     {
//       lat: 50.8637566,
//       lng: 3.2208746,
//     },
//     {
//       lat: 33.6638439,
//       lng: -117.9047429,
//     },
//     {
//       lat: 34.0736204,
//       lng: -118.4003563,
//     },
//     {
//       lat: 45.7458683,
//       lng: -87.0541581,
//     },
//     {
//       lat: 37.791485,
//       lng: -122.4301957,
//     },
//     {
//       lat: 43.8563158,
//       lng: -79.5085383,
//     },
//     {
//       lat: 47.6803799,
//       lng: -116.8847744,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 37.7253452,
//       lng: -122.3948096,
//     },
//     {
//       lat: 34.2745511,
//       lng: -84.5012435,
//     },
//     {
//       lat: 50.0755381,
//       lng: 14.4378005,
//     },
//     {
//       lat: 50.0755381,
//       lng: 14.4378005,
//     },
//     {
//       lat: 37.7253452,
//       lng: -122.3948096,
//     },
//     {
//       lat: 37.7253452,
//       lng: -122.3948096,
//     },
//     {
//       lat: 41.3227985,
//       lng: -85.7752546,
//     },
//     {
//       lat: 40.7127753,
//       lng: -74.0059728,
//     },
//     {
//       lat: 40.7127753,
//       lng: -74.0059728,
//     },
//     {
//       lat: 30.059908,
//       lng: -95.2394115,
//     },
//     {
//       lat: 38.6445146,
//       lng: -121.5120392,
//     },
//     {
//       lat: 41.6504367,
//       lng: 13.3331921,
//     },
//     {
//       lat: 48.0836931,
//       lng: 16.2955828,
//     },
//     {
//       lat: 45.2310461,
//       lng: 2.9352221,
//     },
//     {
//       lat: 46.5090928,
//       lng: -87.6068721,
//     },
//     {
//       lat: 42.9000596,
//       lng: -78.4919671,
//     },
//     {
//       lat: 41.241747,
//       lng: -95.7679011,
//     },
//     {
//       lat: 43.5163625,
//       lng: -73.2310552,
//     },
//     {
//       lat: 50.891147,
//       lng: 3.0632369,
//     },
//     {
//       lat: 41.1847939,
//       lng: -77.3138622,
//     },
//     {
//       lat: 46.5809131,
//       lng: -120.5394518,
//     },
//     {
//       lat: 50.8608509,
//       lng: 17.466831,
//     },
//     {
//       lat: 41.3319904,
//       lng: -85.8499921,
//     },
//     {
//       lat: 47.2892329,
//       lng: -122.5528184,
//     },
//     {
//       lat: 44.7741477,
//       lng: -85.7632001,
//     },
//     {
//       lat: 40.5697336,
//       lng: -90.9629523,
//     },
//     {
//       lat: 30.317144,
//       lng: -92.0490096,
//     },
//     {
//       lat: 46.8513541,
//       lng: -114.0185529,
//     },
//     {
//       lat: 42.1042451,
//       lng: -72.3189519,
//     },
//     {
//       lat: 51.8320826,
//       lng: 4.1586671,
//     },
//     {
//       lat: 38.6601172,
//       lng: -78.9061381,
//     },
//     {
//       lat: 40.7036936,
//       lng: -80.2821857,
//     },
//     {
//       lat: 39.7589478,
//       lng: -84.1916069,
//     },
//     {
//       lat: 49.850152,
//       lng: -119.6508706,
//     },
//     {
//       lat: 29.375859,
//       lng: 47.9774052,
//     },
//     {
//       lat: 38.8462236,
//       lng: -77.3063733,
//     },
//     {
//       lat: 41.7798226,
//       lng: -71.4372796,
//     },
//     {
//       lat: 40.1777339,
//       lng: -74.0137852,
//     },
//     {
//       lat: 51.7793762,
//       lng: -1.1629041,
//     },
//     {
//       lat: 51.8546481,
//       lng: 5.9391535,
//     },
//     {
//       lat: 52.0029263,
//       lng: 4.7672357,
//     },
//     {
//       lat: 51.8546481,
//       lng: 5.9391535,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 39.5500507,
//       lng: -105.7820674,
//     },
//     {
//       lat: 45.7832856,
//       lng: -108.5006904,
//     },
//     {
//       lat: 39.8397705,
//       lng: -104.9032292,
//     },
//     {
//       lat: 38.8338816,
//       lng: -104.8213634,
//     },
//     {
//       lat: 38.8517761,
//       lng: -82.8966547,
//     },
//     {
//       lat: 49.0948002,
//       lng: -122.6061822,
//     },
//     {
//       lat: 32.9265137,
//       lng: -96.8961151,
//     },
//     {
//       lat: 32.9265137,
//       lng: -96.8961151,
//     },
//     {
//       lat: 32.9265137,
//       lng: -96.8961151,
//     },
//     {
//       lat: 39.6821266,
//       lng: -79.9641863,
//     },
//     {
//       lat: 42.7633936,
//       lng: -78.3097382,
//     },
//     {
//       lat: 33.7489954,
//       lng: -84.3879824,
//     },
//     {
//       lat: 47.4967868,
//       lng: 9.7286993,
//     },
//     {
//       lat: 47.4967868,
//       lng: 9.7286993,
//     },
//     {
//       lat: 38.3790793,
//       lng: -120.5146478,
//     },
//     {
//       lat: 49.7451263,
//       lng: -96.865958,
//     },
//     {
//       lat: 37.8778846,
//       lng: -122.0273842,
//     },
//     {
//       lat: 41.8759012,
//       lng: -87.9950414,
//     },
//     {
//       lat: 49.7396213,
//       lng: -96.8743275,
//     },
//     {
//       lat: 41.436068,
//       lng: -87.7848262,
//     },
//     {
//       lat: 40.3122013,
//       lng: -74.0774459,
//     },
//     {
//       lat: 28.8052674,
//       lng: -97.0035982,
//     },
//     {
//       lat: 33.6845673,
//       lng: -117.8265049,
//     },
//     {
//       lat: 33.6666216,
//       lng: -117.9814378,
//     },
//     {
//       lat: 34.2605352,
//       lng: -86.2627788,
//     },
//     {
//       lat: -28.016667,
//       lng: 153.4,
//     },
//     {
//       lat: 38.8048355,
//       lng: -77.0469214,
//     },
//     {
//       lat: 35.2270869,
//       lng: -80.8431267,
//     },
//     {
//       lat: 49.815273,
//       lng: 6.129583,
//     },
//     {
//       lat: 45.8903231,
//       lng: 10.7270243,
//     },
//     {
//       lat: 35.6528323,
//       lng: -97.4780954,
//     },
//     {
//       lat: 42.623146,
//       lng: -82.549617,
//     },
//     {
//       lat: 39.8786639,
//       lng: -104.6821048,
//     },
//     {
//       lat: 40.3779217,
//       lng: -111.7870211,
//     },
//     {
//       lat: 51.3030836,
//       lng: 4.8834494,
//     },
//     {
//       lat: 37.6079568,
//       lng: -97.2984628,
//     },
//     {
//       lat: 40.4591683,
//       lng: -75.8310284,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 40.0378755,
//       lng: -76.3055144,
//     },
//     {
//       lat: 55.6019867,
//       lng: 23.1921218,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 41.5381535,
//       lng: -72.8070435,
//     },
//     {
//       lat: 31.7618778,
//       lng: -106.4850217,
//     },
//     {
//       lat: 52.0960904,
//       lng: -2.6548842,
//     },
//     {
//       lat: 25.693713,
//       lng: -80.1628248,
//     },
//     {
//       lat: 38.810878,
//       lng: -90.8081893,
//     },
//     {
//       lat: 53.8213067,
//       lng: -1.5223057,
//     },
//     {
//       lat: 39.7136754,
//       lng: -82.5993294,
//     },
//     {
//       lat: 42.0640628,
//       lng: -90.6311324,
//     },
//     {
//       lat: 40.3324142,
//       lng: -111.6845559,
//     },
//     {
//       lat: 40.3324142,
//       lng: -111.6845559,
//     },
//     {
//       lat: 43.2360552,
//       lng: -76.2856765,
//     },
//     {
//       lat: 41.8325038,
//       lng: -75.8778425,
//     },
//     {
//       lat: 32.7956175,
//       lng: -117.0113858,
//     },
//     {
//       lat: 41.5581525,
//       lng: -73.0514965,
//     },
//     {
//       lat: 48.1465597,
//       lng: -3.3869422,
//     },
//     {
//       lat: 51.9299735,
//       lng: 8.4422024,
//     },
//     {
//       lat: 47.4825237,
//       lng: 8.2888847,
//     },
//     {
//       lat: 43.768365,
//       lng: -80.08033,
//     },
//     {
//       lat: 37.1478658,
//       lng: -93.3505022,
//     },
//     {
//       lat: 44.435693,
//       lng: 18.1362669,
//     },
//     {
//       lat: 37.2089572,
//       lng: -93.2922989,
//     },
//     {
//       lat: 40.1616743,
//       lng: -86.0690894,
//     },
//     {
//       lat: 28.4966725,
//       lng: -81.7791507,
//     },
//     {
//       lat: 59.7249586,
//       lng: 18.3672174,
//     },
//     {
//       lat: 40.1616743,
//       lng: -86.0690894,
//     },
//     {
//       lat: 33.7514966,
//       lng: -84.7477136,
//     },
//     {
//       lat: 51.334139,
//       lng: 12.5508424,
//     },
//     {
//       lat: 34.1954001,
//       lng: -82.1617883,
//     },
//     {
//       lat: 46.7866719,
//       lng: -92.1004852,
//     },
//     {
//       lat: 42.6977082,
//       lng: 23.3218675,
//     },
//     {
//       lat: -25.7600868,
//       lng: 28.3393513,
//     },
//     {
//       lat: 39.1306656,
//       lng: -94.4891634,
//     },
//     {
//       lat: 39.9611755,
//       lng: -82.9987942,
//     },
//     {
//       lat: 41.2958751,
//       lng: -86.6250139,
//     },
//     {
//       lat: 54.0230034,
//       lng: 23.9746115,
//     },
//     {
//       lat: 54.0229488,
//       lng: 23.9747271,
//     },
//     {
//       lat: 41.4730948,
//       lng: -87.0611412,
//     },
//     {
//       lat: 39.2981565,
//       lng: -78.4361189,
//     },
//     {
//       lat: 35.9258985,
//       lng: -85.4641421,
//     },
//     {
//       lat: 35.5261633,
//       lng: -98.7075744,
//     },
//     {
//       lat: 32.7592955,
//       lng: -97.7972544,
//     },
//     {
//       lat: 35.8652212,
//       lng: -78.7353766,
//     },
//     {
//       lat: 34.2804923,
//       lng: -119.2945199,
//     },
//     {
//       lat: 56.130366,
//       lng: -106.346771,
//     },
//     {
//       lat: 35.1217955,
//       lng: -77.1715228,
//     },
//     {
//       lat: 39.2981565,
//       lng: -78.4361189,
//     },
//     {
//       lat: 51.233566,
//       lng: 4.4982073,
//     },
//     {
//       lat: 36.5103766,
//       lng: -4.8936227,
//     },
//     {
//       lat: 38.5451122,
//       lng: -75.0890709,
//     },
//     {
//       lat: 38.212815,
//       lng: -84.6425067,
//     },
//     {
//       lat: 52.2996146,
//       lng: 5.0386942,
//     },
//     {
//       lat: 34.1442616,
//       lng: -118.0019482,
//     },
//     {
//       lat: 32.6400541,
//       lng: -117.0841955,
//     },
//     {
//       lat: 34.748526,
//       lng: -86.6660075,
//     },
//     {
//       lat: 36.6198599,
//       lng: -80.0072657,
//     },
//     {
//       lat: 39.3913788,
//       lng: -104.752753,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 47.6062095,
//       lng: -122.3320708,
//     },
//     {
//       lat: 37.5629917,
//       lng: -122.3255254,
//     },
//     {
//       lat: 38.3226153,
//       lng: -75.2176892,
//     },
//     {
//       lat: 35.126413,
//       lng: 33.429859,
//     },
//     {
//       lat: 43.5719724,
//       lng: -116.268844,
//     },
//     {
//       lat: 26.0869508,
//       lng: -80.3591224,
//     },
//     {
//       lat: 51.5199606,
//       lng: 19.9815829,
//     },
//     {
//       lat: 38.0030761,
//       lng: 23.7278248,
//     },
//     {
//       lat: 48.3722361,
//       lng: 9.940511,
//     },
//     {
//       lat: 35.5893012,
//       lng: -80.9781318,
//     },
//     {
//       lat: 38.7866701,
//       lng: -77.0694299,
//     },
//     {
//       lat: 38.8048355,
//       lng: -77.0469214,
//     },
//     {
//       lat: 48.8860358,
//       lng: 2.3264686,
//     },
//     {
//       lat: 44.822598,
//       lng: -93.321831,
//     },
//     {
//       lat: 41.1188827,
//       lng: 1.2444909,
//     },
//     {
//       lat: 30.446137,
//       lng: -91.1219801,
//     },
//     {
//       lat: 35.170965,
//       lng: -93.890539,
//     },
//     {
//       lat: 35.170965,
//       lng: -93.890539,
//     },
//     {
//       lat: 51.209347,
//       lng: -2.6445979,
//     },
//     {
//       lat: 40.4332107,
//       lng: -78.3788967,
//     },
//     {
//       lat: 30.1538902,
//       lng: -94.0288176,
//     },
//     {
//       lat: 60.6299525,
//       lng: 24.8580797,
//     },
//     {
//       lat: 41.684782,
//       lng: -85.8490034,
//     },
//     {
//       lat: 41.684782,
//       lng: -85.8490034,
//     },
//     {
//       lat: 41.684782,
//       lng: -85.8490034,
//     },
//     {
//       lat: 51.4677832,
//       lng: -0.2668263,
//     },
//     {
//       lat: 39.3001506,
//       lng: -76.7210953,
//     },
//     {
//       lat: 41.117744,
//       lng: -73.4081575,
//     },
//     {
//       lat: 49.4076571,
//       lng: 8.6823401,
//     },
//     {
//       lat: 30.6696818,
//       lng: -81.4625919,
//     },
//     {
//       lat: 55.755826,
//       lng: 37.6172999,
//     },
//     {
//       lat: 30.8412954,
//       lng: -97.7936268,
//     },
//     {
//       lat: 30.4111934,
//       lng: -90.9802558,
//     },
//     {
//       lat: 41.5623209,
//       lng: -72.6506488,
//     },
//     {
//       lat: 46.5161646,
//       lng: -93.9223451,
//     },
//     {
//       lat: 40.1632814,
//       lng: -76.2699294,
//     },
//     {
//       lat: 40.6369217,
//       lng: -73.9733996,
//     },
//     {
//       lat: 41.093429,
//       lng: -75.0018401,
//     },
//     {
//       lat: 39.2874471,
//       lng: -76.7312397,
//     },
//     {
//       lat: 43.2360552,
//       lng: -76.2856765,
//     },
//     {
//       lat: 41.5236535,
//       lng: -73.7924503,
//     },
//     {
//       lat: 48.184826,
//       lng: 13.8998797,
//     },
//     {
//       lat: 45.0060767,
//       lng: -93.1566107,
//     },
//     {
//       lat: 38.4272984,
//       lng: -83.8632557,
//     },
//     {
//       lat: 39.862508,
//       lng: -74.9540987,
//     },
//     {
//       lat: 36.1220297,
//       lng: -86.7935296,
//     },
//     {
//       lat: 36.1220297,
//       lng: -86.7935296,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 34.5400242,
//       lng: -112.4685025,
//     },
//     {
//       lat: 44.74105,
//       lng: -75.296166,
//     },
//     {
//       lat: 42.24862,
//       lng: 2.93913,
//     },
//     {
//       lat: 1.4528393,
//       lng: 103.7939018,
//     },
//     {
//       lat: 29.5395602,
//       lng: -98.5530034,
//     },
//     {
//       lat: 41.7003713,
//       lng: -73.9209701,
//     },
//     {
//       lat: 41.6529294,
//       lng: -73.8647174,
//     },
//     {
//       lat: 42.3056501,
//       lng: -71.5245087,
//     },
//     {
//       lat: 42.6974655,
//       lng: -71.8732996,
//     },
//     {
//       lat: 37.197578,
//       lng: -108.0930766,
//     },
//     {
//       lat: 43.6150186,
//       lng: -116.2023137,
//     },
//     {
//       lat: 42.6974655,
//       lng: -71.8732996,
//     },
//     {
//       lat: 28.5383355,
//       lng: -81.3792365,
//     },
//     {
//       lat: 40.463667,
//       lng: -3.74922,
//     },
//     {
//       lat: 38.030186,
//       lng: -121.250331,
//     },
//     {
//       lat: 38.030186,
//       lng: -121.250331,
//     },
//     {
//       lat: 45.5478224,
//       lng: -122.7288206,
//     },
//     {
//       lat: 39.4877996,
//       lng: -119.777233,
//     },
//     {
//       lat: 47.516231,
//       lng: 14.550072,
//     },
//     {
//       lat: 34.9471526,
//       lng: -106.0708952,
//     },
//     {
//       lat: 25.6217715,
//       lng: -80.3247748,
//     },
//     {
//       lat: 38.8445251,
//       lng: -82.1370889,
//     },
//     {
//       lat: 32.0808989,
//       lng: -81.091203,
//     },
//     {
//       lat: 52.3702979,
//       lng: 4.8890717,
//     },
//     {
//       lat: 38.0695815,
//       lng: -78.700575,
//     },
//     {
//       lat: 36.548434,
//       lng: -82.5618186,
//     },
//     {
//       lat: 37.2768113,
//       lng: -79.1008543,
//     },
//     {
//       lat: 37.2768113,
//       lng: -79.1008543,
//     },
//     {
//       lat: 41.2958751,
//       lng: -86.6250139,
//     },
//     {
//       lat: 39.1299557,
//       lng: -89.891862,
//     },
//     {
//       lat: 39.1299557,
//       lng: -89.891862,
//     },
//     {
//       lat: 36.3937519,
//       lng: -78.982788,
//     },
//     {
//       lat: 47.5615096,
//       lng: -52.7125768,
//     },
//     {
//       lat: 41.8523954,
//       lng: 12.4315648,
//     },
//     {
//       lat: 40.4406248,
//       lng: -79.9958864,
//     },
//     {
//       lat: 40.17396,
//       lng: -80.2461714,
//     },
//     {
//       lat: 33.6633573,
//       lng: -116.3100095,
//     },
//     {
//       lat: 32.835662,
//       lng: -117.239388,
//     },
//     {
//       lat: 40.2033145,
//       lng: -8.4102573,
//     },
//     {
//       lat: 32.8328112,
//       lng: -117.2712717,
//     },
//     {
//       lat: 44.4267674,
//       lng: 26.1025384,
//     },
//     {
//       lat: 51.6150177,
//       lng: 7.1991794,
//     },
//     {
//       lat: 48.7641822,
//       lng: 2.1635253,
//     },
//     {
//       lat: 41.9027835,
//       lng: 12.4963655,
//     },
//     {
//       lat: 42.6907233,
//       lng: 22.1720303,
//     },
//     {
//       lat: 42.6907233,
//       lng: 22.1720303,
//     },
//     {
//       lat: 41.6023196,
//       lng: -72.9867718,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 40.17396,
//       lng: -80.2461714,
//     },
//     {
//       lat: 35.2489244,
//       lng: -82.5272294,
//     },
//     {
//       lat: 37.959309,
//       lng: -122.0571634,
//     },
//     {
//       lat: 37.959309,
//       lng: -122.0571634,
//     },
//     {
//       lat: 37.959309,
//       lng: -122.0571634,
//     },
//     {
//       lat: 28.2690592,
//       lng: -82.7201512,
//     },
//     {
//       lat: 52.2970818,
//       lng: 6.7988319,
//     },
//     {
//       lat: 37.42441,
//       lng: -5.96185,
//     },
//     {
//       lat: 40.4372967,
//       lng: -3.6212055,
//     },
//     {
//       lat: 48.8837304,
//       lng: 2.2882308,
//     },
//     {
//       lat: 33.7480567,
//       lng: -90.7677432,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9205033,
//       lng: 5.5404522,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 36.7989589,
//       lng: -80.8295697,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 50.9209797,
//       lng: 5.5410313,
//     },
//     {
//       lat: 45.6604316,
//       lng: -121.5686109,
//     },
//     {
//       lat: 29.398802,
//       lng: -98.264807,
//     },
//     {
//       lat: 40.3933956,
//       lng: -82.4857181,
//     },
//     {
//       lat: 40.3933956,
//       lng: -82.4857181,
//     },
//     {
//       lat: 39.0067768,
//       lng: -76.7791365,
//     },
//     {
//       lat: 41.1285672,
//       lng: -74.000518,
//     },
//     {
//       lat: 33.6843681,
//       lng: -117.653981,
//     },
//     {
//       lat: 42.8617433,
//       lng: -85.7013423,
//     },
//     {
//       lat: 48.8848073,
//       lng: 12.6091741,
//     },
//     {
//       lat: 52.1365344,
//       lng: 5.2103806,
//     },
//     {
//       lat: 51.680886,
//       lng: -4.160248,
//     },
//     {
//       lat: 60.1698557,
//       lng: 24.9383791,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 51.6726843,
//       lng: -4.0991551,
//     },
//     {
//       lat: 44.6261814,
//       lng: -93.7469113,
//     },
//     {
//       lat: 44.6261814,
//       lng: -93.7469113,
//     },
//     {
//       lat: 44.6261814,
//       lng: -93.7469113,
//     },
//     {
//       lat: 41.3850639,
//       lng: 2.1734035,
//     },
//     {
//       lat: 44.6261814,
//       lng: -93.7469113,
//     },
//     {
//       lat: 44.6261814,
//       lng: -93.7469113,
//     },
//     {
//       lat: 43.4442432,
//       lng: -71.6472988,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 48.856614,
//       lng: 2.3522219,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 42.6974655,
//       lng: -71.8732996,
//     },
//     {
//       lat: 32.3546679,
//       lng: -89.3985283,
//     },
//     {
//       lat: 39.1785858,
//       lng: -96.5774837,
//     },
//     {
//       lat: 49.2827291,
//       lng: -123.1207375,
//     },
//     {
//       lat: 39.1785858,
//       lng: -96.5774837,
//     },
//     {
//       lat: 37.5485396,
//       lng: -121.988583,
//     },
//     {
//       lat: 37.3086631,
//       lng: -83.9241933,
//     },
//     {
//       lat: 30.4382559,
//       lng: -84.2807329,
//     },
//     {
//       lat: 39.6552634,
//       lng: -106.8286507,
//     },
//     {
//       lat: 43.6150186,
//       lng: -116.2023137,
//     },
//     {
//       lat: 43.6150186,
//       lng: -116.2023137,
//     },
//     {
//       lat: 43.6150186,
//       lng: -116.2023137,
//     },
//     {
//       lat: 52.6953046,
//       lng: 4.7241352,
//     },
//     {
//       lat: 37.3086631,
//       lng: -83.9241933,
//     },
//     {
//       lat: 48.1725005,
//       lng: 16.2846903,
//     },
//     {
//       lat: 43.3381465,
//       lng: -1.7888495,
//     },
//     {
//       lat: 37.3086631,
//       lng: -83.9241933,
//     },
//     {
//       lat: 54.233849,
//       lng: -1.341377,
//     },
//     {
//       lat: 34.446682,
//       lng: -82.660008,
//     },
//     {
//       lat: 38.6418278,
//       lng: -77.3439283,
//     },
//     {
//       lat: 38.6418278,
//       lng: -77.3439283,
//     },
//     {
//       lat: 43.7310801,
//       lng: 10.9195237,
//     },
//     {
//       lat: 47.1600974,
//       lng: -114.8519677,
//     },
//     {
//       lat: 32.4805774,
//       lng: -86.1618029,
//     },
//     {
//       lat: 39.1836082,
//       lng: -96.5716694,
//     },
//     {
//       lat: 33.2181385,
//       lng: -87.7662819,
//     },
//     {
//       lat: 31.1171194,
//       lng: -97.7277959,
//     },
//     {
//       lat: 31.1171194,
//       lng: -97.7277959,
//     },
//     {
//       lat: -33.9248685,
//       lng: 18.4240553,
//     },
//     {
//       lat: -33.9248685,
//       lng: 18.4240553,
//     },
//     {
//       lat: 52.3575918,
//       lng: 4.7958184,
//     },
//     {
//       lat: 42.6576619,
//       lng: -83.5774367,
//     },
//     {
//       lat: 35.3187279,
//       lng: -82.4609528,
//     },
//     {
//       lat: 30.4382559,
//       lng: -84.2807329,
//     },
//     {
//       lat: 44.6704998,
//       lng: -70.1512169,
//     },
//     {
//       lat: 41.774181,
//       lng: -88.1894192,
//     },
//     {
//       lat: 43.6150186,
//       lng: -116.2023137,
//     },
//     {
//       lat: 42.340536,
//       lng: -88.4839409,
//     },
//     {
//       lat: 42.3147436,
//       lng: -88.4487021,
//     },
//     {
//       lat: 45.4642035,
//       lng: 9.189982,
//     },
//     {
//       lat: 35.2270869,
//       lng: -80.8431267,
//     },
//     {
//       lat: 38.9591379,
//       lng: -0.1269865,
//     },
//     {
//       lat: 44.6367836,
//       lng: -124.0534505,
//     },
//     {
//       lat: 36.3728538,
//       lng: -94.2088172,
//     },
//     {
//       lat: 40.9742601,
//       lng: -81.4788751,
//     },
//     {
//       lat: -12.0463731,
//       lng: -77.042754,
//     },
//     {
//       lat: 45.7798826,
//       lng: 4.834452,
//     },
//     {
//       lat: 41.2780613,
//       lng: -95.8529825,
//     },
//     {
//       lat: 40.7641183,
//       lng: -73.9793049,
//     },
//     {
//       lat: 40.7641183,
//       lng: -73.9793049,
//     },
//     {
//       lat: 40.3941176,
//       lng: -79.8812027,
//     },
//     {
//       lat: 51.919438,
//       lng: 19.145136,
//     },
//     {
//       lat: 38.9411953,
//       lng: -119.9775196,
//     },
//     {
//       lat: 32.8323225,
//       lng: -79.8284258,
//     },
//     {
//       lat: 49.9593613,
//       lng: 8.9588974,
//     },
//     {
//       lat: 35.7927134,
//       lng: -84.1433075,
//     },
//     {
//       lat: 42.2775281,
//       lng: -71.3468091,
//     },
//     {
//       lat: 38.3383754,
//       lng: -91.3385914,
//     },
//     {
//       lat: 32.2226066,
//       lng: -110.9747108,
//     },
//     {
//       lat: 40.3933956,
//       lng: -82.4857181,
//     },
//     {
//       lat: 40.3933956,
//       lng: -82.4857181,
//     },
//     {
//       lat: 40.3933956,
//       lng: -82.4857181,
//     },
//     {
//       lat: 37.9829496,
//       lng: -120.3821724,
//     },
//     {
//       lat: 48.8688209,
//       lng: -122.5403066,
//     },
//     {
//       lat: 53.6483708,
//       lng: 11.3704625,
//     },
//     {
//       lat: 42.4150899,
//       lng: 12.1154209,
//     },
//     {
//       lat: 51.209348,
//       lng: 3.2246995,
//     },
//     {
//       lat: 42.4298846,
//       lng: -8.6446202,
//     },
//     {
//       lat: 41.5827274,
//       lng: -72.6990708,
//     },
//     {
//       lat: 51.880087,
//       lng: 0.5509269,
//     },
//     {
//       lat: 51.880087,
//       lng: 0.5509269,
//     },
//     {
//       lat: 45.4748604,
//       lng: -73.4946501,
//     },
//     {
//       lat: 54.7593445,
//       lng: 11.883215,
//     },
//     {
//       lat: 54.7593445,
//       lng: 11.883215,
//     },
//     {
//       lat: 45.483357,
//       lng: -122.688687,
//     },
//     {
//       lat: 40.7127753,
//       lng: -74.0059728,
//     },
//     {
//       lat: 29.0302514,
//       lng: -82.6687161,
//     },
//     {
//       lat: 43.2547731,
//       lng: 6.6378577,
//     },
//     {
//       lat: 53.3929696,
//       lng: -6.1394884,
//     },
//     {
//       lat: 53.3498053,
//       lng: -6.2603097,
//     },
//     {
//       lat: 33.3061605,
//       lng: -111.8412502,
//     },
//     {
//       lat: 47.3082859,
//       lng: -122.3148733,
//     },
//     {
//       lat: 47.3082859,
//       lng: -122.3148733,
//     },
//     {
//       lat: 41.5964869,
//       lng: -72.8776013,
//     },
//     {
//       lat: 41.3503671,
//       lng: -72.405239,
//     },
//     {
//       lat: 40.4991922,
//       lng: -4.0652559,
//     },
//     {
//       lat: 56.26392,
//       lng: 9.501785,
//     },
//     {
//       lat: 54.762441,
//       lng: -1.32895,
//     },
//     {
//       lat: 37.220356,
//       lng: -79.417928,
//     },
//     {
//       lat: 42.6977082,
//       lng: 23.3218675,
//     },
//     {
//       lat: 40.463667,
//       lng: -3.74922,
//     },
//     {
//       lat: 42.331427,
//       lng: -83.0457538,
//     },
//     {
//       lat: 42.331427,
//       lng: -83.0457538,
//     },
//     {
//       lat: 39.26354,
//       lng: -77.634333,
//     },
//     {
//       lat: 26.6735771,
//       lng: -81.6304057,
//     },
//     {
//       lat: 50.0933997,
//       lng: -96.9298277,
//     },
//     {
//       lat: 36.2240786,
//       lng: -94.0971095,
//     },
//     {
//       lat: 41.9161361,
//       lng: -72.5578799,
//     },
//     {
//       lat: 42.4184296,
//       lng: -71.1061639,
//     },
//     {
//       lat: 42.4184296,
//       lng: -71.1061639,
//     },
//     {
//       lat: 34.0880507,
//       lng: -118.2965121,
//     },
//     {
//       lat: 24.453884,
//       lng: 54.3773438,
//     },
//     {
//       lat: 52.033157,
//       lng: 5.60933,
//     },
//     {
//       lat: 39.399872,
//       lng: -8.224454,
//     },
//     {
//       lat: 51.4598506,
//       lng: 9.7970061,
//     },
//     {
//       lat: 42.6907443,
//       lng: -83.4066132,
//     },
//     {
//       lat: 28.1843173,
//       lng: -81.6263273,
//     },
//     {
//       lat: 40.3772059,
//       lng: -105.5216651,
//     },
//     {
//       lat: 33.0151205,
//       lng: -96.6130486,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 27.6958511,
//       lng: -82.4536115,
//     },
//     {
//       lat: 34.4263886,
//       lng: -117.3008784,
//     },
//     {
//       lat: 35.1650918,
//       lng: -86.1738412,
//     },
//     {
//       lat: 30.6332618,
//       lng: -97.6779842,
//     },
//     {
//       lat: 33.7700504,
//       lng: -118.1937395,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 29.6927349,
//       lng: -95.3440978,
//     },
//     {
//       lat: 50.8275764,
//       lng: 3.284274,
//     },
//     {
//       lat: 54.6922529,
//       lng: 25.2717468,
//     },
//     {
//       lat: 48.0951929,
//       lng: 16.3064685,
//     },
//     {
//       lat: 31.9534397,
//       lng: -106.596737,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 35.8275672,
//       lng: -86.0716565,
//     },
//     {
//       lat: 35.8275672,
//       lng: -86.0716565,
//     },
//     {
//       lat: 35.5818423,
//       lng: -97.5608844,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 49.9555201,
//       lng: 6.5246643,
//     },
//     {
//       lat: 38.0937341,
//       lng: 13.3753848,
//     },
//     {
//       lat: 40.8955793,
//       lng: -73.9823142,
//     },
//     {
//       lat: 40.8955793,
//       lng: -73.9823142,
//     },
//     {
//       lat: 40.8955793,
//       lng: -73.9823142,
//     },
//     {
//       lat: 41.7709943,
//       lng: -87.8173532,
//     },
//     {
//       lat: 42.4390069,
//       lng: -123.3283925,
//     },
//     {
//       lat: 33.7449817,
//       lng: -117.8009406,
//     },
//     {
//       lat: 36.778261,
//       lng: -119.4179324,
//     },
//     {
//       lat: 42.4072107,
//       lng: -71.3824374,
//     },
//     {
//       lat: 33.8862024,
//       lng: -118.1132607,
//     },
//     {
//       lat: 37.3456227,
//       lng: -121.8847222,
//     },
//     {
//       lat: 29.8723253,
//       lng: -97.9847776,
//     },
//     {
//       lat: 33.4495641,
//       lng: -111.7674514,
//     },
//     {
//       lat: 33.4495641,
//       lng: -111.7674514,
//     },
//     {
//       lat: 52.3081686,
//       lng: 6.5213493,
//     },
//     {
//       lat: 33.4150485,
//       lng: -111.5495777,
//     },
//     {
//       lat: 60.128161,
//       lng: 18.643501,
//     },
//     {
//       lat: 43.9074328,
//       lng: -78.7175059,
//     },
//     {
//       lat: 38.976101,
//       lng: -95.300659,
//     },
//     {
//       lat: 38.976101,
//       lng: -95.300659,
//     },
//     {
//       lat: 45.415734,
//       lng: -122.7163273,
//     },
//     {
//       lat: 46.1212023,
//       lng: -60.2402512,
//     },
//     {
//       lat: 33.4483771,
//       lng: -112.0740373,
//     },
//     {
//       lat: 33.4535139,
//       lng: -111.9311265,
//     },
//     {
//       lat: 40.2544529,
//       lng: -83.7503537,
//     },
//     {
//       lat: 32.231872,
//       lng: -90.282825,
//     },
//     {
//       lat: 33.0390724,
//       lng: -117.267359,
//     },
//     {
//       lat: 43.0481221,
//       lng: -76.1474244,
//     },
//     {
//       lat: 46.1212023,
//       lng: -60.2402512,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 41.2799027,
//       lng: -72.8141185,
//     },
//     {
//       lat: 56.130366,
//       lng: -106.346771,
//     },
//     {
//       lat: 41.5756728,
//       lng: -72.3320051,
//     },
//     {
//       lat: 34.028548,
//       lng: -117.1645485,
//     },
//     {
//       lat: 31.3967874,
//       lng: -100.4400292,
//     },
//     {
//       lat: 38.966368,
//       lng: -95.2431561,
//     },
//     {
//       lat: 38.966368,
//       lng: -95.2431561,
//     },
//     {
//       lat: 49.9831536,
//       lng: 9.1506527,
//     },
//     {
//       lat: 42.8861811,
//       lng: -79.2528823,
//     },
//     {
//       lat: 51.209348,
//       lng: 3.2246995,
//     },
//     {
//       lat: 50.675942,
//       lng: 3.1687134,
//     },
//     {
//       lat: 40.1630941,
//       lng: -75.0601302,
//     },
//     {
//       lat: 51.5421412,
//       lng: 4.4701677,
//     },
//     {
//       lat: 14.6114708,
//       lng: -90.563609,
//     },
//     {
//       lat: 26.2963579,
//       lng: -80.1505993,
//     },
//     {
//       lat: 40.7304051,
//       lng: -74.2038772,
//     },
//     {
//       lat: 42.4528571,
//       lng: -75.0637746,
//     },
//     {
//       lat: 40.9700549,
//       lng: -81.6965168,
//     },
//     {
//       lat: 42.4528571,
//       lng: -75.0637746,
//     },
//     {
//       lat: 40.9700549,
//       lng: -81.6965168,
//     },
//     {
//       lat: 43.474388,
//       lng: 0.27621,
//     },
//     {
//       lat: 36.6002378,
//       lng: -121.8946761,
//     },
//     {
//       lat: 30.375321,
//       lng: 69.345116,
//     },
//     {
//       lat: 26.0869508,
//       lng: -80.3591224,
//     },
//     {
//       lat: 32.3487851,
//       lng: -111.0567756,
//     },
//     {
//       lat: 50.8363139,
//       lng: 4.3490946,
//     },
//     {
//       lat: 51.4689134,
//       lng: -0.1715112,
//     },
//     {
//       lat: 51.4689134,
//       lng: -0.1715112,
//     },
//     {
//       lat: 51.4689134,
//       lng: -0.1715112,
//     },
//     {
//       lat: 45.4885498,
//       lng: 9.2417243,
//     },
//     {
//       lat: 35.7817621,
//       lng: -88.9912557,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 45.3837409,
//       lng: -75.7478071,
//     },
//     {
//       lat: 33.6609169,
//       lng: -111.8871807,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 33.7831588,
//       lng: -118.3699123,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 33.7831588,
//       lng: -118.3699123,
//     },
//     {
//       lat: 43.9644223,
//       lng: -79.2564136,
//     },
//     {
//       lat: 49.3240078,
//       lng: -123.1019566,
//     },
//     {
//       lat: 42.4072107,
//       lng: -71.3824374,
//     },
//     {
//       lat: 46.5021897,
//       lng: 6.6855523,
//     },
//     {
//       lat: 49.303454,
//       lng: 14.158029,
//     },
//     {
//       lat: 49.303454,
//       lng: 14.158029,
//     },
//     {
//       lat: 49.303454,
//       lng: 14.158029,
//     },
//     {
//       lat: 49.303454,
//       lng: 14.158029,
//     },
//     {
//       lat: 49.303454,
//       lng: 14.158029,
//     },
//     {
//       lat: 36.1699412,
//       lng: -115.1398296,
//     },
//     {
//       lat: 33.6282187,
//       lng: -112.0628671,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 33.5185892,
//       lng: -86.8103567,
//     },
//     {
//       lat: 34.1209303,
//       lng: -116.4428491,
//     },
//     {
//       lat: 38.7698576,
//       lng: -90.7109161,
//     },
//     {
//       lat: 34.203297,
//       lng: -84.525174,
//     },
//     {
//       lat: 33.7952534,
//       lng: -118.2791902,
//     },
//     {
//       lat: 51.0447331,
//       lng: -114.0718831,
//     },
//     {
//       lat: 44.3148443,
//       lng: -85.6023643,
//     },
//     {
//       lat: 50.9757455,
//       lng: 2.9575634,
//     },
//     {
//       lat: 50.9757455,
//       lng: 2.9575634,
//     },
//     {
//       lat: 41.0814447,
//       lng: -81.5190053,
//     },
//     {
//       lat: 40.9937575,
//       lng: -81.3976961,
//     },
//     {
//       lat: 39.3578781,
//       lng: -78.0399994,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 33.6995254,
//       lng: -116.281534,
//     },
//     {
//       lat: 41.3253756,
//       lng: -72.1931338,
//     },
//     {
//       lat: 35.2270869,
//       lng: -80.8431267,
//     },
//     {
//       lat: 44.647128,
//       lng: 10.9252269,
//     },
//     {
//       lat: 39.3578781,
//       lng: -78.0399994,
//     },
//     {
//       lat: 51.6439705,
//       lng: -121.2950097,
//     },
//     {
//       lat: 41.7901024,
//       lng: -71.4838179,
//     },
//     {
//       lat: 28.0575699,
//       lng: -81.6803551,
//     },
//     {
//       lat: 28.0575699,
//       lng: -81.6803551,
//     },
//     {
//       lat: 28.0575699,
//       lng: -81.6803551,
//     },
//     {
//       lat: 36.122311,
//       lng: -83.492396,
//     },
//     {
//       lat: 55.378051,
//       lng: -3.435973,
//     },
//     {
//       lat: 40.7843907,
//       lng: -81.5521225,
//     },
//     {
//       lat: 40.7967244,
//       lng: -81.5215093,
//     },
//     {
//       lat: 43.0799383,
//       lng: -89.3676459,
//     },
//     {
//       lat: 51.6439705,
//       lng: -121.2950097,
//     },
//     {
//       lat: 43.0799383,
//       lng: -89.3676459,
//     },
//     {
//       lat: 42.9842755,
//       lng: -82.9529914,
//     },
//     {
//       lat: 47.8209301,
//       lng: -122.3151314,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 48.9352839,
//       lng: 8.7442765,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 40.5142026,
//       lng: -88.9906312,
//     },
//     {
//       lat: 46.9645573,
//       lng: -122.5960066,
//     },
//     {
//       lat: 51.444581,
//       lng: -0.335246,
//     },
//     {
//       lat: 39.2222405,
//       lng: -81.4933336,
//     },
//     {
//       lat: 35.7561401,
//       lng: -86.8879246,
//     },
//     {
//       lat: 42.6755298,
//       lng: -85.4478525,
//     },
//     {
//       lat: 42.5182428,
//       lng: -113.6280649,
//     },
//     {
//       lat: 42.5182428,
//       lng: -113.6280649,
//     },
//     {
//       lat: 39.394041,
//       lng: -119.7726758,
//     },
//     {
//       lat: 39.6978269,
//       lng: -86.111958,
//     },
//     {
//       lat: 38.939926,
//       lng: -119.9771868,
//     },
//     {
//       lat: 37.7749295,
//       lng: -122.4194155,
//     },
//     {
//       lat: 41.2754259,
//       lng: -81.7842568,
//     },
//     {
//       lat: 42.3086444,
//       lng: -83.482116,
//     },
//     {
//       lat: 42.6152323,
//       lng: -87.8341187,
//     },
//     {
//       lat: 33.7700504,
//       lng: -118.1937395,
//     },
//     {
//       lat: 33.7700504,
//       lng: -118.1937395,
//     },
//     {
//       lat: 33.7700504,
//       lng: -118.1937395,
//     },
//     {
//       lat: 33.7700504,
//       lng: -118.1937395,
//     },
//     {
//       lat: 42.24862,
//       lng: 2.93913,
//     },
//     {
//       lat: 31.7618778,
//       lng: -106.4850217,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 44.8787324,
//       lng: -93.1241219,
//     },
//     {
//       lat: 33.7685485,
//       lng: -112.3480734,
//     },
//     {
//       lat: 19.5429151,
//       lng: -155.6658568,
//     },
//     {
//       lat: 40.8534318,
//       lng: -73.4115091,
//     },
//     {
//       lat: 19.5429151,
//       lng: -155.6658568,
//     },
//     {
//       lat: 44.298992,
//       lng: -105.4743161,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 39.178085,
//       lng: -84.469886,
//     },
//     {
//       lat: 38.4600212,
//       lng: -93.2027036,
//     },
//     {
//       lat: 33.7475203,
//       lng: -116.9719684,
//     },
//     {
//       lat: 32.9712353,
//       lng: -117.1362413,
//     },
//     {
//       lat: 41.5173203,
//       lng: -72.7120402,
//     },
//     {
//       lat: 41.5623209,
//       lng: -72.6506488,
//     },
//     {
//       lat: 32.7203668,
//       lng: -79.9422341,
//     },
//     {
//       lat: 42.5834228,
//       lng: -71.8022955,
//     },
//     {
//       lat: 51.165691,
//       lng: 10.451526,
//     },
//     {
//       lat: 52.7095702,
//       lng: 4.8076765,
//     },
//     {
//       lat: 45.9626398,
//       lng: 12.6551362,
//     },
//     {
//       lat: 30.0629372,
//       lng: -95.2522729,
//     },
//     {
//       lat: 32.6088043,
//       lng: -97.3765445,
//     },
//     {
//       lat: 32.6088043,
//       lng: -97.3765445,
//     },
//     {
//       lat: 34.4480495,
//       lng: -119.242889,
//     },
//     {
//       lat: 13.1024788,
//       lng: -59.620437,
//     },
//     {
//       lat: 43.3442239,
//       lng: 5.4629924,
//     },
//     {
//       lat: 51.2461619,
//       lng: -0.5550501,
//     },
//     {
//       lat: 52.0648969,
//       lng: 4.3697732,
//     },
//     {
//       lat: 40.2015266,
//       lng: -74.4291135,
//     },
//     {
//       lat: 28.8985201,
//       lng: -81.2602506,
//     },
//     {
//       lat: 51.611309,
//       lng: 0.52068,
//     },
//     {
//       lat: 45.2788561,
//       lng: -92.9852183,
//     },
//     {
//       lat: 40.3137191,
//       lng: -78.9837568,
//     },
//     {
//       lat: 47.0646136,
//       lng: -122.817491,
//     },
//     {
//       lat: 36.4993007,
//       lng: -80.6072858,
//     },
//     {
//       lat: 47.0378741,
//       lng: -122.9006951,
//     },
//     {
//       lat: 45.7704359,
//       lng: -108.5054885,
//     },
//     {
//       lat: 35.3732921,
//       lng: -119.0187125,
//     },
//     {
//       lat: 42.5998139,
//       lng: -71.3672838,
//     },
//     {
//       lat: 31.1214226,
//       lng: -97.7195464,
//     },
//     {
//       lat: 47.8209301,
//       lng: -122.3151314,
//     },
//     {
//       lat: 41.3303692,
//       lng: 2.0466854,
//     },
//     {
//       lat: 44.9467714,
//       lng: -93.1114487,
//     },
//     {
//       lat: 3.4516467,
//       lng: -76.5319854,
//     },
//     {
//       lat: 40.6424161,
//       lng: -73.9940103,
//     },
//     {
//       lat: 41.4008724,
//       lng: -73.4159492,
//     },
//     {
//       lat: 40.5067737,
//       lng: -105.0116138,
//     },
//     {
//       lat: 41.554753,
//       lng: -88.0986709,
//     },
//     {
//       lat: 48.1075908,
//       lng: -1.6850563,
//     },
//     {
//       lat: 50.12533,
//       lng: 8.6744,
//     },
//     {
//       lat: 30.421309,
//       lng: -87.2169149,
//     },
//     {
//       lat: 34.9086753,
//       lng: -81.0503445,
//     },
//     {
//       lat: 45.5028134,
//       lng: -73.5796116,
//     },
//     {
//       lat: 34.4263886,
//       lng: -117.3008784,
//     },
//     {
//       lat: 42.7012906,
//       lng: -83.2219858,
//     },
//     {
//       lat: 41.2443334,
//       lng: -81.2657781,
//     },
//     {
//       lat: 47.516231,
//       lng: 14.550072,
//     },
//     {
//       lat: 40.6781784,
//       lng: -73.9441579,
//     },
//     {
//       lat: 34.095287,
//       lng: -118.1270146,
//     },
//     {
//       lat: 35.1103433,
//       lng: -107.8767684,
//     },
//     {
//       lat: 51.3670263,
//       lng: 6.180971,
//     },
//     {
//       lat: 36.204824,
//       lng: 138.252924,
//     },
//     {
//       lat: 53.5904194,
//       lng: 9.9997619,
//     },
//     {
//       lat: 33.681026,
//       lng: -117.2864178,
//     },
//     {
//       lat: 51.0992514,
//       lng: 3.3009016,
//     },
//     {
//       lat: 33.681026,
//       lng: -117.2864178,
//     },
//     {
//       lat: 30.7631963,
//       lng: -81.5804211,
//     },
//     {
//       lat: 40.1549846,
//       lng: -111.6042854,
//     },
//     {
//       lat: 33.4941704,
//       lng: -111.9260519,
//     },
//     {
//       lat: 33.9791793,
//       lng: -118.032844,
//     },
//     {
//       lat: 33.9791793,
//       lng: -118.032844,
//     },
//     {
//       lat: 34.2257359,
//       lng: -87.0779285,
//     },
//     {
//       lat: 38.3762974,
//       lng: -85.7625092,
//     },
//     {
//       lat: 37.6890134,
//       lng: -122.4556807,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 38.8716834,
//       lng: -77.170762,
//     },
//     {
//       lat: 38.9085472,
//       lng: -77.2405153,
//     },
//     {
//       lat: 24.5550593,
//       lng: -81.7799871,
//     },
//     {
//       lat: 41.1721458,
//       lng: -81.7473163,
//     },
//     {
//       lat: 38.9822282,
//       lng: -94.6707917,
//     },
//     {
//       lat: 43.653226,
//       lng: -79.3831843,
//     },
//     {
//       lat: 21.521757,
//       lng: -77.781167,
//     },
//     {
//       lat: 48.9740614,
//       lng: -123.073636,
//     },
//     {
//       lat: 49.69107,
//       lng: -124.9497018,
//     },
//     {
//       lat: -33.8688197,
//       lng: 151.2092955,
//     },
//     {
//       lat: 48.8572606,
//       lng: 1.8243269,
//     },
//     {
//       lat: 45.6252737,
//       lng: -92.3448964,
//     },
//     {
//       lat: 42.2878848,
//       lng: -96.7831529,
//     },
//     {
//       lat: 37.9102697,
//       lng: -122.0241203,
//     },
//     {
//       lat: 42.2878848,
//       lng: -96.7831529,
//     },
//     {
//       lat: 38.2526647,
//       lng: -85.7584557,
//     },
//     {
//       lat: 33.9902162,
//       lng: -117.9480345,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 38.5284841,
//       lng: -106.0060471,
//     },
//     {
//       lat: 38.5284841,
//       lng: -106.0060471,
//     },
//     {
//       lat: 44.0520691,
//       lng: -123.0867536,
//     },
//     {
//       lat: 48.8444504,
//       lng: 2.3326107,
//     },
//     {
//       lat: 45.532485,
//       lng: 9.2391888,
//     },
//     {
//       lat: 40.4406248,
//       lng: -79.9958864,
//     },
//     {
//       lat: 41.288906,
//       lng: -72.674364,
//     },
//     {
//       lat: 45.458626,
//       lng: 9.181873,
//     },
//     {
//       lat: 29.4241219,
//       lng: -98.4936282,
//     },
//     {
//       lat: 40.7533039,
//       lng: -74.2696736,
//     },
//     {
//       lat: 41.7634219,
//       lng: -72.6128339,
//     },
//     {
//       lat: 34.150027,
//       lng: -92.031127,
//     },
//     {
//       lat: 41.3909139,
//       lng: -72.8595447,
//     },
//     {
//       lat: 38.176193,
//       lng: -94.837027,
//     },
//     {
//       lat: 38.7791446,
//       lng: -90.769575,
//     },
//     {
//       lat: 52.132633,
//       lng: 5.291266,
//     },
//     {
//       lat: 51.5714842,
//       lng: 0.2189212,
//     },
//     {
//       lat: 51.723993,
//       lng: 0.3825072,
//     },
//     {
//       lat: 45.0500396,
//       lng: 7.6743004,
//     },
//     {
//       lat: 52.371496,
//       lng: 20.9285879,
//     },
//     {
//       lat: 52.371496,
//       lng: 20.9285879,
//     },
//     {
//       lat: 37.5354673,
//       lng: -5.0819224,
//     },
//     {
//       lat: 40.789142,
//       lng: -73.134961,
//     },
//     {
//       lat: 40.789142,
//       lng: -73.134961,
//     },
//     {
//       lat: 34.0560642,
//       lng: -117.6306107,
//     },
//     {
//       lat: 33.606934,
//       lng: -117.6827033,
//     },
//     {
//       lat: 40.6548085,
//       lng: -75.3541236,
//     },
//     {
//       lat: 49.2827291,
//       lng: -123.1207375,
//     },
//     {
//       lat: 49.2827291,
//       lng: -123.1207375,
//     },
//     {
//       lat: 47.4156824,
//       lng: -120.2931263,
//     },
//     {
//       lat: 40.7533039,
//       lng: -74.2696736,
//     },
//     {
//       lat: -26.0963217,
//       lng: 27.8076959,
//     },
//     {
//       lat: 52.371496,
//       lng: 20.9285879,
//     },
//     {
//       lat: 52.371496,
//       lng: 20.9285879,
//     },
//     {
//       lat: 51.165691,
//       lng: 10.451526,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 26.4537404,
//       lng: -81.8254772,
//     },
//     {
//       lat: 26.4537404,
//       lng: -81.8254772,
//     },
//     {
//       lat: 47.4826865,
//       lng: 8.2844067,
//     },
//     {
//       lat: 40.8478065,
//       lng: -74.5747248,
//     },
//     {
//       lat: 37.4315734,
//       lng: -78.6568942,
//     },
//     {
//       lat: 51.025477,
//       lng: 4.1019516,
//     },
//     {
//       lat: 47.4438932,
//       lng: -122.3492007,
//     },
//     {
//       lat: 35.0843859,
//       lng: -106.650422,
//     },
//     {
//       lat: 36.0839489,
//       lng: -87.794747,
//     },
//     {
//       lat: 51.0521273,
//       lng: 4.4204758,
//     },
//     {
//       lat: 50.942539,
//       lng: 6.9375935,
//     },
//     {
//       lat: 51.9273311,
//       lng: 5.3198854,
//     },
//     {
//       lat: 52.7576139,
//       lng: 4.8885393,
//     },
//     {
//       lat: 54.2637644,
//       lng: 10.0679504,
//     },
//     {
//       lat: 52.289125,
//       lng: -0.600363,
//     },
//     {
//       lat: 29.718512,
//       lng: -95.8191909,
//     },
//     {
//       lat: 57.0511215,
//       lng: 9.9169895,
//     },
//     {
//       lat: 36.0395247,
//       lng: -114.9817213,
//     },
//     {
//       lat: 38.052576,
//       lng: -84.7299464,
//     },
//     {
//       lat: 51.209348,
//       lng: 3.2246995,
//     },
//     {
//       lat: 41.7141997,
//       lng: -89.3287061,
//     },
//     {
//       lat: 41.8814046,
//       lng: -87.689347,
//     },
//     {
//       lat: 37.977561,
//       lng: -87.603953,
//     },
//     {
//       lat: 29.7857765,
//       lng: -95.8245093,
//     },
//     {
//       lat: 44.1109858,
//       lng: -79.5794265,
//     },
//     {
//       lat: 37.7643595,
//       lng: -121.9539616,
//     },
//     {
//       lat: 39.1200575,
//       lng: -76.5835441,
//     },
//     {
//       lat: 41.4378004,
//       lng: 22.6427428,
//     },
//     {
//       lat: 41.5058294,
//       lng: -73.0399172,
//     },
//     {
//       lat: 42.395444,
//       lng: -71.92183,
//     },
//     {
//       lat: 43.7071525,
//       lng: -79.6955048,
//     },
//     {
//       lat: 41.9219522,
//       lng: -91.4168371,
//     },
//     {
//       lat: 47.1003598,
//       lng: 15.704377,
//     },
//     {
//       lat: 35.7595731,
//       lng: -79.0192997,
//     },
//     {
//       lat: 45.47763,
//       lng: 8.71233,
//     },
//     {
//       lat: 46.5436199,
//       lng: -87.3953713,
//     },
//     {
//       lat: 46.5436199,
//       lng: -87.3953713,
//     },
//     {
//       lat: 37.3803107,
//       lng: -121.9555775,
//     },
//     {
//       lat: 40.7383644,
//       lng: -73.9975569,
//     },
//     {
//       lat: 33.5967815,
//       lng: -83.8601827,
//     },
//     {
//       lat: 33.7924557,
//       lng: -83.9704782,
//     },
//     {
//       lat: 33.5967815,
//       lng: -83.8601827,
//     },
//     {
//       lat: 48.5734053,
//       lng: 7.7521113,
//     },
//     {
//       lat: 51.313132,
//       lng: 9.4591696,
//     },
//     {
//       lat: 51.313132,
//       lng: 9.4591696,
//     },
//     {
//       lat: 33.0225052,
//       lng: -96.6354613,
//     },
//     {
//       lat: 35.4798757,
//       lng: -79.1802994,
//     },
//     {
//       lat: 35.1168131,
//       lng: -80.7236804,
//     },
//     {
//       lat: 41.0814447,
//       lng: -81.5190053,
//     },
//     {
//       lat: 41.1579438,
//       lng: -8.6291053,
//     },
//     {
//       lat: 42.626969,
//       lng: -83.8961779,
//     },
//     {
//       lat: 37.7643595,
//       lng: -121.9539616,
//     },
//     {
//       lat: 35.0028683,
//       lng: -85.3293108,
//     },
//     {
//       lat: 33.884558,
//       lng: -87.8028971,
//     },
//     {
//       lat: 33.884558,
//       lng: -87.8028971,
//     },
//     {
//       lat: 33.884558,
//       lng: -87.8028971,
//     },
//     {
//       lat: 47.278458,
//       lng: -93.6201919,
//     },
//     {
//       lat: 35.9690953,
//       lng: -95.8093248,
//     },
//     {
//       lat: 51.132377,
//       lng: 0.263695,
//     },
//     {
//       lat: 27.2730492,
//       lng: -80.3582261,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 38.5950532,
//       lng: -90.5462339,
//     },
//     {
//       lat: 38.5950532,
//       lng: -90.5462339,
//     },
//     {
//       lat: 27.950575,
//       lng: -82.4571776,
//     },
//     {
//       lat: 32.6956488,
//       lng: -97.0710105,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 38.5779555,
//       lng: -122.9888319,
//     },
//     {
//       lat: 44.6365107,
//       lng: -123.1059282,
//     },
//     {
//       lat: 52.2391565,
//       lng: 4.8344616,
//     },
//     {
//       lat: 50.8276293,
//       lng: 5.4523802,
//     },
//     {
//       lat: 43.0070691,
//       lng: -78.8984451,
//     },
//     {
//       lat: 55.8363955,
//       lng: 9.2415717,
//     },
//     {
//       lat: 33.5801103,
//       lng: -85.0766113,
//     },
//     {
//       lat: 37.5644615,
//       lng: -97.3522675,
//     },
//     {
//       lat: 51.2299337,
//       lng: 3.2028416,
//     },
//     {
//       lat: 51.025477,
//       lng: 4.1019516,
//     },
//     {
//       lat: 40.7829186,
//       lng: -73.8415147,
//     },
//     {
//       lat: 32.786511,
//       lng: -117.1011985,
//     },
//     {
//       lat: 33.9791793,
//       lng: -118.032844,
//     },
//     {
//       lat: 60.8678835,
//       lng: 26.7041601,
//     },
//     {
//       lat: 45.6554377,
//       lng: 10.2392653,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 48.5572749,
//       lng: 7.6544427,
//     },
//     {
//       lat: 43.5475771,
//       lng: -71.4067385,
//     },
//     {
//       lat: 36.7473114,
//       lng: -95.9808179,
//     },
//     {
//       lat: 53.5572771,
//       lng: -2.5176318,
//     },
//     {
//       lat: 33.7542553,
//       lng: -117.0568361,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 33.7542553,
//       lng: -117.0568361,
//     },
//     {
//       lat: 26.3321336,
//       lng: -80.2074468,
//     },
//     {
//       lat: 33.1651199,
//       lng: -97.0294532,
//     },
//     {
//       lat: 33.1651199,
//       lng: -97.0294532,
//     },
//     {
//       lat: 50.4496465,
//       lng: 4.4837748,
//     },
//     {
//       lat: 30.8782423,
//       lng: -97.4016697,
//     },
//     {
//       lat: 35.9879709,
//       lng: -95.7936537,
//     },
//     {
//       lat: 27.6305933,
//       lng: -82.5418523,
//     },
//     {
//       lat: 25.4358011,
//       lng: 81.846311,
//     },
//     {
//       lat: 38.7886238,
//       lng: -89.9107588,
//     },
//     {
//       lat: 38.7886238,
//       lng: -89.9107588,
//     },
//     {
//       lat: 38.7886238,
//       lng: -89.9107588,
//     },
//     {
//       lat: 33.5909679,
//       lng: -79.0276465,
//     },
//     {
//       lat: 49.6200009,
//       lng: 6.1185358,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 23.9845482,
//       lng: 38.2533052,
//     },
//     {
//       lat: 38.9120704,
//       lng: -77.4985673,
//     },
//     {
//       lat: 40.7967667,
//       lng: -74.4815438,
//     },
//     {
//       lat: 54.6933579,
//       lng: 25.2693157,
//     },
//     {
//       lat: 41.3660868,
//       lng: 2.1228774,
//     },
//     {
//       lat: 53.381129,
//       lng: -1.470085,
//     },
//     {
//       lat: 41.6946309,
//       lng: 44.8126665,
//     },
//     {
//       lat: 36.5619881,
//       lng: -86.2480443,
//     },
//     {
//       lat: 41.9141945,
//       lng: -88.3086867,
//     },
//     {
//       lat: 46.7866719,
//       lng: -92.1004852,
//     },
//     {
//       lat: 35.6334712,
//       lng: -80.1158813,
//     },
//     {
//       lat: 41.3325962,
//       lng: -73.2073358,
//     },
//     {
//       lat: 59.2471538,
//       lng: 17.9674026,
//     },
//     {
//       lat: 59.2471538,
//       lng: 17.9674026,
//     },
//     {
//       lat: 39.6136578,
//       lng: -86.1066526,
//     },
//     {
//       lat: 52.0396617,
//       lng: 4.2567152,
//     },
//     {
//       lat: 33.8673877,
//       lng: 35.5157993,
//     },
//     {
//       lat: 41.2947345,
//       lng: -95.8212714,
//     },
//     {
//       lat: 38.9310796,
//       lng: -94.6004163,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 51.5073509,
//       lng: -0.1277583,
//     },
//     {
//       lat: 41.2619444,
//       lng: -95.8608333,
//     },
//     {
//       lat: 33.6638439,
//       lng: -117.9047429,
//     },
//     {
//       lat: 28.0947604,
//       lng: -80.6848494,
//     },
//     {
//       lat: 28.0947604,
//       lng: -80.6848494,
//     },
//     {
//       lat: 36.3108407,
//       lng: -119.2502347,
//     },
//     {
//       lat: 36.3302284,
//       lng: -119.2920585,
//     },
//     {
//       lat: 41.3753049,
//       lng: -8.7004037,
//     },
//     {
//       lat: 41.3517302,
//       lng: -8.7478619,
//     },
//     {
//       lat: 38.9906657,
//       lng: -77.026088,
//     },
//     {
//       lat: 38.92526,
//       lng: -77.20422,
//     },
//     {
//       lat: 38.92526,
//       lng: -77.20422,
//     },
//     {
//       lat: 41.0018197,
//       lng: -80.587738,
//     },
//     {
//       lat: 47.8223137,
//       lng: -122.8757199,
//     },
//     {
//       lat: 39.6607978,
//       lng: -79.9680982,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 51.3434357,
//       lng: 5.4753963,
//     },
//     {
//       lat: 51.2277411,
//       lng: 6.7734556,
//     },
//     {
//       lat: 37.4847955,
//       lng: -122.228141,
//     },
//     {
//       lat: 40.249932,
//       lng: -75.274657,
//     },
//     {
//       lat: 39.391803,
//       lng: -104.724784,
//     },
//     {
//       lat: 26.4614625,
//       lng: -80.0728201,
//     },
//     {
//       lat: 26.4614625,
//       lng: -80.0728201,
//     },
//     {
//       lat: 40.8306028,
//       lng: -85.2416379,
//     },
//     {
//       lat: 41.4641806,
//       lng: -91.8382273,
//     },
//     {
//       lat: 38.1312523,
//       lng: -105.9273318,
//     },
//     {
//       lat: 49.129121,
//       lng: -122.8591098,
//     },
//     {
//       lat: 49.1913466,
//       lng: -122.8490125,
//     },
//     {
//       lat: 49.1913466,
//       lng: -122.8490125,
//     },
//     {
//       lat: 32.1796963,
//       lng: -80.7177818,
//     },
//     {
//       lat: 40.7617658,
//       lng: -111.8761759,
//     },
//     {
//       lat: 44.6718605,
//       lng: 10.6414492,
//     },
//     {
//       lat: 46.7866719,
//       lng: -92.1004852,
//     },
//     {
//       lat: 46.7866719,
//       lng: -92.1004852,
//     },
//     {
//       lat: 37.4137536,
//       lng: -79.1422464,
//     },
//     {
//       lat: 50.5193403,
//       lng: 2.6266276,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 36.9125857,
//       lng: -104.415326,
//     },
//     {
//       lat: 33.9130466,
//       lng: -116.9662333,
//     },
//     {
//       lat: 39.9261665,
//       lng: -85.913883,
//     },
//     {
//       lat: 41.5623209,
//       lng: -72.6506488,
//     },
//     {
//       lat: 26.6845104,
//       lng: -80.6675577,
//     },
//     {
//       lat: 41.4970432,
//       lng: -72.5570348,
//     },
//     {
//       lat: 33.6188829,
//       lng: -117.9298493,
//     },
//     {
//       lat: 31.9522498,
//       lng: -106.6655078,
//     },
//     {
//       lat: 47.5537361,
//       lng: -122.3474433,
//     },
//     {
//       lat: 52.3430448,
//       lng: 4.8380237,
//     },
//     {
//       lat: 48.8837304,
//       lng: 2.2882308,
//     },
//     {
//       lat: 40.249932,
//       lng: -75.274657,
//     },
//     {
//       lat: 40.9293759,
//       lng: -73.1025502,
//     },
//     {
//       lat: 35.6150716,
//       lng: -87.0352831,
//     },
//     {
//       lat: 39.5186002,
//       lng: -104.7613633,
//     },
//     {
//       lat: 45.6668517,
//       lng: 12.2430617,
//     },
//     {
//       lat: 34.0868314,
//       lng: -118.1607335,
//     },
//     {
//       lat: 28.4415021,
//       lng: -81.5543722,
//     },
//     {
//       lat: 26.3683064,
//       lng: -80.1289321,
//     },
//     {
//       lat: 33.8622366,
//       lng: -118.3995194,
//     },
//     {
//       lat: 38.1503113,
//       lng: -92.6179621,
//     },
//     {
//       lat: 35.939471,
//       lng: -78.601319,
//     },
//     {
//       lat: 41.959293,
//       lng: -91.517751,
//     },
//     {
//       lat: 33.8937913,
//       lng: 35.5017767,
//     },
//     {
//       lat: 33.8937913,
//       lng: 35.5017767,
//     },
//     {
//       lat: 38.7222524,
//       lng: -9.1393366,
//     },
//     {
//       lat: 33.8937913,
//       lng: 35.5017767,
//     },
//     {
//       lat: 38.8097107,
//       lng: -97.6083931,
//     },
//     {
//       lat: 35.939471,
//       lng: -78.601319,
//     },
//     {
//       lat: 30.1005541,
//       lng: -95.6090214,
//     },
//     {
//       lat: 37.8227128,
//       lng: -82.8336845,
//     },
//     {
//       lat: 41.5200523,
//       lng: -81.4887315,
//     },
//     {
//       lat: 41.5120772,
//       lng: -83.581615,
//     },
//     {
//       lat: 41.5120772,
//       lng: -83.581615,
//     },
//     {
//       lat: 39.2788772,
//       lng: -80.3553594,
//     },
//     {
//       lat: 43.7965838,
//       lng: -79.3249364,
//     },
//     {
//       lat: 35.0815702,
//       lng: -106.6078672,
//     },
//     {
//       lat: 50.6087751,
//       lng: 3.3884036,
//     },
//     {
//       lat: 33.9698809,
//       lng: -84.2214706,
//     },
//     {
//       lat: 33.9698809,
//       lng: -84.2214706,
//     },
//     {
//       lat: 50.937531,
//       lng: 6.9602786,
//     },
//     {
//       lat: -14.235004,
//       lng: -51.92528,
//     },
//     {
//       lat: 33.606677,
//       lng: -112.265284,
//     },
//     {
//       lat: 33.8360034,
//       lng: -79.0478143,
//     },
//     {
//       lat: 40.9383992,
//       lng: -72.8816628,
//     },
//     {
//       lat: 35.6709727,
//       lng: -80.4742261,
//     },
//     {
//       lat: 48.2571308,
//       lng: 16.3976645,
//     },
//     {
//       lat: 57.354611,
//       lng: 9.942907,
//     },
//     {
//       lat: 33.0265227,
//       lng: -80.0510256,
//     },
//     {
//       lat: 35.2219971,
//       lng: -101.8312969,
//     },
//     {
//       lat: 55.953252,
//       lng: -3.188267,
//     },
//     {
//       lat: 10.8505159,
//       lng: 76.2710833,
//     },
//     {
//       lat: 44.7780273,
//       lng: -68.8710484,
//     },
//     {
//       lat: 33.6091465,
//       lng: -112.2701806,
//     },
//     {
//       lat: 33.435163,
//       lng: -83.657377,
//     },
//     {
//       lat: 44.9374831,
//       lng: -93.2009998,
//     },
//     {
//       lat: 44.9374831,
//       lng: -93.2009998,
//     },
//     {
//       lat: 41.2562645,
//       lng: -80.4526581,
//     },
//     {
//       lat: 36.6363723,
//       lng: -79.3840365,
//     },
//     {
//       lat: 50.937531,
//       lng: 6.9602786,
//     },
//     {
//       lat: 50.2103605,
//       lng: 15.825211,
//     },
//     {
//       lat: 40.688432,
//       lng: -75.2207323,
//     },
//     {
//       lat: 35.0712154,
//       lng: -85.0817091,
//     },
//     {
//       lat: 37.1161409,
//       lng: -1.8388447,
//     },
//     {
//       lat: 44.0279847,
//       lng: 1.0426014,
//     },
//     {
//       lat: 27.150325,
//       lng: -80.234835,
//     },
//     {
//       lat: 28.4423799,
//       lng: -81.2421052,
//     },
//     {
//       lat: 51.330018,
//       lng: 4.838376,
//     },
//     {
//       lat: 40.3947236,
//       lng: -95.8322191,
//     },
//     {
//       lat: 42.4525157,
//       lng: -89.0723317,
//     },
//     {
//       lat: 40.3947236,
//       lng: -95.8322191,
//     },
//     {
//       lat: 42.4525157,
//       lng: -89.0723317,
//     },
//     {
//       lat: 38.2312502,
//       lng: -77.2302062,
//     },
//     {
//       lat: 38.2664512,
//       lng: -77.1807842,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 50.79059,
//       lng: 4.0405638,
//     },
//     {
//       lat: 38.4783198,
//       lng: -107.8761738,
//     },
//     {
//       lat: 41.9027835,
//       lng: 12.4963655,
//     },
//     {
//       lat: 41.9027835,
//       lng: 12.4963655,
//     },
//     {
//       lat: 50.2103605,
//       lng: 15.825211,
//     },
//     {
//       lat: 43.7479,
//       lng: -122.4870536,
//     },
//     {
//       lat: 37.7719074,
//       lng: -87.1111676,
//     },
//     {
//       lat: 40.5865396,
//       lng: -122.3916754,
//     },
//     {
//       lat: 50.7952811,
//       lng: 4.1180489,
//     },
//     {
//       lat: 50.7952811,
//       lng: 4.1180489,
//     },
//     {
//       lat: 51.9658097,
//       lng: 5.6637864,
//     },
//     {
//       lat: 36.7504638,
//       lng: -76.3654921,
//     },
//     {
//       lat: 54.6910248,
//       lng: 25.2753229,
//     },
//     {
//       lat: 34.0421125,
//       lng: -117.9260289,
//     },
//     {
//       lat: 34.0421125,
//       lng: -117.9260289,
//     },
//     {
//       lat: 41.3503671,
//       lng: -72.405239,
//     },
//     {
//       lat: 41.3838782,
//       lng: -72.9026064,
//     },
//     {
//       lat: 45.6965992,
//       lng: 9.6266852,
//     },
//     {
//       lat: 51.7217152,
//       lng: 0.4509747,
//     },
//     {
//       lat: 51.3837434,
//       lng: 0.49665,
//     },
//     {
//       lat: 54.720642,
//       lng: 25.2219079,
//     },
//     {
//       lat: 54.7184028,
//       lng: 25.3082702,
//     },
//     {
//       lat: 34.1808392,
//       lng: -118.3089661,
//     },
//     {
//       lat: 34.1808392,
//       lng: -118.3089661,
//     },
//     {
//       lat: 39.2667418,
//       lng: -81.5615135,
//     },
//     {
//       lat: 35.1751148,
//       lng: -82.1892795,
//     },
//     {
//       lat: 32.6769628,
//       lng: -117.0004146,
//     },
//     {
//       lat: 32.6769628,
//       lng: -117.0004146,
//     },
//     {
//       lat: 35.2467557,
//       lng: -80.7592654,
//     },
//     {
//       lat: 44.9374831,
//       lng: -93.2009998,
//     },
//     {
//       lat: 44.9334686,
//       lng: -93.0601241,
//     },
//     {
//       lat: 34.1655778,
//       lng: -118.7563988,
//     },
//     {
//       lat: 47.1698197,
//       lng: -122.6026274,
//     },
//     {
//       lat: 43.6745179,
//       lng: -116.293216,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 48.5561689,
//       lng: 16.0842305,
//     },
//     {
//       lat: 33.7090212,
//       lng: -117.9544069,
//     },
//     {
//       lat: 32.735687,
//       lng: -97.1080656,
//     },
//     {
//       lat: 30.267153,
//       lng: -97.7430608,
//     },
//     {
//       lat: 45.9225982,
//       lng: -64.65424,
//     },
//     {
//       lat: 41.278637,
//       lng: -73.0265277,
//     },
//     {
//       lat: 29.5341896,
//       lng: -81.255883,
//     },
//     {
//       lat: 47.290662,
//       lng: -122.5219294,
//     },
//     {
//       lat: 42.7134508,
//       lng: -87.8086921,
//     },
//     {
//       lat: 47.6695409,
//       lng: 8.6137445,
//     },
//     {
//       lat: 37.1746177,
//       lng: -3.6078905,
//     },
//     {
//       lat: 41.808434,
//       lng: -71.3369972,
//     },
//     {
//       lat: 39.9623501,
//       lng: -81.9908985,
//     },
//     {
//       lat: 45.6257379,
//       lng: -122.6760886,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 32.7459645,
//       lng: -96.9977846,
//     },
//     {
//       lat: 51.0675633,
//       lng: 7.0332162,
//     },
//     {
//       lat: 35.3288589,
//       lng: -82.4696699,
//     },
//     {
//       lat: 51.0996057,
//       lng: 4.2404586,
//     },
//     {
//       lat: 44.4223762,
//       lng: -0.2194651,
//     },
//     {
//       lat: 37.5619555,
//       lng: -97.3464819,
//     },
//     {
//       lat: 26.6719209,
//       lng: -80.2533334,
//     },
//     {
//       lat: 41.7100852,
//       lng: -86.1690528,
//     },
//     {
//       lat: 41.3920531,
//       lng: -84.1434724,
//     },
//     {
//       lat: 25.2854473,
//       lng: 51.5310398,
//     },
//     {
//       lat: 41.1585876,
//       lng: -85.0114808,
//     },
//     {
//       lat: 31.7618778,
//       lng: -106.4850217,
//     },
//     {
//       lat: 41.0106563,
//       lng: -72.4850859,
//     },
//     {
//       lat: 38.4952367,
//       lng: -121.4519144,
//     },
//     {
//       lat: 38.4952367,
//       lng: -121.4519144,
//     },
//     {
//       lat: 38.4952367,
//       lng: -121.4519144,
//     },
//     {
//       lat: 50.4782719,
//       lng: 4.2121153,
//     },
//     {
//       lat: 47.5044648,
//       lng: 12.1952873,
//     },
//     {
//       lat: 34.1705609,
//       lng: -118.8375937,
//     },
//     {
//       lat: 44.4866783,
//       lng: -88.0607565,
//     },
//     {
//       lat: 42.4968878,
//       lng: -82.9313954,
//     },
//     {
//       lat: 28.9684619,
//       lng: -82.0245573,
//     },
//     {
//       lat: 43.7613629,
//       lng: -96.7403289,
//     },
//     {
//       lat: 52.2574671,
//       lng: 21.0510121,
//     },
//     {
//       lat: 41.0106563,
//       lng: -72.4850859,
//     },
//     {
//       lat: 40.7492783,
//       lng: -73.989101,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 39.399872,
//       lng: -8.224454,
//     },
//     {
//       lat: 41.1579438,
//       lng: -8.6291053,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 40.7491267,
//       lng: -73.9893387,
//     },
//     {
//       lat: 32.9741275,
//       lng: -80.0201145,
//     },
//     {
//       lat: 44.9167519,
//       lng: -68.6276101,
//     },
//     {
//       lat: 41.1517233,
//       lng: -85.0033283,
//     },
//     {
//       lat: 33.4894077,
//       lng: -111.9812431,
//     },
//     {
//       lat: 27.007376,
//       lng: -82.0253663,
//     },
//     {
//       lat: 33.4483771,
//       lng: -112.0740373,
//     },
//     {
//       lat: 26.1224386,
//       lng: -80.1373174,
//     },
//     {
//       lat: 26.1224386,
//       lng: -80.1373174,
//     },
//     {
//       lat: 26.1224386,
//       lng: -80.1373174,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 42.4972583,
//       lng: -82.9371409,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 47.9812824,
//       lng: 10.1774906,
//     },
//     {
//       lat: 37.4315734,
//       lng: -78.6568942,
//     },
//     {
//       lat: 43.006371,
//       lng: -79.125327,
//     },
//     {
//       lat: 49.2827291,
//       lng: -123.1207375,
//     },
//     {
//       lat: 40.9714448,
//       lng: -82.1098748,
//     },
//     {
//       lat: 38.8375215,
//       lng: -120.8958242,
//     },
//     {
//       lat: 28.5575539,
//       lng: -82.0625052,
//     },
//     {
//       lat: 42.4131049,
//       lng: -123.3460341,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 34.2586812,
//       lng: -118.288508,
//     },
//     {
//       lat: 35.0734317,
//       lng: -106.6586982,
//     },
//     {
//       lat: 35.0734317,
//       lng: -106.6586982,
//     },
//     {
//       lat: 51.6450216,
//       lng: 4.8631799,
//     },
//     {
//       lat: 27.9739723,
//       lng: -82.7418154,
//     },
//     {
//       lat: 37.5665009,
//       lng: 22.799064,
//     },
//     {
//       lat: 38.5347193,
//       lng: -105.9989022,
//     },
//     {
//       lat: 51.5395234,
//       lng: 0.1254319,
//     },
//     {
//       lat: 51.5395234,
//       lng: 0.1254319,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 25.7904657,
//       lng: -108.985882,
//     },
//     {
//       lat: 29.0491048,
//       lng: -95.7182716,
//     },
//     {
//       lat: 49.851343,
//       lng: -99.9706889,
//     },
//     {
//       lat: 34.4263886,
//       lng: -117.3008784,
//     },
//     {
//       lat: 42.573948,
//       lng: 20.9229724,
//     },
//     {
//       lat: 41.0017643,
//       lng: -73.6656834,
//     },
//     {
//       lat: 42.0135375,
//       lng: -111.8071694,
//     },
//     {
//       lat: 42.3625953,
//       lng: -71.3614484,
//     },
//     {
//       lat: 34.1489719,
//       lng: -118.451357,
//     },
//     {
//       lat: 52.4395469,
//       lng: 9.7405673,
//     },
//     {
//       lat: 50.8267705,
//       lng: 3.2774683,
//     },
//     {
//       lat: 38.2149294,
//       lng: -75.6721914,
//     },
//     {
//       lat: 38.5471327,
//       lng: -122.8163802,
//     },
//     {
//       lat: 29.383845,
//       lng: -94.9027002,
//     },
//     {
//       lat: 49.2827291,
//       lng: -123.1207375,
//     },
//     {
//       lat: 49.2827291,
//       lng: -123.1207375,
//     },
//     {
//       lat: 44.9089611,
//       lng: 21.4262876,
//     },
//     {
//       lat: 37.9577273,
//       lng: -0.7036573,
//     },
//     {
//       lat: 41.3486033,
//       lng: -72.2021806,
//     },
//     {
//       lat: 41.9093336,
//       lng: -87.7100647,
//     },
//     {
//       lat: 41.9024826,
//       lng: -87.7418842,
//     },
//     {
//       lat: 34.134728,
//       lng: -116.3130661,
//     },
//     {
//       lat: 45.7790066,
//       lng: 4.8348171,
//     },
//     {
//       lat: 55.9138751,
//       lng: 21.052822,
//     },
//     {
//       lat: 55.9138751,
//       lng: 21.052822,
//     },
//     {
//       lat: 55.9138751,
//       lng: 21.052822,
//     },
//     {
//       lat: 32.2313793,
//       lng: -88.3044908,
//     },
//     {
//       lat: 37.7749295,
//       lng: -122.4194155,
//     },
//     {
//       lat: 55.9138751,
//       lng: 21.052822,
//     },
//     {
//       lat: 44.5230895,
//       lng: -78.7315245,
//     },
//     {
//       lat: 44.5337469,
//       lng: -78.9006484,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 42.324204,
//       lng: -83.400211,
//     },
//     {
//       lat: 28.0499952,
//       lng: -82.1165296,
//     },
//     {
//       lat: 55.9138751,
//       lng: 21.052822,
//     },
//     {
//       lat: 44.3911448,
//       lng: -89.8116995,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 41.079273,
//       lng: -85.1393513,
//     },
//     {
//       lat: 32.7218072,
//       lng: -84.0132433,
//     },
//     {
//       lat: 52.4030229,
//       lng: 13.0741641,
//     },
//     {
//       lat: 30.1805306,
//       lng: -85.684578,
//     },
//     {
//       lat: 47.4450221,
//       lng: 8.703338,
//     },
//     {
//       lat: 43.5349629,
//       lng: 13.2578492,
//     },
//     {
//       lat: 33.8111975,
//       lng: -112.0963078,
//     },
//     {
//       lat: 27.703626,
//       lng: -82.6781306,
//     },
//     {
//       lat: 33.6217038,
//       lng: -112.4547107,
//     },
//     {
//       lat: 33.843885,
//       lng: -118.360682,
//     },
//     {
//       lat: 39.6961606,
//       lng: -105.0382076,
//     },
//     {
//       lat: 39.6961606,
//       lng: -105.0382076,
//     },
//     {
//       lat: 43.0125274,
//       lng: -83.6874562,
//     },
//     {
//       lat: 49.8996282,
//       lng: 8.8551008,
//     },
//     {
//       lat: 42.4341507,
//       lng: -123.3823754,
//     },
//     {
//       lat: 41.8019185,
//       lng: -72.3039404,
//     },
//     {
//       lat: 39.9611755,
//       lng: -82.9987942,
//     },
//     {
//       lat: 42.8142432,
//       lng: -73.9395687,
//     },
//     {
//       lat: 42.9236823,
//       lng: -124.4500174,
//     },
//     {
//       lat: 43.2814132,
//       lng: -83.5296774,
//     },
//     {
//       lat: 33.6737656,
//       lng: -117.8178879,
//     },
//     {
//       lat: 50.937531,
//       lng: 6.9602786,
//     },
//     {
//       lat: 35.1427533,
//       lng: -120.6412827,
//     },
//     {
//       lat: 37.8637376,
//       lng: -122.2682245,
//     },
//     {
//       lat: 33.9318591,
//       lng: -117.946137,
//     },
//     {
//       lat: 38.5581072,
//       lng: -91.0120878,
//     },
//     {
//       lat: 41.3789985,
//       lng: 2.1399129,
//     },
//     {
//       lat: 42.842256,
//       lng: -84.5691487,
//     },
//     {
//       lat: 39.0452891,
//       lng: -81.8541392,
//     },
//     {
//       lat: 34.2429261,
//       lng: -86.8671293,
//     },
//     {
//       lat: 37.0965278,
//       lng: -113.5684164,
//     },
//     {
//       lat: 49.2827291,
//       lng: -123.1207375,
//     },
//     {
//       lat: 41.9645406,
//       lng: -83.860763,
//     },
//     {
//       lat: 41.9645406,
//       lng: -83.860763,
//     },
//     {
//       lat: 32.7721946,
//       lng: -117.007799,
//     },
//     {
//       lat: 46.7297771,
//       lng: -117.1817377,
//     },
//     {
//       lat: 43.2856337,
//       lng: -71.4672651,
//     },
//     {
//       lat: 41.9645406,
//       lng: -83.860763,
//     },
//     {
//       lat: 41.9867114,
//       lng: -83.8310513,
//     },
//     {
//       lat: 35.0486272,
//       lng: -106.6544831,
//     },
//     {
//       lat: 28.5225159,
//       lng: 77.2082125,
//     },
//     {
//       lat: 51.8317644,
//       lng: 4.1591836,
//     },
//     {
//       lat: -26.0583,
//       lng: 27.98439,
//     },
//     {
//       lat: -26.0583,
//       lng: 27.98439,
//     },
//     {
//       lat: 51.0875251,
//       lng: 4.2539243,
//     },
//     {
//       lat: 51.132377,
//       lng: 0.263695,
//     },
//     {
//       lat: 51.1562658,
//       lng: 2.9676422,
//     },
//     {
//       lat: 51.1562658,
//       lng: 2.9676422,
//     },
//     {
//       lat: 52.266678,
//       lng: -2.9053645,
//     },
//     {
//       lat: 52.266678,
//       lng: -2.9053645,
//     },
//     {
//       lat: 27.6648274,
//       lng: -81.5157535,
//     },
//     {
//       lat: 32.7766642,
//       lng: -96.7969879,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 40.2971934,
//       lng: -111.6804654,
//     },
//     {
//       lat: 34.3555988,
//       lng: -86.3538143,
//     },
//     {
//       lat: 36.8687921,
//       lng: -81.5596427,
//     },
//     {
//       lat: 34.0686208,
//       lng: -117.9389526,
//     },
//     {
//       lat: 34.0686208,
//       lng: -117.9389526,
//     },
//     {
//       lat: 41.9239178,
//       lng: -89.0687073,
//     },
//     {
//       lat: 49.4205289,
//       lng: 8.4248742,
//     },
//     {
//       lat: 32.7077356,
//       lng: -97.4376739,
//     },
//     {
//       lat: 50.503887,
//       lng: 4.469936,
//     },
//     {
//       lat: 39.4233946,
//       lng: -81.4350447,
//     },
//     {
//       lat: 52.0779634,
//       lng: 4.7473234,
//     },
//     {
//       lat: 55.9605318,
//       lng: -3.1232722,
//     },
//     {
//       lat: 55.9605318,
//       lng: -3.1232722,
//     },
//     {
//       lat: 55.9605318,
//       lng: -3.1232722,
//     },
//     {
//       lat: 32.0808989,
//       lng: -81.091203,
//     },
//     {
//       lat: 32.0808989,
//       lng: -81.091203,
//     },
//     {
//       lat: 32.0808989,
//       lng: -81.091203,
//     },
//     {
//       lat: 35.0843859,
//       lng: -106.650422,
//     },
//     {
//       lat: 27.349941,
//       lng: -82.45853,
//     },
//     {
//       lat: 27.349941,
//       lng: -82.45853,
//     },
//     {
//       lat: 51.9367113,
//       lng: -2.4102108,
//     },
//     {
//       lat: 32.7639897,
//       lng: -117.094627,
//     },
//     {
//       lat: 34.2071288,
//       lng: -119.036446,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 29.7604267,
//       lng: -95.3698028,
//     },
//     {
//       lat: 48.39108,
//       lng: -114.212925,
//     },
//     {
//       lat: 41.6763545,
//       lng: -86.2519898,
//     },
//     {
//       lat: 32.2226066,
//       lng: -110.9747108,
//     },
//     {
//       lat: 40.1462213,
//       lng: -74.7118264,
//     },
//     {
//       lat: 33.9672981,
//       lng: -84.4419432,
//     },
//     {
//       lat: 50.4564901,
//       lng: 4.2385389,
//     },
//     {
//       lat: 36.0395247,
//       lng: -114.9817213,
//     },
//     {
//       lat: 41.8781136,
//       lng: -87.6297982,
//     },
//     {
//       lat: 51.476111,
//       lng: -0.1826413,
//     },
//     {
//       lat: 45.4064349,
//       lng: 11.8767611,
//     },
//     {
//       lat: 51.476111,
//       lng: -0.1826413,
//     },
//     {
//       lat: 40.1053196,
//       lng: -85.6802541,
//     },
//     {
//       lat: 45.4064349,
//       lng: 11.8767611,
//     },
//     {
//       lat: 40.1053196,
//       lng: -85.6802541,
//     },
//     {
//       lat: 51.476111,
//       lng: -0.1826413,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 41.5964869,
//       lng: -72.8776013,
//     },
//     {
//       lat: 10.4805937,
//       lng: -66.9036063,
//     },
//     {
//       lat: 50.7890127,
//       lng: 3.2032784,
//     },
//     {
//       lat: 50.7890127,
//       lng: 3.2032784,
//     },
//     {
//       lat: 50.7890127,
//       lng: 3.2032784,
//     },
//     {
//       lat: 45.532485,
//       lng: 9.2391888,
//     },
//     {
//       lat: 44.0265525,
//       lng: -116.9629378,
//     },
//     {
//       lat: 40.8450075,
//       lng: -73.8588599,
//     },
//     {
//       lat: 40.8719871,
//       lng: -73.176977,
//     },
//     {
//       lat: 38.6308115,
//       lng: -94.584835,
//     },
//     {
//       lat: 40.9008923,
//       lng: -80.8567501,
//     },
//     {
//       lat: 28.4412244,
//       lng: -82.5401641,
//     },
//     {
//       lat: 44.7677424,
//       lng: -93.2777226,
//     },
//     {
//       lat: 44.7677424,
//       lng: -93.2777226,
//     },
//     {
//       lat: 29.2108147,
//       lng: -81.0228331,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 25.7616798,
//       lng: -80.1917902,
//     },
//     {
//       lat: 52.209839,
//       lng: 4.512362,
//     },
//     {
//       lat: 52.209839,
//       lng: 4.512362,
//     },
//     {
//       lat: 40.8832824,
//       lng: -73.1581273,
//     },
//     {
//       lat: 37.09024,
//       lng: -95.712891,
//     },
//     {
//       lat: 48.3851834,
//       lng: 16.2114752,
//     },
//     {
//       lat: 51.2108986,
//       lng: 6.8071834,
//     },
//     {
//       lat: 42.6764417,
//       lng: -89.0328485,
//     },
//     {
//       lat: 27.9658533,
//       lng: -82.8001026,
//     },
//     {
//       lat: 40.4371212,
//       lng: -79.8945835,
//     },
//     {
//       lat: 37.6871761,
//       lng: -97.330053,
//     },
//     {
//       lat: 37.6788254,
//       lng: -97.3031224,
//     },
//     {
//       lat: 43.1725552,
//       lng: -79.0358782,
//     },
//     {
//       lat: 48.8681009,
//       lng: 8.683194,
//     },
//     {
//       lat: 45.4408474,
//       lng: 12.3155151,
//     },
//     {
//       lat: 45.477581,
//       lng: 9.1973511,
//     },
//     {
//       lat: 45.4408474,
//       lng: 12.3155151,
//     },
//     {
//       lat: 47.398357,
//       lng: 10.9190759,
//     },
//     {
//       lat: 47.398357,
//       lng: 10.9190759,
//     },
//     {
//       lat: 40.8059699,
//       lng: -73.3970022,
//     },
//     {
//       lat: 45.4064349,
//       lng: 11.8767611,
//     },
//     {
//       lat: 50.4654862,
//       lng: 4.8212918,
//     },
//     {
//       lat: 28.1795599,
//       lng: -82.473854,
//     },
//     {
//       lat: 51.504286,
//       lng: -3.576945,
//     },
//     {
//       lat: 37.4746386,
//       lng: -105.8758893,
//     },
//     {
//       lat: 33.8662985,
//       lng: -84.301232,
//     },
//     {
//       lat: 33.8662985,
//       lng: -84.301232,
//     },
//     {
//       lat: 47.741901,
//       lng: -65.7113519,
//     },
//     {
//       lat: 53.1551439,
//       lng: -2.1850536,
//     },
//     {
//       lat: 53.1551439,
//       lng: -2.1850536,
//     },
//     {
//       lat: 32.6666667,
//       lng: -16.75,
//     },
//     {
//       lat: 48.0795365,
//       lng: -123.1018437,
//     },
//     {
//       lat: 40.0390435,
//       lng: -76.4282196,
//     },
//     {
//       lat: 34.134728,
//       lng: -116.3130661,
//     },
//     {
//       lat: 33.276361,
//       lng: -117.2972552,
//     },
//     {
//       lat: 32.5612335,
//       lng: -95.8780161,
//     },
//     {
//       lat: 32.5612335,
//       lng: -95.8780161,
//     },
//     {
//       lat: 34.2477359,
//       lng: -118.4554863,
//     },
//     {
//       lat: -28.7738773,
//       lng: 32.1039171,
//     },
//     {
//       lat: 55.9138751,
//       lng: 21.052822,
//     },
//     {
//       lat: 50.6272565,
//       lng: 3.0575867,
//     },
//     {
//       lat: 45.4123765,
//       lng: 11.8771382,
//     },
//     {
//       lat: 28.0836269,
//       lng: -80.6081089,
//     },
//     {
//       lat: 40.0087994,
//       lng: -105.0328657,
//     },
//     {
//       lat: 39.8443863,
//       lng: -75.7187498,
//     },
//     {
//       lat: 26.8171165,
//       lng: -80.0590804,
//     },
//     {
//       lat: 36.7518789,
//       lng: -119.7338492,
//     },
//     {
//       lat: 34.4223391,
//       lng: -117.2644197,
//     },
//     {
//       lat: 41.3253756,
//       lng: -72.1931338,
//     },
//     {
//       lat: 37.9219097,
//       lng: -85.4791763,
//     },
//     {
//       lat: 37.8989742,
//       lng: -85.4733533,
//     },
//     {
//       lat: 43.1864501,
//       lng: -77.8038972,
//     },
//     {
//       lat: 12.862807,
//       lng: 30.217636,
//     },
//     {
//       lat: 37.7251448,
//       lng: -78.4927955,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 51.5742447,
//       lng: 0.4856781,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 32.8256427,
//       lng: -117.1558867,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 46.2086683,
//       lng: -119.119948,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 38.7913987,
//       lng: -9.1041157,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 39.8445189,
//       lng: -75.7187733,
//     },
//     {
//       lat: 51.454513,
//       lng: -2.58791,
//     },
//     {
//       lat: 50.0971008,
//       lng: 8.5129309,
//     },
//     {
//       lat: 34.8338922,
//       lng: -82.6762325,
//     },
//     {
//       lat: 28.4415021,
//       lng: -81.5543722,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 34.2743668,
//       lng: -118.7989699,
//     },
//     {
//       lat: 35.3189828,
//       lng: -119.080424,
//     },
//     {
//       lat: 35.3189828,
//       lng: -119.080424,
//     },
//     {
//       lat: 33.9129709,
//       lng: -117.9172567,
//     },
//     {
//       lat: 34.2150918,
//       lng: -118.3793618,
//     },
//     {
//       lat: 32.715738,
//       lng: -117.1610838,
//     },
//     {
//       lat: 34.2150918,
//       lng: -118.3793618,
//     },
//     {
//       lat: 41.9165032,
//       lng: -70.552174,
//     },
//     {
//       lat: 41.9165032,
//       lng: -70.552174,
//     },
//     {
//       lat: 34.1190447,
//       lng: -117.4596077,
//     },
//     {
//       lat: 42.9096911,
//       lng: -73.8951486,
//     },
//     {
//       lat: 35.2270869,
//       lng: -80.8431267,
//     },
//     {
//       lat: 51.1447167,
//       lng: 5.5911687,
//     },
//     {
//       lat: 50.93196,
//       lng: 5.57227,
//     },
//     {
//       lat: 38.6624874,
//       lng: -94.3498072,
//     },
//     {
//       lat: 38.6624874,
//       lng: -94.3498072,
//     },
//     {
//       lat: 38.6624874,
//       lng: -94.3498072,
//     },
//     {
//       lat: 38.6624874,
//       lng: -94.3498072,
//     },
//     {
//       lat: -33.9248685,
//       lng: 18.4240553,
//     },
//     {
//       lat: 40.4455868,
//       lng: -3.7150644,
//     },
//     {
//       lat: 29.4241219,
//       lng: -98.4936282,
//     },
//     {
//       lat: 43.5081323,
//       lng: 16.4401935,
//     },
//     {
//       lat: 39.9711036,
//       lng: -82.7658082,
//     },
//     {
//       lat: 43.6703995,
//       lng: -121.503636,
//     },
//     {
//       lat: 43.6703995,
//       lng: -121.503636,
//     },
//     {
//       lat: 55.0454563,
//       lng: -6.9336758,
//     },
//     {
//       lat: 55.0454563,
//       lng: -6.9336758,
//     },
//     {
//       lat: -25.711581,
//       lng: 28.230245,
//     },
//     {
//       lat: -25.711581,
//       lng: 28.230245,
//     },
//     {
//       lat: 49.7172692,
//       lng: 9.4426104,
//     },
//     {
//       lat: 41.9027835,
//       lng: 12.4963655,
//     },
//     {
//       lat: 26.111919,
//       lng: -80.2874005,
//     },
//     {
//       lat: 41.4558735,
//       lng: -73.7783043,
//     },
//     {
//       lat: 43.2994285,
//       lng: -74.2179326,
//     },
//     {
//       lat: 33.7743477,
//       lng: -118.1626895,
//     },
//     {
//       lat: 41.9097338,
//       lng: 12.498475,
//     },
//     {
//       lat: 41.9097338,
//       lng: 12.498475,
//     },
//     {
//       lat: 53.839905,
//       lng: -2.452137,
//     },
//     {
//       lat: 53.8384875,
//       lng: -2.4568244,
//     },
//     {
//       lat: 39.2734659,
//       lng: -76.6158513,
//     },
//     {
//       lat: 39.2903848,
//       lng: -76.6121893,
//     },
//     {
//       lat: 37.4315734,
//       lng: -78.6568942,
//     },
//     {
//       lat: 33.4151843,
//       lng: -111.8314724,
//     },
//     {
//       lat: 27.5214269,
//       lng: -82.5723193,
//     },
//     {
//       lat: 41.3973163,
//       lng: -73.6170721,
//     },
//     {
//       lat: 39.7281964,
//       lng: -105.1441131,
//     },
//     {
//       lat: 38.7014389,
//       lng: -90.1487199,
//     },
//     {
//       lat: 49.149028,
//       lng: -122.9156858,
//     },
//     {
//       lat: 38.7014389,
//       lng: -90.1487199,
//     },
//     {
//       lat: 40.7557253,
//       lng: -73.8856811,
//     },
//     {
//       lat: 40.7557253,
//       lng: -73.8856811,
//     },
//     {
//       lat: 33.6188829,
//       lng: -117.9298493,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 48.8801457,
//       lng: 2.3317393,
//     },
//     {
//       lat: 48.8801457,
//       lng: 2.3317393,
//     },
//     {
//       lat: 43.2041878,
//       lng: -77.4901606,
//     },
//     {
//       lat: 43.2041878,
//       lng: -77.4901606,
//     },
//     {
//       lat: 43.5460223,
//       lng: -96.731265,
//     },
//     {
//       lat: 45.9049113,
//       lng: -116.0870586,
//     },
//     {
//       lat: 43.0389025,
//       lng: -87.9064736,
//     },
//     {
//       lat: 47.2528768,
//       lng: -122.4442906,
//     },
//     {
//       lat: 47.1118846,
//       lng: -122.3943503,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 41.0520097,
//       lng: -73.8164274,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 43.8517489,
//       lng: -79.3006075,
//     },
//     {
//       lat: 47.1118846,
//       lng: -122.3943503,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 43.8517489,
//       lng: -79.3006075,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 41.4970432,
//       lng: -72.5570348,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 33.598267,
//       lng: -112.2056386,
//     },
//     {
//       lat: 40.4985507,
//       lng: -4.2407525,
//     },
//     {
//       lat: 48.8767178,
//       lng: 2.3513948,
//     },
//     {
//       lat: 40.6565861,
//       lng: -7.9124712,
//     },
//     {
//       lat: 32.5007037,
//       lng: -94.7404891,
//     },
//     {
//       lat: 32.2145453,
//       lng: -110.9555534,
//     },
//     {
//       lat: 42.4670464,
//       lng: -83.0726502,
//     },
//     {
//       lat: 33.0113694,
//       lng: -115.4733554,
//     },
//     {
//       lat: 30.267153,
//       lng: -97.7430608,
//     },
//     {
//       lat: 33.1057721,
//       lng: -117.3034449,
//     },
//     {
//       lat: 37.739895,
//       lng: -122.3908712,
//     },
//     {
//       lat: 33.4595954,
//       lng: -88.8192704,
//     },
//     {
//       lat: 37.192009,
//       lng: -93.4492719,
//     },
//     {
//       lat: -33.9881281,
//       lng: 22.4529878,
//     },
//     {
//       lat: 43.7844397,
//       lng: -88.7878678,
//     },
//     {
//       lat: 49.5775342,
//       lng: 6.1377362,
//     },
//     {
//       lat: 41.3829499,
//       lng: -84.1377069,
//     },
//     {
//       lat: 40.8214894,
//       lng: -73.9876388,
//     },
//     {
//       lat: 40.8214894,
//       lng: -73.9876388,
//     },
//     {
//       lat: 39.087809,
//       lng: -94.3774519,
//     },
//     {
//       lat: 41.9027835,
//       lng: 12.4963655,
//     },
//     {
//       lat: 52.2296756,
//       lng: 21.0122287,
//     },
//     {
//       lat: 50.2103605,
//       lng: 15.825211,
//     },
//     {
//       lat: 34.370239,
//       lng: -77.671558,
//     },
//     {
//       lat: 49.3955549,
//       lng: 13.2950937,
//     },
//     {
//       lat: 39.1501709,
//       lng: -123.2077831,
//     },
//     {
//       lat: 47.2481558,
//       lng: -122.312899,
//     },
//     {
//       lat: 48.2497306,
//       lng: 16.4533745,
//     },
//     {
//       lat: 51.8415344,
//       lng: -0.2021797,
//     },
//     {
//       lat: 41.3920531,
//       lng: -84.1434724,
//     },
//     {
//       lat: 51.8031689,
//       lng: -0.208661,
//     },
//     {
//       lat: 48.4106373,
//       lng: -114.3352652,
//     },
//     {
//       lat: 42.9849233,
//       lng: -81.2452768,
//     },
//     {
//       lat: 39.6231721,
//       lng: -104.8007313,
//     },
//     {
//       lat: 39.6231721,
//       lng: -104.8007313,
//     },
//     {
//       lat: 39.6231721,
//       lng: -104.8007313,
//     },
//     {
//       lat: 26.2767591,
//       lng: -80.2130799,
//     },
//     {
//       lat: 49.055378,
//       lng: -122.422284,
//     },
//     {
//       lat: 44.0008729,
//       lng: -79.6831472,
//     },
//     {
//       lat: 33.0198431,
//       lng: -96.6988856,
//     },
//     {
//       lat: 35.9798734,
//       lng: -78.5097228,
//     },
//     {
//       lat: 52.076422,
//       lng: 5.0852295,
//     },
//     {
//       lat: 40.4167754,
//       lng: -3.7037902,
//     },
//     {
//       lat: 41.87194,
//       lng: 12.56738,
//     },
//     {
//       lat: 41.4817822,
//       lng: -72.6812582,
//     },
//     {
//       lat: 30.9335436,
//       lng: -6.937016,
//     },
//     {
//       lat: 38.7222524,
//       lng: -9.1393366,
//     },
//     {
//       lat: 34.980664,
//       lng: -80.7770128,
//     },
//     {
//       lat: 34.9245935,
//       lng: -80.7434019,
//     },
//     {
//       lat: 28.0300723,
//       lng: -82.3898357,
//     },
//     {
//       lat: 27.950575,
//       lng: -82.4571776,
//     },
//     {
//       lat: 47.6732281,
//       lng: -117.2393748,
//     },
//     {
//       lat: 47.6732281,
//       lng: -117.2393748,
//     },
//     {
//       lat: 49.9133537,
//       lng: 7.0120943,
//     },
//     {
//       lat: 39.9243564,
//       lng: -91.5231806,
//     },
//     {
//       lat: 41.6488226,
//       lng: -0.8890853,
//     },
//     {
//       lat: 33.8286423,
//       lng: -83.8568698,
//     },
//     {
//       lat: -33.9248685,
//       lng: 18.4240553,
//     },
//     {
//       lat: 52.4147568,
//       lng: -1.873188,
//     },
//     {
//       lat: 4.7109886,
//       lng: -74.072092,
//     },
//     {
//       lat: 47.6734632,
//       lng: -116.7812225,
//     },
//     {
//       lat: 53.2338177,
//       lng: 6.598332,
//     },
//     {
//       lat: 30.0335053,
//       lng: -93.8773389,
//     },
//     {
//       lat: 26.9761707,
//       lng: -82.0906448,
//     },
//     {
//       lat: 52.2296756,
//       lng: 21.0122287,
//     },
//     {
//       lat: 49.303454,
//       lng: 14.158029,
//     },
//     {
//       lat: 36.0971068,
//       lng: -115.0455565,
//     },
//     {
//       lat: 50.2103605,
//       lng: 15.825211,
//     },
//     {
//       lat: 50.0755381,
//       lng: 14.4378005,
//     },
//     {
//       lat: 40.4675835,
//       lng: -74.6628114,
//     },
//     {
//       lat: 33.1641559,
//       lng: -86.7853264,
//     },
//     {
//       lat: 37.9838096,
//       lng: 23.7275388,
//     },
//     {
//       lat: 38.6072936,
//       lng: -122.87057,
//     },
//     {
//       lat: 25.8575963,
//       lng: -80.2781057,
//     },
//     {
//       lat: 40.4329141,
//       lng: -74.663464,
//     },
//     {
//       lat: 41.3850639,
//       lng: 2.1734035,
//     },
//     {
//       lat: 34.2035746,
//       lng: -77.849927,
//     },
//     {
//       lat: 30.267153,
//       lng: -97.7430608,
//     },
//     {
//       lat: 40.8059699,
//       lng: -73.3970022,
//     },
//     {
//       lat: 41.5911589,
//       lng: 1.5208624,
//     },
//     {
//       lat: 33.6745384,
//       lng: -84.5134323,
//     },
//     {
//       lat: 40.8059699,
//       lng: -73.3970022,
//     },
//     {
//       lat: 7.119349,
//       lng: -73.1227416,
//     },
//     {
//       lat: 36.2708796,
//       lng: -115.1677716,
//     },
//     {
//       lat: 40.8447819,
//       lng: -73.8648268,
//     },
//     {
//       lat: 48.6942751,
//       lng: 9.3275689,
//     },
//     {
//       lat: 34.1823859,
//       lng: -118.4222166,
//     },
//     {
//       lat: 37.197578,
//       lng: -108.0930766,
//     },
//     {
//       lat: 35.7478769,
//       lng: -95.3696909,
//     },
//     {
//       lat: 34.0522342,
//       lng: -118.2436849,
//     },
//     {
//       lat: 36.2708796,
//       lng: -115.1677716,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 35.9798734,
//       lng: -78.5097228,
//     },
//     {
//       lat: 38.5815719,
//       lng: -121.4943996,
//     },
//     {
//       lat: 39.9243564,
//       lng: -91.5231806,
//     },
//     {
//       lat: 40.2645371,
//       lng: -75.8040852,
//     },
//     {
//       lat: 52.7013353,
//       lng: 6.1871652,
//     },
//     {
//       lat: 30.1658207,
//       lng: -95.4612625,
//     },
//   ];

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src =
//       "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
//     script.async = true;
//     document.body.appendChild(script);
//   });

//   const setGoogleMapRef = (map, maps) => {
//     let googleMapRef = map;
//     let googleRef = maps;
//     const infoWindow = new googleRef.InfoWindow({
//       content: "<p>Hello</p>",
//     });
//     let markers =
//       coordinates &&
//       coordinates.map((marker, index) => {
//         const refMarker = new googleRef.Marker({
//           position: marker,
//           label: labels[index % labels.length],
//           title: "Car",
//         });

//         // googleRef.event.addEventListener(refMarker, 'click', () => {
//         //   infoWindow.open(map, googleMapRef)
//         // })

//         // refMarker.addEventListener('click', () => {
//         //   infoWindow.open(map, refMarker)
//         // })

//         return refMarker;
//       });

//     let markerCluster = new MarkerClusterer(map, markers, {
//       imagePath: "/marker_images/m",
//       minimumClusterSize: 4,
//     });
//   };


//   const propertiesList = isFiltering ? filteredProperties : properties;
//   return (
//     <>
//       <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <AppContainer>
//               {/* listings - Start */}
//               <div className="mapContainer">
//                 <GoogleMapReact
//                   bootstrapURLKeys={{ key: "" }}
//                   defaultCenter={{ lat: 21.032275891056212, lng: 105.81722252298444 }}
//                   defaultZoom={12} // 20 fix zoom
//                   yesIWantToUseGoogleMapApiInternals
//                 >
//                   {/* <Marker2 lat={37.7749} lng={-122.4194} text="San Francisco" />
//                   <Marker2 lat={37.8049} lng={-122.2711} text="Oakland" />
//                   <Marker2 lat={37.8715} lng={-122.2730} text="Berkeley" />
//                   <Marker2 lat={37.3382} lng={-121.8863} text="San Jose" />
//                   <Marker2 lat={37.6879} lng={-122.4692} text="Daly City" />
//                   <Marker2 lat={37.6541} lng={-122.4076} text="South San Francisco" />
//                   <Marker2 lat={37.8591} lng={-122.4855} text="Sausalito" />
//                   <Marker2 lat={37.5623} lng={-122.2711} text="San Mateo" />
//                   <Marker2 lat={37.4419} lng={-122.1430} text="Palo Alto" />
//                   <Marker2 lat={37.5483} lng={-121.9886} text="Fremont" />
//                   <Marker2 lat={37.9358} lng={-122.3478} text="Richmond" /> */}

//                   {loading ? (
//                       <p>Loading markers...</p>
//                   ) : (
//                       markers.map(marker => (
//                           <Marker2
//                               key={marker._id} // Ensure each marker has a unique key
//                               lat={marker.location.coordinates[0]} // Latitude at index 1
//                               lng={marker.location.coordinates[1]} // Longitude at index 0
//                               text={marker.title} // Title of the marker
//                           />
//                       ))
//                   )}


//                 </GoogleMapReact>
//               </div>

//               <div className="listings">
//                 <Header
//                   filterIsVisible={filterIsVisible}
//                   toggleFilter={toggleFilter}
//                   clearFilter={clearFilter}
//                   handleFilterChange={handleFilterChange}
//                 />
//                 <div className="cards container">
//                   <div className={`cards-list row ${propertiesList.length === 0 ? 'is-empty' : ''}`}>
//                     {markers.map(marker => (
//                       <Card
//                         key={marker._id}
//                         // property={property}
//                         property={marker}
//                         activeProperty={activeProperty}
//                         setActiveProperty={setActiveProperty}
//                         setShowPopup={setShowPopup}
//                       />
//                     ))}
//                     {isFiltering && propertiesList.length === 0 && (
//                       <p className="warning">
//                         <img src={image} alt="No properties were found." /><br />
//                         No properties were found.
//                       </p>
//                     )}

//                     {showPopup && activeProperty && (
//                         <Popup property={activeProperty} setShowPopup={setShowPopup} />
//                     )}
//                   </div>
//                 </div>
//               </div>
//               {/* listings - End */}
//             </AppContainer>
//           }
//         />

//         <Route path="/current" element={<MapComponent />} />
//         {/* <Route path="/current" element={<SubMapComponent />} /> */}
//         <Route path="/test" element={<h1>TEST PAGE</h1>} />
//         <Route path="/login" element={<Login />}  />
//         {/* <Route path="/login" element={<h1>TEST Login PAGE </h1>}   /> */}


//       </Routes>
//       </Router>

//     </>
//   );
// }
