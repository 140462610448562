



// v2 add more function, improve the Marker, what should I display on this page, cards and popups??
//make this work on pc too, on pc remove the grey button and put it on 1/3 left like explore page
// (fix this did not work nice on phone yet)


// v1.6.2 customize this: add diff size like figma, add swipe, now only the click on dot works


// ok v1.6.1 ( found the nice demo) add figma stuff to this

import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";
import Header from "./Header";
import { AppContainer } from "../App.styles2";
import axios from "axios";

import { Carousel } from '@mantine/carousel';
import { Button, Paper, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classes from './CardsCarousel.module.css';
import { MantineProvider } from '@mantine/core';
// import { Button, Paper, Text, Title } from '@mantine/core';
import { Container, px, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import './HomePage.css'


// Flick failed
import Flickity from 'flickity'; // Import Flickity styles and functionality
import 'flickity/css/flickity.css'; // Import Flickity CSS


// Swiper failed
// https://codesandbox.io/p/devbox/swiper-react-manipulation-dv22fx?file=%2Fsrc%2FApp.jsx%3A2%2C1-9%2C1
// https://swiperjs.com/demos
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';


// slick failed
import Slider from 'react-slick'; // Import Slider for carousel functionality


// prebuilt comp https://codesandbox.io/p/sandbox/multiple-items-slider-using-react-slick-lgrs2?file=%2Fsrc%2FApp.js%3A6%2C4
import { SliderComp } from "./Slider/SliderComp";

const data = [
  {
    title: "Milan",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0444_city-header_milan_marianna-tomaselli-768x432.jpg"
  },
  {
    title: "Hong Kong",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0378_hong-kong_jisu-choi_final_header-1-768x512.jpg"
  },
  {
    title: "Prague",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/location-page-header-prague-greta-samuel-768x512.jpg"
  },
  {
    title: "Florence",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0440_florence_giordano-poloni_final_header-768x512.jpg"
  },
  {
    title: "Venice",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0449_venice_marianna-tomaselli_final_header-768x432.jpg"
  },
  {
    title: "Los Angeles",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/venice-beach_final-768x512.jpg"
  },
  {
    title: "Milan",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0444_city-header_milan_marianna-tomaselli-768x432.jpg"
  },
  {
    title: "Hong Kong",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0378_hong-kong_jisu-choi_final_header-1-768x512.jpg"
  },
  {
    title: "Prague",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/location-page-header-prague-greta-samuel-768x512.jpg"
  },
  {
    title: "Florence",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0440_florence_giordano-poloni_final_header-768x512.jpg"
  },
  {
    title: "Venice",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/ia_0449_venice_marianna-tomaselli_final_header-768x432.jpg"
  },
  {
    title: "Los Angeles",
    image:
      "https://cdn.theculturetrip.com/wp-content/uploads/2019/04/venice-beach_final-768x512.jpg"
  }
];

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';


const getChild = (height) => <Skeleton height={height} radius="md" animate={false} />;
const BASE_HEIGHT = 360;
const getSubHeight = (children, spacing) =>
  BASE_HEIGHT / children - spacing * ((children - 1) / children);

function Subgrid() {
    const theme = useMantineTheme();
    return (
      <Container my="md">
        <SimpleGrid cols={{ base: 1, xs: 4 }}>
          {getChild(BASE_HEIGHT)}
          <Stack>
            {getChild(getSubHeight(2, theme.spacing.md))}
            {getChild(getSubHeight(2, theme.spacing.md))}
          </Stack>
          <Stack>
            {getChild(getSubHeight(3, theme.spacing.md))}
            {getChild(getSubHeight(3, theme.spacing.md))}
            {getChild(getSubHeight(3, theme.spacing.md))}
          </Stack>
          {getChild(BASE_HEIGHT)}
        </SimpleGrid>
      </Container>
    );
  }

function ArticleCardImage() {
    return (
      <Paper shadow="md" p="xl" radius="md" className={classes.card}>
        <div>
          <Text className={classes.category} size="xs">
            nature
          </Text>
          <Title order={3} className={classes.title}>
            Best forests to visit in North America
          </Title>
        </div>
        <Button variant="white" color="dark">
          Read article
        </Button>
      </Paper>
    );
  }

function Card({ image, title, category }) {
    return (
      <Paper
        shadow="md"
        p="xl"
        radius="md"
        style={{ backgroundImage: `url(${image})` }}
        className={classes.card}
      >
        <div>
          <Text className={classes.category} size="xs">
            {category}
          </Text>
          <Title order={3} className={classes.title}>
            {title}
          </Title>
        </div>
        <Button variant="white" color="dark">
          Read article
        </Button>
      </Paper>
    );
  }

const data2 = [
    {
      image:
        'https://images.unsplash.com/photo-1508193638397-1c4234db14d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
      title: 'Best forests to visit in North America',
      category: 'nature',
    },
    {
      image:
        'https://images.unsplash.com/photo-1559494007-9f5847c49d94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
      title: 'Hawaii beaches review: better than you think',
      category: 'beach',
    },
    {
      image:
        'https://images.unsplash.com/photo-1608481337062-4093bf3ed404?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
      title: 'Mountains at night: 12 best locations to enjoy the view',
      category: 'nature',
    },
    {
      image:
        'https://images.unsplash.com/photo-1507272931001-fc06c17e4f43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
      title: 'Aurora in Norway: when to visit for best experience',
      category: 'nature',
    },
    {
      image:
        'https://images.unsplash.com/photo-1510798831971-661eb04b3739?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
      title: 'Best places to visit this winter',
      category: 'tourism',
    },
    {
      image:
        'https://images.unsplash.com/photo-1582721478779-0ae163c05a60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
      title: 'Active volcanos reviews: travel at your own risk',
      category: 'nature',
    },
];

function CardsCarousel() {
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const slides = data.map((item) => (
      <Carousel.Slide key={item.title}>
        <Card {...item} />
      </Carousel.Slide>
    ));

    return (
      <Carousel
        slideSize={{ base: '100%', sm: '50%' }}
        slideGap={{ base: 2, sm: 'xl' }}
        align="start"
        slidesToScroll={mobile ? 1 : 2}
      >
        {slides}
      </Carousel>
    );
  }

const theme = {
// Customize the theme if required
colorScheme: 'light', // Example: set light/dark theme
};



const FlickityCarousel = () => {
  // Array of cards
  const cards = [
    "Card 1",
    "Card 2",
    "Card 3",
    "Card 4",
    "Card 5",
  ];

  // Slick settings
  const settings = {
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed in ms
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 3, // Number of slides to scroll at a time
    centerMode: true, // Center the active slide
    focusOnSelect: true, // Focus on the selected slide
    autoplay: false, // Enable autoplay
    autoplaySpeed: 2000, // Autoplay interval in ms
    draggable: true, // Allow dragging of slides
  };

  return (
    <div className="main-container">
      <div style={{ height: 100 }} />
      <SliderComp
        autoplay={true}
        autoplaySpeed={3000}
        slideNum={4}
        data={data}
        label={"Travel"}
        heading={"Places to travel to in 2022"}
        subheading={"2022 is going to be epic!"}
      />
      <div style={{ height: 100 }} />
      <SliderComp
        slideNum={3}
        data={data}
        label={"Japan"}
        heading={"Things to do in Kyoto"}
        subheading={"Kyoto is hip!"}
      />
      <div style={{ height: 100 }} />
      <SliderComp
        autoplay={true}
        autoplaySpeed={4000}
        slideNum={2}
        data={data}
        label={"Tours"}
        heading={"Lisbon Tours"}
        subheading={"Check out the fun in Lisbon!"}
      />
      <div style={{ height: 100 }} />
      <SliderComp
        slideNum={1}
        data={data}
        label={"Shop"}
        heading={"Places to shop in Barcelona"}
        subheading={"Shop like a local"}
      />
      <div style={{ height: 100 }} />
    </div>
  );
};



  const FlickityCarousel2 = () => {
    // Create a reference to the carousel container
    const carouselRef = useRef(null);

    useEffect(() => {
      // Initialize Flickity when the component mounts
      const flickityInstance = new Flickity(carouselRef.current, {
        cellAlign: 'left', // Align items to the left
        contain: true, // Prevent wrapping of cells
        freeScroll: true, // Enable free scrolling
        wrapAround: true, // Enable wrap-around behavior
        autoPlay: false, //true, // Enable autoplay
        prevNextButtons: false, // Hide next/prev buttons
      });

      // Cleanup Flickity instance when the component unmounts
      return () => {
        flickityInstance.destroy();
      };
    }, []);

    return (
<div className="carousel" ref={carouselRef}>

<div class="carousel-cell">
    <img class="carousel-cell-image"
      data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/grapes.jpg" alt="grapes" />
  </div>

  <div className="carousel-cell" style={{ width: '160px', height: '90px' }}>
    {/* <img
      src="https://via.placeholder.com/300x200"
      alt="Slide 1"
      style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block' }}
    /> */}
        <img
                  src={ 'https://obj.tang17so82duytan.com/demo-map//default user2.png'}
        />
  </div>
  <div className="carousel-cell" style={{ width: '160px', height: '90px' }}>
    <img
      src="https://via.placeholder.com/300x200"
      alt="Slide 2"
      style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block' }}
    />
  </div>
  <div className="carousel-cell" style={{ width: '160px', height: '90px' }}>
    <img
      src="https://via.placeholder.com/300x200"
      alt="Slide 3"
      style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block' }}
    />
  </div>
  <div className="carousel-cell" style={{ width: '160px', height: '90px' }}>
    <img
      src="https://via.placeholder.com/300x200"
      alt="Slide 4"
      style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block' }}
    />
  </div>
</div>

    );
  };





  const FlickityCarousel3 = () => {
    // Create a reference to the carousel container
    const carouselRef = useRef(null);

    useEffect(() => {
      // Initialize Flickity when the component mounts
      const flickityInstance = new Flickity(carouselRef.current, {
        cellAlign: 'left', // Align items to the left
        contain: true, // Prevent wrapping of cells
        freeScroll: true, // Enable free scrolling
        wrapAround: true, // Enable wrap-around behavior
        autoPlay: true, // Enable autoplay
        prevNextButtons: false, // Hide next/prev buttons
      });

      // Cleanup Flickity instance when the component unmounts
      return () => {
        flickityInstance.destroy();
      };
    }, []);

    return (
      <div className="carousel" ref={carouselRef}>
        <div className="carousel-cell" style={{ width: '320px' }}>
          <img src="https://via.placeholder.com/300x200" alt="Slide 1" />
        </div>
        <div className="carousel-cell" style={{ width: '320px' }}>
          <img src="https://via.placeholder.com/300x200" alt="Slide 2" />
        </div>
        <div className="carousel-cell" style={{ width: '320px' }}>
          <img src="https://via.placeholder.com/300x200" alt="Slide 3" />
        </div>
        <div className="carousel-cell" style={{ width: '320px' }}>
          <img src="https://via.placeholder.com/300x200" alt="Slide 4" />
        </div>
      </div>
    );
  };




const HomePage = () => {
    const defaultProps = {
        center: {
            lat: 21.044175, // Center to San Francisco
            lng: 105.846411,
        },
        zoom: 10,
    };

    const [markers, setMarkers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedHeight, setExpandedHeight] = useState(53); // Use number for comparison, not string
    const [startTouchY, setStartTouchY] = useState(null);

    const videoRef = useRef(null);

    // Fetch markers from the API
    const fetchMarkers = async () => {
        setLoading(true);
        setError(null);

        // Retrieve token from localStorage
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(apiBaseUrl + 'own', {
                headers: {
                    Authorization: `Bearer ${token}`, // Add token to the Authorization header
                },
            });

            setMarkers(response.data); // Update the state with fetched markers
        } catch (error) {
            // setError('Error fetching markers');
            // console.error('Error fetching markers:', error);
            // Check if the error is due to 401 Unauthorized
            if (error.response && error.response.status === 401) {
                setError('Hãy đăng nhập');
                // Optionally, you can redirect to the login page here
                // window.location.href = '/login'; // Uncomment this line if you want to redirect
            } else {
                setError('Error fetching markers');
            }
            console.error('Error fetching markers:', error);

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMarkers(); // Fetch markers when component mounts
    }, []);

    // Handlers for swipe interactions
    const handleTouchStart = (e) => {
        const touchStart = e.touches[0].clientY;
        setStartTouchY(touchStart);
    };

    const handleTouchMove = (e) => {
        if (startTouchY === null) return; // Ignore if no touch started
        const touchMove = e.touches[0].clientY;
        const diff = startTouchY - touchMove; // Calculate the difference in vertical movement
        const newHeight = Math.max(0, expandedHeight + diff); // Prevent negative height values
        setExpandedHeight(newHeight); // Dynamically change height
        setStartTouchY(touchMove); // Update start position for smooth movement
    };

    const handleTouchEnd = () => {
        if (expandedHeight > 90) {
            setExpandedHeight(90); // Expand to 50%
        } else if (expandedHeight < 33) {
            setExpandedHeight(33); // Collapse to 33%
        }
        setStartTouchY(null); // Reset touch position
    };

    // Toggle between showing text or not on click
    const handleToggleText = (id) => {
        setMarkers((prevMarkers) => {
            return prevMarkers.map((marker) => {
                if (marker._id === id) {
                    return {
                        ...marker,
                        showText: !marker.showText, // Toggle text visibility
                    };
                }
                return marker;
            });
        });
    };

    return (
        <AppContainer>
            <Header />
            <div className="app" style={{ height: "100vh", width: "100%", position: "relative" }}>
                {/* Google Map */}
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }} // Ensure your API key is valid
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    options={{
                        gestureHandling: 'greedy',
                    }}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: `calc(100vh - ${expandedHeight}vh)` // Map height adjusts with expanded area
                    }}
                >
                    {loading ? (
                        <p>Loading markers...</p>
                    ) : error ? (
                        <p
                        style={{
                            color: '#f5f5f5',        // Red color for the error text
                            fontSize: '15px',    // Increase the font size
                            textAlign: 'left', // Center-align the text
                            marginLeft: '0px',
                            // padding: '20px',     // Add padding around the text
                            // backgroundColor: 'rgba(255, 0, 0, 0.1)', // Light red background
                            // borderRadius: '8px', // Rounded corners
                            // maxWidth: '80%',     // Max width for better responsiveness
                            // margin: '20px auto', // Center the error box horizontally
                            // fontWeight: 'bold'   // Make the text bold
                        }}
                    >
                        {error}
                    </p>
                    ) : (
                        markers.map((marker) => (
                            <Marker
                                key={marker._id} // Ensure each marker has a unique key
                                lat={marker.location.coordinates[0]} // Latitude at index 0
                                lng={marker.location.coordinates[1]} // Longitude at index 1
                                text={marker.title} // Title of the marker
                            />
                        ))
                    )}
                </GoogleMapReact>


                {/* Expanded Area */}
                <div
                    style={{
                        position: "absolute",
                        bottom: "30px", // Adjust to stay above the swipeable bar
                        left: "0",
                        width: "100%",
                        height: `${expandedHeight}vh`, // Dynamic height based on expandedHeight state
                        backgroundColor: "#f5f5f5",
                        overflowY: "auto",
                        // overflowY: "hidden",
                        transition: "height 0.3s ease-in-out", // Smooth transition for height change
                        borderTopLeftRadius: "20px", // Rounded top left corner
                        borderTopRightRadius: "20px", // Rounded top right corner
                        boxShadow: "0px -4px 10px rgba(0,0,0,0.1)",
                        zIndex: 9, // Ensure it's beneath the swipeable bar
                    }}
                >

                {/* Swipeable Horizontal Bar */}
                <div
                    style={{
                        // position: "absolute",
                        position: "sticky",
                        top: "0%", // Always at the bottom of the expanded area
                        left: "0",
                        width: "100%",
                        height: "30px", // Fixed height for the swipeable bar
                        backgroundColor: "#f5f5f5",
                        cursor: "pointer",
                        zIndex: 10,
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div
                        style={{
                            position: "absolute",
                            // position: "sticky",
                            width: "40px",
                            height: "5px",
                            backgroundColor: "#D3D3D3",
                            borderRadius: "3px",
                            top: "50%",
                            left: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                        }}
                    ></div>
                </div>

                <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
                    {/* <Subgrid /> */}
                </MantineProvider>


                    {/* Expanded Content */}
                    {expandedHeight > 0 && (
                        <div
                            // style={{
                            //     padding: "10px",
                            //     maxHeight: "calc(100% - 40px)", // Max height based on available space
                            //     overflowY: "auto", // Allow scrolling within content if it overflows
                            //     display: "grid", // Use grid for layout
                            //     gridTemplateColumns: "repeat(3, 1fr)", // 3 columns per row
                            //     gap: "16px", // Gap between items
                            // }}
                        >

                            {/* <FlickityCarousel ></FlickityCarousel>
                            <br/> <br/>
                            <FlickityCarousel2 ></FlickityCarousel2>
                            <br/> <br/>
                            <FlickityCarousel3 ></FlickityCarousel3>
                            <br/> <br/> */}
    <div className="main-container">
      <div style={{ height: 60 }} />
      <SliderComp
        autoplay={true}
        autoplaySpeed={3000}
        slideNum={4}
        data={data}
        label={"Travel"}
        heading={"Places to travel to in 2022"}
        subheading={"2022 is going to be epic!"}
      />
      <div style={{ height: 60 }} />
      <SliderComp
        slideNum={3}
        data={data}
        label={"Japan"}
        heading={"Things to do in Kyoto"}
        subheading={"Kyoto is hip!"}
      />
      <div style={{ height: 60 }} />
      <SliderComp
        autoplay={true}
        autoplaySpeed={4000}
        slideNum={2}
        data={data}
        label={"Tours"}
        heading={"Lisbon Tours"}
        subheading={"Check out the fun in Lisbon!"}
      />
      <div style={{ height: 60 }} />
      <SliderComp
        slideNum={1}
        data={data}
        label={"Shop"}
        heading={"Places to shop in Barcelona"}
        subheading={"Shop like a local"}
      />
      <div style={{ height: 60 }} />
    </div>

                            {/* {markers.map((marker) => (
                                <div
                                    key={marker._id}
                                    className="card position-relative overflow-hidden"
                                    style={{
                                        borderRadius: "10px !important",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",  // Ensure full width
                                        height: "100%", // Set height to make it square
                                        paddingBottom: "100%", // Maintain aspect ratio (square)
                                        position: "relative", // Important for absolute positioning inside card
                                    }}
                                    onClick={() => handleToggleText(marker._id)} // Toggle text visibility on click
                                >

                                    <div className="media-container" style={{ height: "100%", width: "100%"
                                      //  Media rendering (image or video)
                                    }}>

                                        {marker.media?.[0]?.url && (
                                            marker.media[0].url.includes(".mp4") ? (
                                                <video
                                                    ref={videoRef}
                                                    className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
                                                    src={`https://${marker.media[0].url}`}
                                                    muted
                                                    loop
                                                    playsInline
                                                />
                                            ) : (
                                                <img
                                                    src={`https://${marker.media[0].url}`}
                                                    alt={marker.title}
                                                    className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
                                                />
                                            )
                                        )}
                                    </div>

                                    {marker.showText && (
                                        <div
                                            className="card-body position-relative z-index-2 text-white p-3"
                                            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                                            //                                      Card body with the title, description, and location

                                        >
                                            <p className="price fs-5 mb-1">{marker.title}</p>
                                            <div className="details">
                                                <span>{marker.description}</span>
                                                {marker.address && (
                                                    <p
                                                        className="location"
                                                        style={{ fontSize: "14px", color: "#ddd" }}
                                                    >
                                                        {marker.address}, {marker.city}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}  */}
                        </div>
                    )}
                </div>
            </div>
        </AppContainer>
    );
};

export default HomePage;



// // ok (reorganize stuff) v1.6
// import React, { useState, useEffect, useRef } from "react";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import axios from "axios";

// import { Carousel } from '@mantine/carousel';
// import { Button, Paper, Text, Title, useMantineTheme } from '@mantine/core';
// import { useMediaQuery } from '@mantine/hooks';
// import classes from './CardsCarousel.module.css';
// import { MantineProvider } from '@mantine/core';
// // import { Button, Paper, Text, Title } from '@mantine/core';
// import { Container, px, SimpleGrid, Skeleton, Stack } from '@mantine/core';


// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';


// const getChild = (height) => <Skeleton height={height} radius="md" animate={false} />;
// const BASE_HEIGHT = 360;
// const getSubHeight = (children, spacing) =>
//   BASE_HEIGHT / children - spacing * ((children - 1) / children);

// function Subgrid() {
//     const theme = useMantineTheme();
//     return (
//       <Container my="md">
//         <SimpleGrid cols={{ base: 1, xs: 4 }}>
//           {getChild(BASE_HEIGHT)}
//           <Stack>
//             {getChild(getSubHeight(2, theme.spacing.md))}
//             {getChild(getSubHeight(2, theme.spacing.md))}
//           </Stack>
//           <Stack>
//             {getChild(getSubHeight(3, theme.spacing.md))}
//             {getChild(getSubHeight(3, theme.spacing.md))}
//             {getChild(getSubHeight(3, theme.spacing.md))}
//           </Stack>
//           {getChild(BASE_HEIGHT)}
//         </SimpleGrid>
//       </Container>
//     );
//   }

// function ArticleCardImage() {
//     return (
//       <Paper shadow="md" p="xl" radius="md" className={classes.card}>
//         <div>
//           <Text className={classes.category} size="xs">
//             nature
//           </Text>
//           <Title order={3} className={classes.title}>
//             Best forests to visit in North America
//           </Title>
//         </div>
//         <Button variant="white" color="dark">
//           Read article
//         </Button>
//       </Paper>
//     );
//   }

// function Card({ image, title, category }) {
//     return (
//       <Paper
//         shadow="md"
//         p="xl"
//         radius="md"
//         style={{ backgroundImage: `url(${image})` }}
//         className={classes.card}
//       >
//         <div>
//           <Text className={classes.category} size="xs">
//             {category}
//           </Text>
//           <Title order={3} className={classes.title}>
//             {title}
//           </Title>
//         </div>
//         <Button variant="white" color="dark">
//           Read article
//         </Button>
//       </Paper>
//     );
//   }

// const data = [
//     {
//       image:
//         'https://images.unsplash.com/photo-1508193638397-1c4234db14d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
//       title: 'Best forests to visit in North America',
//       category: 'nature',
//     },
//     {
//       image:
//         'https://images.unsplash.com/photo-1559494007-9f5847c49d94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
//       title: 'Hawaii beaches review: better than you think',
//       category: 'beach',
//     },
//     {
//       image:
//         'https://images.unsplash.com/photo-1608481337062-4093bf3ed404?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
//       title: 'Mountains at night: 12 best locations to enjoy the view',
//       category: 'nature',
//     },
//     {
//       image:
//         'https://images.unsplash.com/photo-1507272931001-fc06c17e4f43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
//       title: 'Aurora in Norway: when to visit for best experience',
//       category: 'nature',
//     },
//     {
//       image:
//         'https://images.unsplash.com/photo-1510798831971-661eb04b3739?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
//       title: 'Best places to visit this winter',
//       category: 'tourism',
//     },
//     {
//       image:
//         'https://images.unsplash.com/photo-1582721478779-0ae163c05a60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
//       title: 'Active volcanos reviews: travel at your own risk',
//       category: 'nature',
//     },
// ];

// function CardsCarousel() {
//     const theme = useMantineTheme();
//     const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
//     const slides = data.map((item) => (
//       <Carousel.Slide key={item.title}>
//         <Card {...item} />
//       </Carousel.Slide>
//     ));

//     return (
//       <Carousel
//         slideSize={{ base: '100%', sm: '50%' }}
//         slideGap={{ base: 2, sm: 'xl' }}
//         align="start"
//         slidesToScroll={mobile ? 1 : 2}
//       >
//         {slides}
//       </Carousel>
//     );
//   }

// const theme = {
// // Customize the theme if required
// colorScheme: 'light', // Example: set light/dark theme
// };

// const HomePage = () => {
//     const defaultProps = {
//         center: {
//             lat: 21.044175, // Center to San Francisco
//             lng: 105.846411,
//         },
//         zoom: 10,
//     };

//     const [markers, setMarkers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [expandedHeight, setExpandedHeight] = useState(53); // Use number for comparison, not string
//     const [startTouchY, setStartTouchY] = useState(null);

//     const videoRef = useRef(null);

//     // Fetch markers from the API
//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);

//         // Retrieve token from localStorage
//         const token = localStorage.getItem('token');

//         try {
//             const response = await axios.get(apiBaseUrl + 'own', {
//                 headers: {
//                     Authorization: `Bearer ${token}`, // Add token to the Authorization header
//                 },
//             });

//             setMarkers(response.data); // Update the state with fetched markers
//         } catch (error) {
//             // setError('Error fetching markers');
//             // console.error('Error fetching markers:', error);
//             // Check if the error is due to 401 Unauthorized
//             if (error.response && error.response.status === 401) {
//                 setError('Hãy đăng nhập');
//                 // Optionally, you can redirect to the login page here
//                 // window.location.href = '/login'; // Uncomment this line if you want to redirect
//             } else {
//                 setError('Error fetching markers');
//             }
//             console.error('Error fetching markers:', error);

//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers(); // Fetch markers when component mounts
//     }, []);

//     // Handlers for swipe interactions
//     const handleTouchStart = (e) => {
//         const touchStart = e.touches[0].clientY;
//         setStartTouchY(touchStart);
//     };

//     const handleTouchMove = (e) => {
//         if (startTouchY === null) return; // Ignore if no touch started
//         const touchMove = e.touches[0].clientY;
//         const diff = startTouchY - touchMove; // Calculate the difference in vertical movement
//         const newHeight = Math.max(0, expandedHeight + diff); // Prevent negative height values
//         setExpandedHeight(newHeight); // Dynamically change height
//         setStartTouchY(touchMove); // Update start position for smooth movement
//     };

//     const handleTouchEnd = () => {
//         if (expandedHeight > 50) {
//             setExpandedHeight(50); // Expand to 50%
//         } else if (expandedHeight < 33) {
//             setExpandedHeight(33); // Collapse to 33%
//         }
//         setStartTouchY(null); // Reset touch position
//     };

//     // Toggle between showing text or not on click
//     const handleToggleText = (id) => {
//         setMarkers((prevMarkers) => {
//             return prevMarkers.map((marker) => {
//                 if (marker._id === id) {
//                     return {
//                         ...marker,
//                         showText: !marker.showText, // Toggle text visibility
//                     };
//                 }
//                 return marker;
//             });
//         });
//     };

//     return (
//         <AppContainer>
//             <Header />
//             <div className="app" style={{ height: "100vh", width: "100%", position: "relative" }}>
//                 {/* Google Map */}
//                 <GoogleMapReact
//                     bootstrapURLKeys={{ key: "" }} // Ensure your API key is valid
//                     defaultCenter={defaultProps.center}
//                     defaultZoom={defaultProps.zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                     options={{
//                         gestureHandling: 'greedy',
//                     }}
//                     style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: `calc(100vh - ${expandedHeight}vh)` // Map height adjusts with expanded area
//                     }}
//                 >
//                     {loading ? (
//                         <p>Loading markers...</p>
//                     ) : error ? (
//                         <p
//                         style={{
//                             color: '#f5f5f5',        // Red color for the error text
//                             fontSize: '15px',    // Increase the font size
//                             textAlign: 'left', // Center-align the text
//                             marginLeft: '0px',
//                             // padding: '20px',     // Add padding around the text
//                             // backgroundColor: 'rgba(255, 0, 0, 0.1)', // Light red background
//                             // borderRadius: '8px', // Rounded corners
//                             // maxWidth: '80%',     // Max width for better responsiveness
//                             // margin: '20px auto', // Center the error box horizontally
//                             // fontWeight: 'bold'   // Make the text bold
//                         }}
//                     >
//                         {error}
//                     </p>
//                     ) : (
//                         markers.map((marker) => (
//                             <Marker
//                                 key={marker._id} // Ensure each marker has a unique key
//                                 lat={marker.location.coordinates[0]} // Latitude at index 0
//                                 lng={marker.location.coordinates[1]} // Longitude at index 1
//                                 text={marker.title} // Title of the marker
//                             />
//                         ))
//                     )}
//                 </GoogleMapReact>


//                 {/* Expanded Area */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         bottom: "30px", // Adjust to stay above the swipeable bar
//                         left: "0",
//                         width: "100%",
//                         height: `${expandedHeight}vh`, // Dynamic height based on expandedHeight state
//                         backgroundColor: "#f5f5f5",
//                         overflowY: "auto",
//                         transition: "height 0.3s ease-in-out", // Smooth transition for height change
//                         borderTopLeftRadius: "20px", // Rounded top left corner
//                         borderTopRightRadius: "20px", // Rounded top right corner
//                         boxShadow: "0px -4px 10px rgba(0,0,0,0.1)",
//                         zIndex: 9, // Ensure it's beneath the swipeable bar
//                     }}
//                 >

//                 {/* Swipeable Horizontal Bar */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         top: "0%", // Always at the bottom of the expanded area
//                         left: "0",
//                         width: "100%",
//                         height: "30px", // Fixed height for the swipeable bar
//                         backgroundColor: "#f5f5f5",
//                         cursor: "pointer",
//                         zIndex: 10,
//                     }}
//                     onTouchStart={handleTouchStart}
//                     onTouchMove={handleTouchMove}
//                     onTouchEnd={handleTouchEnd}
//                 >
//                     <div
//                         style={{
//                             position: "absolute",
//                             width: "40px",
//                             height: "5px",
//                             backgroundColor: "#D3D3D3",
//                             borderRadius: "3px",
//                             top: "50%",
//                             left: "50%",
//                             transform: "translateX(-50%) translateY(-50%)",
//                         }}
//                     ></div>
//                 </div>

//                 <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
//                     {/* <Subgrid /> */}
//                 </MantineProvider>


//                     {/* Expanded Content */}
//                     {expandedHeight > 0 && (
//                         <div
//                             style={{
//                                 padding: "10px",
//                                 maxHeight: "calc(100% - 40px)", // Max height based on available space
//                                 overflowY: "auto", // Allow scrolling within content if it overflows
//                                 display: "grid", // Use grid for layout
//                                 gridTemplateColumns: "repeat(3, 1fr)", // 3 columns per row
//                                 gap: "16px", // Gap between items
//                             }}
//                         >
//                             {markers.map((marker) => (
//                                 <div
//                                     key={marker._id}
//                                     className="card position-relative overflow-hidden"
//                                     style={{
//                                         borderRadius: "10px !important",
//                                         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                                         display: "flex",
//                                         flexDirection: "column",
//                                         width: "100%",  // Ensure full width
//                                         height: "100%", // Set height to make it square
//                                         paddingBottom: "100%", // Maintain aspect ratio (square)
//                                         position: "relative", // Important for absolute positioning inside card
//                                     }}
//                                     onClick={() => handleToggleText(marker._id)} // Toggle text visibility on click
//                                 >
//                                     {/* Media rendering (image or video) */}
//                                     <div className="media-container" style={{ height: "100%", width: "100%" }}>
//                                         {marker.media?.[0]?.url && (
//                                             marker.media[0].url.includes(".mp4") ? (
//                                                 <video
//                                                     ref={videoRef}
//                                                     className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                     src={`https://${marker.media[0].url}`}
//                                                     muted
//                                                     loop
//                                                     playsInline
//                                                 />
//                                             ) : (
//                                                 <img
//                                                     src={`https://${marker.media[0].url}`}
//                                                     alt={marker.title}
//                                                     className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                 />
//                                             )
//                                         )}
//                                     </div>

//                                     {/* Card body with the title, description, and location */}
//                                     {marker.showText && (
//                                         <div
//                                             className="card-body position-relative z-index-2 text-white p-3"
//                                             style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
//                                         >
//                                             <p className="price fs-5 mb-1">{marker.title}</p>
//                                             <div className="details">
//                                                 <span>{marker.description}</span>
//                                                 {marker.address && (
//                                                     <p
//                                                         className="location"
//                                                         style={{ fontSize: "14px", color: "#ddd" }}
//                                                     >
//                                                         {marker.address}, {marker.city}
//                                                     </p>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </AppContainer>
//     );
// };

// export default HomePage;


// ok v1.5 fix the swipe button ref snapchat for ui design
// import React, { useState, useEffect, useRef } from "react";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import axios from "axios";

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';

// const HomePage = () => {
//     const defaultProps = {
//         center: {
//             lat: 21.044175, // Center to San Francisco
//             lng: 105.846411,
//         },
//         zoom: 10,
//     };

//     const [markers, setMarkers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [expandedHeight, setExpandedHeight] = useState(53); // Use number for comparison, not string
//     const [startTouchY, setStartTouchY] = useState(null);

//     const videoRef = useRef(null);

//     // Fetch markers from the API
//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);

//         // Retrieve token from localStorage
//         const token = localStorage.getItem('token');

//         try {
//             const response = await axios.get(apiBaseUrl + 'own', {
//                 headers: {
//                     Authorization: `Bearer ${token}`, // Add token to the Authorization header
//                 },
//             });

//             setMarkers(response.data); // Update the state with fetched markers
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers(); // Fetch markers when component mounts
//     }, []);

//     // Handlers for swipe interactions
//     const handleTouchStart = (e) => {
//         const touchStart = e.touches[0].clientY;
//         setStartTouchY(touchStart);
//     };

//     const handleTouchMove = (e) => {
//         if (startTouchY === null) return; // Ignore if no touch started
//         const touchMove = e.touches[0].clientY;
//         const diff = startTouchY - touchMove; // Calculate the difference in vertical movement
//         const newHeight = Math.max(0, expandedHeight + diff); // Prevent negative height values
//         setExpandedHeight(newHeight); // Dynamically change height
//         setStartTouchY(touchMove); // Update start position for smooth movement
//     };

//     const handleTouchEnd = () => {
//         if (expandedHeight > 50) {
//             setExpandedHeight(50); // Expand to 50%
//         } else if (expandedHeight < 33) {
//             setExpandedHeight(33); // Collapse to 33%
//         }
//         setStartTouchY(null); // Reset touch position
//     };

//     // Toggle between showing text or not on click
//     const handleToggleText = (id) => {
//         setMarkers((prevMarkers) => {
//             return prevMarkers.map((marker) => {
//                 if (marker._id === id) {
//                     return {
//                         ...marker,
//                         showText: !marker.showText, // Toggle text visibility
//                     };
//                 }
//                 return marker;
//             });
//         });
//     };

//     return (
//         <AppContainer>
//             <Header />
//             <div className="app" style={{ height: "100vh", width: "100%", position: "relative" }}>
//                 {/* Google Map */}
//                 <GoogleMapReact
//                     bootstrapURLKeys={{ key: "" }} // Ensure your API key is valid
//                     defaultCenter={defaultProps.center}
//                     defaultZoom={defaultProps.zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                     options={{
//                         gestureHandling: 'greedy',
//                     }}
//                     style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: `calc(100vh - ${expandedHeight}vh)` // Map height adjusts with expanded area
//                     }}
//                 >
//                     {loading ? (
//                         <p>Loading markers...</p>
//                     ) : error ? (
//                         <p>{error}</p> // Display error message if any error occurred
//                     ) : (
//                         markers.map((marker) => (
//                             <Marker
//                                 key={marker._id} // Ensure each marker has a unique key
//                                 lat={marker.location.coordinates[0]} // Latitude at index 0
//                                 lng={marker.location.coordinates[1]} // Longitude at index 1
//                                 text={marker.title} // Title of the marker
//                             />
//                         ))
//                     )}
//                 </GoogleMapReact>

//                 {/* Swipeable Horizontal Bar */}

//                 {/* Expanded Area */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         bottom: "30px", // Adjust to stay above the swipeable bar
//                         left: "0",
//                         width: "100%",
//                         height: `${expandedHeight}vh`, // Dynamic height based on expandedHeight state
//                         backgroundColor: "#f5f5f5",
//                         overflowY: "auto",
//                         transition: "height 0.3s ease-in-out", // Smooth transition for height change
//                         borderTopLeftRadius: "20px", // Rounded top left corner
//                         borderTopRightRadius: "20px", // Rounded top right corner
//                         boxShadow: "0px -4px 10px rgba(0,0,0,0.1)",
//                         zIndex: 9, // Ensure it's beneath the swipeable bar
//                     }}
//                 >
//                 <div
//                     style={{
//                         position: "absolute",
//                         top: "0%", // Always at the bottom of the expanded area
//                         left: "0",
//                         width: "100%",
//                         height: "30px", // Fixed height for the swipeable bar
//                         backgroundColor: "#f5f5f5",
//                         cursor: "pointer",
//                         zIndex: 10,
//                     }}
//                     onTouchStart={handleTouchStart}
//                     onTouchMove={handleTouchMove}
//                     onTouchEnd={handleTouchEnd}
//                 >
//                     <div
//                         style={{
//                             position: "absolute",
//                             width: "40px",
//                             height: "5px",
//                             backgroundColor: "#D3D3D3",
//                             borderRadius: "3px",
//                             top: "50%",
//                             left: "50%",
//                             transform: "translateX(-50%) translateY(-50%)",
//                         }}
//                     ></div>
//                 </div>


//                     {/* Expanded Content */}
//                     {expandedHeight > 0 && (
//                         <div
//                             style={{
//                                 padding: "10px",
//                                 maxHeight: "calc(100% - 40px)", // Max height based on available space
//                                 overflowY: "auto", // Allow scrolling within content if it overflows
//                                 display: "grid", // Use grid for layout
//                                 gridTemplateColumns: "repeat(3, 1fr)", // 3 columns per row
//                                 gap: "16px", // Gap between items
//                             }}
//                         >
//                             {markers.map((marker) => (
//                                 <div
//                                     key={marker._id}
//                                     className="card position-relative overflow-hidden"
//                                     style={{
//                                         borderRadius: "10px",
//                                         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                                         display: "flex",
//                                         flexDirection: "column",
//                                         width: "100%",  // Ensure full width
//                                         height: "100%", // Set height to make it square
//                                         paddingBottom: "100%", // Maintain aspect ratio (square)
//                                         position: "relative", // Important for absolute positioning inside card
//                                     }}
//                                     onClick={() => handleToggleText(marker._id)} // Toggle text visibility on click
//                                 >
//                                     {/* Media rendering (image or video) */}
//                                     <div className="media-container" style={{ height: "100%", width: "100%" }}>
//                                         {marker.media?.[0]?.url && (
//                                             marker.media[0].url.includes(".mp4") ? (
//                                                 <video
//                                                     ref={videoRef}
//                                                     className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                     src={`https://${marker.media[0].url}`}
//                                                     muted
//                                                     loop
//                                                     playsInline
//                                                 />
//                                             ) : (
//                                                 <img
//                                                     src={`https://${marker.media[0].url}`}
//                                                     alt={marker.title}
//                                                     className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                 />
//                                             )
//                                         )}
//                                     </div>

//                                     {/* Card body with the title, description, and location */}
//                                     {marker.showText && (
//                                         <div
//                                             className="card-body position-relative z-index-2 text-white p-3"
//                                             style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
//                                         >
//                                             <p className="price fs-5 mb-1">{marker.title}</p>
//                                             <div className="details">
//                                                 <span>{marker.description}</span>
//                                                 {marker.address && (
//                                                     <p
//                                                         className="location"
//                                                         style={{ fontSize: "14px", color: "#ddd" }}
//                                                     >
//                                                         {marker.address}, {marker.city}
//                                                     </p>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </AppContainer>
//     );
// };

// export default HomePage;



// ok v1.4 make the card square
// import React, { useState, useEffect, useRef } from "react";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import axios from "axios";

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';

// const HomePage = () => {
//     const defaultProps = {
//         center: {
//             lat: 21.044175, // Center to San Francisco
//             lng: 105.846411,
//         },
//         zoom: 10,
//     };

//     const [markers, setMarkers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [expandedHeight, setExpandedHeight] = useState(53); // Use number for comparison, not string
//     const [startTouchY, setStartTouchY] = useState(null);

//     const videoRef = useRef(null);

//     // Fetch markers from the API
//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);

//         // Retrieve token from localStorage
//         const token = localStorage.getItem('token');

//         try {
//             const response = await axios.get(apiBaseUrl + 'own', {
//                 headers: {
//                     Authorization: `Bearer ${token}`, // Add token to the Authorization header
//                 },
//             });

//             setMarkers(response.data); // Update the state with fetched markers
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers(); // Fetch markers when component mounts
//     }, []);

//     // Handlers for swipe interactions
//     const handleTouchStart = (e) => {
//         const touchStart = e.touches[0].clientY;
//         setStartTouchY(touchStart);
//     };

//     const handleTouchMove = (e) => {
//         if (startTouchY === null) return; // Ignore if no touch started
//         const touchMove = e.touches[0].clientY;
//         const diff = startTouchY - touchMove; // Calculate the difference in vertical movement
//         const newHeight = Math.max(0, expandedHeight + diff); // Prevent negative height values
//         setExpandedHeight(newHeight); // Dynamically change height
//         setStartTouchY(touchMove); // Update start position for smooth movement
//     };

//     const handleTouchEnd = () => {
//         if (expandedHeight > 50) {
//             setExpandedHeight(50); // Expand to 50%
//         } else if (expandedHeight < 33) {
//             setExpandedHeight(33); // Collapse to 33%
//         }
//         setStartTouchY(null); // Reset touch position
//     };

//     // Toggle between showing text or not on click
//     const handleToggleText = (id) => {
//         setMarkers((prevMarkers) => {
//             return prevMarkers.map((marker) => {
//                 if (marker._id === id) {
//                     return {
//                         ...marker,
//                         showText: !marker.showText, // Toggle text visibility
//                     };
//                 }
//                 return marker;
//             });
//         });
//     };

//     return (
//         <AppContainer>
//             <Header />
//             <div className="app" style={{ height: "100vh", width: "100%", position: "relative" }}>
//                 <GoogleMapReact
//                     bootstrapURLKeys={{ key: "" }} // Ensure your API key is valid
//                     defaultCenter={defaultProps.center}
//                     defaultZoom={defaultProps.zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                     options={{
//                         gestureHandling: 'greedy',
//                     }}
//                 >
//                     {loading ? (
//                         <p>Loading markers...</p>
//                     ) : error ? (
//                         <p>{error}</p> // Display error message if any error occurred
//                     ) : (
//                         markers.map((marker) => (
//                             <Marker
//                                 key={marker._id} // Ensure each marker has a unique key
//                                 lat={marker.location.coordinates[0]} // Latitude at index 0
//                                 lng={marker.location.coordinates[1]} // Longitude at index 1
//                                 text={marker.title} // Title of the marker
//                             />
//                         ))
//                     )}
//                 </GoogleMapReact>

//                 {/* Swipeable Horizontal Bar */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         bottom: "0",
//                         left: "0",
//                         width: "100%",
//                         height: "30px", // Fixed height for the swipeable bar
//                         backgroundColor: "#007bff",
//                         cursor: "pointer",
//                         zIndex: 10,
//                     }}
//                     onTouchStart={handleTouchStart}
//                     onTouchMove={handleTouchMove}
//                     onTouchEnd={handleTouchEnd}
//                 >
//                     <div
//                         style={{
//                             position: "absolute",
//                             width: "40px",
//                             height: "5px",
//                             backgroundColor: "#f5f5f5",
//                             borderRadius: "3px",
//                             top: "50%",
//                             left: "50%",
//                             transform: "translateX(-50%) translateY(-50%)",
//                         }}
//                     ></div>
//                 </div>

//                 {/* Expanded Area */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         bottom: "30px", // Adjust to stay above the swipeable bar
//                         left: "0",
//                         width: "100%",
//                         height: `${expandedHeight}vh`, // Dynamic height based on expandedHeight state
//                         backgroundColor: "#f5f5f5",
//                         overflowY: "auto",
//                         transition: "height 0.3s ease-in-out", // Smooth transition for height change
//                         borderTopLeftRadius: "20px", // Rounded top left corner
//                         borderTopRightRadius: "20px", // Rounded top right corner
//                         boxShadow: "0px -4px 10px rgba(0,0,0,0.1)",
//                         zIndex: 9, // Ensure it's beneath the swipeable bar
//                     }}
//                 >
//                     {/* Expanded Content */}
//                     {expandedHeight > 0 && (
//                         <div
//                             style={{
//                                 padding: "10px",
//                                 maxHeight: "calc(100% - 40px)", // Max height based on available space
//                                 overflowY: "auto", // Allow scrolling within content if it overflows
//                                 display: "grid", // Use grid for layout
//                                 gridTemplateColumns: "repeat(3, 1fr)", // 3 columns per row
//                                 gap: "16px", // Gap between items
//                             }}
//                         >
//                             {markers.map((marker) => (
//                                 <div
//                                     key={marker._id}
//                                     className="card position-relative overflow-hidden"
//                                     style={{
//                                         borderRadius: "10px",
//                                         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                                         display: "flex",
//                                         flexDirection: "column",
//                                         width: "100%",  // Ensure full width
//                                         height: "100%", // Set height to make it square
//                                         paddingBottom: "100%", // Maintain aspect ratio (square)
//                                         position: "relative", // Important for absolute positioning inside card
//                                     }}
//                                     onClick={() => handleToggleText(marker._id)} // Toggle text visibility on click
//                                 >
//                                     {/* Media rendering (image or video) */}
//                                     <div className="media-container" style={{ height: "100%", width: "100%" }}>
//                                         {marker.media?.[0]?.url && (
//                                             marker.media[0].url.includes(".mp4") ? (
//                                                 <video
//                                                     ref={videoRef}
//                                                     className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                     src={`https://${marker.media[0].url}`}
//                                                     muted
//                                                     loop
//                                                     playsInline
//                                                 />
//                                             ) : (
//                                                 <img
//                                                     src={`https://${marker.media[0].url}`}
//                                                     alt={marker.title}
//                                                     className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                 />
//                                             )
//                                         )}
//                                     </div>

//                                     {/* Card body with the title, description, and location */}
//                                     {marker.showText && (
//                                         <div
//                                             className="card-body position-relative z-index-2 text-white p-3"
//                                             style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
//                                         >
//                                             <p className="price fs-5 mb-1">{marker.title}</p>
//                                             <div className="details">
//                                                 <span>{marker.description}</span>
//                                                 {marker.address && (
//                                                     <p
//                                                         className="location"
//                                                         style={{ fontSize: "14px", color: "#ddd" }}
//                                                     >
//                                                         {marker.address}, {marker.city}
//                                                     </p>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </AppContainer>
//     );
// };

// export default HomePage;


// ok v1.3  add data first, expandable later
// import React, { useState, useEffect, useRef } from "react";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import axios from "axios";

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';

// const HomePage = () => {
//     const defaultProps = {
//         center: {
//             lat: 21.044175, // Center to San Francisco
//             lng: 105.846411,
//         },
//         zoom: 10,
//     };

//     const [markers, setMarkers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [expandedHeight, setExpandedHeight] = useState(53); // Use number for comparison, not string
//     const [startTouchY, setStartTouchY] = useState(null);

//     const videoRef = useRef(null);

//     // Fetch markers from the API
//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);

//         // Retrieve token from localStorage
//         const token = localStorage.getItem('token');

//         try {
//             const response = await axios.get(apiBaseUrl + 'own', {
//                 headers: {
//                     Authorization: `Bearer ${token}`, // Add token to the Authorization header
//                 },
//             });

//             setMarkers(response.data); // Update the state with fetched markers
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers(); // Fetch markers when component mounts
//     }, []);

//     // Handlers for swipe interactions
//     const handleTouchStart = (e) => {
//         const touchStart = e.touches[0].clientY;
//         setStartTouchY(touchStart);
//     };

//     const handleTouchMove = (e) => {
//         if (startTouchY === null) return; // Ignore if no touch started
//         const touchMove = e.touches[0].clientY;
//         const diff = startTouchY - touchMove; // Calculate the difference in vertical movement
//         const newHeight = Math.max(0, expandedHeight + diff); // Prevent negative height values
//         setExpandedHeight(newHeight); // Dynamically change height
//         setStartTouchY(touchMove); // Update start position for smooth movement
//     };

//     const handleTouchEnd = () => {
//         if (expandedHeight > 50) {
//             setExpandedHeight(50); // Expand to 50%
//         } else if (expandedHeight < 33) {
//             setExpandedHeight(33); // Collapse to 33%
//         }
//         setStartTouchY(null); // Reset touch position
//     };

//     // Toggle between showing text or not on click
//     const handleToggleText = (id) => {
//         setMarkers((prevMarkers) => {
//             return prevMarkers.map((marker) => {
//                 if (marker._id === id) {
//                     return {
//                         ...marker,
//                         showText: !marker.showText, // Toggle text visibility
//                     };
//                 }
//                 return marker;
//             });
//         });
//     };

//     return (
//         <AppContainer>
//             <Header />
//             <div className="app" style={{ height: "100vh", width: "100%", position: "relative" }}>
//                 <GoogleMapReact
//                     bootstrapURLKeys={{ key: "" }} // Ensure your API key is valid
//                     defaultCenter={defaultProps.center}
//                     defaultZoom={defaultProps.zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                     options={{
//                         gestureHandling: 'greedy',
//                     }}
//                 >
//                     {loading ? (
//                         <p>Loading markers...</p>
//                     ) : error ? (
//                         <p>{error}</p> // Display error message if any error occurred
//                     ) : (
//                         markers.map((marker) => (
//                             <Marker
//                                 key={marker._id} // Ensure each marker has a unique key
//                                 lat={marker.location.coordinates[0]} // Latitude at index 0
//                                 lng={marker.location.coordinates[1]} // Longitude at index 1
//                                 text={marker.title} // Title of the marker
//                             />
//                         ))
//                     )}
//                 </GoogleMapReact>

//                 {/* Swipeable Horizontal Bar */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         bottom: "0",
//                         left: "0",
//                         width: "100%",
//                         height: "30px", // Fixed height for the swipeable bar
//                         backgroundColor: "#007bff",
//                         cursor: "pointer",
//                         zIndex: 10,
//                     }}
//                     onTouchStart={handleTouchStart}
//                     onTouchMove={handleTouchMove}
//                     onTouchEnd={handleTouchEnd}
//                 >
//                     <div
//                         style={{
//                             position: "absolute",
//                             width: "40px",
//                             height: "5px",
//                             backgroundColor: "#fff",
//                             borderRadius: "3px",
//                             top: "50%",
//                             left: "50%",
//                             transform: "translateX(-50%) translateY(-50%)",
//                         }}
//                     ></div>
//                 </div>

//                 {/* Expanded Area */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         bottom: "30px", // Adjust to stay above the swipeable bar
//                         left: "0",
//                         width: "100%",
//                         height: `${expandedHeight}vh`, // Dynamic height based on expandedHeight state
//                         backgroundColor: "#fff",
//                         overflowY: "auto",
//                         transition: "height 0.3s ease-in-out", // Smooth transition for height change
//                         borderTopLeftRadius: "20px", // Rounded top left corner
//                         borderTopRightRadius: "20px", // Rounded top right corner
//                         boxShadow: "0px -4px 10px rgba(0,0,0,0.1)",
//                         zIndex: 9, // Ensure it's beneath the swipeable bar
//                     }}
//                 >
//                     {/* Expanded Content */}
//                     {expandedHeight > 0 && (
//                         <div
//                             style={{
//                                 padding: "10px",
//                                 maxHeight: "calc(100% - 40px)", // Max height based on available space
//                                 overflowY: "auto", // Allow scrolling within content if it overflows
//                                 display: "grid", // Use grid for layout
//                                 gridTemplateColumns: "repeat(3, 1fr)", // 3 columns per row
//                                 gap: "16px", // Gap between items
//                             }}
//                         >
//                             {markers.map((marker) => (
//                                 <div
//                                     key={marker._id}
//                                     className="card position-relative overflow-hidden"
//                                     style={{
//                                         borderRadius: "10px",
//                                         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                                         display: "flex",
//                                         flexDirection: "column",
//                                     }}
//                                     onClick={() => handleToggleText(marker._id)} // Toggle text visibility on click
//                                 >
//                                     {/* Media rendering (image or video) */}
//                                     <div className="media-container">
//                                         {marker.media?.[0]?.url && (
//                                             marker.media[0].url.includes(".mp4") ? (
//                                                 <video
//                                                     ref={videoRef}
//                                                     className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                     src={`https://${marker.media[0].url}`}
//                                                     muted
//                                                     loop
//                                                     playsInline
//                                                 />
//                                             ) : (
//                                                 <img
//                                                     src={`https://${marker.media[0].url}`}
//                                                     alt={marker.title}
//                                                     className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                                                 />
//                                             )
//                                         )}
//                                     </div>

//                                     {/* Card body with the title, description, and location */}
//                                     {marker.showText && (
//                                         <div
//                                             className="card-body position-relative z-index-2 text-white p-3"
//                                             style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
//                                         >
//                                             <p className="price fs-5 mb-1">{marker.title}</p>
//                                             <div className="details">
//                                                 <span>{marker.description}</span>
//                                                 {marker.address && (
//                                                     <p
//                                                         className="location"
//                                                         style={{ fontSize: "14px", color: "#ddd" }}
//                                                     >
//                                                         {marker.address}, {marker.city}
//                                                     </p>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </AppContainer>
//     );
// };

// export default HomePage;




// failed v1.2 replace the button with some swipe action
// import React, { useState, useEffect } from "react";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import axios from "axios";

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';

// const HomePage = () => {
//     const defaultProps = {
//         center: {
//             lat: 21.044175, // Center to San Francisco
//             lng: 105.846411,
//         },
//         zoom: 10,
//     };

//     const [markers, setMarkers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [expandedHeight, setExpandedHeight] = useState('33vh'); // Start with 1/3 screen height
//     const [startTouchY, setStartTouchY] = useState(null); // Track touch start position

//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);

//         // Retrieve token from localStorage
//         const token = localStorage.getItem('token');

//         try {
//             const response = await axios.get(apiBaseUrl + 'own', {
//                 headers: {
//                     Authorization: `Bearer ${token}`, // Add token to the Authorization header
//                 },
//             });

//             setMarkers(response.data); // Update the state with fetched markers
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     // Handlers for swipe interactions
//     const handleTouchStart = (e) => {
//         const touchStart = e.touches[0].clientY;
//         setStartTouchY(touchStart);
//     };

//     const handleTouchMove = (e) => {
//         if (startTouchY === null) return; // Ignore if no touch started
//         const touchMove = e.touches[0].clientY;
//         const diff = startTouchY - touchMove; // Calculate the difference in vertical movement
//         const newHeight = Math.max(0, expandedHeight + diff); // Prevent negative height values
//         setExpandedHeight(newHeight); // Dynamically change height
//         setStartTouchY(touchMove); // Update start position for smooth movement
//     };

//     const handleTouchEnd = () => {
//         if (expandedHeight > '50vh') {
//             setExpandedHeight('50vh'); // Expand to 1/2 screen height
//         } else if (expandedHeight < '33vh') {
//             setExpandedHeight('33vh'); // Collapse back to 1/3 screen height
//         }
//         setStartTouchY(null); // Reset touch position
//     };

//     return (
//         <AppContainer>
//             <Header />
//             <div className="app" style={{ height: "100vh", width: "100%", position: "relative" }}>
//                 <GoogleMapReact
//                     bootstrapURLKeys={{ key: "" }} // Replace with your Google Maps API key
//                     defaultCenter={defaultProps.center}
//                     defaultZoom={defaultProps.zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                     options={{
//                         gestureHandling: 'greedy',
//                     }}
//                 >
//                     {loading ? (
//                         <p>Loading markers...</p>
//                     ) : (
//                         markers.map((marker) => (
//                             <Marker
//                                 key={marker._id} // Ensure each marker has a unique key
//                                 lat={marker.location.coordinates[0]} // Latitude at index 1
//                                 lng={marker.location.coordinates[1]} // Longitude at index 0
//                                 text={marker.title} // Title of the marker
//                             />
//                         ))
//                     )}
//                 </GoogleMapReact>

//                 {/* Swipeable Horizontal Bar */}

//             </div>
//         </AppContainer>
//     );
// };

// export default HomePage;




// ok (but fix the header on pc screen, it is taking space, not lay no top of the map, so it pushes the whole page down, nice on mobile those) v1.1 add retractable cart list, fix onehand move map
// import React, { useState, useEffect } from "react";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import axios from "axios";

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';

// const HomePage = () => {
//     const defaultProps = {
//         center: {
//             lat:  21.044175, // Center to San Francisco
//             lng: 105.846411,
//         },
//         zoom: 10,
//     };

//     const [markers, setMarkers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [cartVisible, setCartVisible] = useState(false); // State for toggling the cart visibility

//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);

//         // Retrieve token from localStorage
//         const token = localStorage.getItem('token');

//         try {
//             // Include the token in the Authorization header for the API request
//             const response = await axios.get(apiBaseUrl + 'own', {
//                 headers: {
//                     Authorization: `Bearer ${token}`, // Add token to the Authorization header
//                 },
//             });

//             console.log("########### fetch response App.js ######### ");
//             console.log(response.data);
//             setMarkers(response.data); // Update the state with fetched markers
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     return (
//         <AppContainer>
//             <Header />
//             <div className="app" style={{ height: "100vh", width: "100%", position: "relative" }}>
//                 <GoogleMapReact
//                     bootstrapURLKeys={{ key: "" }} // Replace with your Google Maps API key
//                     defaultCenter={defaultProps.center}
//                     defaultZoom={defaultProps.zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                     options={{
//                       gestureHandling: 'greedy', // Dynamically set gesture handling // Disable map pointer and interactions (e.g., drag, zoom)
//                       // scrollwheel: false,       // Disable scroll zoom
//                       // draggable: false,         // Disable dragging
//                       // disableDoubleClickZoom: true, // Disable double-click zoom
//                     }}


//                 >
//                     {loading ? (
//                         <p>Loading markers...</p>
//                     ) : (
//                         markers.map((marker) => (
//                             <Marker
//                                 key={marker._id} // Ensure each marker has a unique key
//                                 lat={marker.location.coordinates[0]} // Latitude at index 1
//                                 lng={marker.location.coordinates[1]} // Longitude at index 0
//                                 text={marker.title} // Title of the marker
//                             />
//                         ))
//                     )}
//                 </GoogleMapReact>

//                 {/* Cart Button */}
//                 <button
//                     onClick={() => setCartVisible(!cartVisible)}
//                     style={{
//                         position: "fixed",
//                         bottom: "10%",
//                         left: "50%",
//                         transform: "translateX(-50%)",
//                         backgroundColor: "#007bff",
//                         color: "#fff",
//                         border: "none",
//                         padding: "10px 20px",
//                         borderRadius: "25px",
//                         cursor: "pointer",
//                         zIndex: 20,
//                         boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"
//                     }}
//                 >
//                     {cartVisible ? "Hide Cart" : "Show Cart"}
//                 </button>

//                 {/* Cart List */}
//                 <div
//                     style={{
//                         position: "absolute",
//                         bottom: "0",
//                         left: "0",
//                         width: "100%",
//                         height: cartVisible ? "33%" : "0", // Control height based on cart visibility
//                         backgroundColor: "#fff",
//                         overflowY: "auto",
//                         transition: "height 0.3s ease-in-out", // Smooth animation for expanding and collapsing
//                         borderTopLeftRadius: "20px", // Rounded top left corner
//                         borderTopRightRadius: "20px", // Rounded top right corner
//                         boxShadow: "0px -4px 10px rgba(0,0,0,0.1)",
//                         zIndex: 10,
//                     }}
//                 >
//                     {/* Cart List Content */}
//                     {cartVisible && (
//                         <div style={{ padding: "10px", maxHeight: "calc(100% - 40px)", overflowY: "auto" }}>
//                             {markers.map((marker) => (
//                                 <div key={marker._id} style={{ padding: "8px", borderBottom: "1px solid #eee" }}>
//                                     <strong>{marker.title}</strong>
//                                     <p>{marker.description}</p>
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </AppContainer>
//     );
// };

// export default HomePage;




// ok v1 only map
// import React, { useState, useEffect } from "react";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import axios from "axios";
// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';

// const HomePage = () => {
//     const defaultProps = {
//         center: {
//             lat:  21.044175, // Center to San Francisco
//             lng: 105.846411,
//         },
//         zoom: 10,
//     };

//     const [markers, setMarkers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);

//         // Retrieve token from localStorage
//         const token = localStorage.getItem('token');

//         try {
//           // Include the token in the Authorization header for the API request
//           const response = await axios.get(apiBaseUrl+'own', {
//             headers: {
//               Authorization: `Bearer ${token}`, // Add token to the Authorization header
//             },
//           });

//           // setPlaces(response.data)
//           console.log("########### fetch response App.js ######### ");
//           console.log(response.data);
//           setMarkers(response.data);  // Update the state with fetched markers
//         } catch (error) {
//           setError('Error fetching markers');
//           console.error('Error fetching markers:', error);
//         } finally {
//           setLoading(false);
//         }
//     };



//     const [hoveredMarker, setHoveredMarker] = useState(null);


//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     return (
//         <AppContainer>
//             <Header/>
//             <div className="app" style={{ height: "100vh", width: "100%" }}>
//             <GoogleMapReact
//                 bootstrapURLKeys={{ key: "" }} // Replace with your Google Maps API key
//                 defaultCenter={defaultProps.center}
//                 defaultZoom={defaultProps.zoom}
//                 yesIWantToUseGoogleMapApiInternals
//             >
//                 {/* <Marker
//                     lat={21.044175} // Latitude for the marker
//                     lng={105.846411} // Longitude for the marker
//                     text="San Francisco"
//                     isHovered={hoveredMarker === 1}
//                     onMouseEnter={() => setHoveredMarker(1)}
//                     onMouseLeave={() => setHoveredMarker(null)}
//                 /> */}

//                 {loading ? (
//                     <p>Loading markers...</p>
//                   ) : (
//                     markers.map((marker) => (
//                       <Marker
//                         key={marker._id} // Ensure each marker has a unique key
//                         lat={marker.location.coordinates[0]} // Latitude at index 1
//                         lng={marker.location.coordinates[1]} // Longitude at index 0
//                         text={marker.title} // Title of the marker
//                         // property={marker}
//                         // mapRef={mapRef}
//                         // onClick={handleMarkerClick} // Trigger the handleMarkerClick function
//                         // selectedPlace={selectedPlace}
//                         // setGestureHandling={setGestureHandling}
//                       />
//                     ))
//                   )}
//             </GoogleMapReact>
//         </div>
//         </AppContainer>

//     );
// };

// export default HomePage;





