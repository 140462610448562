


// v4 fix pop up: cannot put mouse on popup and scroll now, scroll on mobile did not work either
// on duckduck android, not on chrome, review this later,


// ok v3.4  css img cutoff
import React, { useEffect, useState } from 'react';

const FullScreenSidebar = ({ property, onClose, mapRef }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust based on your mobile breakpoint
    };

    // Check on initial load
    handleResize();

    // Add event listener for resizing
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (mapRef?.current && mapRef.current.googleMap) {
      // If the mapRef has a current reference and the map instance exists
      const mapContainer = mapRef.current.googleMap.getDiv();

      // Disable pointer events on the map container when sidebar is open
      mapContainer.style.pointerEvents = 'none';

      // Re-enable pointer events when sidebar is closed
      return () => {
        mapContainer.style.pointerEvents = 'auto';
      };
    }
  }, [mapRef]);

  if (!property) return null; // If no property is passed, return null.

  // Extract property data
  const { title, address, price, city, description, media } = property;

  return (
    <div
      // className='popup-helper'
      style={{
        position: 'fixed', // Fixed to viewport
        top: isMobile ? '-19vh' : '50%', // Vertically center on desktop, 1/3 from top on mobile
        // top: '55vh !important' ,
        left: isMobile ? '30%' : '2%', // Center horizontally on mobile, keep left on desktop
        transform: isMobile ? 'translateX(-50%)' : 'translate(-50%, -50%)', // Center correctly
        width: isMobile ? '90vw' : '33vw', // 1/3 of the viewport width for both, but centered on mobile
        height: isMobile ? '29vh' : '95vh', // 1/3 height for mobile, full height for desktop
        // backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white
        backgroundColor: '#ffffff',
        padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
        borderRadius: '8px', // Add some radius for a more compact look on mobile
        zIndex: '999999 !important', // Ensure it is on top of other content
        overflowY: 'auto', // Enable scrolling if content overflows
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        willChange: 'transform', // Optimizes rendering and prevents layout shifts
        pointerEvents: 'auto', // Ensure sidebar captures clicks
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',

      }}
    >
      {/* Close button positioned to the left side */}
      <button
        onClick={onClose} // Ensure this triggers the onClose function
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px', // Move to top-left
          backgroundColor: 'transparent',
          // backgroundColor: '#F5F5F5',
          color: '#333', // Modern dark icon color
          border: 'none',
          padding: '8px',
          borderRadius: '50%',
          cursor: 'pointer',
          fontSize: '20px', // Slightly larger icon
          transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transitions

        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = '#f0f0f0'; // Change background on hover
          e.target.style.transform = 'scale(1.1)'; // Slight scaling on hover
        }}
        onMouseLeave={(e) => {
          // e.target.style.backgroundColor = 'transparent'; // Revert to transparent background
          e.target.style.transform = 'scale(1)'; // Revert scaling
        }}
      >
        <span style={{ fontWeight: 'bold', fontSize: '18px' }}>×</span> {/* Modern "X" */}
      </button>

      {/* Sidebar Title */}
      <h2>{title || "Property Details"}</h2>

      {/* Sidebar Content */}
      <div
        style={{
          width: '100%',
          flex: 1, // Ensure content takes remaining space
          overflowY: 'auto', // Allow scrolling of content if it overflows
          padding: '20px 0',
          maxHeight: '100%', // Allow media to scroll inside the container
        }}
      >
        <p><strong>Address:</strong> {address}</p>
        {/* <p><strong>Price:</strong> ${price}</p> */}
        <p><strong>City:</strong> {city}</p>
        <p><strong>Description:</strong> {description}</p>

        {/* Media Slideshow */}
        <div
          className="media-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto', // Ensure the media container scrolls when content overflows
            maxHeight: 'calc(100vh - 200px)', // Allow media to scroll but leave space for other content
            width: '100%',
          }}
        >
          {media && media.length > 0 && (
            media.map((item, index) => (
              <div key={index} className="media-item" style={{ marginBottom: '20px' }}>
                {item.type === 'video' ? (
                  <video
                    src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
                    controls
                    className="popup-video"
                    alt={city}
                    autoPlay
                    style={{
                      width: '100%',
                      height: 'auto', // Allow video to scale vertically without cutting off
                      maxHeight: '70vh', // Allow it to scale within the available space
                      objectFit: 'contain', // Ensure video scales without cutting off
                    }}
                  />
                ) : (
                  <img
                    src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
                    alt={city}
                    // className="popup-image"
                    style={{
                      width: '100%',
                      height: 'auto', // Allow image to scale vertically within the available height
                      maxHeight: '70vh', // Allow the image to scale properly within the space
                      objectFit: 'contain', // Ensures the full image is shown without cropping
                      // boxShadow: '0 0 6px rgba(0, 0, 0, 0.5)', -> does not work fix this
                      boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
                      borderRadius: '5px',
                      // border: '',



                    }}
                  />
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default FullScreenSidebar;





// ok v3.3 fix css img got cut off -> ok on duckduck android, not on chrome, review this later
// import React, { useEffect, useState } from 'react';

// const FullScreenSidebar = ({ property, onClose, mapRef }) => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768); // Adjust based on your mobile breakpoint
//     };

//     // Check on initial load
//     handleResize();

//     // Add event listener for resizing
//     window.addEventListener('resize', handleResize);

//     // Cleanup the event listener on unmount
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (mapRef?.current && mapRef.current.googleMap) {
//       // If the mapRef has a current reference and the map instance exists
//       const mapContainer = mapRef.current.googleMap.getDiv();

//       // Disable pointer events on the map container when sidebar is open
//       mapContainer.style.pointerEvents = 'none';

//       // Re-enable pointer events when sidebar is closed
//       return () => {
//         mapContainer.style.pointerEvents = 'auto';
//       };
//     }
//   }, [mapRef]);

//   if (!property) return null; // If no property is passed, return null.

//   // Extract property data
//   const { title, address, price, city, description, media } = property;

//   return (
//     <div
//       style={{
//         position: 'fixed', // Fixed to viewport
//         top: isMobile ? '20%' : '50%', // Vertically center on desktop, 1/3 from top on mobile
//         left: isMobile ? '0%' : '2%', // Center horizontally on mobile, keep left on desktop
//         transform: isMobile ? 'translateX(-50%)' : 'translate(-50%, -50%)', // Center correctly
//         width: isMobile ? '90vw' : '33vw', // 1/3 of the viewport width for both, but centered on mobile
//         height: isMobile ? '60vh' : '100vh', // 1/3 height for mobile, full height for desktop
//         backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white
//         padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
//         borderRadius: '8px', // Add some radius for a more compact look on mobile
//         zIndex: 9999, // Ensure it is on top of other content
//         overflowY: 'auto', // Enable scrolling if content overflows
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'flex-start',
//         alignItems: 'center',
//         willChange: 'transform', // Optimizes rendering and prevents layout shifts
//         pointerEvents: 'auto', // Ensure sidebar captures clicks
//       }}
//     >
//       {/* Close button positioned to the left side */}
//       <button
//         onClick={onClose} // Ensure this triggers the onClose function
//         style={{
//           position: 'absolute',
//           top: '10px',
//           left: '10px', // Move to top-left
//           backgroundColor: 'transparent',
//           color: '#333', // Modern dark icon color
//           border: 'none',
//           padding: '8px',
//           borderRadius: '50%',
//           cursor: 'pointer',
//           fontSize: '20px', // Slightly larger icon
//           transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transitions
//         }}
//         onMouseEnter={(e) => {
//           e.target.style.backgroundColor = '#f0f0f0'; // Change background on hover
//           e.target.style.transform = 'scale(1.1)'; // Slight scaling on hover
//         }}
//         onMouseLeave={(e) => {
//           e.target.style.backgroundColor = 'transparent'; // Revert to transparent background
//           e.target.style.transform = 'scale(1)'; // Revert scaling
//         }}
//       >
//         <span style={{ fontWeight: 'bold', fontSize: '18px' }}>×</span> {/* Modern "X" */}
//       </button>

//       {/* Sidebar Title */}
//       <h2>{title || "Property Details"}</h2>

//       {/* Sidebar Content */}
//       <div
//         style={{
//           width: '100%',
//           flex: 1, // Ensure content takes remaining space
//           overflowY: 'auto', // Allow scrolling of content if it overflows
//           padding: '20px 0',
//           maxHeight: '100%', // Allow media to scroll inside the container
//         }}
//       >
//         <p><strong>Address:</strong> {address}</p>
//         <p><strong>Price:</strong> ${price}</p>
//         <p><strong>City:</strong> {city}</p>
//         <p><strong>Description:</strong> {description}</p>

//         {/* Media Slideshow */}
//         <div
//           className="media-container"
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             overflowY: 'auto', // Ensure the media container scrolls when content overflows
//             maxHeight: 'calc(100vh - 200px)', // Allow media to scroll but leave space for other content
//             width: '100%',
//           }}
//         >
//           {media && media.length > 0 && (
//             media.map((item, index) => (
//               <div key={index} className="media-item" style={{ marginBottom: '20px' }}>
//                 {item.type === 'video' ? (
//                   <video
//                     src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
//                     controls
//                     className="popup-video"
//                     alt={city}
//                     autoPlay
//                     style={{
//                       width: '100%',
//                       height: 'auto', // Allow video to scale vertically without cutting off
//                       maxHeight: '70vh', // Allow it to scale within the available space
//                       objectFit: 'contain', // Ensure video scales without cutting off
//                     }}
//                   />
//                 ) : (
//                   <img
//                     src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
//                     alt={city}
//                     className="popup-image"
//                     style={{
//                       width: '100%',
//                       height: 'auto', // Allow image to scale vertically within the available height
//                       maxHeight: '70vh', // Allow the image to scale properly within the space
//                       objectFit: 'contain', // Ensures the full image is shown without cropping
//                     }}
//                   />
//                 )}
//               </div>
//             ))
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FullScreenSidebar;






// ok (only need to fix in Marker2.js, add stuff in handleMarkerClick(), and handleClosePopup()  ) v3.2 fix the potion of the map so I can scroll -> fix in other comp, not this
// import React, { useEffect, useState  } from 'react';

// const FullScreenSidebar = ({ property, onClose, mapRef }) => {

//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobile(window.innerWidth <= 768); // Adjust based on your mobile breakpoint
//         };

//         // Check on initial load
//         handleResize();

//         // Add event listener for resizing
//         window.addEventListener('resize', handleResize);

//         // Cleanup the event listener on unmount
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     useEffect(() => {
//         if (mapRef?.current && mapRef.current.googleMap) {
//             // If the mapRef has a current reference and the map instance exists
//             const mapContainer = mapRef.current.googleMap.getDiv();

//             // Disable pointer events on the map container when sidebar is open
//             mapContainer.style.pointerEvents = 'none';

//             // Re-enable pointer events when sidebar is closed
//             return () => {
//                 mapContainer.style.pointerEvents = 'auto';
//             };
//         }
//     }, [mapRef]);


//     if (!property) return null; // If no property is passed, return null.

//     // Extract property data
//     const { title, address, price, city, description, media } = property;

//     return (
//         <div
//             style={{
//                 position: 'fixed', // Fixed to viewport
//                 top: isMobile ? '20%' : '50%', // Vertically center on desktop, 1/3 from top on mobile
//                 left: isMobile ? '0%' : '23%', // Center horizontally on mobile, keep left on desktop
//                 transform: isMobile ? 'translateX(-50%)' : 'translate(-50%, -50%)', // Center correctly
//                 width: isMobile ? '90vw' : '33vw', // 1/3 of the viewport width for both, but centered on mobile
//                 height: isMobile ? '60vh' : '100vh', // 1/3 height for mobile, full height for desktop
//                 backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white
//                 padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
//                 borderRadius: isMobile ? '8px' : '8px', // Add some radius for a more compact look on mobile
//                 zIndex: 9999, // Ensure it is on top of other content
//                 overflowY: 'auto', // Enable scrolling if content overflows
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'flex-start',
//                 alignItems: 'center',
//                 willChange: 'transform', // Optimizes rendering and prevents layout shifts
//                 pointerEvents: 'auto', // Ensure sidebar captures clicks
//             }}
//         >
//             {/* Close button positioned to the left side */}
//             <button
//                 onClick={onClose} // Ensure this triggers the onClose function
//                 style={{
//                     position: 'absolute',
//                     top: '10px',
//                     left: '10px', // Move to top-left
//                     backgroundColor: 'transparent',
//                     color: '#333', // Modern dark icon color
//                     border: 'none',
//                     padding: '8px',
//                     borderRadius: '50%',
//                     cursor: 'pointer',
//                     fontSize: '20px', // Slightly larger icon
//                     transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transitions
//                 }}
//                 onMouseEnter={(e) => {
//                     e.target.style.backgroundColor = '#f0f0f0'; // Change background on hover
//                     e.target.style.transform = 'scale(1.1)'; // Slight scaling on hover
//                 }}
//                 onMouseLeave={(e) => {
//                     e.target.style.backgroundColor = 'transparent'; // Revert to transparent background
//                     e.target.style.transform = 'scale(1)'; // Revert scaling
//                 }}
//             >
//                 <span style={{ fontWeight: 'bold', fontSize: '18px' }}>×</span> {/* Modern "X" */}
//             </button>

//             {/* Sidebar Title */}
//             <h2>{title || "Property Details"}</h2>

//             {/* Sidebar Content */}
//             <div
//                 style={{
//                     width: '100%',
//                     flex: 1, // Ensure content takes remaining space
//                     overflowY: 'auto', // Allow scrolling of content if it overflows
//                     padding: '20px 0',
//                     maxHeight: '100%', // Allow media to scroll inside the container
//                 }}
//             >
//                 <p><strong>Address:</strong> {address}</p>
//                 <p><strong>Price:</strong> ${price}</p>
//                 <p><strong>City:</strong> {city}</p>
//                 <p><strong>Description:</strong> {description}</p>

//                 {/* Media Slideshow */}
//                 <div className="media-container"
//                      style={{
//                          display: 'flex',
//                          flexDirection: 'column',
//                          overflowY: 'auto', // Ensure the media container scrolls when content overflows
//                          maxHeight: 'calc(100vh - 200px)', // Adjust this depending on your design, leave room for other content
//                          width: '100%',
//                      }}
//                 >
//                     {media && media.length > 0 && (
//                         media.map((item, index) => (
//                             <div key={index} className="media-item" style={{ marginBottom: '20px' }}>
//                                 {item.type === 'video' ? (
//                                     <video
//                                         src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
//                                         controls
//                                         className="popup-video"
//                                         alt={city}
//                                         autoPlay
//                                         style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
//                                     />
//                                 ) : (
//                                     <img
//                                         src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
//                                         alt={city}
//                                         className="popup-image"
//                                         style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
//                                     />
//                                 )}
//                             </div>
//                         ))
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default FullScreenSidebar;






// ok v3.1 add over flow on phone and scroll
// import React, { useEffect, useState } from 'react';

// const FullScreenSidebar = ({ property, onClose, mapRef }) => {

//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobile(window.innerWidth <= 768); // Adjust based on your mobile breakpoint
//         };

//         // Check on initial load
//         handleResize();

//         // Add event listener for resizing
//         window.addEventListener('resize', handleResize);

//         // Cleanup the event listener on unmount
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     useEffect(() => {
//         if (mapRef?.current && mapRef.current.googleMap) {
//             // If the mapRef has a current reference and the map instance exists
//             const mapContainer = mapRef.current.googleMap.getDiv();

//             // Disable pointer events on the map container when sidebar is open
//             mapContainer.style.pointerEvents = 'none';

//             // Re-enable pointer events when sidebar is closed
//             return () => {
//                 mapContainer.style.pointerEvents = 'auto';
//             };
//         }
//     }, [mapRef]);


//     if (!property) return null; // If no property is passed, return null.

//     // Extract property data
//     const { title, address, price, city, description, media } = property;

//     return (
//         <div
//             style={{
//                 position: 'fixed', // Fixed to viewport
//                 top: isMobile ? '20%' : '50%', // Vertically center on desktop, 1/3 from top on mobile
//                 left: isMobile ? '0%' : '23%', // Center horizontally on mobile, keep left on desktop
//                 transform: isMobile ? 'translateX(-50%)' : 'translate(-50%, -50%)', // Center correctly
//                 width: isMobile ? '90vw' : '33vw', // 1/3 of the viewport width for both, but centered on mobile
//                 height: isMobile ? '60vh' : '100vh', // 1/3 height for mobile, full height for desktop
//                 backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white
//                 padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
//                 borderRadius: isMobile ? '8px' : '8px', // Add some radius for a more compact look on mobile
//                 zIndex: 9999, // Ensure it is on top of other content
//                 overflowY: 'auto', // Enable scrolling if content overflows
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'flex-start',
//                 alignItems: 'center',
//                 willChange: 'transform', // Optimizes rendering and prevents layout shifts
//                 pointerEvents: 'auto', // Ensure sidebar captures clicks
//             }}
//         >
//             {/* Close button positioned to the left side */}
//             <button
//                 onClick={onClose} // Ensure this triggers the onClose function
//                 style={{
//                     position: 'absolute',
//                     top: '10px',
//                     left: '10px', // Move to top-left
//                     backgroundColor: 'transparent',
//                     color: '#333', // Modern dark icon color
//                     border: 'none',
//                     padding: '8px',
//                     borderRadius: '50%',
//                     cursor: 'pointer',
//                     fontSize: '20px', // Slightly larger icon
//                     transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transitions
//                 }}
//                 onMouseEnter={(e) => {
//                     e.target.style.backgroundColor = '#f0f0f0'; // Change background on hover
//                     e.target.style.transform = 'scale(1.1)'; // Slight scaling on hover
//                 }}
//                 onMouseLeave={(e) => {
//                     e.target.style.backgroundColor = 'transparent'; // Revert to transparent background
//                     e.target.style.transform = 'scale(1)'; // Revert scaling
//                 }}
//             >
//                 <span style={{ fontWeight: 'bold', fontSize: '18px' }}>×</span> {/* Modern "X" */}
//             </button>

//             {/* Sidebar Title */}
//             <h2>{title || "Property Details"}</h2>

//             {/* Sidebar Content */}
//             <div
//                 style={{
//                     width: '100%',
//                     flex: 1, // Ensure content takes remaining space
//                     overflowY: 'auto', // Allow scrolling of content if it overflows
//                     padding: '20px 0',
//                     maxHeight: '100%', // Allow media to scroll inside the container
//                 }}
//             >
//                 <p><strong>Address:</strong> {address}</p>
//                 <p><strong>Price:</strong> ${price}</p>
//                 <p><strong>City:</strong> {city}</p>
//                 <p><strong>Description:</strong> {description}</p>

//                 {/* Media Slideshow */}
//                 <div className="media-container"
//                      style={{
//                          display: 'flex',
//                          flexDirection: 'column',
//                          overflowY: 'auto', // Ensure the media container scrolls when content overflows
//                          maxHeight: 'calc(100vh - 200px)', // Adjust this depending on your design, leave room for other content
//                          width: '100%',
//                      }}
//                 >
//                     {media && media.length > 0 && (
//                         media.map((item, index) => (
//                             <div key={index} className="media-item" style={{ marginBottom: '20px' }}>
//                                 {item.type === 'video' ? (
//                                     <video
//                                         src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
//                                         controls
//                                         className="popup-video"
//                                         alt={city}
//                                         autoPlay
//                                         style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
//                                     />
//                                 ) : (
//                                     <img
//                                         src={item.url.startsWith('http') ? item.url : `https://${item.url}`}
//                                         alt={city}
//                                         className="popup-image"
//                                         style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
//                                     />
//                                 )}
//                             </div>
//                         ))
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default FullScreenSidebar;




// ok v3 clone v2 fix color and phone css
// import React, { useEffect, useState } from 'react';

// const FullScreenSidebar = ({ property, onClose }) => {

//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
//         const handleResize = () => {
//           setIsMobile(window.innerWidth <= 768); // Adjust based on your mobile breakpoint
//         };

//         // Check on initial load
//         handleResize();

//         // Add event listener for resizing
//         window.addEventListener('resize', handleResize);

//         // Cleanup the event listener on unmount
//         return () => {
//           window.removeEventListener('resize', handleResize);
//         };
//   }, []);

//   if (!property) return null; // If no property is passed, return null.

//   // Extract property data
//   const { title, address, price, city, description, media } = property;

//   // State to track screen size for responsiveness


//     // Check window width on mount and resize


//   return (
//     <div
//       style={{
//         position: 'fixed', // Fixed to viewport
//         // top: '50%', // Center vertically
//         // left: '23%', // Keep the left position
//         // transform: 'translate(-50%, -50%)', // Adjust for perfect centering
//         // width: '33vw', // 1/3 of the viewport width
//         // height: '100vh', // Full height of the viewport
//         // // backgroundColor: 'white',
//         // backgroundColor: "rgba(255, 255, 255, 0.9)",
//         // padding: '20px',
//         // borderRadius: '8px',

//         top: isMobile ? '20%' : '50%', // Vertically center on desktop, 1/3 from top on mobile
//         left: isMobile ? '0%' : '23%', // Center horizontally on mobile, keep left on desktop
//         transform: isMobile ? 'translateX(-50%)' : 'translate(-50%, -50%)', // Center correctly
//         width: isMobile ? '90vw' : '33vw', // 1/3 of the viewport width for both, but centered on mobile
//         height: isMobile ? '60vh' : '100vh', // 1/3 height for mobile, full height for desktop
//         backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white
//         padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
//         borderRadius: isMobile ? '8px' : '8px', // Add some radius for a more compact look on mobile


//         zIndex: 9999, // Ensure it is on top of other content
//         overflowY: 'auto', // Enable scrolling if content overflows
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'flex-start',
//         alignItems: 'center',
//         willChange: 'transform', // Optimizes rendering and prevents layout shifts
//         pointerEvents: 'auto', // Ensure sidebar captures clicks
//       }}
//     >
//       {/* Close button positioned to the left side */}
//       <button
//         onClick={onClose} // Ensure this triggers the onClose function
//         style={{
//           position: 'absolute',
//           top: '10px',
//           left: '10px', // Move to top-left
//           backgroundColor: 'transparent',
//           color: '#333', // Modern dark icon color
//           border: 'none',
//           padding: '8px',
//           borderRadius: '50%',
//           cursor: 'pointer',
//           fontSize: '20px', // Slightly larger icon
//           transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transitions
//         }}
//         onMouseEnter={(e) => {
//           e.target.style.backgroundColor = '#f0f0f0'; // Change background on hover
//           e.target.style.transform = 'scale(1.1)'; // Slight scaling on hover
//         }}
//         onMouseLeave={(e) => {
//           e.target.style.backgroundColor = 'transparent'; // Revert to transparent background
//           e.target.style.transform = 'scale(1)'; // Revert scaling
//         }}
//       >
//         <span style={{ fontWeight: 'bold', fontSize: '18px' }}>×</span> {/* Modern "X" */}
//       </button>

//       {/* Sidebar Title */}
//       <h2>{title || "Property Details"}</h2>

//       {/* Sidebar Content */}
//       <div
//         style={{
//           width: '100%',
//           flex: 1, // Ensure content takes remaining space
//           overflowY: 'auto', // Allow scrolling of content if it overflows
//           padding: '20px 0',
//         }}
//       >
//         <p><strong>Address:</strong> {address}</p>
//         <p><strong>Price:</strong> ${price}</p>
//         <p><strong>City:</strong> {city}</p>
//         <p><strong>Description:</strong> {description}</p>

//         {/* Media Slideshow */}
//         <div className="media-container">
//           {media && media.length > 0 && (
//             media.map((item, index) => (
//               <div key={index} className="media-item">
//                 {item.type === 'video' ? (
//                   <video
//                     src={`https://${item.url}`}
//                     controls
//                     className="popup-video"
//                     alt={city}
//                     autoPlay
//                   />
//                 ) : (
//                   <img
//                     src={`https://${item.url}`}
//                     alt={city}
//                     className="popup-image"
//                   />
//                 )}
//               </div>
//             ))
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FullScreenSidebar;

// ok v2 take obj param
// import React from 'react';

// const FullScreenSidebar = ({ property, onClose }) => {
//   if (!property) return null; // If no property is passed, return null.

//   // Extract property data
//   const { title, address, price, city, description, media } = property;

//   return (
//     <div
//       style={{
//         position: 'fixed', // Fixed to viewport
//         top: '50%', // Center vertically
//         left: '23%', // Keep the left position
//         transform: 'translate(-50%, -50%)', // Adjust for perfect centering
//         width: '33vw', // 1/3 of the viewport width
//         height: '100vh', // Full height of the viewport
//         backgroundColor: 'white',
//         padding: '20px',
//         borderRadius: '8px',
//         zIndex: 9999, // Ensure it is on top of other content
//         overflowY: 'auto', // Enable scrolling if content overflows
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'flex-start',
//         alignItems: 'center',
//         willChange: 'transform', // Optimizes rendering and prevents layout shifts
//         pointerEvents: 'auto', // Ensure sidebar captures clicks
//       }}
//     >
//       {/* Close button positioned to the left side */}
//       <button
//         onClick={onClose} // Ensure this triggers the onClose function
//         style={{
//           position: 'absolute',
//           top: '10px',
//           left: '10px', // Move to top-left
//           backgroundColor: 'transparent',
//           color: '#333', // Modern dark icon color
//           border: 'none',
//           padding: '8px',
//           borderRadius: '50%',
//           cursor: 'pointer',
//           fontSize: '20px', // Slightly larger icon
//           transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transitions
//         }}
//         onMouseEnter={(e) => {
//           e.target.style.backgroundColor = '#f0f0f0'; // Change background on hover
//           e.target.style.transform = 'scale(1.1)'; // Slight scaling on hover
//         }}
//         onMouseLeave={(e) => {
//           e.target.style.backgroundColor = 'transparent'; // Revert to transparent background
//           e.target.style.transform = 'scale(1)'; // Revert scaling
//         }}
//       >
//         <span style={{ fontWeight: 'bold', fontSize: '18px' }}>×</span> {/* Modern "X" */}
//       </button>

//       {/* Sidebar Title */}
//       <h2>{title || "Property Details"}</h2>

//       {/* Sidebar Content */}
//       <div
//         style={{
//           width: '100%',
//           flex: 1, // Ensure content takes remaining space
//           overflowY: 'auto', // Allow scrolling of content if it overflows
//           padding: '20px 0',
//         }}
//       >
//         <p><strong>Address:</strong> {address}</p>
//         <p><strong>Price:</strong> ${price}</p>
//         <p><strong>City:</strong> {city}</p>
//         <p><strong>Description:</strong> {description}</p>

//         {/* Media Slideshow */}
//         <div className="media-container">
//           {media && media.length > 0 && (
//             media.map((item, index) => (
//               <div key={index} className="media-item">
//                 {item.type === 'video' ? (
//                   <video
//                     src={`https://${item.url}`}
//                     controls
//                     className="popup-video"
//                     alt={city}
//                     autoPlay
//                   />
//                 ) : (
//                   <img
//                     src={`https://${item.url}`}
//                     alt={city}
//                     className="popup-image"
//                   />
//                 )}
//               </div>
//             ))
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FullScreenSidebar;



// ok v1
// import React from 'react';

// const FullScreenSidebar = ({ title, content, onClose }) => {
//   return (
//     <div
//       style={{
//         position: 'fixed', // Fixed to viewport
//         top: '50%', // Center vertically
//         left: '23%', // Keep the left position
//         transform: 'translate(-50%, -50%)', // Adjust for perfect centering
//         width: '33vw', // 1/3 of the viewport width
//         height: '100vh', // Full height of the viewport
//         backgroundColor: 'white',
//         padding: '20px',
//         borderRadius: '8px',
//         zIndex: 9999, // Ensure it is on top of other content
//         overflowY: 'auto', // Enable scrolling if content overflows
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'flex-start',
//         alignItems: 'center',
//         willChange: 'transform', // Optimizes rendering and prevents layout shifts
//         pointerEvents: 'auto', // Ensure sidebar captures clicks
//       }}
//     >
//       {/* Close button positioned to the left side and modernized */}
//       <button
//         onClick={onClose} // Ensure this triggers the onClose function
//         style={{
//           position: 'absolute',
//           top: '10px',
//           left: '10px', // Move to top-left
//           backgroundColor: 'transparent',
//           color: '#333', // Modern dark icon color
//           border: 'none',
//           padding: '8px',
//           borderRadius: '50%',
//           cursor: 'pointer',
//           fontSize: '20px', // Slightly larger icon
//           transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transitions
//         }}
//         onMouseEnter={(e) => {
//           e.target.style.backgroundColor = '#f0f0f0'; // Change background on hover
//           e.target.style.transform = 'scale(1.1)'; // Slight scaling on hover
//         }}
//         onMouseLeave={(e) => {
//           e.target.style.backgroundColor = 'transparent'; // Revert to transparent background
//           e.target.style.transform = 'scale(1)'; // Revert scaling
//         }}
//       >
//         <span style={{ fontWeight: 'bold', fontSize: '18px' }}>×</span> {/* Modern "X" */}
//       </button>

//       {/* Title */}
//       <h2>{title}</h2>

//       {/* Content */}
//       <div
//         style={{
//           width: '100%',
//           flex: 1, // Ensure content takes remaining space
//           overflowY: 'auto', // Allow scrolling of content if it overflows
//           padding: '20px 0',
//         }}
//       >
//         {content}
//       </div>
//     </div>
//   );
// };

// export default FullScreenSidebar;
