

// v6.1, make this nicer
import React, { Component } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import './LoginClass.css'; // Import CSS file

var config = require('../config');

// Set up react-modal for accessibility
Modal.setAppElement('#root'); // this is important for screen readers

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',  // Username field
      password: '',  // Password field
      isLoggedIn: false,  // Track login status
      isSignup: false,  // Track signup form visibility
      confirmPassword: '',  // Confirm password field for signup
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleSignup = this.toggleSignup.bind(this);
  }

  // Notification methods
  notifySuccess = (message) => toast(message);
  notifyError = (message) => toast(message);

  // Check if the user is logged in when the component mounts
  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      this.setState({ isLoggedIn: true });
    }
  }

  // Handle form submission (Login and Signup)
  handleSubmit(event) {
    event.preventDefault();

    const { name, password, isSignup, confirmPassword } = this.state;

    // If signup is selected, check if passwords match
    if (isSignup && password !== confirmPassword) {
      this.notifyError("Passwords do not match!");
      return;
    }

    const data = JSON.stringify({
      "username": name,
      "password": password
    });

    const url = isSignup ? config.getAllURL + 'users/signup' : config.getAllURL + 'users/login'; // Use different endpoint for signup vs login

    const axioConfig = {
      method: 'post',
      url: url,
      headers: { 'Content-Type': 'application/json' },
      data: data
    };

    axios.request(axioConfig)
      .then(response => {
        console.log(isSignup ? "Signup successful" : "Login successful", response.data);
        this.notifySuccess(isSignup ? "Signup successful!" : "Login successful!");

        const token = response.data.token;

        // Save the token to localStorage
        localStorage.setItem('token', token);

        // Update login status in state
        this.setState({ isLoggedIn: true });

        // Close the modal after successful login/signup
        this.props.closeModal(); // Close the modal on success
      })
      .catch(error => {
        console.error(isSignup ? "Signup failed" : "Login failed", error);
        this.notifyError(isSignup ? "Signup failed!" : "Login failed!");
      });
  }

  // Handle input changes
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  // Handle logout
  handleLogout() {
    localStorage.removeItem('token');
    this.setState({ isLoggedIn: false }); // Update state to logged out
  }

  // Toggle between login and signup
  toggleSignup() {
    this.setState({
      isSignup: !this.state.isSignup,
      confirmPassword: '' // Reset confirm password field when toggling
    });
  }

  render() {
    return (
      <div>
        {/* Modal for login or signup */}
        <Modal
          isOpen={this.props.isModalOpen}  // Modal visibility controlled via props
          onRequestClose={this.props.closeModal}  // Close modal when clicked outside
          contentLabel="Login/Signup Modal"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
            {this.state.isSignup ? "Sign Up" : "Login"}
          </h2>

          <form onSubmit={this.handleSubmit}>
            <div>
              <label htmlFor='name' style={{ display: 'block', marginBottom: '5px' }}>Username</label>
              <input
                name='name'
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
                required
              />
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label htmlFor='password' style={{ display: 'block', marginBottom: '5px' }}>Password</label>
              <input
                name='password'
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
                required
                minLength={6}
              />
            </div>

            {this.state.isSignup && (
              <div style={{ marginBottom: '15px' }}>
                <label htmlFor='confirmPassword' style={{ display: 'block', marginBottom: '5px' }}>Confirm Password</label>
                <input
                  name='confirmPassword'
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                  required
                  minLength={6}
                />
              </div>
            )}

            <div style={{ marginBottom: '20px' }}>
              <button
                type="submit"
              >
                {this.state.isSignup ? "Sign Up" : "Login"}
              </button>
            </div>
          </form>

          <div style={{ textAlign: 'center' }}>
            <button
              onClick={this.toggleSignup}
              style={{
                background: 'none',
                border: 'none',
                color: '#007BFF',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {this.state.isSignup ? "Already have an account? Login" : "Don't have an account? Sign Up"}
            </button>
          </div>

          {/* Close Modal Button */}
          <button
            onClick={this.props.closeModal}
            style={{
              marginTop: '20px',
              background: '#f44336',
              color: '#fff',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            Close
          </button>

          {/* Toast Notifications */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal>

        {/* Conditional rendering of login/logout button based on login status */}
        <div className="user-status" style={{ textAlign: 'center', marginTop: '20px' }}>
          {this.state.isLoggedIn ? (
            <>
              <span  className="welcome-message">Welcome, {this.state.name}!</span> <br /> <br />
              <button
                onClick={this.handleLogout}
                className="logout"
              >
                Logout
              </button>
            </>
          ) : (
            <div className="login-prompt">
              <span >Please log in</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Login;


// ok v6 clone from 4.1 to dev: add signup form -> fix this signup form, the ui worked but get failed result, check this, might be the cors prob in the BE, add the plugin to fix this
// import React, { Component } from 'react';
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Modal from 'react-modal';

// var config = require('../config');

// // Set up react-modal for accessibility
// Modal.setAppElement('#root'); // this is important for screen readers

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: '',  // Username field
//       password: '',  // Password field
//       isLoggedIn: false,  // Track login status
//       isSignup: false,  // Track signup form visibility
//       confirmPassword: '',  // Confirm password field for signup
//     };

//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.handleLogout = this.handleLogout.bind(this);
//     this.toggleSignup = this.toggleSignup.bind(this);
//   }

//   // Notification methods
//   notifySuccess = (message) => toast(message);
//   notifyError = (message) => toast(message);

//   // Check if the user is logged in when the component mounts
//   componentDidMount() {
//     const token = localStorage.getItem('token');
//     if (token) {
//       this.setState({ isLoggedIn: true });
//     }
//   }

//   // Handle form submission (Login and Signup)
//   handleSubmit(event) {
//     event.preventDefault();

//     const { name, password, isSignup, confirmPassword } = this.state;

//     // If signup is selected, check if passwords match
//     if (isSignup && password !== confirmPassword) {
//       this.notifyError("Passwords do not match!");
//       return;
//     }

//     const data = JSON.stringify({
//       "username": name,
//       "password": password
//     });

//     const url = isSignup ? config.getAllURL + 'users/signup' : config.getAllURL + 'users/login'; // Use different endpoint for signup vs login

//     const axioConfig = {
//       method: 'post',
//       url: url,
//       headers: { 'Content-Type': 'application/json' },
//       data: data
//     };

//     axios.request(axioConfig)
//       .then(response => {
//         console.log(isSignup ? "Signup successful" : "Login successful", response.data);
//         this.notifySuccess(isSignup ? "Signup successful!" : "Login successful!");

//         const token = response.data.token;

//         // Save the token to localStorage
//         localStorage.setItem('token', token);

//         // Update login status in state
//         this.setState({ isLoggedIn: true });

//         // Close the modal after successful login/signup
//         this.props.closeModal(); // Close the modal on success
//       })
//       .catch(error => {
//         console.error(isSignup ? "Signup failed" : "Login failed", error);
//         this.notifyError(isSignup ? "Signup failed!" : "Login failed!");
//       });
//   }

//   // Handle input changes
//   handleChange(event) {
//     this.setState({
//       [event.target.name]: event.target.value
//     });
//   }

//   // Handle logout
//   handleLogout() {
//     localStorage.removeItem('token');
//     this.setState({ isLoggedIn: false }); // Update state to logged out
//   }

//   // Toggle between login and signup
//   toggleSignup() {
//     this.setState({
//       isSignup: !this.state.isSignup,
//       confirmPassword: '' // Reset confirm password field when toggling
//     });
//   }

//   render() {
//     return (
//       <div>
//         {/* Modal for login or signup */}
//         <Modal
//           isOpen={this.props.isModalOpen}  // Modal visibility controlled via props
//           onRequestClose={this.props.closeModal}  // Close modal when clicked outside
//           contentLabel="Login/Signup Modal"
//           style={{
//             overlay: {
//               backgroundColor: 'rgba(0, 0, 0, 0.75)',
//               zIndex: 9999,  // Make sure overlay is above everything
//               fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'

//             },
//             content: {
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)', // Center the modal
//               padding: '20px',
//               background: '#f5f5f5',
//               borderRadius: '4px',
//               width: '300px',
//               height: 'auto',
//               zIndex: 10000, // Ensure modal content is above overlay and other elements
//             }
//           }} // Custom styles to make the modal appear centered
//         >
//           <h2>{this.state.isSignup ? "Sign Up" : "Login"}</h2>
//           <form onSubmit={this.handleSubmit}>
//             <div>
//               <label htmlFor='name'>Username</label>
//               <input
//                 name='name'
//                 value={this.state.name}
//                 onChange={this.handleChange}
//                 required
//               />
//             </div>
//             <div>
//               <label htmlFor='password'>Password</label>
//               <input
//                 name='password'
//                 type="password"
//                 value={this.state.password}
//                 onChange={this.handleChange}
//                 required
//                 minLength={6}  // Ensure password is at least 6 characters
//               />
//             </div>
//             {this.state.isSignup && (
//               <div>
//                 <label htmlFor='confirmPassword'>Confirm Password</label>
//                 <input
//                   name='confirmPassword'
//                   type="password"
//                   value={this.state.confirmPassword}
//                   onChange={this.handleChange}
//                   required
//                   minLength={6}  // Ensure password is at least 6 characters
//                 />
//               </div>
//             )}
//             <div>
//               <button type="submit">{this.state.isSignup ? "Sign Up" : "Login"}</button>
//             </div>
//           </form>

//           {/* Toggle between login and signup */}
//           <div style={{ textAlign: 'center', marginTop: '10px' }}>
//             <button onClick={this.toggleSignup} style={{ background: 'none', border: 'none', color: '#007BFF' }}>
//               {this.state.isSignup ? "Already have an account? Login" : "Don't have an account? Sign Up"}
//             </button>
//           </div>

//           {/* Close Modal Button */}
//           <button onClick={this.props.closeModal} style={{ marginTop: '10px' }}>Close</button>

//           {/* Toast Notifications */}
//           <ToastContainer
//             position="top-right"
//             autoClose={5000}
//             hideProgressBar={false}
//             newestOnTop={false}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//             style={{ zIndex: 10002 }} // Make sure it's on top of the modal
//           />
//         </Modal>

//         {/* Conditional rendering of login/logout button based on login status */}
//         <div className="user-status">
//           {this.state.isLoggedIn ? (
//             <>
//               {/* User is logged in, show user icon and logout option */}
//               <span>Welcome, {this.state.name}!</span> <br /> <br />
//               <button onClick={this.handleLogout}>Logout</button>
//             </>
//           ) : (
//             <>
//               {/* User is not logged in, show login icon and prompt */}
//               <span>Please log in</span>
//             </>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

// export default Login;

// later v5 add img of user


// (later clone v4.1 to fix this, skip this for now, might return username in jwt so I can display it or store it in localstorage like jwt) v4.2 keep username across refresh



// ok v4.1 improve login status check even on page refresh
// import React, { Component } from 'react';
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Modal from 'react-modal';

// var config = require('../config');

// // Set up react-modal for accessibility
// Modal.setAppElement('#root'); // this is important for screen readers

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: '',
//       password: '',
//       isLoggedIn: false,  // Track login status
//     };

//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.handleLogout = this.handleLogout.bind(this);
//   }

//   // Notification methods
//   notifySuccess = () => toast("Login successful!");
//   notifyError = () => toast("Login failed!");

//   // Check if the user is logged in when the component mounts
//   componentDidMount() {
//     const token = localStorage.getItem('token');
//     if (token) {
//       this.setState({ isLoggedIn: true });
//     }
//   }

//   // Handle form submission
//   handleSubmit(event) {
//     event.preventDefault();

//     const { name, password } = this.state;

//     const data = JSON.stringify({
//       "username": name,
//       "password": password
//     });

//     const axioConfig = {
//       method: 'post',
//       url: config.getAllURL + 'users/login',
//       headers: { 'Content-Type': 'application/json' },
//       data: data
//     };

//     axios.request(axioConfig)
//       .then(response => {
//         console.log("Login successful", response.data);
//         this.notifySuccess();

//         const token = response.data.token;

//         // Log the token to the console
//         console.log("######## token ######");
//         console.log("Token:", token);

//         // Save the token to localStorage
//         localStorage.setItem('token', token);

//         // Update login status in state
//         this.setState({ isLoggedIn: true });

//         // Close the modal after successful login
//         this.props.closeModal(); // Close the modal on success
//       })
//       .catch(error => {
//         console.error("Login failed", error);
//         this.notifyError();
//       });
//   }

//   // Handle input changes
//   handleChange(event) {
//     this.setState({
//       [event.target.name]: event.target.value
//     });
//   }

//   // Handle logout
//   handleLogout() {
//     localStorage.removeItem('token');
//     this.setState({ isLoggedIn: false }); // Update state to logged out
//   }

//   render() {
//     return (
//       <div>
//         {/* Modal for login */}
//         <Modal
//           isOpen={this.props.isModalOpen}  // Modal visibility controlled via props
//           onRequestClose={this.props.closeModal}  // Close modal when clicked outside
//           contentLabel="Login Modal"
//           style={{
//             overlay: {
//               backgroundColor: 'rgba(0, 0, 0, 0.75)',
//               zIndex: 9999,  // Make sure overlay is above everything
//             },
//             content: {
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)', // Center the modal
//               padding: '20px',
//               background: '#fff',
//               borderRadius: '4px',
//               width: '300px',
//               height: 'auto',
//               zIndex: 10000, // Ensure modal content is above overlay and other elements
//             }
//           }} // Custom styles to make the modal appear centered
//         >
//           <h2>Login</h2>
//           <form onSubmit={this.handleSubmit}>
//             <div>
//               <label htmlFor='name'>User</label>
//               <input
//                 name='name'
//                 value={this.state.name}
//                 onChange={this.handleChange}
//                 required
//               />
//             </div>
//             <div>
//               <label htmlFor='password'>Password</label>
//               <input
//                 name='password'
//                 type="password"
//                 value={this.state.password}
//                 onChange={this.handleChange}
//                 required
//               />
//             </div>
//             <div>
//               <button type="submit">Login</button>
//             </div>
//           </form>

//           {/* Close Modal Button */}
//           <button onClick={this.props.closeModal}>Close</button>

//           {/* Toast Notifications */}
//           <ToastContainer
//             position="top-right"
//             autoClose={5000}
//             hideProgressBar={false}
//             newestOnTop={false}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//             style={{ zIndex: 10002 }} // Make sure it's on top of the modal
//           />
//         </Modal>

//         {/* Conditional rendering of login/logout button based on login status */}
//         <div className="user-status">
//           {this.state.isLoggedIn ? (
//             <>
//               {/* User is logged in, show user icon and logout option */}
//               <span>Welcome, {this.state.name}!</span> <br /> <br />
//               <button onClick={this.handleLogout}>Logout</button>
//             </>
//           ) : (
//             <>
//               {/* User is not logged in, show login icon and prompt */}
//               <span>Please log in</span>
//             </>
//           )}
//         </div>
//       </div>
//     );
// }
// }
// export default Login;



// ok v4 login user has diff icon -> text for now, make it nicer later
// import React, { Component } from 'react';
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Modal from 'react-modal';

// var config = require('../config');

// // Set up react-modal for accessibility
// Modal.setAppElement('#root'); // this is important for screen readers

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: '',
//       password: '',
//       isLoggedIn: false,  // Track login status
//     };

//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   // Notification methods
//   notifySuccess = () => toast("Login successful!");
//   notifyError = () => toast("Login failed!");

//   // Handle form submission
//   handleSubmit(event) {
//     event.preventDefault();

//     const { name, password } = this.state;

//     const data = JSON.stringify({
//       "username": name,
//       "password": password
//     });

//     const axioConfig = {
//       method: 'post',
//       url: config.getAllURL + 'users/login',
//       headers: { 'Content-Type': 'application/json' },
//       data: data
//     };

//     axios.request(axioConfig)
//       .then(response => {
//         console.log("Login successful", response.data);
//         this.notifySuccess();

//         const token = response.data.token;

//         // Log the token to the console
//         console.log("######## token ######");
//         console.log("Token:", token);

//         // Save the token to localStorage
//         localStorage.setItem('token', token);

//         // Update login status in state
//         this.setState({ isLoggedIn: true });

//         // Close the modal after successful login
//         this.props.closeModal(); // Close the modal on success
//       })
//       .catch(error => {
//         console.error("Login failed", error);
//         this.notifyError();
//       });
//   }

//   // Handle input changes
//   handleChange(event) {
//     this.setState({
//       [event.target.name]: event.target.value
//     });
//   }

//   // Handle logout
//   handleLogout = () => {
//     localStorage.removeItem('token');
//     this.setState({ isLoggedIn: false }); // Update state to logged out
//   };

//   render() {
//     return (
//       <div>
//         {/* Modal for login */}
//         <Modal
//           isOpen={this.props.isModalOpen}  // Modal visibility controlled via props
//           onRequestClose={this.props.closeModal}  // Close modal when clicked outside
//           contentLabel="Login Modal"
//           style={{
//             overlay: {
//               backgroundColor: 'rgba(0, 0, 0, 0.75)',
//               zIndex: 9999,  // Make sure overlay is above everything
//             },
//             content: {
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)', // Center the modal
//               padding: '20px',
//               background: '#fff',
//               borderRadius: '4px',
//               width: '300px',
//               height: 'auto',
//               zIndex: 10000, // Ensure modal content is above overlay and other elements
//             }
//           }} // Custom styles to make the modal appear centered
//         >
//           <h2>Login</h2>
//           <form onSubmit={this.handleSubmit}>
//             <div>
//               <label htmlFor='name'>User</label>
//               <input
//                 name='name'
//                 value={this.state.name}
//                 onChange={this.handleChange}
//                 required
//               />
//             </div>
//             <div>
//               <label htmlFor='password'>Password</label>
//               <input
//                 name='password'
//                 type="password"
//                 value={this.state.password}
//                 onChange={this.handleChange}
//                 required
//               />
//             </div>
//             <div>
//               <button type="submit">Login</button>
//             </div>
//           </form>

//           {/* Close Modal Button */}
//           <button onClick={this.props.closeModal}>Close</button>

//           {/* Toast Notifications */}
//           <ToastContainer
//             position="top-right"
//             autoClose={5000}
//             hideProgressBar={false}
//             newestOnTop={false}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//             style={{ zIndex: 10002 }} // Make sure it's on top of the modal
//           />
//         </Modal>

//         {/* Conditional rendering of login/logout button based on login status */}
//         <div className="user-status">
//           {this.state.isLoggedIn ? (
//             <>
//               {/* User is logged in, show user icon */}
//               <span>Welcome, {this.state.name}!</span>
//               <button onClick={this.handleLogout}>Logout</button>
//             </>
//           ) : (
//             <>
//               {/* User is not logged in, show login icon */}
//               <span>Please log in</span>
//             </>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

// export default Login;


// ok v3 turn into modal
// import React, { Component } from 'react';
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Modal from 'react-modal';

// var config = require('../config');

// // Set up react-modal for accessibility
// Modal.setAppElement('#root'); // this is important for screen readers

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: '',
//       password: '',
//     };

//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   // Notification methods
//   notifySuccess = () => toast("Login successful!");
//   notifyError = () => toast("Login failed!");

//   // Handle form submission
//   handleSubmit(event) {
//     event.preventDefault();

//     const { name, password } = this.state;

//     const data = JSON.stringify({
//       "username": name,
//       "password": password
//     });

//     const axioConfig = {
//       method: 'post',
//       url: config.getAllURL + 'users/login',
//       headers: { 'Content-Type': 'application/json' },
//       data: data
//     };

//     axios.request(axioConfig)
//       .then(response => {
//         console.log("Login successful", response.data);
//         this.notifySuccess();

//         const token = response.data.token;

//         // Log the token to the console
//         console.log("######## token ######");
//         console.log("Token:", token);

//         // Save the token to localStorage
//         localStorage.setItem('token', token);

//         // Close the modal after successful login
//         this.props.closeModal(); // Close the modal on success
//       })
//       .catch(error => {
//         console.error("Login failed", error);
//         this.notifyError();
//       });
//   }

//   // Handle input changes
//   handleChange(event) {
//     this.setState({
//       [event.target.name]: event.target.value
//     });
//   }

//   render() {
//     return (
//       <div>
//         {/* Modal for login */}
//         <Modal
//           isOpen={this.props.isModalOpen}  // Modal visibility controlled via props
//           onRequestClose={this.props.closeModal}  // Close modal when clicked outside
//           contentLabel="Login Modal"
//           style={{
//             overlay: {
//               backgroundColor: 'rgba(0, 0, 0, 0.75)',
//               zIndex: 9999,  // Make sure overlay is above everything
//             },
//             content: {
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)', // Center the modal
//               padding: '20px',
//               background: '#fff',
//               borderRadius: '4px',
//               width: '300px',
//               height: 'auto',
//               zIndex: 10000, // Ensure modal content is above overlay and other elements
//             }
//           }} // Custom styles to make the modal appear centered
//         >
//           <h2>Login</h2>
//           <form onSubmit={this.handleSubmit}>
//             <div>
//               <label htmlFor='name'>User</label>
//               <input
//                 name='name'
//                 value={this.state.name}
//                 onChange={this.handleChange}
//                 required
//               />
//             </div>
//             <div>
//               <label htmlFor='password'>Password</label>
//               <input
//                 name='password'
//                 type="password"
//                 value={this.state.password}
//                 onChange={this.handleChange}
//                 required
//               />
//             </div>
//             <div>
//               <button type="submit">Login</button>
//             </div>
//           </form>

//           {/* Close Modal Button */}
//           <button onClick={this.props.closeModal}>Close</button>

//           {/* <ToastContainer /> */}
//           <ToastContainer
//             position="top-right"
//             autoClose={5000}
//             hideProgressBar={false}
//             newestOnTop={false}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//             style={{ zIndex: 10002 }} // Make sure it's on top of the modal
//           />
//         </Modal>
//       </div>
//     );
//   }
// }

// export default Login;


// ok v2 cleanup
// import React, { Component } from 'react';
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// var config = require('../config');

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { name: '', password: '', age: null, address: '', phoneNo: '' };

//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   // Notification methods
//   notifySuccess = () => toast("Login successful!");
//   notifyError = () => toast("Login failed!");

//   // Handle form submission
//   handleSubmit(event) {
//     event.preventDefault();

//     const { name, password } = this.state;

//     const data = JSON.stringify({
//       "username": name,
//       "password": password
//     });

//     const axioConfig = {
//       method: 'post',
//       url: config.getAllURL + 'users/login',
//       headers: { 'Content-Type': 'application/json' },
//       data: data
//     };

//     axios.request(axioConfig)
//       .then(response => {
//         console.log("Login successful", response.data);
//         this.notifySuccess();

//         const token = response.data.token;

//       // Log the token to the console
//       console.log("######## tokken ######");
//       console.log("Token:", token);

//       // Save the token to localStorage
//       localStorage.setItem('token', token);



//       })
//       .catch(error => {
//         console.error("Login failed", error);
//         this.notifyError();
//       });
//   }

//   // Handle input changes
//   handleChange(event) {
//     this.setState({
//       [event.target.name]: event.target.value
//     });
//   }

//   render() {
//     return (
//       <form onSubmit={this.handleSubmit}>
//         <div>
//           <label htmlFor='name'>User</label>
//           <input
//             name='name'
//             value={this.state.name}
//             onChange={this.handleChange}
//           />
//         </div>
//         <div>
//           <label htmlFor='password'>Password</label>
//           <input
//             name='password'
//             type="password"
//             value={this.state.password}
//             onChange={this.handleChange}
//           />
//         </div>
//         <div>
//           <button type="submit">Login</button>
//         </div>

//         <ToastContainer />
//       </form>
//     );
//   }
// }

// export default Login;



// v1 draft
// // https://www.geeksforgeeks.org/how-to-handle-multiple-input-field-in-react-form-with-a-single-function/

// import React,{ Component, useState } from 'react'
// import axios from "axios";

// //https://www.geeksforgeeks.org/how-to-add-push-notification-feature-in-reactjs/
// // npm i react-push-notification --force
// import { Notifications } from 'react-push-notification';
// import addNotification from 'react-push-notification';

// // https://openbase.com/js/react-toastify
// // npm i react-toastify --force
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// var config = require('../config');



// class Login extends Component{
//     constructor(props){
//       super(props)
//       // this.state = { seat:'',dish:'', age:null, address:'',phoneNo:''}
//       this.state = { name:'',password:'', age:null, address:'',phoneNo:''}

//       this.handleChange = this.handleChange.bind(this)
//       this.handleSubmit = this.handleSubmit.bind(this)
//     }

//     //https://stackoverflow.com/questions/64456890/how-to-display-toast-using-react-toastify-inside-class-component-when-props-chec
//     notify = () => toast('Wow so easy!');
//     notify2 = () => toast('Wow error so easy!');


//     // https://stackoverflow.com/questions/53809194/two-ways-to-define-methods-in-react-whats-the-different
//     // warningNotification = () =>{
//     //   addNotification({
//     //     title: 'Warning',
//     //     subtitle: 'Please fill it',
//     //     message: 'You have to enter name',
//     //     theme: 'red',
//     //     closeButton:"X",
//     //   })
//     // };

//     // successNotification= () =>  {
//     //   addNotification({
//     //     title: 'Success',
//     //     subtitle: 'You have successfully submitted',
//     //     message: 'Welcome to GeeksforGeeks',
//     //     theme: 'light',
//     //     closeButton:"X",
//     //     backgroundTop:"green",
//     //     backgroundBottom:"yellowgreen"
//     //   })
//     // };

//     // Form submitting logic, prevent default page refresh
//     handleSubmit2(event){
//       const { seat, dish, age, address, phoneNo } = this.state
//       event.preventDefault()
//       // alert(`
//       //   ____Your Order____\n
//       //   seat : ${seat}
//       //   dish : ${dish}
//       // `)
//       axios.put(config.getAllURL+"seats/orderfood/"+ seat.toLowerCase() + "/" + dish)
//       .then((response) => {
//         // setPost(response.data);
//             console.log("############ response data in handleSubmit #############")
//             console.log(response.data);
//             console.log("############ push noti #############")

//             if (response.data == "The seat is not empty"){
//               alert(`
//               ____Ghế đã có người____\n
//               seat : ${seat}
//               dish : ${dish}
//             `)
//             }
//             // successNotification()
//             // addNotification({
//             //   title: 'Success',
//             //   subtitle: 'You have successfully submitted',
//             //   message: 'Welcome to GeeksforGeeks',
//             //   theme: 'light',
//             //   closeButton:"X",
//             //   backgroundTop:"green",
//             //   backgroundBottom:"yellowgreen"
//             // })
//             else {
//             toast("Wow so easy!");
//             this.notify();
//             alert(`
//               ____Gọi món thành công____\n
//               seat : ${seat}
//               dish : ${dish}
//             `)    }


//           })
//       .catch(error => {
//         console.log("############ error response data in handleSubmit #############")
//             console.log(error.response.data.error)
//             // warningNotification();
//             console.log("############ push error noti #############")
//             // addNotification({
//             //   title: 'Warning',
//             //   subtitle: 'Please fill it',
//             //   message: 'You have to enter name',
//             //   theme: 'red',
//             //   closeButton:"X",
//             // })
//             toast("Wow error so easy!");
//             this.notify2();
//             alert(`
//               ____Ghế đã có người____\n
//               seat : ${seat}
//               dish : ${dish}
//             `)

//          });


//       // axios.put(getAllURL+"seats/orderfood/"+ seat + "/" + dish).then((response) => {
//       //   // setPost(response.data);
//       //       console.log("############ response data in handleSubmit  #############")
//       //       console.log(response.data);
//       //     });
//       // axios.put(getAllURL+"seats/orderfood/"+ "c2" + "/" + "new1").then((response) => {
//       //   // setPost(response.data);
//       //       console.log("############ response data in handleSubmit  #############")
//       //       console.log(response.data);
//       //     });
//     }
//   // Method causes to store all the values of the
//   // input field in react state single method handle
//   // input changes of all the input field using ES6
//   // javascript feature computed property names
//   handleChange(event){
//     this.setState({
//       // Computed property names
//       // keys of the objects are computed dynamically
//       [event.target.name] : event.target.value
//     })
//   }

//   handleSubmit(event){
//     // const { seat, dish, age, address, phoneNo } = this.state
//     const { name, password, age, address, phoneNo } = this.state

//     const axios = require('axios');
//     let data = JSON.stringify({
//       "username": name,
//       "password": password
//     });

//     let config = {
//       method: 'post',
//       maxBodyLength: Infinity,
//       url: config.getAllURL+'users/login',
//       headers: {
//         'Content-Type': 'application/json',
//         // 'Cookie': 'session-id=s%3A3ss0T4m2FBgehrixqXZYa-ulPG_oLT1I.bmR8ze%2FHME0ypcbOCdmIMwYh2y8NqgTu54QVSNeUBmI'
//       },
//       data : data
//     };

//     axios.request(config)
//     .then((response) => {
//       console.log("################ LoginClass.js ###############");
//       console.log(JSON.stringify(response.data));
//     })
//     .catch((error) => {
//       console.log("################ LoginClass.js ###############");
//       console.log(error);
//     });




//     //       this.state = { name:'',password:'', age:null, address:'',phoneNo:''}


//     //https://stackoverflow.com/questions/932653/how-to-prevent-buttons-from-submitting-forms
//     event.preventDefault()
//     // ref https://sebhastian.com/react-preventdefault/
//     // For example, a form’s submit button in React will always trigger a browser refresh to submit the data into a backend system.


//     axios.post(config.getAllURL+"seats/orderfood/"+ name + "/" + password).then((response) => {
//       // setPost(response.data);
//           console.log("############ response data in handleSubmit  #############")
//           console.log(response.data);
//         });

//     // console.log("############ user and pass summited   #############")

//  }

//   // Return a controlled form i.e. values of the
//   // input field not stored in DOM values are exist
//   // in react component itself as state
//   render(){
//     return(
//       <form onSubmit={this.handleSubmit}>
//         <div>
//           <label htmlFor='name'> User </label>
//           <input
//             name='name'
//             // placeholder=' ví dụ: 1a1, 1a2, ...'
//             value = {this.state.name}
//             onChange={this.handleChange}
//           />
//         </div>
//         <div>
//           <label htmlFor='password'>Password</label>
//           <input
//             name='password'
//             // placeholder= ' món ăn và yêu cầu'
//             value={this.state.password}
//             onChange={this.handleChange}
//           />
//         </div>
//         {/* this.state = { name:'',password:'', age:null, address:'',phoneNo:''} */}

//         {/* <div>
//           <label htmlFor='age'>Age</label>
//           <input
//             name='age'
//             placeholder='Age'
//             value={this.state.age}
//             onChange={this.handleChange}
//           />
//         </div>
//         <div>
//           <label htmlFor='address'>Address</label>
//           <input
//             name='address'
//             placeholder='Address'
//             value={this.state.address}
//             onChange={this.handleChange}
//           />
//         </div>
//         <div>
//           <label htmlFor='phoneNo'>Phone Number</label>
//           <input
//             name='phoneNo'
//             placeholder='Phone No'
//             value={this.state.phoneNo}
//             onChange={this.handleChange}
//           />
//         </div> */}
//         <div>
//           <button>Login</button>
//         </div>
//         <Notifications />
//       </form>
//     )
//   }
// }
// export default Login


// // https://stackoverflow.com/questions/23929432/submit-form-in-reactjs-using-button-element
// const Form = () => {

//     const [name, setName] = useState('');

//     const handleSubmit = (e) => {

//         e.preventDefault();

//         console.log(`Form submitted, ${name}`);

//     }

//     return(
//         <form onSubmit = {handleSubmit}>
//             <input onChange = {(e) => setName(e.target.value)} value = {name}></input>
//             <button type = 'submit'>Click to submit</button>
//         </form>
//     );

//   }