

// v10.4 test Subgrid, make it nicer
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// import GoogleMap from "./GoogleMap";
import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// Fix for default icon paths
import data from "./data";
import MarkerForm from "./MarkerForm";
import Upload from "./Upload";
// import MarkerForm from "./MarkerForm";
import Header from "./Header";
import { AppContainer } from "../App.styles2";
import TripFetcher from "./Trip";
import { Subgrid } from "./Subgrid";

import { Carousel } from '@mantine/carousel';
import { Button, Paper, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
// import classes from './CardsCarousel.module.css';
import { MantineProvider } from '@mantine/core';
// import { Button, Paper, Text, Title } from '@mantine/core';
import { Container, px, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import {  Card, Group, Image } from '@mantine/core';
import { IconStar } from '@tabler/icons-react';

import classes from './CarouselCard.module.css';
const theme = {
  // Customize the theme if required
  colorScheme: 'light', // Example: set light/dark theme
  };


const icon =
  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
const iconShadow =
  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
});

L.Marker.prototype.options.icon = DefaultIcon;

const center = {
  lat: 21.044175,
  lng: 105.846411,
};

// MinIO Configuration
const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
const BUCKET_NAME = "demo-map";
const FILE_NAME = "location.json";




function ArticleCardImage() {
  return (
    <Paper shadow="md" p="xl" radius="md" className={classes.card}>
      <div>
        <Text className={classes.category} size="xs">
          nature
        </Text>
        <Title order={3} className={classes.title}>
          Best forests to visit in North America
        </Title>
      </div>
      <Button variant="white" color="dark">
        Read article
      </Button>
    </Paper>
  );
}

function myCard({ image, title, category }) {
  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      style={{ backgroundImage: `url(${image})` }}
      className={classes.card}
    >
      <div>
        <Text className={classes.category} size="xs">
          {category}
        </Text>
        <Title order={3} className={classes.title}>
          {title}
        </Title>
      </div>
      <Button variant="white" color="dark">
        Read article
      </Button>
    </Paper>
  );
}

const testData = [
  {
    image:
      'https://images.unsplash.com/photo-1508193638397-1c4234db14d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Best forests to visit in North America',
    category: 'nature',
  },
  {
    image:
      'https://images.unsplash.com/photo-1559494007-9f5847c49d94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Hawaii beaches review: better than you think',
    category: 'beach',
  },
  {
    image:
      'https://images.unsplash.com/photo-1608481337062-4093bf3ed404?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Mountains at night: 12 best locations to enjoy the view',
    category: 'nature',
  },
  {
    image:
      'https://images.unsplash.com/photo-1507272931001-fc06c17e4f43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Aurora in Norway: when to visit for best experience',
    category: 'nature',
  },
  {
    image:
      'https://images.unsplash.com/photo-1510798831971-661eb04b3739?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Best places to visit this winter',
    category: 'tourism',
  },
  {
    image:
      'https://images.unsplash.com/photo-1582721478779-0ae163c05a60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Active volcanos reviews: travel at your own risk',
    category: 'nature',
  },
];

const images = [
  'https://images.unsplash.com/photo-1598928506311-c55ded91a20c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80',
  'https://images.unsplash.com/photo-1567767292278-a4f21aa2d36e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80',
  'https://images.unsplash.com/photo-1605774337664-7a846e9cdf17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80',
  'https://images.unsplash.com/photo-1554995207-c18c203602cb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80',
  'https://images.unsplash.com/photo-1616486029423-aaa4789e8c9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80',
];
function CarouselCard() {
  const slides = images.map((image) => (
    <Carousel.Slide key={image}>
      <Image src={image} height={220} />
    </Carousel.Slide>
  ));

  return (
    <Card radius="md" withBorder padding="xl">
      <Card.Section>
        <Carousel
          withIndicators
          loop
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
            indicator: classes.carouselIndicator,
          }}
        >
          {slides}
        </Carousel>
      </Card.Section>

      <Group justify="space-between" mt="lg">
        <Text fw={500} fz="lg">
          Forde, Norway
        </Text>

        <Group gap={5}>
          <IconStar size={16} />
          <Text fz="xs" fw={500}>
            4.78
          </Text>
        </Group>
      </Group>

      <Text fz="sm" c="dimmed" mt="sm">
        Relax, rejuvenate and unplug in this unique contemporary Birdbox. Feel close to nature in
        ultimate comfort. Enjoy the view of the epic mountain range of Blegja and the Førdefjord.
      </Text>

      <Group justify="space-between" mt="md">
        <div>
          <Text fz="xl" span fw={500} className={classes.price}>
            397$
          </Text>
          <Text span fz="sm" c="dimmed">
            {' '}
            / night
          </Text>
        </div>

        <Button radius="md">Book now</Button>
      </Group>
    </Card>
  );
}

function CardsCarousel() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const slides = testData.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      slideSize={{ base: '100%', sm: '50%' }}
      slideGap={{ base: 2, sm: 'xl' }}
      align="start"
      slidesToScroll={mobile ? 1 : 2}
    >
      {slides}
    </Carousel>
  );
}


const DraggableMarkerOld = ({ position, setPosition }) => {
  const markerRef = useRef(null);

  useMapEvents({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        setPosition(marker.getLatLng());
      }
    },
  });

  return <Marker draggable position={position} ref={markerRef} />;
};

const DraggableMarkerOld2 = ({ position, setPosition }) => {
  const markerRef = useRef(null);
  const [currentCoords, setCurrentCoords] = useState(position);

  useMapEvents({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        const newPosition = marker.getLatLng();
        setPosition(newPosition);
        setCurrentCoords(newPosition); // Update the current coordinates

        // Log current location
        // console.log("####### current ##########");
        // console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
      }
    },
  });

  return (
    <Marker draggable position={position} ref={markerRef}>
      <Popup>
        Latitude: {currentCoords.lat}<br />
        Longitude: {currentCoords.lng}
      </Popup>
    </Marker>
  );
};

const DraggableMarker = ({ position, setPosition }) => {
  const markerRef = useRef(null);

  useMapEvents({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        const newPosition = marker.getLatLng();
        setPosition(newPosition);
        console.log("Marker dragged to: ", newPosition); // Log the new position
      }
    },
  });

  return (
    <Marker
      draggable
      position={position}
      ref={markerRef}
      eventHandlers={{
        dragend: () => {
          const marker = markerRef.current;
          if (marker) {
            const newPosition = marker.getLatLng();
            setPosition(newPosition);
            console.log("Marker dragged to: ", newPosition); // Log the new position
            console.log(marker)
            console.log("###### marker ref ###")
            console.log(markerRef)

          }
        },
      }}
    >
      <Popup>
        Latitude: {position.lat}<br />
        Longitude: {position.lng}
      </Popup>
    </Marker>
  );
};

const SubMapComponent = () => {
  const [markerPosition, setMarkerPosition] = useState(center);
  const [notes, setNotes] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const mapRef = useRef(null);

  // Update the map view whenever the marker position changes
  useEffect(() => {
    console.log("###### MapComp potition update ########")
    console.log(markerPosition)
    console.log("###### MapComp useEffect ########")
    console.log(mapRef.current)
    if (mapRef.current) {
      mapRef.current.setView(markerPosition, 13); // Update map view to new position
    }
  }, [markerPosition]);

  // Move the marker to the current geolocation
  const moveMarkerToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
        const newLocation = { lat: latitude, lng: longitude };
        setMarkerPosition(newLocation); // Update marker position
        if (mapRef.current) {
          mapRef.current.setView(newLocation, 13); // Center the map view
        }
      },
      (error) => {
        console.error("Geolocation Error:", error);
        alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
      }
    );
  };

  // Handle file input changes
  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  // Upload file to the MinIO bucket
  const uploadFile = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `https://obj.tang17so82duytan.com/demo-map/${type}/${file.name}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return `https://obj.tang17so82duytan.com/demo-map/${type}/${file.name}`;
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
      throw error;
    }
  };

  // Save the current location and other data
  const saveLocation = async () => {
    try {
      let existingData = { locations: [] };

      try {
        const response = await axios.get(
          "https://obj.tang17so82duytan.com/demo-map/location.json"
        );
        existingData = response.data;
      } catch (fetchError) {
        if (fetchError.response && fetchError.response.status === 404) {
          console.warn("File does not exist. Creating new file with empty data.");
        } else {
          throw fetchError;
        }
      }

      let locations = existingData.locations || [];

      const roundCoordinates = (coord) => ({
        lat: Math.round(coord.lat * 1e6) / 1e6,
        lng: Math.round(coord.lng * 1e6) / 1e6,
      });

      console.log("Current locations:", locations);

      const isDuplicate = locations.some((loc) => {
        const locCoords = loc.position || {};
        const markerCoords = markerPosition || {};
        if (
          !locCoords.lat ||
          !locCoords.lng ||
          !markerCoords.lat ||
          !markerCoords.lng
        ) {
          console.error("Invalid coordinates found. Skipping duplicate check.");
          return false;
        }
        return (
          roundCoordinates(locCoords).lat ===
            roundCoordinates(markerCoords).lat &&
          roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
        );
      });

      if (!isDuplicate) {
        const locationData = {
          position: markerPosition,
          notes: notes,
          imageUrl: null,
          videoUrl: null,
        };

        if (image) {
          try {
            locationData.imageUrl = await uploadFile(image, "images");
          } catch (uploadError) {
            console.error("Error uploading image:", uploadError);
          }
        }

        if (video) {
          try {
            locationData.videoUrl = await uploadFile(video, "videos");
          } catch (uploadError) {
            console.error("Error uploading video:", uploadError);
          }
        }

        locations.push(locationData);

        const updatedData = JSON.stringify({ locations });

        try {
          await axios.put(
            "https://obj.tang17so82duytan.com/demo-map/location.json",
            updatedData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          console.log("Location saved successfully:", updatedData);
          alert("Location saved successfully!");
        } catch (uploadError) {
          console.error("Error updating JSON file:", uploadError);
          alert("Error updating location data.");
        }
      } else {
        alert("This location has already been saved.");
      }
    } catch (error) {
      console.error("Error saving location:", error);
      alert("Error saving location.");
    }
  };

  const buttonStyle = {
    backgroundColor: '#007bff',  // Button color
    color: 'white',  // Text color
    padding: '12px 20px',  // Padding
    fontSize: '16px',  // Font size
    fontWeight: '600',  // Bold text
    borderRadius: '8px',  // Rounded corners
    border: 'none',  // No border
    cursor: 'pointer',  // Pointer cursor on hover
    transition: 'background-color 0.3s ease, transform 0.2s ease',  // Smooth transitions
    boxShadow: '0 4px 10px rgba(0, 123, 255, 0.3)',  // Subtle shadow

  };


  return (
    <div>
      <AppContainer>
        <Header />
        <MapContainer
          center={markerPosition}
          zoom={13}
          style={{ height: "400px", width: "100%" }}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance; // Initialize map reference
          }}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          <DraggableMarker position={markerPosition} setPosition={setMarkerPosition} />
        </MapContainer>

        <div>
          <button onClick={moveMarkerToCurrentLocation}
                style={buttonStyle}
          >
            Di chuyển đến vị trí hiện tại
          </button>
          {/* <button onClick={saveLocation}>
            Save Final Marker Location
          </button> */}
        </div>

        <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />

      </AppContainer>
      {/* <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <CarouselCard/>
      </MantineProvider> */}

<TripFetcher/>
    </div>
  );
};

const Home2 = () => {
  return (
    <div>
      <h2>Welcome to the Map Application!</h2>
      <p>This application allows you to find and save locations on a map.</p>
      <p>Navigate to the "Add Marker" page to start adding your locations!</p>
    </div>
  );
};

const MapComponent = () => {
  // const data = await response.json();

  const [properties, setProperties] = useState(data.properties);
  const [DefaultCenter] = useState(data.properties[0]);
  const [activeProperty, setActiveProperty] = useState(data.properties[0]);
  return (
    <SubMapComponent />
    // <BrowserRouter>
    //   <Routes>
    //     {/* fix GG component */}
    //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
    //     <Route path="/current" element={<SubMapComponent />} />
    //   </Routes>
    // </BrowserRouter>
  );
};
export default MapComponent;




// ok v10.3 add trip ui
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";
// // import MarkerForm from "./MarkerForm";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// import TripFetcher from "./Trip";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarkerOld = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const DraggableMarkerOld2 = ({ position, setPosition }) => {
//   const markerRef = useRef(null);
//   const [currentCoords, setCurrentCoords] = useState(position);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         setCurrentCoords(newPosition); // Update the current coordinates

//         // Log current location
//         // console.log("####### current ##########");
//         // console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
//       }
//     },
//   });

//   return (
//     <Marker draggable position={position} ref={markerRef}>
//       <Popup>
//         Latitude: {currentCoords.lat}<br />
//         Longitude: {currentCoords.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         console.log("Marker dragged to: ", newPosition); // Log the new position
//       }
//     },
//   });

//   return (
//     <Marker
//       draggable
//       position={position}
//       ref={markerRef}
//       eventHandlers={{
//         dragend: () => {
//           const marker = markerRef.current;
//           if (marker) {
//             const newPosition = marker.getLatLng();
//             setPosition(newPosition);
//             console.log("Marker dragged to: ", newPosition); // Log the new position
//             console.log(marker)
//             console.log("###### marker ref ###")
//             console.log(markerRef)

//           }
//         },
//       }}
//     >
//       <Popup>
//         Latitude: {position.lat}<br />
//         Longitude: {position.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   // Update the map view whenever the marker position changes
//   useEffect(() => {
//     console.log("###### MapComp potition update ########")
//     console.log(markerPosition)
//     console.log("###### MapComp useEffect ########")
//     console.log(mapRef.current)
//     if (mapRef.current) {
//       mapRef.current.setView(markerPosition, 13); // Update map view to new position
//     }
//   }, [markerPosition]);

//   // Move the marker to the current geolocation
//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view
//         }
//       },
//       (error) => {
//         console.error("Geolocation Error:", error);
//         alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
//       }
//     );
//   };

//   // Handle file input changes
//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   // Upload file to the MinIO bucket
//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `https://obj.tang17so82duytan.com/demo-map/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `https://obj.tang17so82duytan.com/demo-map/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   // Save the current location and other data
//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           "https://obj.tang17so82duytan.com/demo-map/location.json"
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn("File does not exist. Creating new file with empty data.");
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (!isDuplicate) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             "https://obj.tang17so82duytan.com/demo-map/location.json",
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <AppContainer>
//         <Header />
//         <MapContainer
//           center={markerPosition}
//           zoom={13}
//           style={{ height: "400px", width: "100%" }}
//           whenCreated={(mapInstance) => {
//             mapRef.current = mapInstance; // Initialize map reference
//           }}
//           ref={mapRef}
//         >
//           <TileLayer
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           />

//           <DraggableMarker position={markerPosition} setPosition={setMarkerPosition} />
//         </MapContainer>

//         <div>
//           <button onClick={moveMarkerToCurrentLocation}>
//             Di chuyển đến vị trí hiện tại
//           </button>
//           {/* <button onClick={saveLocation}>
//             Save Final Marker Location
//           </button> */}
//         </div>

//         <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />

//       </AppContainer>

// <TripFetcher/>
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;


// ok v10.2 (if the has prob later, rollback to 10.1 and add the mapRef to the map) fix auto recenter
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";
// // import MarkerForm from "./MarkerForm";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarkerOld = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const DraggableMarkerOld2 = ({ position, setPosition }) => {
//   const markerRef = useRef(null);
//   const [currentCoords, setCurrentCoords] = useState(position);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         setCurrentCoords(newPosition); // Update the current coordinates

//         // Log current location
//         // console.log("####### current ##########");
//         // console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
//       }
//     },
//   });

//   return (
//     <Marker draggable position={position} ref={markerRef}>
//       <Popup>
//         Latitude: {currentCoords.lat}<br />
//         Longitude: {currentCoords.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         console.log("Marker dragged to: ", newPosition); // Log the new position
//       }
//     },
//   });

//   return (
//     <Marker
//       draggable
//       position={position}
//       ref={markerRef}
//       eventHandlers={{
//         dragend: () => {
//           const marker = markerRef.current;
//           if (marker) {
//             const newPosition = marker.getLatLng();
//             setPosition(newPosition);
//             console.log("Marker dragged to: ", newPosition); // Log the new position
//             console.log(marker)
//             console.log("###### marker ref ###")
//             console.log(markerRef)

//           }
//         },
//       }}
//     >
//       <Popup>
//         Latitude: {position.lat}<br />
//         Longitude: {position.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   // Update the map view whenever the marker position changes
//   useEffect(() => {
//     console.log("###### MapComp potition update ########")
//     console.log(markerPosition)
//     console.log("###### MapComp useEffect ########")
//     console.log(mapRef.current)
//     if (mapRef.current) {
//       mapRef.current.setView(markerPosition, 13); // Update map view to new position
//     }
//   }, [markerPosition]);

//   // Move the marker to the current geolocation
//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view
//         }
//       },
//       (error) => {
//         console.error("Geolocation Error:", error);
//         alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
//       }
//     );
//   };

//   // Handle file input changes
//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   // Upload file to the MinIO bucket
//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `https://obj.tang17so82duytan.com/demo-map/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `https://obj.tang17so82duytan.com/demo-map/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   // Save the current location and other data
//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           "https://obj.tang17so82duytan.com/demo-map/location.json"
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn("File does not exist. Creating new file with empty data.");
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (!isDuplicate) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             "https://obj.tang17so82duytan.com/demo-map/location.json",
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <AppContainer>
//         <Header />
//         <MapContainer
//           center={markerPosition}
//           zoom={13}
//           style={{ height: "400px", width: "100%" }}
//           whenCreated={(mapInstance) => {
//             mapRef.current = mapInstance; // Initialize map reference
//           }}
//           ref={mapRef}
//         >
//           <TileLayer
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           />

//           <DraggableMarker position={markerPosition} setPosition={setMarkerPosition} />
//         </MapContainer>

//         <div>
//           <button onClick={moveMarkerToCurrentLocation}>
//             Di chuyển đến vị trí hiện tại
//           </button>
//           {/* <button onClick={saveLocation}>
//             Save Final Marker Location
//           </button> */}
//         </div>

//         <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />

//       </AppContainer>
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;



// ok v10.1 add header
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";
// // import MarkerForm from "./MarkerForm";
// import Header from "./Header";
// import { AppContainer } from "../App.styles2";
// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarkerOld = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const DraggableMarkerOld2 = ({ position, setPosition }) => {
//   const markerRef = useRef(null);
//   const [currentCoords, setCurrentCoords] = useState(position);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         setCurrentCoords(newPosition); // Update the current coordinates

//         // Log current location
//         console.log("####### current ##########");
//         console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
//       }
//     },
//   });

//   return (
//     <Marker draggable position={position} ref={markerRef}>
//       <Popup>
//         Latitude: {currentCoords.lat}<br />
//         Longitude: {currentCoords.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         console.log("Marker dragged to: ", newPosition); // Log the new position
//       }
//     },
//   });

//   return (
//     <Marker
//       draggable
//       position={position}
//       ref={markerRef}
//       eventHandlers={{
//         dragend: () => {
//           const marker = markerRef.current;
//           if (marker) {
//             const newPosition = marker.getLatLng();
//             setPosition(newPosition);
//             console.log("Marker dragged to: ", newPosition); // Log the new position
//           }
//         },
//       }}
//     >
//       <Popup>
//         Latitude: {position.lat}<br />
//         Longitude: {position.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       (error) => {
//         console.error("Geolocation Error:", error);
//         alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
//       }
//     );
//   };

//   const V1moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("########### cord #######");
//         console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };



//   const headerStyle = { display: 'flex', textAlign: 'center', flexDirection: 'row', padding: '5px 15px', position: 'relative', position: 'fixed', backgroundColor: '#e0e0e0', borderRadius: '6px', zIndex: 2, boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',};
//   return (
//     <div>
//       <AppContainer>
//       <Header/>
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >

//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {/* 7/10 kind of ok, old <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         /> */}
//         {/* 7/10 too fade <TileLayer
//           url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         /> */}

//         {/* 6/10 <TileLayer
//           url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.opentopomap.org/copyright">OpenTopoMap</a>'
//         /> */}
//         {/* 4/10 too dark <TileLayer
//           url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://carto.com/attributions">CartoDB</a>'
//         /> */}
//         {/* <TileLayer
//           url="https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=YOUR_API_KEY"
//           attribution='&copy; <a href="https://www.thunderforest.com/">Thunderforest</a>'
//         /> */}

//         {/* 7/10 satelite <TileLayer
//           url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
//           attribution='&copy; <a href="https://www.esri.com">Esri</a>'
//         /> */}


//         {/*  7/10 fade but looks mordern,

//         <TileLayer
//           url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://carto.com/attributions">CartoDB</a>'
//         /> */}



//         {/* 6/10 far is ok, zoom in is ugly <TileLayer
//           url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://www.cyclosm.org">CyclOSM</a>'
//         /> */}

//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         {/* <button onClick={saveLocation}>Save Final Marker Location</button> */}
//       </div>
//       {/* <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div> */}
//       {/* <MarkerForm/> */}
//       <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />
//       {/* <Upload/> */}

//       </AppContainer>
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;




// ~v10 make it nicer
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";
// // import MarkerForm from "./MarkerForm";
// import Header from "./Header";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarkerOld = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const DraggableMarkerOld2 = ({ position, setPosition }) => {
//   const markerRef = useRef(null);
//   const [currentCoords, setCurrentCoords] = useState(position);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         setCurrentCoords(newPosition); // Update the current coordinates

//         // Log current location
//         console.log("####### current ##########");
//         console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
//       }
//     },
//   });

//   return (
//     <Marker draggable position={position} ref={markerRef}>
//       <Popup>
//         Latitude: {currentCoords.lat}<br />
//         Longitude: {currentCoords.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         console.log("Marker dragged to: ", newPosition); // Log the new position
//       }
//     },
//   });

//   return (
//     <Marker
//       draggable
//       position={position}
//       ref={markerRef}
//       eventHandlers={{
//         dragend: () => {
//           const marker = markerRef.current;
//           if (marker) {
//             const newPosition = marker.getLatLng();
//             setPosition(newPosition);
//             console.log("Marker dragged to: ", newPosition); // Log the new position
//           }
//         },
//       }}
//     >
//       <Popup>
//         Latitude: {position.lat}<br />
//         Longitude: {position.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       (error) => {
//         console.error("Geolocation Error:", error);
//         alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
//       }
//     );
//   };

//   const V1moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("########### cord #######");
//         console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       {/* <Header/> */}
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         {/* <button onClick={saveLocation}>Save Final Marker Location</button> */}
//       </div>
//       {/* <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div> */}
//       {/* <MarkerForm/> */}
//       <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />
//       {/* <Upload/> */}
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;



// ok v9 combine with MarkerForm.js

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";
// // import MarkerForm from "./MarkerForm";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarkerOld = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const DraggableMarkerOld2 = ({ position, setPosition }) => {
//   const markerRef = useRef(null);
//   const [currentCoords, setCurrentCoords] = useState(position);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         setCurrentCoords(newPosition); // Update the current coordinates

//         // Log current location
//         console.log("####### current ##########");
//         console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
//       }
//     },
//   });

//   return (
//     <Marker draggable position={position} ref={markerRef}>
//       <Popup>
//         Latitude: {currentCoords.lat}<br />
//         Longitude: {currentCoords.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         console.log("Marker dragged to: ", newPosition); // Log the new position
//       }
//     },
//   });

//   return (
//     <Marker
//       draggable
//       position={position}
//       ref={markerRef}
//       eventHandlers={{
//         dragend: () => {
//           const marker = markerRef.current;
//           if (marker) {
//             const newPosition = marker.getLatLng();
//             setPosition(newPosition);
//             console.log("Marker dragged to: ", newPosition); // Log the new position
//           }
//         },
//       }}
//     >
//       <Popup>
//         Latitude: {position.lat}<br />
//         Longitude: {position.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       (error) => {
//         console.error("Geolocation Error:", error);
//         alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
//       }
//     );
//   };

//   const V1moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("########### cord #######");
//         console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         {/* <button onClick={saveLocation}>Save Final Marker Location</button> */}
//       </div>
//       {/* <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div> */}
//       {/* <MarkerForm/> */}
//       <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />
//       {/* <Upload/> */}
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;



// // v8-> clone from v7 (note oK, img, vid, dup, recenter not ok) add note, img, fix duplicate -> looks like it is saving current location not the pin location; fix this

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("########### cord #######");
//         console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         <button onClick={saveLocation}>Save Final Marker Location</button>
//       </div>
//       <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div>
//       <MarkerForm/>
//       <Upload/>
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;




// ~v7(note oK, img, vid, dup, recenter not ok) add note, img, fix duplicate -> looks like it is saving current location not the pin location; fix this
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         <button onClick={saveLocation}>Save Final Marker Location</button>
//       </div>
//       <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div>
//       <MarkerForm/>
//       <Upload/>
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;

// OK v6 (still not auto move, write minio OK ) fix auto move map, write to minio
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// // Fix for default icon paths
// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);
//   const [markerPosition, setMarkerPosition] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setLocation(newLocation);
//         setMarkerPosition(newLocation); // Place marker at current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   }, []);
//   const saveLocation = async () => {
//     try {
//       // Initialize with an empty locations array
//       let existingData = { locations: [] };

//       try {
//         // Attempt to fetch the existing JSON file from MinIO
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           // File does not exist, initialize with empty data
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           // Re-throw any other errors
//           throw fetchError;
//         }
//       }

//       // Parse the existing JSON data
//       let locations = existingData.locations || [];

//       // Log the current locations before appending
//       console.log("Current locations:", locations);

//       // Append the new location
//       locations.push(markerPosition);

//       // Log the new location being added
//       console.log("New location to be added:", markerPosition);

//       // Update the JSON data
//       const updatedData = JSON.stringify({ locations });

//       // Upload the updated JSON file to MinIO
//       await axios.put(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//         updatedData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       // Log successful save
//       console.log("Location saved successfully:", updatedData);

//       alert("Location saved successfully!");
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   const placeMarkerAtCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation);
//         setLocation(newLocation); // Update the location state
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {markerPosition && (
//           <DraggableMarker
//             position={markerPosition}
//             setPosition={setMarkerPosition}
//           />
//         )}
//       </MapContainer>
//       <button onClick={placeMarkerAtCurrentLocation}>Current Location</button>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

//OK v5 fix marker icon
// import React, { useState, useEffect, useRef } from "react";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// // Fix for default icon paths
// // import icon from "leaflet/dist/images/marker-icon.png";
// // import iconShadow from "leaflet/dist/images/marker-shadow.png";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);
//   const [markerPosition, setMarkerPosition] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setLocation(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   }, []);

//   const saveLocation = () => {
//     const locationData = JSON.stringify(markerPosition);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   const placeMarkerAtCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {markerPosition && (
//           <DraggableMarker
//             position={markerPosition}
//             setPosition={setMarkerPosition}
//           />
//         )}
//       </MapContainer>
//       <button onClick={placeMarkerAtCurrentLocation}>Current Location</button>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

// (OK, but the map does not move to current location )v4 fix current location
// import React, { useState, useEffect, useRef } from "react";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);
//   const [markerPosition, setMarkerPosition] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setLocation(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   }, []);

//   const saveLocation = () => {
//     const locationData = JSON.stringify(markerPosition);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   const placeMarkerAtCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {markerPosition && (
//           <DraggableMarker
//             position={markerPosition}
//             setPosition={setMarkerPosition}
//           />
//         )}
//       </MapContainer>
//       <button onClick={placeMarkerAtCurrentLocation}>Current Location</button>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

// OK (can save location, current location does not work, marker icon prob) v3 without api key
// import React, { useState, useEffect } from "react";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = React.useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setLocation({ lat: latitude, lng: longitude });
//       },
//       () => null
//     );
//   }, []);

//   const saveLocation = () => {
//     const locationData = JSON.stringify(location);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker position={location} setPosition={setLocation} />
//       </MapContainer>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

//OK v2 but need api key
// import React, { useState, useCallback } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// const containerStyle = {
//   width: "100%",
//   height: "400px",
// };

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);

//   const onLoad = useCallback((map) => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setLocation({ lat: latitude, lng: longitude });
//         map.panTo({ lat: latitude, lng: longitude });
//       },
//       () => null
//     );
//   }, []);

//   const onMarkerDragEnd = (e) => {
//     const lat = e.latLng.lat();
//     const lng = e.latLng.lng();
//     setLocation({ lat, lng });
//   };

//   const saveLocation = () => {
//     const locationData = JSON.stringify(location);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   return (
//     <LoadScript googleMapsApiKey="AIzaSyApo7SZ0cD2Gl3y35Z52qcg4aUvpCMActA">
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={location}
//         zoom={10}
//         onLoad={onLoad}
//       >
//         <Marker
//           position={location}
//           draggable={true}
//           onDragEnd={onMarkerDragEnd}
//         />
//       </GoogleMap>
//       <button onClick={saveLocation}>Save Location</button>
//     </LoadScript>
//   );
// };

// export default MapComponent;

// v1 failed
// // src/MapComponent.js
// import React, { useState, useEffect } from "react";
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   Popup,
//   useMapEvents,
// } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// import { saveAs } from "file-saver";

// // Custom hook to handle map events
// function LocationMarker({ onLocationChange }) {
//   useMapEvents({
//     locationfound(e) {
//       onLocationChange(e.latlng);
//     },
//   });
//   return null;
// }

// const MapComponent = () => {
//   const [position, setPosition] = useState(null);

//   useEffect(() => {
//     const fetchLocation = () => {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setPosition([position.coords.latitude, position.coords.longitude]);
//         },
//         (error) => {
//           console.error("Error getting location:", error);
//         }
//       );
//     };

//     fetchLocation();
//   }, []);

//   const handleMarkerDragEnd = (e) => {
//     setPosition(e.latlng);
//   };

//   const handleSaveLocation = () => {
//     if (position) {
//       const blob = new Blob(
//         [JSON.stringify({ lat: position.lat, lng: position.lng })],
//         { type: "application/json" }
//       );
//       saveAs(blob, "location.json");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={position || [51.505, -0.09]} // Default center if position is null
//         zoom={13}
//         style={{ height: "600px", width: "100%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {position && (
//           <Marker
//             position={position}
//             draggable={true}
//             onDragend={handleMarkerDragEnd}
//           >
//             <Popup>
//               <span>Your Location</span>
//             </Popup>
//           </Marker>
//         )}
//         <LocationMarker onLocationChange={setPosition} />
//       </MapContainer>
//       <button onClick={handleSaveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;
