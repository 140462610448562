
// ok v2 -> this file is ok but still cannot reuse instance correctly ( no drag, 1/2 size, gotta fix this on the App.js too )
// MapInstance.js
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';

// Create a Map context to provide the map instance
const MapContext = createContext(null);

// Initialize ReactMapboxGl instance (directly within the Map component)
const MapInstance = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA',
});

// MapProvider component: This component will provide the map instance
export const MapProvider = ({ children }) => {
  const [mapInstance, setMapInstance] = useState(null);

  const onMapLoad = (map) => {
    console.log('Map Loaded:', map);
    setMapInstance(map); // Store the Map instance when loaded
  };

  return (
    <MapContext.Provider value={mapInstance}>
      <MapInstance
        style="mapbox://styles/nhan131998/cm5e0e8o100fp01rg8n9a0ws6"
        containerStyle={{ height: '100%', width: '100%' }}
        center={[105.8000, 21.0381]} // Center the map
        zoom={[12]} // Zoom level
        onLoad={onMapLoad} // Handle map loading
      >
        {children}
      </MapInstance>
    </MapContext.Provider>
  );
};

// Custom hook to use the map
export const useMap = () => {
  return useContext(MapContext);
};



// failed v1 // MapInstance.js
// import React, { useEffect, useRef } from 'react';
// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
// import Pin from './Pin';

// const MapInstance = ({ showMarker }) => {
//   const mapContainerRef = useRef();
//   const mapRef = useRef();

//   useEffect(() => {
//     mapboxgl.accessToken = 'pk.eyJ1IjoibmhhbjEzMTk5OCIsImEiOiJjbTU5cjEwcHEwdTl5Mm1vOWg0ajB1aXF4In0.c07c-9Q_wzmye4OkxDA3sA';

//     // Initialize map
//     mapRef.current = new mapboxgl.Map({
//       container: mapContainerRef.current,
//       style:"mapbox://styles/nhan131998/cm5e0e8o100fp01rg8n9a0ws6",
//       center: [ 105.8000, 21.0381 ],
//       zoom: 12,
//       // pitch=[50],// Set the default tilt (pitch) angle (45 degrees)
//       // // dragRotate={false} // Disable rotation (dragRotate set to false)
//       // // touchZoomRotate={false} // Disable zoom/rotate with touch gestures
//       // scrollZoom=true, // Enable zoom with scroll
//       // // doubleClickZoom={false} // Disable double click to zoom
//       // bearing=[60]
//     });

//     // Cleanup on component unmount
//     return () => {
//       mapRef.current.remove();
//     };
//   }, []);

//   return (
//     <div id="map" ref={mapContainerRef} style={{ height: '100%' }}>
//       {showMarker && <Pin mapRef={mapRef} />}
//     </div>
//   );
// };

// export default MapInstance;


