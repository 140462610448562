import { Container, Grid, Skeleton } from '@mantine/core';
import { MantineProvider } from '@mantine/core';



const child = <Skeleton height={140} radius="md" animate={false} />;

const theme = {
  // Customize the theme if required
  colorScheme: 'light', // Example: set light/dark theme
  };

export function GridAsymmetrical() {
  return (

    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
    <Container my="md">
      <Grid>
        <Grid.Col span={{ base: 12, xs: 4 }}>{child}</Grid.Col>
        <Grid.Col span={{ base: 12, xs: 8 }}>{child}</Grid.Col>
        <Grid.Col span={{ base: 12, xs: 8 }}>{child}</Grid.Col>
        <Grid.Col span={{ base: 12, xs: 4 }}>{child}</Grid.Col>
        <Grid.Col span={{ base: 12, xs: 3 }}>{child}</Grid.Col>
        <Grid.Col span={{ base: 12, xs: 3 }}>{child}</Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6 }}>{child}</Grid.Col>
      </Grid>
    </Container>
    </MantineProvider>

  );
}

